<div class="container mt-5">
    <h2>Week 1 Review</h2>
    <div class="mt-4" *ngFor="let question of questions">
        <div class="d-flex">
            <div *ngIf="showResults" class="mr-2">
                <div *ngIf="question.gotCorrect"><img src="https://img.icons8.com/color/24/000000/checked-2.png"/></div>
                <div *ngIf="!question.gotCorrect"><img src="https://img.icons8.com/color/24/000000/break--v4.png"/></div>
            </div>
            <h4>{{question.question}}</h4>
        </div>
        <div class="mb-2" *ngIf="showResults && !question.gotCorrect">
            {{question.followUpMsg}}
        </div>
      
        <div class="d-flex">
            <div *ngFor="let option of question.options">
                <div class="option" [ngClass]="{'selectedAnswer': option.selected}" (click)="toggleAsAnswer(question, option)">
                    {{option.potentialAnswer}}</div>
            </div>
        </div>
    </div>
    <div class="mt-5">
        <button mat-raised-button *ngIf="!showResults" [disabled]="!hasAnsweredAllQuestions()" (click)="checkAnswers()">Check Answers</button>
    </div>
        <div *ngIf="showResults">
            <h5>Result: {{result | number}}%</h5>
            <div class="mt-3" *ngIf="result === 100">
                <button mat-raised-button>Congrats! Click here to get started on Week Two</button>
            </div>
            <div *ngIf="result < 100">
                <div class="mb-3">Hard Luck! Please review the related material and check your answers again.</div>
                <button mat-raised-button (click)="checkAnswers()" [disabled]="!hasAnsweredAllQuestions()">Check Answers Again</button>
            </div>
        </div>
</div>