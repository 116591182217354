import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-standalone-splashscreen-builder',
  templateUrl: './standalone-splashscreen-builder.component.html',
  styleUrls: ['./standalone-splashscreen-builder.component.sass', '../standalone-builder-parent/screen.css']
})
export class StandaloneSplashscreenBuilderComponent implements OnInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output()
  splashScreenSubmittedSucessfully: EventEmitter<StandAloneApp> = new EventEmitter();


  splashColor;

  initMouseX;
  initMouseY;

  finalMouseX;
  finalMouseY;

  prevEvent : MouseEvent;

  _f;
  
  public set OX(v : any) {
    console.log('fjdksfdsl', v);
    this._f = v;
  }
  

  OY: number;


  logoWidth = 150;

  public imagePath;
  imgURL: any;
  public message: string;

  logoFile: File;
  logoURL: string;

  constructor(private staService: StandaloneAppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
   if (this.standaloneApp.splashScreenHTMLCreated) {
    document.getElementById('phoneWrapper').innerHTML = this.standaloneApp.splashScreenHTML;
    document.getElementById('phone').addEventListener('dragover', this.onDragOver);
    document.getElementById('logo').addEventListener('dragstart', (event) => {
      this.OX = event.clientX;
      this.OY = event.clientY;
      console.log('drag start:  ', this.OX, this.OY)
  
      // event
      //   .dataTransfer
      //   .setData('text/plain', event.target.id);
      this.prevEvent = event;
      console.log('########', this.prevEvent);
   
    });
    
    var img = document.querySelector('#myImg') as HTMLImageElement;
    console.log(img);
    (document.getElementById('width') as HTMLInputElement).value = `${img.width}`;
    
    this.splashColor = this.standaloneApp.spashScreenBackgroundColor;
    (document.getElementById('splashColor') as HTMLInputElement).value = this.splashColor;
    document.getElementById('phone').addEventListener('drop', (event) => {
      console.log('drop', event);
 
      const logo = document.getElementById("myImg");
      const logoWrapper = document.getElementById("logo");
      console.log(1, logoWrapper);
      
      console.log(2, this.prevEvent);
      
      console.log(2, this._f, this.OY);
   
      let oldX = this.prevEvent.clientX;
      let oldY = this.prevEvent.clientY;
  
      
   
      let newX = event.clientX;
      let newY = event.clientY;
   
      let deltaX = oldX - newX;
      let deltaY = oldY - newY;
   
      console.log(`X -- Old: ${oldX} --- New: ${newX}  --- delta: ${deltaX}`)
      console.log(`Y -- Old: ${oldY} --- New: ${newY}  --- delta: ${deltaY}`)
   
      if (logoWrapper.style.marginLeft === '') {
        logoWrapper.style.marginLeft = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaX) + 'px';
      } else {
        logoWrapper.style.marginLeft = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaX) + 'px';
      }
   
    if (logoWrapper.style.marginTop ==='') {
      logoWrapper.style.marginTop = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaY) + 'px';
   
    } else {
        logoWrapper.style.marginTop = (+logoWrapper.style.marginTop.slice(0, -2) - deltaY) + 'px';
   
    }
    document.getElementById('phone').style.cursor = 'default';
   
    document.getElementById('myImg').style.cursor = 'grab';
    });
   } else {
    this.splashColor = this.standaloneApp.iconBackgroundColor;
    (document.getElementById('splashColor') as HTMLInputElement).value = this.splashColor;
   }

  }

  submitSplashScreen() {
    const splashScreenHTML = document.getElementById('phoneWrapper').innerHTML;
    this.staService.uploadSplashScreenHTML(this.standaloneApp._id, splashScreenHTML, this.splashColor).subscribe(data => {
      this.standaloneApp = data;
      this.splashScreenSubmittedSucessfully.emit(this.standaloneApp);
    });
  }

  bgColorUpdated() {
    const color = (document.getElementById('splashColor') as HTMLInputElement).value;
    console.log(color)
    this.splashColor = color;
    document.getElementById('phone').style.backgroundColor = this.splashColor;
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var img = document.querySelector('#myImg') as HTMLImageElement;

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      console.log(this.imgURL)
      img.style.display = 'flex';
      this.updateWidth()
      img.src = this.imgURL;
      this.centerLogo();
      
    }
  }

  updateWidth() {
    console.log('fdsfdasfsd');
    
    this.logoWidth = +(document.getElementById('width') as HTMLInputElement).value;
    console.log('JKJLJKJK', this.logoWidth);
    
    var img = document.querySelector('#myImg') as HTMLImageElement;
    img.style.width = `${this.logoWidth}px `
  }

  centerLogo() {
    const logoWrapper = document.getElementById('logo');
    logoWrapper.style.marginLeft = (374/2 - this.logoWidth/2) + 'px';
  }

  onDrop(event) {
    console.log('drop', event);
 
    const logo = document.getElementById("myImg");
    const logoWrapper = document.getElementById("logo");
    console.log(1, logoWrapper);
    
    console.log(2, this.prevEvent);
    
    console.log(2, this._f, this.OY);
 
    let oldX = this.prevEvent.clientX;
    let oldY = this.prevEvent.clientY;

    
 
    let newX = event.clientX;
    let newY = event.clientY;
 
    let deltaX = oldX - newX;
    let deltaY = oldY - newY;
 
    console.log(`X -- Old: ${oldX} --- New: ${newX}  --- delta: ${deltaX}`)
    console.log(`Y -- Old: ${oldY} --- New: ${newY}  --- delta: ${deltaY}`)
 
    if (logoWrapper.style.marginLeft === '') {
      logoWrapper.style.marginLeft = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaX) + 'px';
    } else {
      logoWrapper.style.marginLeft = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaX) + 'px';
    }
 
  if (logoWrapper.style.marginTop ==='') {
    logoWrapper.style.marginTop = (+logoWrapper.style.marginLeft.slice(0, -2) - deltaY) + 'px';
 
  } else {
      logoWrapper.style.marginTop = (+logoWrapper.style.marginTop.slice(0, -2) - deltaY) + 'px';
 
  }
  document.getElementById('phone').style.cursor = 'default';
 
  document.getElementById('myImg').style.cursor = 'grab';
  }
 

  onDragOver(event) {
    event.preventDefault();
    console.log(243242, this.OX, this.OY);

  }

  onDragStart(event) {

    this.OX = event.clientX;
    this.OY = event.clientY;
    console.log('drag start:  ', this.OX, this.OY)

    event
      .dataTransfer
      .setData('text/plain', event.target.id);
    this.prevEvent = event;
    console.log('########', this.prevEvent);
 
    
    // document.getElementById('phone').style.cursor = 'grabbing';
  }

}
