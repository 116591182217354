import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, QueryList, ViewChildren, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Stage } from 'src/models/stage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Admin } from 'src/models/admin';
import { Branch } from 'src/models/branch';
import { TrackService } from 'src/app/services/track.service';
import { Track } from 'src/models/track';
import { Job, Role } from 'src/models/job';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { TestService } from 'src/app/services/test.service';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ArticlesService } from 'src/app/services/articles.service';
import { Article } from 'src/models/article';
import { InteractiveJobService } from 'src/modules/client-portal/services/interactive-job.service';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { MatDialog } from '@angular/material/dialog';
import { StageCompletionDialogComponent } from '../stage-completion-dialog/stage-completion-dialog.component';

import {IFrameComponent, iframeResizer} from 'iframe-resizer';

declare let gtag: Function;

@Component({
  selector: 'app-stage-detail',
  templateUrl: './stage-detail.component.html',
  styleUrls: ['./stage-detail.component.sass', './content.css']
})
export class StageDetailComponent implements OnInit, AfterViewInit {

  admin: Admin;
  branch: Branch;
  track: Track;
  job: Job;
  stage: Stage;
  selectedJob: Job;
  loading = true;
  stickyFlag: boolean = false;
  menuPosition: any;
  validJobIds: string[] = [];
  lang = 'en';
  certificateDownloadPending = false;

  validJobsForThisStage: Job[] = [];

  showAdditionalFeedbackForm = false;

  public get additionalFeedbackSubmitted(): boolean  {
    let selectedJobItem = this.branch.joblist.find(ji => ji.job === this.selectedJob._id);
    // console.log(selectedJobItem);
    
    if (selectedJobItem) {
      return selectedJobItem.additionalFeedbackSubmitted;
    } else {
      return false;
    }
  }

  interactiveJobs: {stageId: string, title: string, auxPath: string, required: boolean, job?: string}[] = [];
  interactiveJobMode = false;
  selectedInteractiveJob: {stageId: string, title: string, auxPath: string, required: boolean, job?: string};

  jobHasRendered = true;
  @ViewChild('stickyNav') menuElement: ElementRef;
  @ViewChild("jobdetails") jobDetails: ElementRef;
  validJobs: Job[] = [];

  constructor(private auth: AuthenticationService, private adminService: AdminService, private articlesService: ArticlesService, private interactiveJobService: InteractiveJobService, private router: Router, private branchService: BranchService,
    private route: ActivatedRoute, private trackService: TrackService,  public dialog: MatDialog, private testService: TestService, private domSantizer: DomSanitizer, private translate: TranslateService, private renderer: Renderer2) {
      let currentLang = window.localStorage.getItem('currentLang');
      if (!currentLang) {
          window.localStorage.setItem('currentLang', this.lang);
          currentLang = this.lang;
      } else {
        this.lang = currentLang;
      }

      translate.use(this.lang);
     }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        this.admin = adminData;
        this.adminService.getBranchForAdmin(this.admin).subscribe(data => {
          this.branch = data;
          this.validJobIds = this.branch.joblist.map(j => j.job);

          window.localStorage.setItem('branchId', this.branch.branchId.trim());
          
          this.branch.track.stages.forEach(stage => {
            stage.jobs.forEach(job => {
              if (this.isValidJob(job)) {
                this.validJobs.push(job);
              }
            })
            
          })
          this.route.params.subscribe(data => {
            const stageId = data.Id;
  
            this.trackService.getStage(stageId).subscribe(data => {
              this.stage = data;
              this.stage.jobs = this.stage.jobs.filter(job => job.role === 0);
              this.stage.jobs = this.stage.jobs.sort(this.sortByDeadline);
              this.interactiveJobs = this.interactiveJobService.getInteractiveJobs(stageId);

              this.stage.jobs.forEach(job => {
                if (this.isValidJob(job)) {
                  this.validJobsForThisStage.push(job);
                }

                job.articles = [];
                job.articleIds.forEach(aId => {
                  this.articlesService.getArticle(+aId).subscribe(data => {
                    job.articles.push(data.article);
                  })
                })
              })
              if (this.route.snapshot.queryParams.job && this.route.snapshot.queryParams.job === 'web-request') {
                this.selectInteractiveJob(this.interactiveJobs[0]);
                this.loading = false;

              } else {                
                const entryJob = this.getEntryJob();
                this.loading = false;
                this.selectedJob = entryJob;
                this.switchJob(entryJob);
              }
              if (this.stage.test) {
                this.testService.getTest(`${this.stage.test}`).subscribe(testData => {
                  this.stage.test = testData;
                });
              }
             

          
            });
          });
        });
      });

    }

  }


  ngAfterViewInit() {
      window.scrollTo(0, 0);

      this.menuPosition = this.menuElement.nativeElement.offsetTop;
  }


 
  openStageCompleteDialog(stage: Stage){
    this.adminService.updateJobStatus(this.branch._id, this.getJobItem(this.selectedJob)._id, true).subscribe(branch => {
      this.branch.joblist = branch.joblist;
    });    
    window.scrollTo(0, 0);
    const dialogRef = this.dialog.open(StageCompletionDialogComponent, {
      width:'auto',
      height: 'auto',
      data: { 
        stage: stage,
        branch: this.branch,
        admin: this.admin,
        hasBackdrop: false
       }

    } );
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }
 
  // returnToDashboard() {
   
  // }


  private getEntryJob(): Job {
    const job = this.stage.jobs.find(j => this.isValidJob(j)  && !this.isInteractiveJobProxy(j) && this.branch.joblist.find(jI => jI.job === j._id && !jI.status));
    if (job) {
      return job;
    } else {
      return this.stage.jobs.find(j => this.isValidJob(j) && !this.isInteractiveJobProxy(j));
    }
  }

  switchJob(job: Job) {
    this.jobHasRendered = false;
    if (!this.isInteractiveJobProxy(job)) {
      this.selectedJob = job;
      this.interactiveJobMode = false;
      if (!this.jobDetails) {
        setTimeout(_ => {
          this.sanatize();

        }, 100);
      } else {
        this.sanatize();
      }
    } else {
      this.selectInteractiveJob(this.interactiveJobs.find(intJob => intJob.job && job._id === intJob.job))
    }
  }

  getTranslation(job: Job) {
    if (this.translate.currentLang === 'en' || this.translate.currentLang === 'pt-br') {
      return job;
    }
    return job.translations.find(t => t.language === this.translate.currentLang).translation;
  }

  getStageTranslation(stage: Stage) {
    if (this.translate.currentLang === 'en' || this.translate.currentLang === 'pt-br') {
      return stage;
    } else {
      return stage.translations.find(trans => trans.language === this.translate.currentLang).translation;
    }

  }

  selectInteractiveJob(job) {
    this.selectedInteractiveJob = job;
    this.interactiveJobMode = true;
    this.router.navigate(['client-dashboard/stage', this.stage._id, { outlets: { 'auxoutlet': [job.auxPath] }}]).catch(err => console.log(err));
    this.selectedJob = undefined;
  }

  isInteractiveJobComplete(interactiveJob): boolean {
    let jobItem = this.branch.joblist.find(ji => ji.job === interactiveJob.job);
    return jobItem.status;
  }

  showInteractiveJob(interactiveJob): boolean {
    if (interactiveJob.job) {
      let jobItem = this.branch.joblist.find(ji => ji.job === interactiveJob.job);
      return this.isValidJob(this.stage.jobs.find(job => job._id === jobItem.job));
    } else {
      return true;
    }
  }

  isInteractiveJobProxy(job): boolean {
    return this.interactiveJobs.map(interactiveJob => interactiveJob.job).includes(job._id);
  }

  isValidJob(job: Job): boolean {
    if (job.filterOn.length > 0) {
      if (this.branch.onboardingOptions.length === 0) {
        return false;
      } else {
        return job.filterOn.some(filter => this.branch.onboardingOptions.includes(filter)) && this.validJobIds.filter(j => j === job._id).length > 0;
      }
    } else {
      
      return this.validJobIds.filter(j => j === job._id).length > 0
    }

  }

  nextJob() {
    const index = this.validJobsForThisStage.indexOf(this.selectedJob);

    this.adminService.updateJobStatus(this.branch._id, this.getJobItem(this.selectedJob)._id, true).subscribe(branch => {
      this.branch.joblist = branch.joblist;
    });
    this.switchJob(this.validJobsForThisStage[index + 1]);
    window.scrollTo({top: 200,  behavior: 'smooth'});
  }

  // returnToDashboard() {
  //   this.adminService.updateJobStatus(this.branch._id, this.getJobItem(this.selectedJob)._id, true).subscribe(branch => {
  //     this.branch.joblist = branch.joblist;
  //     this.router.navigate(['/client-dashboard']);
  //   });
  // }

  hasNextJob() {
    return this.validJobsForThisStage.indexOf(this.selectedJob) < (this.validJobsForThisStage.length - 1);
  }

  placeholder() {
  }

  getJobItem(job: Job) {
    return this.branch.joblist.find(jobItem => jobItem.job === job._id);
  }

  updateStatus($event, jobItem): void {
    this.adminService.updateJobStatus(this.branch._id, jobItem._id, $event.checked).subscribe(branch => {
      this.branch.joblist = branch.joblist;
    });
  }

  getStageProgress(stage: Stage): number {
    //update
    const jobItems = this.branch.joblist;
    const jobs = stage.jobs.filter(j => this.isValidJob(j));
    let res: Job[] = jobs;
    const jobIds = res.map(j => j._id);
    const finishedJobs = jobItems.filter(ji => ji.status && jobIds.includes(ji.job));
    return (finishedJobs.length / res.length) * 100;
  }

  getArticle(id: number): Article {
    return this.selectedJob.articles.find(art => +art.id === +id);
  }

  getTotalProgress(): number {
    return Math.ceil(this.branch.joblist.filter(jobItem => (jobItem.status)).length / this.validJobs.length * 100);
  }

  hasReviewed(): boolean {    
    return this.branch.joblist.find(ji => ji.job === this.selectedJob._id).review >= 0;
  }

  showFeedbackForm() {
    this.showAdditionalFeedbackForm = true;
  }

  submitAdditionalFeedback() {
    let selectedJobItem = this.branch.joblist.find(ji => ji.job === this.selectedJob._id);
    selectedJobItem.additionalFeedbackSubmitted = true;
    this.showAdditionalFeedbackForm = false;

    let feedback = (document.getElementById('feedbackInput') as HTMLInputElement).value;
    this.branchService.leaveAdditionalFeedback(this.branch, this.selectedJob, this.admin, feedback).subscribe()
  }

  reviewAJob(rating: number) {
    let selectedJobItem = this.branch.joblist.find(ji => ji.job === this.selectedJob._id);
    if (selectedJobItem) {
      selectedJobItem.review = rating;
      this.branchService.reviewJob(this.branch, this.selectedJob, this.admin, rating).subscribe(_ => {})
      
    }
  }

  isSelectedReview(rating) {
    let selectedJobItem = this.branch.joblist.find(ji => ji.job === this.selectedJob._id);
    if (selectedJobItem) {
      
      return selectedJobItem.review === rating;
    } else {
      return false;
    }

  }


  goToVideos(videoIds) { 
    this.router.navigate(['client-dashboard/videos'], { queryParams: { videos: videoIds } } );
  
  }

  getYouTubeUrl(videoId: string): SafeHtml {

    return this.domSantizer.bypassSecurityTrustHtml(`
    <style>
    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  </style>
  <div class='embed-container'>
        <iframe src='https://www.youtube.com/embed/${videoId}' frameborder='0' allowfullscreen></iframe>
    </div>


    `);

  }

  sanatize() {
    console.log('hit sanatise');

    
    
    if (!this.jobHasRendered) {
      this.renderer.setProperty(this.jobDetails.nativeElement, 'innerHTML', this.getTranslation(this.selectedJob).details)
      this.jobHasRendered = true;

      if (this.selectedJob.details.includes('<iframe')) {
        console.log('has iframe');
        iframeResizer({
          checkOrigin: false,
          heightCalculationMethod: 'lowestElement',
          log: false,
          minHeight: 300
      }, "#glofox_lead_cap_demo");
        
      }

      if (this.selectedJob.details.includes('lead_cap_generator')) {
        console.log('has iframe');
        iframeResizer({
          checkOrigin: false,
          heightCalculationMethod: 'taggedElement',
          log: false,
          minHeight: 300
      }, "#lead_cap_generator");
        
      }

      if (this.selectedJob.details.includes('cms_guides')) {
        console.log('has iframe');
        iframeResizer({
          checkOrigin: false,
          heightCalculationMethod: 'taggedElement',
          log: false,
          minHeight: 300
      }, "#cms_guides");
        
      }
    }
    
  }

  sortByDeadline(a: Job, b: Job) {
    // Use toUpperCase() to ignore character casing
    const deadlineA = a.hoursToComplete;
    const deadlineB = b.hoursToComplete;

    let comparison = 0;
    if (deadlineA > deadlineB) {
      comparison = 1;
    } else if (deadlineA < deadlineB) {
      comparison = -1;
    }
    return comparison; 
  }
}
