<div class="loading" *ngIf="loading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div class="wrapper" style=" color: #fff; border-radius: 0; ">
  <div class="container-fluid mt-5">
    <div class="row">
      <!-- side bar Start-->
      <div class="list-group mb-5 pr-2 pl-2 ">
        <mat-button-toggle-group [class.sticky]="stickyFlag" #stickyNav class="webint-nav" name="fontStyle"
          style="display: flex; flex-direction: column; flex-wrap: wrap;">

          <mat-button-toggle mat-stroked-button mat-button-toggle-checked class="side-nav" 
            (click)="switchPage('code-snippet')" style="
            outline: none;" [ngClass]="{'active': activePage === 'code-snippet'}">
            <span class="button-text">Website Code Snippets</span></mat-button-toggle>

          <!-- <mat-button-toggle mat-stroked-button class="side-nav" (click)="switchPage('add-developer')"
            [ngClass]="{'active': activePage === 'add-developer'}">
            <span class="button-text">Adding a Developer to your site</span>
          </mat-button-toggle> -->

          <mat-button-toggle *ngIf="branch && branch.hasSMT" mat-stroked-button class="side-nav"
            (click)="switchPage('add-leadCapture')" [ngClass]="{'active': activePage === 'add-leadCapture'}"> 
            <span class="button-text">Adding Lead Capture to your site</span>
          </mat-button-toggle>

          <mat-button-toggle mat-stroked-button class="side-nav" (click)="switchPage('wordpress')"
            [ngClass]="{'active': activePage === 'wordpress'}">
            <span class="button-text">WordPress Integration Guide </span>
          </mat-button-toggle>

          <mat-button-toggle mat-stroked-button class="side-nav" (click)="switchPage('squarespace')"
            [ngClass]="{'active': activePage === 'squarespace'}">
            <span class="button-text">SquareSpace Integration Guide</span>
          </mat-button-toggle>

          <mat-button-toggle mat-stroked-button class="side-nav" (click)="switchPage('wix')"
            [ngClass]="{'active': activePage === 'wix'}">
            <span class="button-text">Wix Integration Guide</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- side bar END -->
      <div *ngIf="branch" [ngClass]="{ 'stickyNav-space' : stickyFlag !== false }"
        class=" list_data col-xs-12 col-md-12 col-lg-9 pl-3">
        <div [ngSwitch]="switchOn">
          <app-webint-code-parent [branch]="branch" *ngSwitchCase="'code-snippet'"></app-webint-code-parent>
          <app-add-developer *ngSwitchCase="'add-developer'"></app-add-developer>
          <app-leadcapture-integration *ngSwitchCase="'add-leadCapture'" [branch]="branch"></app-leadcapture-integration>
          <app-wordpress-integration *ngSwitchCase="'wordpress'" [branch]="branch"></app-wordpress-integration>
          <app-squarespace-integration *ngSwitchCase="'squarespace'"></app-squarespace-integration>
          <app-wix-integration *ngSwitchCase="'wix'"></app-wix-integration>
          <app-glofox-api [memberships]="memberships" *ngSwitchCase="'glofox-api'"></app-glofox-api>
          <app-webint-code-parent [branch]="branch" *ngSwitchDefault></app-webint-code-parent>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>