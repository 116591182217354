<div>
<div class="">
  <div class="d-flex align-items-center">
    <div style="width: 100%;">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
         <div id="managerNote" class="managerNote">{{branch.managerNote.note}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>