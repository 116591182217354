import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Track } from 'src/models/track';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Option } from 'src/models/option';
import { Branch } from 'src/models/branch';
import { Admin } from 'src/models/admin';

@Component({
  selector: 'app-launch-demo-dialog',
  templateUrl: './launch-demo-dialog.component.html',
  styleUrls: ['./launch-demo-dialog.component.sass']
})
export class LaunchDemoDialogComponent implements OnInit {

  track: Track;
  options: Option[] = [];

  selectedOptions: Set<Option> = new Set();

  branchFG: FormGroup;
  optionsFG: FormGroup;

  constructor(public dialogRef: MatDialogRef<LaunchDemoDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: {track: Track, options: Option[]}, private fb: FormBuilder) { 
    this.track = data.track;
    this.options = data.options;
   }

  ngOnInit() {
    this.branchFG = new FormGroup({
      name: new FormControl(`Fake Fitness (${this.track.title})`, Validators.required),
      adminFirstName: new FormControl('Bob', Validators.required),
      adminLastName: new FormControl('Smith', Validators.required),
      adminEmail: new FormControl(`demo@${this.track.title.split(' ').join('').toLowerCase()}.com`, [Validators.required, Validators.email])
    });

    if (this.options.length) {
      this.optionsFG = this.fb.group({
        optionsArray: this.fb.array([])
      })

      this.options.forEach(option => {
        (this.optionsFG.get('optionsArray') as FormArray).push(new FormControl(false));
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateOptions(event, option: Option): void {
    event.checked ? this.selectedOptions.add(option) : this.selectedOptions.delete(option);  
  }


  launchDemo() {

    let branch = new Branch();

    
    branch.name = this.branchFG.controls.name.value;

    let admin = new Admin();

    admin.firstName = this.branchFG.controls.adminFirstName.value;

    admin.lastName = this.branchFG.controls.adminLastName.value;
    admin.email = this.branchFG.controls.adminEmail.value;
    branch.admins = [];

    branch.admins.push(admin);

    branch.onboardingOptions = [];
    this.selectedOptions.forEach(option => {
      branch.onboardingOptions.push(option._id);
    })

    this.dialogRef.close(branch);
  }

}
