<h2 mat-dialog-title class="ml-5" style="font-weight: 700">{{'client_guide.welcome' | translate}} </h2>
<mat-dialog-content>
    <div class="ml-5">
        {{'client_guide.tool_explainer' | translate}}
    </div>

    <div class="d-flex align-items-center mt-2 ml-5">
        
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="20" height="20"
viewBox="0 0 172 172"
style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#2ecc71"><path d="M35.83333,14.33333c-11.79242,0 -21.5,9.70758 -21.5,21.5v100.33333c0,11.79243 9.70758,21.5 21.5,21.5h100.33333c11.79243,0 21.5,-9.70757 21.5,-21.5v-57.33333h-14.33333v57.33333c0,3.97424 -3.19243,7.16667 -7.16667,7.16667h-100.33333c-3.97424,0 -7.16667,-3.19243 -7.16667,-7.16667v-100.33333c0,-3.97424 3.19242,-7.16667 7.16667,-7.16667h64.5v-14.33333zM146.7207,28.05078l-66.5996,70.60286l-26.31511,-26.3151l-10.13411,10.13411l36.74316,36.74317l76.73372,-81.32487z"></path></g></g></svg>
            <span class="ml-2">{{'client_guide.please_tick_jobs_off' | translate}}</span>
     
    </div>

    <div class="d-flex justify-content-center p-4">
        <img  src="../../../../../assets/images/example_job_completion.gif" alt="">
    </div>

</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
    <div >
        <button mat-button color="accent" [mat-dialog-close]="true">{{'client_guide.git_it' | translate}}</button>
    </div>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
</mat-dialog-actions>