import { Component, OnInit, Input } from '@angular/core';
import { Stage } from 'src/models/stage';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { log } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { Branch } from 'src/models/branch';
import { StageIconService } from 'src/app/services/stage-icon.service';

@Component({
  selector: 'app-stage-thumbnail',
  templateUrl: './stage-thumbnail.component.html',
  styleUrls: ['./stage-thumbnail.component.sass']
})
export class StageThumbnailComponent implements OnInit {
  @Input() stage: Stage;
  @Input() stageProgress: number;

  @Input() branch: Branch;
  @Input() role: number;
  @Input() index: number;
  // @Input() initiallyOpened: boolean = false;

  constructor(private router: ActivatedRoute, private sanitizer: DomSanitizer, private translate: TranslateService, private stageIconService: StageIconService) {
    this.translate.use(window.localStorage.getItem('currentLang'));
    
  }



  ngOnInit() {
  }

  getStageProgress(stage: Stage): string {
    return `${this.stageProgress}%`;
  }

  getStageTranslation(stage: Stage) {
    
    if (this.translate.currentLang === 'en') {
      return stage;
    } else {
      const trans = stage.translations.find(trans => trans.language === this.translate.currentLang);
      console.log('hello', trans);
      return trans ? trans.translation : stage;
    }

  }

  getStageIcon(stage) {
    return this.stageIconService.getIcon(stage.icon);
  }

}
