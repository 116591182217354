import { Component, OnInit, Input } from '@angular/core';
import { WebIntegration } from 'src/models/web-integration';
import { faCheck, } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-webintegration-progress-tracker',
  templateUrl: './webintegration-progress-tracker.component.html',
  styleUrls: ['./webintegration-progress-tracker.component.sass']
})
export class WebintegrationProgressTrackerComponent implements OnInit {

  @Input() webIntegration: WebIntegration;


  tickIcon = faCheck;

  constructor() { }

  ngOnInit() {
  }

}
