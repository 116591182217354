<div class ="d-flex  mb-3">
  <div class="web-status mt-3 pl-3"><strong>Standalone App Status</strong></div>
</div>
<div class=" pl-3 pr-3 mt-3 step ">Thank you for getting us everything we need to create your standalone app. You can follow it's progress here:</div>

<div class="mt-4" *ngIf="standAloneApp">
  <div class="progress_container mb-3">
    <div class="d-flex justify-content-around align-items-center flex-wrap" style="width: 100%;">
      <div class="node">
        <div class="icon complete" *ngIf="standAloneApp.hasBeenSubmitted">
          <!-- <fa-icon [icon]="tickIcon"></fa-icon> -->
          <mat-icon aria-hidden="false" style="color:#fff; font-weight: bold!important;">done</mat-icon>
        </div>
        <div class="name">Queued</div>
      </div>
      <div class="node">
        <div class="icon complete" *ngIf="standAloneApp.status !== 'Queued'">
          <mat-icon aria-hidden="false" style="color:#fff; font-weight: bold!important;">done</mat-icon>
        </div>
        <div class="icon incomplete" *ngIf="standAloneApp.status === 'Queued'">2</div>
        <div class="name">In Progress</div>
      </div>
      <div class="node">
        <div class="icon complete" *ngIf="standAloneApp.status === 'Store Review' || standAloneApp.status === 'Live Transfer'">
          <mat-icon aria-hidden="false" style="color:#fff; font-weight: bold!important;">done</mat-icon>
        </div>
        <div class="icon incomplete" *ngIf="standAloneApp.status !== 'Store Review' && standAloneApp.status !== 'Live Transfer'">3</div>
        <div class="name">Store Review</div>
      </div>
      <div class="node">
        <div class="icon complete" *ngIf="standAloneApp.status === 'Live Transfer'">
          <mat-icon aria-hidden="false" style="color:#fff; font-weight: bold!important;">done</mat-icon>
        </div>
        <div class="icon incomplete" *ngIf="standAloneApp.status !== 'Live Transfer'">4</div>
        <div class="name">Live</div>
      </div>
    </div>

    <div class="pl-3 pr-3" *ngIf="standAloneApp.status === 'Live Transfer'">
      <div class="mb-2">Here are the links to your app:</div> 
      <div class="d-flex">

        <div class="d-flex align-items-center"> <img class="pr-2" src="https://icon-library.com/images/android-icon-transparent-background/android-icon-transparent-background-4.jpg" style="width: 30px" alt="" srcset=""> Android:  <a class="ml-2" [href]="standAloneApp.androidLink" target="_blank">Open</a> </div>
        <div class="d-flex align-items-center ml-4"> <img class="pr-2 ml-1" src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c516.png" style="width: 25px" alt="" srcset=""> Apple: <a class="ml-2" [href]="standAloneApp.iOSLink" target="_blank">Open</a> </div>
      </div>
    </div>

  </div>
</div>
