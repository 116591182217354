import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Job } from 'src/models/job';
import { Option } from 'src/models/option';
import { Track } from 'src/models/track';
import { TrackService } from 'src/app/services/track.service';
import { ToastrService } from 'ngx-toastr';
import { Stage } from 'src/models/stage';
import { TrackBuilderService } from '../../services/track-builder.service';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
  selector: 'app-selected-job-details',
  templateUrl: './selected-job-details.component.html',
  styleUrls: ['./selected-job-details.component.sass']
})
export class SelectedJobDetailsComponent implements OnInit {

  private _job: Job;

  @Input()
  public set job(v : Job) {
    this._job = v;
  }

  @Input()
  categorisedOptions: {}[] = [];

  @Input()
  options: Option[] = [];

  @Input()
  track: Track;
  
  public get job() : Job {
    return this._job;
  }

  @Input()
  stage: Stage;

  @Output()
  stageUpdated$: EventEmitter<Stage> = new EventEmitter();  


  constructor(private translator: TranslatorService, private trackService: TrackService, private toastrService: ToastrService, private trackBuilderService: TrackBuilderService) { }

  ngOnInit() {
  }

  getTranslation(lang: string): {language: string, translation: {details: string, description: string}} {
    return this.job.translations.find(trans => trans.language === lang);

  }

  translateJob() {

    this.translator.translateJob(this.job, this.track.translations).subscribe(data => {
      let descriptionTranslation = data[0];
      let detailsTranslation = data[1];
      console.log(1, this.job.translations);

      this.track.translations.forEach(lang => {
        let trans = this.getTranslation(lang);
        if (!trans || !trans.translation) {
          trans = {language: lang, translation :{details: '', description: ''} }
          trans.translation.description = descriptionTranslation.translations.find(descTrans=> descTrans.to === lang).text;
          trans.translation.details = detailsTranslation.translations.find(detailsTrans=> detailsTrans.to === lang).text;
          this.job.translations.push(trans);
        } else {
          trans.translation.description = descriptionTranslation.translations.find(descTrans=> descTrans.to === lang).text;
          trans.translation.details = detailsTranslation.translations.find(detailsTrans=> detailsTrans.to === lang).text;
        }
      })
      console.log(2, this.job.translations);
      

    })
  }

  saveTranslation(selectedJob: Job) {
    this.trackService.saveJobTranslation(selectedJob._id, selectedJob.translations).subscribe(data => {
      console.log(data);
      this.toastrService.success('Translation Saved.', 'Good Job!');
    })
  }

  deleteJob(event) {
    this.stage.jobs = this.stage.jobs.filter(j => j._id !== event._id);
    this.job = undefined;
    console.log('bubbled to selected job');
    this.trackBuilderService.changeJobList(this.stage.jobs);
    this.stageUpdated$.emit(this.stage);
  }

}
