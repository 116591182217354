import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Track } from 'src/models/track';
import { Stage } from 'src/models/stage';
import { DataSource } from '@angular/cdk/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { TrackService } from 'src/app/services/track.service';
import { Subscription, Subject } from 'rxjs';
import { TrackBuilderService } from '../../services/track-builder.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stage-list',
  templateUrl: './stage-list.component.html',
  styleUrls: ['./stage-list.component.sass']
})
export class StageListComponent implements OnInit, OnDestroy {

  _track: Track;
  selectedStage: Stage;

  private unsubscribe$ = new Subject<void>();

  newStageSubscription: Subscription;
  stageDeletedSubscription: Subscription;

  @Input()
  public set track(v : Track) {
    this._track = v;
    this.selectStage(this._track.stages[0]);
    this.stageTableDS = this._track.stages;
  }

  
  public get track() : Track {
    return this._track;
  }

  @Output()
  selectedStageChanged$: EventEmitter<any> = new EventEmitter();
  
  
  

  stageTableDS = [];
  stageTableDisplayedColumns: string[] = ['drag', 'title', 'has review'];
  @ViewChild('table') table: MatTable<Stage>;

  constructor(private trackService: TrackService, private trackBuilderService: TrackBuilderService) { 
    this.newStageSubscription = this.trackBuilderService.newStageAdded$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedStage._id = data._id;
    });

    this.stageDeletedSubscription = this.trackBuilderService.stageDeleted$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      const currentIndex = this._track.stages.findIndex(stage => stage._id == data._id);
      
      this._track.stages = this._track.stages.filter(stage => stage._id !== data._id);
      if (currentIndex < this._track.stages.length) {
        this.selectStage(this._track.stages[currentIndex]);
      } else {
        this.selectStage(this._track.stages[currentIndex - 1]);
      }
      this.stageTableDS = this._track.stages;
      this.table.renderRows();
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectStage(stage: Stage): void {
    this.selectedStage = stage;
    
    this.selectedStageChanged$.emit(this.selectedStage);
  }

  reorderStages(event: CdkDragDrop<Stage[]>) {
    const previousIndex = this._track.stages.findIndex((row) => row === event.item.data);
    moveItemInArray(this.stageTableDS, previousIndex, event.currentIndex);

    this.table.renderRows();
    this.trackService.updateStageOrder(this.track._id, event.container.data.map(s => s._id)).subscribe(_ => {})
  }

  newStage() {
    let stage = new Stage();
    stage.title = 'New Stage';
    stage.jobs = [];
    stage.icon = 'default';
    stage.translations = [];
    this.track.stages.push(stage);
    this.table.renderRows();
    this.selectStage(stage);
  }

  hasTest(stage: Stage): boolean {
    return true;
  }

}
