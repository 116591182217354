<div class="container" *ngIf="branch else loading">
    <div class="row">
      <div class="form-container col-xs-12 col-md-12 col-lg-12">
        <!--
  
           ********** WEB INT INTRO ***********
  
  
          -->
  
        <div class="form-container pl-3">
          <div style="width: 100%">
            <div class="ck-content" *ngIf="!showRequestForm && !branch.webIntegration.integrationRequestSubmitted">
              <p>
                If you are unable to perform the integration yourself, you can request a member of our Web Development team perform the integration on your behalf. 
              </p>
  
              <h4  class="mt-4">How will it work?</h4>
  
              <p>First you will need to add Glofox as an <strong>administrator</strong> for your website. Then you will fill out a short form letting us know which features you require. A member of our team will add a new page to your website for each Glofox feature you have requested.</p>
  
              <p>We will do our best to match these new pages to the style of your website. Please note additional/custom development work falls outside the scope of what we are available to offer.</p>
  
              <p>On the form you can choose whether you want the integration to be made public immediately or not. If you choose to make the integration public on a future date, we will send you links to the new pages so you can review them before they are set live. </p>
  
              <h4 class="mt-4">Step One: Add Glofox as an admin for your website</h4>
  
              <p>
                In order for a Glofox web developer to access your website you will first need to add
                <strong>webdevelopment@glofox.com</strong> as an admin for your site. If you are unable to add us as a website contributor, please contact us at the above email with website login details.
              </p>
  
              <p>If you are unsure how to add a user/contibuter to your website, please follow the link for the Content Management System (CMS) you use:</p>
  
              <ul class="mt-3 mb-3 cms-options">
                <li><a href="https://make.wordpress.org/support/user-manual/customizing-wordpress/managing-users-on-your-wordpress-site/adding-users-to-your-wordpress-site/" target="_blank">Wordpress</a></li>
                <li><a href="https://support.wix.com/en/article/inviting-site-contributors" target="_blank">WIX</a></li>
                <li><a href="https://support.squarespace.com/hc/en-us/articles/206537287-Inviting-a-contributor" target="_blank">Squarespace</a></li>
                <li><a href="https://support.squarespace.com/hc/en-us/articles/206537287-Inviting-a-contributors" target="_blank">Weebly</a></li>
                <li><a href="https://ie.godaddy.com/help/invite-a-delegate-to-access-my-godaddy-account-12376" target="_blank">GoDaddy</a></li>
              </ul>
  
              <div class="mt-5 mb-4">
                <h4>Step Two: Create your request</h4>
  
                <p>Please click the button below to create your request: </p>
                <div class="d-flex mt-3">
                  <button mat-raised-button color="accent" style="color: #fff; font-weight: bold" (click)="addWebIntDetails()">Create your request</button>
                </div>
              </div>
              
            </div>
              <div *ngIf="showRequestForm">
                <div class="link-container pl-2 mb-3">
                  <div class=" article-list__item">
                    <div (click)="back()" class="article-list__link">
                      <div class="article-list__link-inner"><strong>Back</strong></div>
                    </div>
                  </div>
                </div>
                <app-webint-request-form [webInt]="branch.webIntegration" [hasSMT]="branch.hasSMT" (requestSubmitted)="onRequestSucessfullySubmitted($event)"></app-webint-request-form>
              </div>
  
            <div *ngIf="branch.webIntegration.integrationRequestSubmitted">
              <h4 class="mb-3">Good Job!</h4>
    
              <p>You will receive an email when the integration is complete and in the meantime you can track it's progress here:</p> 
  
              <app-webintegration-progress-tracker [webIntegration]="branch.webIntegration">
              </app-webintegration-progress-tracker>            
            </div>
          </div>
        </div>
  
        <!--
  
           **********
  
           WEB INT FORM
  
           ***********
  
  
          -->
  
         
      </div>
    </div>
  </div>
  

  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>