<div class="container">
  <form [formGroup]="adminCreateForm" (ngSubmit)="onAddAdmin()">
    <div>
      <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName" />
        <mat-error *ngIf="
            !adminCreateForm.get('firstName').valid &&
            adminCreateForm.get('firstName').touched
          ">Please Enter First Name</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Last Name" formControlName="lastName" />
        <mat-error *ngIf="
            !adminCreateForm.get('lastName').valid &&
            adminCreateForm.get('lastName').touched
          ">Please Enter Last Name</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="example-full-width">
      <input matInput type="email" placeholder="Email Address" formControlName="email" />
      <mat-error *ngIf="adminCreateForm.get('email').hasError('unavailable')">Email address already exists!</mat-error>
      <mat-error *ngIf="
          !adminCreateForm.get('email').valid &&
          adminCreateForm.get('email').touched && !adminCreateForm.get('email').hasError('unavailable')
        ">Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button *ngIf="!emailCheckPending" [disabled]="adminCreateForm.invalid" mat-raised-button color="accent">
        Submit
      </button>
      <button *ngIf="emailCheckPending" [disabled]="adminCreateForm.invalid" mat-raised-button color="accent">
        <fa-icon [icon]="faLoading" [spin]="true"></fa-icon>
      </button>
    </div>
  </form>
</div>
