<div class="container info-container">
  <mat-card>
    <div class="link-container pl-2 mb-3 mt-5">
      <div class=" article-list__item">
        <div  class="article-list__link">
          <a routerLink="/client-dashboard/web-integration/get-started"  class="article-list__link-inner"><strong>Back</strong></a>
        </div>
      </div>
    </div>
  <div class="inner-container">
  <div class="row">
    <div class="col-12 intro-text">
      <h3>ADDING A GLOFOX WEB DEVELOPER TO YOUR CMS PLATFORM</h3>
      <hr />
      <p>
        Adding a contributor to your CMS (Content Management System) platform is
        easier than you might think and it is more secure than sending us your
        personal credentials. When giving access to one of our web developers,
        please use the email address of the developer that contacted you.
      </p>
      <p>
        When you are fully on-board with Glofox we recommend you rescind our web
        developer’s access to your CMS platform. If your CMS platform is not on
        the list below, please check the support section of that CMS provider or
        contact <b><a href="mailto:webdevelopment@glofox.com?Subject=Web%20query"
            target="_top">webdevelopment@glofox.com</a></b> for further queries. We are here to help!
      </p>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../assets/images/wordpressimg.png" class="logo" alt="wordpress" />
        <p class="info-text">
          How to add a Glofox Developer to your WordPress site:<br><b><a
              href="https://make.wordpress.org/support/user-manual/customizing-wordpress/managing-users-on-your-wordpress-site/adding-users-to-your-wordpress-site/"
              target="_blank" rel="">
              Click Here</a></b>
        </p>
        <span>Level of Website Access: <strong>Admin</strong></span>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../../assets/images/wix.png" class="logo" alt="wix" />
        <p class="info-text">
          How to add a Glofox Developer to your Wix site:<br><b><a
              href="https://support.wix.com/en/article/inviting-site-contributors" target="_blank" rel="">
              Click Here</a></b>
        </p>
        <span class="admin">Level of Website Access: <strong>Admin</strong></span>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../../assets/images/squarespace.png" class="logo" alt="squarespace" />
        <p class="info-text">
          How to add a Glofox Developer to your SquareSpace site:<br><b><a
              href="https://support.squarespace.com/hc/en-us/articles/206537287-Inviting-a-contributor" target="_blank"
              rel="">
              Click Here</a></b>
        </p>
        <span>Level of Website Access: <strong>Admin</strong></span>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../../assets/images/godaddy-logo.png" class="logo" alt="go daddy" />
        <p class="info-text">
          How to add a Glofox Developer to your GoDaddy site:<br><b><a
            href="https://ie.godaddy.com/help/invite-a-delegate-to-access-my-godaddy-account-12376" target="_blank"
            rel="">
            Click Here</a></b>
        </p>
        <span class="admin">We need <strong>Product and Domain</strong>> level of access.</span>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../../assets/images/weebly.png" class="logo" alt="weebly" />
        <p class="info-text">
          How to add a Glofox Developer to your Weebly site:<br><b><a
            href="https://support.squarespace.com/hc/en-us/articles/206537287-Inviting-a-contributor" target="_blank"
            rel="">
            Click Here</a></b>
        </p>
        <span>Level of Website Access: <strong>Admin</strong></span>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="platform-section">
        <img src="../../../../assets/images/webflow_logo.svg.png" class="logoWebflow" alt="webflow" />
        <p class="info-text">
          How to add a Glofox Developer to your Webflow site:<br><b><a
              href="https://university.webflow.com/lesson/invite-content-collaborators#:~:text=Add%20collaborators%20in%20your%20Project,Collaborators%20field%2C%20and%20click%20Invite." target="_blank" rel="">
              Click Here</a></b>
        </p>
        <span class="admin">Level of Website Access: <strong>Editor</strong></span>
      </div>
    </div>
  </div>
</div>
</mat-card>
</div>
