import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Stage } from 'src/models/stage';
import { Track } from 'src/models/track';
import { TranslatorService } from 'src/app/services/translator.service';
import { TrackService } from 'src/app/services/track.service';
import { ToastrService } from 'ngx-toastr';
import { TrackBuilderService } from '../../services/track-builder.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StageIconService } from 'src/app/services/stage-icon.service';

@Component({
  selector: 'app-edit-stage',
  templateUrl: './edit-stage.component.html',
  styleUrls: ['./edit-stage.component.sass']
})
export class EditStageComponent implements OnInit, OnDestroy {

  _stage: Stage;

  @Input()
  public set stage(v : Stage) {
    this._stage = v;
    this.postMigration = this._stage.postMigration;
    console.log('PM: ', this.postMigration);
    
  }

  public get stage() : Stage {
    return this._stage;
  }

  @Input()
  track: Track;

  postMigration = false;
  
  icons;
  

  constructor(private translator: TranslatorService, private trackService: TrackService, private toastr: ToastrService, private trackBuilderService: TrackBuilderService, private stageIconService: StageIconService) { }

  ngOnInit() {
    this.icons = this.stageIconService.getIcons();
  }

  ngOnDestroy() {
    
  }

  getStageTranslation(lang) {
    let trans = this.stage.translations.find(trans => trans.language === lang);

    if (trans) {
      return trans.translation;
    } else {
      return {title: undefined}
    }
  }

  updatePostMigrationValue(event:MatCheckboxChange) {
    this.stage.essentialStage = event.checked;
  }

  updateEssentialStageStatus(event:MatCheckboxChange) {
    this.stage.essentialStage = event.checked;
  }

  translateStage() {
    this.translator.translateStage(this.stage, this.track.translations).subscribe(data => {      
      let titleTranslation = data[0];
      this.track.translations.forEach(lang => {
      let trans = this.stage.translations.find(trans => trans.language === lang);
        if (trans) {
          trans.translation.title = titleTranslation.translations.find(titleTrans=> titleTrans.to === lang).text;
        } else {
          this.stage.translations.push({language: lang, translation: {title: titleTranslation.translations.find(titleTrans=> titleTrans.to === lang).text}});
        }
      })

    });
  }

  updateStage() {    
    if (this.stage._id) {
      this.trackService.updateStage(this.stage).subscribe(data => {
        this.toastr.success('Stage Updated.')
      }) 
      } else {
        this.trackService.createStage(this.track._id, this.stage).subscribe(data => {
          this.stage = data;
          this.toastr.success('Stage Created.');
          this.trackBuilderService.addNewStage(this.stage);
        })
      
    }
  }

  deleteStage() {
    this.trackService.deleteStage(this.stage._id).subscribe(data => {
      this.trackBuilderService.deleteStage(this.stage);
      this.toastr.success('Stage Created.');
    })
  }

}
