import { Component, OnInit, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { WebIntegration } from 'src/models/web-integration';
import { Branch } from 'src/models/branch';
import { ActivatedRoute } from '@angular/router';
import { OBManager } from 'src/models/ob-manager';
import { ManagerService } from 'src/modules/manager-portal/services/manager.service';
import { FormGroup, FormControl } from '@angular/forms';
import { WebIntService } from 'src/modules/manager-portal/services/web-int.service';
import { ToastrService } from 'ngx-toastr';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { Admin } from 'src/models/admin';


@Component({
  selector: 'app-web-int-details',
  templateUrl: './web-int-details.component.html',
  styleUrls: ['./web-int-details.component.sass']
})
export class WebIntDetailsComponent implements OnInit {

  newNote: '';
  obManager: OBManager;
  internalNoteFG: FormGroup;

  _selectedWebInt: WebIntegration;
  hasSmt: boolean = false;
  internalNote: string;

  admins: Admin[] = [];

  // get the manager per call
  @Input()
  public set selectedWebInt(v: WebIntegration) {
    this._selectedWebInt = v;
    this.hasSmt = this._selectedWebInt.branch.onboardingOptions.includes('5e305721105de93a5ccf8bba');
    console.log(v);

    this.branchService.getBranchAdmins(this.selectedWebInt.branch._id).subscribe(data => {
      this.admins = data;
    })

    this.obmService.getManagerById(`${this.selectedWebInt.branch.obmanager}`).subscribe(obManagerData => {
      this.selectedWebInt.branch.obmanager = obManagerData;
    });


  }


  public get selectedWebInt(): WebIntegration {
    return this._selectedWebInt;
  }



  constructor(private activatedRoute: ActivatedRoute, private toastr: ToastrService, private webIntService: WebIntService, private obmService: ManagerService, private branchService: BranchService) { }

  ngOnInit() {

    this.internalNoteFG = new FormGroup({
      internalNote: new FormControl('')
    });

  }


  updateWebsiteUrl() {
    this.webIntService.updateWebsiteURL(this.selectedWebInt).subscribe(data => {

    })
  }

  assignInternalNoteToWebInt() {
    const selectedWebIntId = this._selectedWebInt._id;
    const internalNote = this.internalNoteFG.controls.internalNote.value;
    // this.internalNote = internalNote;

    console.log('NOTES:', internalNote, selectedWebIntId );

    this.webIntService.assignNoteToWebInt(selectedWebIntId, internalNote ).subscribe( noteData => {
      console.log('returned', noteData);

      this._selectedWebInt.internalNote = noteData.internalNote;
      // this.internalNoteFG.reset(this.internalNoteFG.controls.value);
      this.toastr.success('Note Saved');
    });
  }

  save() {

  }
  onEditForm() {


  }


}
