import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { log } from 'util';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.sass']
})
export class PasswordResetComponent implements OnInit {


  resetPasswordFG: FormGroup;
  resetToken: string;

  hidePW = true;
  hideCPW = true;
  tokenIsValid = false;

  attemptMade = false;
  successfulReset = false;

  feedbackMsg: string;

  constructor(private route: ActivatedRoute, private adminService: AdminService, private router: Router) {

   }

  ngOnInit() {
    localStorage.removeItem('glofox-token');
    this.route.queryParams.subscribe(params => {
      this.resetToken = params.token;
      // console.log(this.resetToken);
      this.adminService.checkIfTokenIsValid(this.resetToken).subscribe(data => {

          this.tokenIsValid = data.isValid;

      })
    });
    this.resetPasswordFG = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(5)])
    });


  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('confirmPassword').value;

  return pass === confirmPass;
}

cancel() {

}

redirectToLogin() {
  this.router.navigate(['/']);
}

  onFormSubmit() {
    const password = this.resetPasswordFG.controls['password'].value;
    const confirmation = this.resetPasswordFG.controls['confirmPassword'].value;
    try {
      this.adminService.updatePassword(password, this.resetToken).subscribe(data => {
        console.log(data);
        this.attemptMade = true;
        this.successfulReset = data.status;
        this.feedbackMsg = data.message;
      });
    } catch (err) {
      console.log('err: ', err);
    }
  }

}
