import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  SERVER_URL = environment.SERVER_URL;

  constructor(private http: HttpClient) { }

  getAllReviews(masterlist_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api//feedback/masterlist/${masterlist_id}`);
  }

  getTrackFeedback(track_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/feedback/track/${track_id}`); 
  }

  getJobFeedback(job_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/feedback/job/${job_id}`);
  }

  getAdminFeedbackAll(masterlist_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/feedback/masterlist/${masterlist_id}/admins`);
  }

  getAdminFeedback(masterlist_id: string, admin__id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/feedback/masterlist/${masterlist_id}/admins/${admin__id}`);
  }

  deleteFeedbackForAdmin(admin_id: string): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/feedback/admins/${admin_id}`);
  }

}
