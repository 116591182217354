import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Branch } from 'src/models/branch';
import { StandAloneAppUpsell } from 'src/models/standalone-app-upsell';
import { ToastrService } from 'ngx-toastr';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';

@Component({
  selector: 'standalone-app-upsell-form',
  templateUrl: './standalone-app-upsell-form.component.html',
  styleUrls: ['./standalone-app-upsell-form.component.sass']
})
export class StandaloneAppUpsellFormComponent implements OnInit {


  @Input()
  managerId: string;

  standaloneAppUpsellFG: FormGroup;

  @Output() standaloneAppSubmitted = new EventEmitter<any>();

  standaloneAppUpsell: {
    branchId: string,
    name: string,
    managerId: string,
    admin:
        {
          firstName: string,
          lastName: string,
          email: string
        }
    };

  constructor(private toastr: ToastrService, private standAloneAppService: StandaloneAppService, private branchService: BranchService) { }

  ngOnInit() {

    this.standaloneAppUpsellFG = new FormGroup({
      branchId: new FormControl('', Validators.required),
      branchName: new FormControl('', Validators.required),
      adminFirstName: new FormControl('', Validators.required),
      adminLastName: new FormControl('', Validators.required),
      adminEmail: new FormControl('', Validators.required )
    });
    console.log('manager 3', this.managerId);
  }


  onSubmitAppDetail() {



    const branchId = this.standaloneAppUpsellFG.controls.branchId.value.trim();
    const branchName = this.standaloneAppUpsellFG.controls.branchName.value;
    const managerId = this.managerId;
    const adminFirstName  = this.standaloneAppUpsellFG.controls.adminFirstName.value;
    const adminLastName  = this.standaloneAppUpsellFG.controls.adminLastName.value;
    const adminEmail  = this.standaloneAppUpsellFG.controls.adminEmail.value;

    this.standaloneAppUpsell = { branchId: branchId, name: branchName, managerId: managerId, admin: { firstName: adminFirstName, lastName: adminLastName, email: adminEmail} }


    this.branchService.createAppUpsellBranch(this.standaloneAppUpsell).subscribe( data => {
      // if ( data.message === "Branch Already Exists!") {
      //   this.toastr.error('Branch Already Exists!');
      // } else {
      //   this.toastr.success('Sent!');
      //   this.standaloneAppSubmitted.emit(this.standaloneAppUpsell);
      // }
    });

}

}
