import { Component, OnInit, Input } from '@angular/core';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-wordpress-integration',
  templateUrl: './wordpress-integration.component.html',
  styleUrls: ['./wordpress-integration.component.sass']
})
export class WordpressIntegrationComponent implements OnInit {

  loading = true;

  @Input()
  branch: Branch;

  constructor() { }

  ngOnInit() {
    this.loading = false;
  }

  scrollToFAQ() {
    document.getElementById('integration-tips').scrollIntoView({
      behavior: 'smooth'
    })
  }

}
