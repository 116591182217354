
<div class="container-fluid">


<div class="container">
  <div class="row profile" *ngIf="manager">
    <div class="col-3 d-flex flex-column align-items-center">
      <img class="profilePic" src={{manager.imageURL}} alt="" srcset="">
      <div class="changePic clickable" (click)="toggleUpdateProfilePicMode()">Change Image</div>
    </div>
    <div class="col-9">
      <div class="d-flex justify-content-between">
        <h3>{{manager.firstName}} {{manager.lastName}}</h3>
        <button class="btn btn-sm btn-dark" *ngIf="!resetPasswordMode" (click)="toggleResetPasswordMode()">Change Password</button>
        <button class="btn btn-sm btn-dark" *ngIf="resetPasswordMode" (click)="toggleResetPasswordMode()">Cancel</button>
      </div>

      <hr>

      <div *ngIf="resetPasswordMode">
        <h5>Reset Your Password</h5>
        <div>Password must be 7 characters long.</div>
        <form [formGroup]="resetPasswordFG" class="d-flex flex-column">
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Old Password" formControlName="oldPassword">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="New Password" formControlName="newPassword">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="Confirm New Password" formControlName="confirmNewPassword">
          </mat-form-field>

          <button class="btn btn-success" [disabled]="!isPasswordResetFormValid()" style="width: 100px; margin: auto;" (click)="resetPassword()" type="submit">Submit</button>
        </form>
      </div>

      <div *ngIf="updateProfilePicMode">
        <mat-form-field style="width: 80%;" class="example-full-width">
          <input matInput placeholder="Image URL" [(ngModel)]="manager.imageURL">
        </mat-form-field>
        <button class="btn btn-success ml-2" style="float: right;" (click)="updateProfilePic()">Submit</button>
      </div>

      <div class="alert" *ngIf="passwordResetFeedback">
        <h5 [class.text-success]="passwordResetFeedback.status" [class.text-danger]="!passwordResetFeedback.status" style="text-align: center;"><strong>{{passwordResetFeedback.msg}}</strong></h5>
      </div>

      <div *ngIf="!resetPasswordMode && !updateProfilePicMode">
        <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-3">Survey Link</h5>
        <button *ngIf="!updateSurveyLinkMode" (click)="toggleUpdateSurveyLinkMode()" class="btn btn-sm btn-dark">Edit</button>
        <button *ngIf="updateSurveyLinkMode" (click)="toggleUpdateSurveyLinkMode()" class="btn btn-sm btn-dark">Cancel</button>

      </div>
          <div *ngIf="!updateSurveyLinkMode">{{manager.surveyURL}}</div>
          <div *ngIf="updateSurveyLinkMode" class="">
            <mat-form-field style="width: 80%;" class="example-full-width">
              <input matInput placeholder="Survey URL" [(ngModel)]="manager.surveyURL">
            </mat-form-field>
            <button class="btn btn-success ml-2" style="float: right;" (click)="updateSurveyLink()">Submit</button>
          </div>
      </div>

      <div class="mt-5" *ngIf="!resetPasswordMode">
        <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-3">Calendly Link</h5>
        <button *ngIf="!updateCalendlyLinkMode" (click)="toggleUpdateCalendlyLinkMode()" class="btn btn-sm btn-dark">Edit</button>
        <button *ngIf="updateCalendlyLinkMode" (click)="toggleUpdateCalendlyLinkMode()" class="btn btn-sm btn-dark">Cancel</button>
      </div>
          <div *ngIf="!updateCalendlyLinkMode">{{manager.calendlyURL}}</div>
          <div *ngIf="updateCalendlyLinkMode" class="">
            <mat-form-field style="width: 80%;" class="example-full-width">
              <input matInput placeholder="Calendly URL" [(ngModel)]="manager.calendlyURL">
            </mat-form-field>
            <button class="btn btn-success ml-2"  style="float: right;" (click)="updateCalendlyLink()">Submit</button>
          </div>
      </div>

      <div class="mt-5" *ngIf="!resetPasswordMode">
        <div class="d-flex justify-content-between align-items-center mb-3">
        <h5>Welcome Messsage</h5>
        <button  *ngIf="!updateWelcomeMsgMode" (click)="toggleUpdateWelcomeMsgMode()" class="btn btn-sm btn-dark">Edit</button>
        <button  *ngIf="updateWelcomeMsgMode" (click)="toggleUpdateWelcomeMsgMode()" class="btn btn-sm btn-dark">Cancel</button>
      </div>
      <div *ngIf="!updateWelcomeMsgMode">
       {{manager.customWelcomeMsg}}
      </div>
      <div *ngIf="updateWelcomeMsgMode" class="">
        <mat-form-field style="width: 80%;" class="example-full-width">
          <textarea matInput placeholder="Your custom welcome message" [(ngModel)]="manager.customWelcomeMsg"></textarea>
        </mat-form-field>
        <button class="btn btn-success ml-2"  style="float: right;" (click)="updateCustomWelcomeMsg()">Submit</button>
      </div>
      </div>
    </div>
  </div>
</div>
</div>
