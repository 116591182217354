import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/modules/client-portal/components/video-dialog/video-dialog.component';

@Component({
  selector: 'app-confirm-branch-deletion',
  templateUrl: './confirm-branch-deletion.component.html',
  styleUrls: ['./confirm-branch-deletion.component.sass']
})
export class ConfirmBranchDeletionComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmDeletionDialog, {
      width: '250px',

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-confirm-deletion-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogConfirmDeletionDialog {

  constructor(
    public dialogRef: MatDialogRef< DialogConfirmDeletionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
