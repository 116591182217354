import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Stage } from 'src/models/stage';
import { Job, Role } from 'src/models/job';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-training-content',
  templateUrl: './training-content.component.html',
  styleUrls: ['./training-content.component.sass'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', [animate(300)])
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ]),
  ]
})

export class TrainingContentComponent implements OnInit {
  // animation bools
  showStage = false;
  state = 'default';
  boxShadow = true;

  @Input()
  branch: Branch;

  @Input()
  validJobIds: string[] = [];

  constructor() { }

  ngOnInit() {
    let x = localStorage.getItem('toggleTraining')
    if (x) {
      this.showStage = !!x;
    } else {
      this.showStage;
    }

  }

  getStageProgress(stage: Stage): number {
    const jobItems = this.branch.joblist;

    const jobs = stage.jobs.filter(j => j.role === Role.CUSTOMER);
    let res: Job[] = [];
    jobs.forEach(job => {
      if (this.validJobIds.includes(job._id)) {
        if (!job.filterOn.length) {
          res.push(job);
        } else if (job.filterOn.filter(element => this.branch.onboardingOptions.includes(element)).length) {
          res.push(job);
        }
      }
    });
    const jobIds = res.map(j => j._id);
    const finishedJobs = jobItems.filter(ji => ji.status && jobIds.includes(ji.job));
    return (finishedJobs.length / res.length) * 100;
  }


  toggleStage(): void {
    console.log(this.showStage)
    this.showStage = !this.showStage;
    this.state = (this.state === 'default' ? 'rotated' : 'default');
    // this.boxShadow = (this.boxShadow === 'open' ? 'close' : 'open');
    this.boxShadow = !this.boxShadow;
    // console.log('box', this.boxShadow )
    if (this.showStage) {
      localStorage.setItem('toggleTraining', 'true');
    }
    else {
      localStorage.removeItem('toggleTraining')
    }

  }


  getNonEssentialStages() {
    return this.branch.track.stages.filter(stage => !stage.essentialStage);
  }

}
