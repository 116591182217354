<div *ngIf="track">
    <table cdkDropList  #table
    (cdkDropListDropped)="reorderStages($event)" style="width: 100%" mat-table [dataSource]="stageTableDS" [cdkDropListData]="stageTableDS" class="mat-elevation-z8">

    <ng-container matColumnDef="drag">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell style="padding-left: 14px;" *matCellDef="let stage" [ngClass]="{'selected': stage._id === selectedStage._id}">
            <!-- <mat-icon cdkDragHandle svgIcon="dragVertical"></mat-icon> -->
            <i cdkDragHandle style="padding-top: 6px; cursor:grab" class="material-icons">drag_handle</i>
        </td>
        <td mat-footer-cell *matFooterCellDef class="addBtn"> </td>

    </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Stages </th>
          <td mat-cell *matCellDef="let stage" [ngClass]="{'selected': stage._id === selectedStage._id}"> {{stage.title}} </td>
          <td mat-footer-cell *matFooterCellDef (click)="newStage()" class="addBtn clickable"> 
            <span class="material-icons" style="padding-left: 4%; padding-top: 4px;">
            add_circle_outline
            </span> 
          </td>

        </ng-container>

        <ng-container matColumnDef="has review">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Review? </th>
          <td mat-cell style="padding-left: 14px;" *matCellDef="let stage" [ngClass]="{'selected': stage._id === selectedStage._id}" >
             <div [ngClass]="{'green-dot': stage.test}"></div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="addBtn"> </td>
  
      </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="stageTableDisplayedColumns"></tr>
        <tr mat-row (click)="selectStage(row)" class="clickable" *matRowDef="let row; columns: stageTableDisplayedColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"></tr>
        <tr mat-footer-row *matFooterRowDef="stageTableDisplayedColumns"></tr>
      </table>
</div>