import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'en':
        return 'English';
      case 'es':
        return 'Spanish 🇪🇸';
      case 'fr':
        return 'French 🇫🇷';
      case 'pt':
        return 'Portugese 🇵🇹';
      case 'zh-Hans':
        return 'Chinese 🇨🇳';
      case 'tlh-Latn':
        return 'Klingon 👽';
      case 'ja':
          return 'Japanese 🇯🇵'
      default:
        return value;
    }
  }

}
