import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'studioEmoticon'
})
export class StudioEmoticonPipe implements PipeTransform {

  transform(branchName: string, ...args: any[]): string {
    if (branchName.toLowerCase().includes('gym')) {
      return `🏋️`;
    } else if (branchName.toLowerCase().includes('yoga') || branchName.toLowerCase().includes('pilates')) {
      return `🧘🏾‍♀️`;
    } else if (branchName.toLowerCase().includes('fitness')) {
      return `👟`;
    } else if (branchName.toLowerCase().includes('cycl') || branchName.toLowerCase().includes('spin')) {
      return `🚴‍♀️`;
    } else if (branchName.toLowerCase().includes('row')) {
      return `🚣`;
    } else if (branchName.toLowerCase().includes('box') || branchName.toLowerCase().includes('muay thai') || branchName.toLowerCase().includes('fight')) {
      return `🥊`;
    } else if (branchName.toLowerCase().includes('danc') ) {
      return `💃🏿`;
    }  else if (branchName.toLowerCase().includes('esports') || branchName.toLowerCase().includes('e-sports') || branchName.toLowerCase().includes('video')) {
      return `🎮`;
    } else if (branchName.toLowerCase().includes('sports') ) {
      return `⚽`;
    } 
    return `🤸`;
  }

}
