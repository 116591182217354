<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <a class="navbar-brand" [routerLink]="['/']"><img src="https://www.glofox.com/wp-content/uploads/2019/07/glofox-logo.svg" style="height:40px; width:auto; margin-left: 1.5rem;" alt=""></a> -->

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/web-team-dashboard']" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}"> <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['#']" routerLinkActive="router-link-active">All Web Integrations</a>
        </li> -->
      </ul>

      <button mat-raised-button color="warn" (click)="logout()">Logout</button>
    </div>
  </nav>
  <div class="nav-container bg-hero-img d-flex justify-content-center align-items-center" style="color: #fff; padding-left:11.8%; padding-right: 10.9%; ">
    <div class="header-logos">
      <img src="../../../../assets/images/web-dash-logo.png" style="height:40px;">
    </div>
  </div>
