<div class="container" *ngIf="createManagerForm">
	<form [formGroup]="createManagerForm" (ngSubmit)="onSubmit()">
		<div class="d-flex formFieldWrapper">
			<mat-form-field class="firstNameField">
				<input matInput type="text" name="firstName" formControlName="firstName" placeholder="First Name" required />
				<mat-error *ngIf="!createManagerForm.get('firstName').valid && createManagerForm.get('firstName').touched && !createManagerForm.get('firstName').hasError('unavailable')">Please Enter First Name</mat-error>
			</mat-form-field>
			<mat-form-field class="lastNameField">
				<input matInput type="text" name="lastName" formControlName="lastName" placeholder="Last Name" required />
				<mat-error *ngIf="!createManagerForm.get('lastName').valid && createManagerForm.get('lastName').touched && !createManagerForm.get('lastName').hasError('unavailable')">Please Enter Last Name</mat-error>
			</mat-form-field>
		</div>
		<div class="formFieldWrapper">
			<mat-form-field class="emailField">
				<input matInput type="text" name="email" formControlName="email" placeholder="Email" required />
				<mat-hint>Must be a valid @abcfitness.com email</mat-hint>
				<mat-error *ngIf="!createManagerForm.get('email').valid && createManagerForm.get('email').touched && !createManagerForm.get('email').hasError('unavailable')">Please Enter a valid @abcfitness.com email address</mat-error>
			</mat-form-field>
		</div>
		<div class="formFieldWrapper">
			<mat-form-field class="slackIDField">
				<input matInput type="text" name="slackID" formControlName="slackID" placeholder="Slack Member Id" required />
				<mat-hint>View their profile on Slack, click on the three dots within the profile and select "Copy member ID”</mat-hint>
				<mat-error *ngIf="!createManagerForm.get('slackID').valid && createManagerForm.get('slackID').touched && !createManagerForm.get('slackID').hasError('unavailable')">Please enter their Slack Member ID</mat-error>
			</mat-form-field>
		</div>
		<div class="formFieldWrapper">
			<mat-form-field class="slackImgField">
				<input matInput type="text" name="slackImg" formControlName="slackImg" placeholder="Slack Profile Image" required />
				<mat-hint>View their profile on Slack, right click on their profile pic and copy image URL</mat-hint>
				<mat-error *ngIf="!createManagerForm.get('slackImg').valid && createManagerForm.get('slackImg').touched && !createManagerForm.get('slackImg').hasError('unavailable')">Please enter Slack Image URL</mat-error>
			</mat-form-field>
		</div>
		<div class="formFieldWrapper mt-4">
			<button mat-raised-button class="mr-3" color="accent" type="submit" [disabled]="!createManagerForm.valid">Save</button>
		</div>
	</form>
</div>