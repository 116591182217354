<mat-vertical-stepper linear #stepper>
  <mat-step label="Branch Details" completed="validBranch">
    <app-create-branch (newBranchSubmit)="onBranchCreated($event)"></app-create-branch>
  </mat-step>
  <mat-step label="Admin Details" completed="validAdmin">
    <app-create-admin (newAdminAccount)="onAdminCreated($event)"></app-create-admin>
  </mat-step>
  <mat-step completed="validBranch && validAdmin ">
    <ng-template matStepLabel>Complete Set up</ng-template>
    <div class="final-msg">
      <p>Good job - Branch successfully created!</p>
    </div>
    <!-- <button mat-button >Back</button> -->
    <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
    <button *ngIf="branch" [routerLink]="['/manager-dashboard/branches', branch._id]" routerLinkActive="router-link-active" routerLinkActive="router-link-active" mat-raised-button color="accent" type="submit" (click)="submitBranch()">
      Finish Branch Set Up >
    </button>

  </mat-step>

</mat-vertical-stepper>