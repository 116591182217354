<div>
    Reassign this branch from {{currentOM.firstName}} to: 
</div>

<div class="mt-3" style="height: 350px; overflow: scroll;">
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let om of otherManagers" (click)="selectOM(om)">
            {{om.firstName}} {{om.lastName}}
        </mat-list-option> 
    </mat-selection-list>
</div>

<div class="d-flex align-items-center justify-content-center mt-3">
    <button mat-raised-button class="mr-2" (click)="reassignBranch()" [disabled]="!selectedOM" color="primary">Save</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
</div>