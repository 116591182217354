import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Branch, Plan } from "src/models/branch";
import { Observable, Observer } from "rxjs";
import { OBManager } from "src/models/ob-manager";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ManagerService {
  readonly SERVER_URL = environment.SERVER_URL;

  branches: Branch[] = [];
  manager: OBManager;

  constructor(private http: HttpClient) { }

  getBranchesForManager(managerId: string): Observable<Branch[]> {
    return this.http.get(`${this.SERVER_URL}/api/v2/obmanagers/${managerId}/branches`) as Observable<Branch[]>;
  }

  getManager(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/obmanagers/${id}`);
  }

  getManagerById(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/obmanagers/id/${id}`);
  }

  createObManager(manager: OBManager): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/obmanagers/new`, { manager });
  }

  createManagerV2(createManagerRequest: { firstName: string; lastName: string; slackID: string; email: string, slackImg: string }): Observable<any> {
    const body = {
      email: createManagerRequest.email.trim(),
      firstName: createManagerRequest.firstName.trim(),
      lastName: createManagerRequest.lastName.trim(),
      market: 0,
      slackId: createManagerRequest.slackID.trim(),
      imageURL: createManagerRequest.slackImg.trim(),
    }
    return this.http.post(`${this.SERVER_URL}/api/obmanagers`, body);
  }

  createBranch(branch): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/new`, branch);
  }

  deleteBranch(branch: Branch): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/branches/${branch._id}`);
  }

  getOBManagers(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/obmanagers`);
  }

  updateCalendlyURL(managerId: string, calendlyURL: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/obmanagers/${managerId}/calendlyURL`, { calendlyURL });
  }

  updateSurveyURL(managerId: string, surveyURL: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/obmanagers/${managerId}/surveyURL`, { surveyURL });
  }

  getStats(managerId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/obmanagers/${managerId}/getStats`);
  }

  updateCustomWelcomeMsg(managerId: string, customWelcomeMsg: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/obmanagers/${managerId}/customWelcomeMsg`, { customWelcomeMsg });
  }

  changePassword(managerId: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/obmanagers/${managerId}/updatePassword`, { oldPassword, newPassword });
  }

  changeProfilePicture(managerId: string, imageURL: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/obmanagers/${managerId}/profileURL`, { imageURL });
  }

  getOtherManagers(currentManager: OBManager): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/obmanagers/${currentManager._id}/colleagues`);
  }
}
