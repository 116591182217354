<h1 mat-dialog-title>Are you sure you want to delete this branch?</h1>
<div mat-dialog-content>
  <!-- <p>What's your favorite animal?</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button class="btn btn-info mr-3" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-danger" [mat-dialog-close]="{delete: true}" >Delete</button>
</div>
