<div *ngIf="translation">
    <form *ngIf="questionFG" class="mt-3" [formGroup]="questionFG" (ngSubmit)="saveTranslation()" >
        <mat-form-field style="width: 99%">
          <mat-label>Question</mat-label>
          <textarea type="text" matInput formControlName="question" placeholder="What is the question?"></textarea>
        </mat-form-field>
        <!-- <ckeditor  [editor]="editor" formControlName="followUpMsg"></ckeditor> -->

        <div formArrayName="options" class="mt-3">
          <div  *ngFor="let optionFG of getOptions().controls; let optIndex = index">

            <div class="d-flex align-items-center" [formGroup]="optionFG">
              <mat-form-field style=" width: 80%">
              <mat-label>Potential Answer {{getAnswerLabel(optIndex)}}</mat-label>
          <input type="text" matInput formControlName="potentialAnswer" placeholder="Possible answer">
        </mat-form-field>

            </div>
          </div>
        </div>
        <button mat-raised-button type="submit">Save Translation</button>
        <!-- <button mat-raised-button class="ml-3" (click)="discardQuestionForm()">Cancel</button> -->
      </form>
</div>