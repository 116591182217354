import { Injectable } from '@angular/core';
import { Job } from 'src/models/job';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Stage } from 'src/models/stage';
import { Question } from 'src/models/question';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  SERVER_URL = environment.SERVER_URL;

  constructor(private http: HttpClient) { }


  translateJob(job: Job, langs: string[]): Observable<any> {
    const body = {
      text: [
        { text: job.description },
        { text: job.details }
    ],
    langs: langs
  }
    return  this.http.post(`${this.SERVER_URL}/api/translate`, body);
  }

  translateStage(stage: Stage, langs: string[]): Observable<any> {
    const body = {
      text: [
        { text: stage.title }
      ],
      langs: langs
    }
    return this.http.post(`${this.SERVER_URL}/api/translate`, body)
  }

  translateQuestion(question: Question, langs: string[]): Observable<any> {
    const body = {
      text: [
        {text: question.question}
      ],
      langs: langs
    }
    question.options.forEach(option => {
      body.text.push({text: option.potentialAnswer});
    })
    return  this.http.post(`${this.SERVER_URL}/api/translate`, body);
  }
}
