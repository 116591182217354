import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { log } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem('glofox-token');
    if (!req.url.includes('zendesk') && !req.url.includes('api.wistia.com') && token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next.handle(cloned);
    } else if (req.url.includes('zendesk')) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Basic dC5sb2hhbkBnbG9mb3guY29tOk9CUG9ydGFsMiE=`)
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }

  constructor() { }
}
