import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Job } from 'src/models/job';
import { FormGroup, FormControl } from '@angular/forms';
import * as Editor from '../../../../assets/js/ckeditor';
import { Option } from 'src/models/option';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { ArticleSelectorDialogComponent } from 'src/modules/manager-portal/components/dialogs/article-selector-dialog/article-selector-dialog.component';
import { TrackService } from 'src/app/services/track.service';
import { ToastrService } from 'ngx-toastr';
import { Stage } from 'src/models/stage';
import { ConfirmJobDeletionDialogComponent } from '../dialogs/confirm-job-deletion-dialog/confirm-job-deletion-dialog.component';
import { TrackBuilderService } from '../../services/track-builder.service';
import { Article } from 'src/models/article';
import { ArticlesService } from 'src/app/services/articles.service';

@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.sass']
})
export class JobFormComponent implements OnInit, AfterViewInit {
  _job: Job;
  jobloading = true;

  @Input()
  public set job(job : Job) {
    this.jobFormGroup = null;
    this.jobloading = true;
    this._job = job;
    this.badArticleIds = [];
    this.articles = [];
    this.jobFormGroup = new FormGroup({
      description: new FormControl(this.job.description),
      details: new FormControl(this.job.details),
      url: new FormControl(this.job.externalLink.url),
      linkText: new FormControl(this.job.externalLink.text),
      periodsToComplete: new FormControl(this.job.hoursToComplete)
    });

    this.role = this.roles[this.job.role];
    this.job.articleIds.forEach(art => {
      this.articleService.getArticle(+art).subscribe(data => {
        this.articles.push(data.article);
      }, err => {
        this.badArticleIds.push(art);
      })

    });
    this.jobloading = false;
  }


  public get job() : Job {
    return this._job;
  }

  @Input()
  stage: Stage;

  @Input()
  categorisedOptions: {}[] = [];

  @Input()
  options: Option[] = [];

  @Output()
  jobDeleted$: EventEmitter<any> = new EventEmitter();

  // @Input()
  articleIds: string[] = [];

  articles: Article[] = [];
  badArticleIds: string[] = [];

  editor = Editor;
  editorConfig = {
    placeholder: 'Add some details to the job here.',
    // extraPlugins: [ this.imagePluginFactory ]
  };



  jobFormGroup: FormGroup;
  role = 'customer';
  roles = ['customer', 'obmanager', 'webteam', 'appteam', 'migrations'];
  jobPeriodDuration = 'day';

  selectable = true;
  removable = true;
  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private articleService: ArticlesService, private trackService: TrackService, private toastrService: ToastrService, public dialog: MatDialog, private trackBuilderService: TrackBuilderService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    console.log('after view init');

  }

  public onReady( editor ) {
    console.log('ready');

    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  jobHasFilter(option): boolean {
    if (this.job) {
      return this.job.filterOn.includes(option._id);
    } else {
      return false;
    }
  }


  toggleFilterOnJob(option: Option) {
    if (this.job.filterOn.includes(option._id)) {
      this.job.filterOn = this.job.filterOn.filter(f_id => f_id !== option._id);
    } else {
      this.job.filterOn.push(option._id);
    }
  }

  remove(tag: string, list: string[]): void {
    const index = list.indexOf(tag);

    if (index >= 0) {
      list.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent, list: string[]): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      list.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  openArticleSelectorDialog() {
    const dialogRef = this.dialog.open(ArticleSelectorDialogComponent, {
      width: '1050px',
      data: { articles : this.articles}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
      this.job.articles = result;
    });
  }

  removeArticle(articleId: number) {
    this.articles = this.articles.filter(a => a.id !== articleId);
  }

  saveJob() {
    this.job.description = this.jobFormGroup.controls.description.value;
    this.job.details = this.jobFormGroup.controls.details.value;
    this.job.externalLink.url = this.jobFormGroup.controls.url.value;
    this.job.externalLink.text = this.jobFormGroup.controls.linkText.value;
    this.job.hoursToComplete = this.jobFormGroup.controls.periodsToComplete.value;
    this.job.articleIds = this.articles.map(art => `${art.id}`);
    this.job.role = this.roles.findIndex(r => r === this.role);

    if (this.job._id){
      this.trackService.updateJob(this.job).subscribe(() => {
        this.toastrService.success('Job Saved.', 'Good Job!');

        this.trackBuilderService.changeJobList(this.stage.jobs);
      })
    } else {
      this.trackService.createJob(this.stage._id, this.job).subscribe(data => {
        console.log('jobIndex', this.stage.jobs.indexOf(this.job));
        this.stage.jobs[this.stage.jobs.indexOf(this.job)] = data;
        this.job = data;
        this.job._id = data._id;
        console.log('new job id', this.job._id);

        this.trackBuilderService.changeJobList(this.stage.jobs);
      })
    }
  }

  deleteJob() {
    const dialogRef2 = this.dialog.open(ConfirmJobDeletionDialogComponent, {
      width: '400px',
      data: {job: this.job}
    });

    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result === 'delete') {
        console.log('clciked delete');
        this.trackService.deleteJob(this.job._id).subscribe(data => {
          this.jobDeleted$.emit(this.job);
        })
      }

    });
  }



}
