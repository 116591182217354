import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { TestService } from 'src/app/services/test.service';
import { Question } from 'src/models/question';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-question-translator',
  templateUrl: './question-translator.component.html',
  styleUrls: ['./question-translator.component.sass']
})
export class QuestionTranslatorComponent implements OnInit {

  _translation: {language: string, translation: {question: string, options: string[]}};

  @Input()
  public set translation(v : {language: string, translation: {question: string, options: string[]}}) {
    this._translation = v;
    if (v) {
      console.log('v is defined', v);
            this.questionFG = this.fb.group({
        question: this.fb.control(this.translation.translation.question),
        // followUpMsg: this.fb.control(question.followUpMsg),
        options: this.fb.array(
          this.translation.translation.options.map(opt => {
            return this.fb.group({
              potentialAnswer: this.fb.control(opt, [Validators.required])
            })
          })
        )
      })
    } else {
      console.log('v is undefined');
      this.questionFG = null;
    }
    
    // if (v) {
    //   this.questionFG = this.fb.group({
    //     question: this.fb.control(this.translation.translation.question),
    //     // followUpMsg: this.fb.control(question.followUpMsg),
    //     options: this.fb.array(
    //       this.translation.translation.options.map(opt => {
    //         return this.fb.group({
    //           potentialAnswer: this.fb.control(opt, [Validators.required])
    //         })
    //       })
    //     )
    //   })

    // } else {
    //   this.questionFG = this.fb.group({
    //     question: this.fb.control(''),
    //     // followUpMsg: this.fb.control(question.followUpMsg),
    //     options: this.fb.array(
    //       this.translation.translation.options.map(opt => {
    //         return this.fb.group({
    //           potentialAnswer: this.fb.control('', [Validators.required])
    //         })
    //       })
    //     )
    //   })
    // } 
  }

  public get translation(): {language: string, translation: {question: string, options: string[]}} {
    return this._translation;
  }

  @Input()
  question: Question;

  questionFG: FormGroup;
  
  constructor(private fb: FormBuilder, private testService: TestService, private toastr: ToastrService) { }

  ngOnInit() {

  }

  getAnswerLabel(optIndex: number): string {
    return String.fromCharCode(65 + optIndex);
  }

  getOptions() {
    return this.questionFG.get('options') as FormArray;
  }

  saveTranslation() {
    this.translation.translation.question = this.questionFG.controls.question.value;
    this.translation.translation.options = [];
    this.getOptions().controls.forEach(opt => {
      this.translation.translation.options.push((opt as FormGroup).controls.potentialAnswer.value);
    })
    this.testService.saveQuestionSpecificTranslation(this.question._id, this.translation).subscribe(_ => {
      this.toastr.success(`Translation (${this.translation.language}) Saved.`)
    })
  }

}
