<div class="main-content">
  <div class="container-fluid">
    <div class="container-fluid stages mt-4 mb-2" *ngIf="branch">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-4">
          <app-branch-banner [branch]="branch" class="mb-2"></app-branch-banner>

          <div>
            <app-submit-review [admin]="admin" *ngIf="admin && admin.onboardingSurvey.has_submitted && admin.onboardingSurvey.response.netPromoterScore >= 8">
            </app-submit-review>
          </div>


          <app-onboarding-welcome-banner *ngIf="branch.version === 'v2' && branch.percent_complete_essential < 6" [branch]="branch" [admin]="admin"></app-onboarding-welcome-banner>

          <app-obmanager-detail-card *ngIf="branch.percent_complete_essential < 50 else kb_add" [branch]="branch" [admin]="admin"></app-obmanager-detail-card>

          <app-progress-tracker *ngIf="branch.version === 'v2' && branch.percent_complete_essential >= 6 && !branch.name.includes('UFC') && !branch.name.includes('Dominican Republic')" [branch]="branch"></app-progress-tracker>
          <!-- </div> -->
        </div>
        <div class="col-12 col-md-12 col-lg-8" *ngIf="branch">
          <mat-card class="mb-2 hide-complete-cards">
            <div class="d-flex justify-content-between align-items-center">
              <div>

                <mat-form-field *ngIf="branch.track.translations.length">
                  <select matTooltip="{{lang === 'en' ? 'Por favor, seleccione un idioma ' : 'Please select a Language'}}" matTooltipPosition="BOTTOM" aria-label="Button that displays a tooltip when focused or hovered over" matNativeControl required [(ngModel)]="lang" (change)="useLanguage()">
                    <option value="en">English 🇬🇧</option>
                    <option *ngFor="let trans of branch.track.translations" [value]="trans">{{trans | language}}
                    </option>
                  </select>
                </mat-form-field>
              </div>
              <mat-slide-toggle class="hideCompleteCards" [(ngModel)]="branch.hideCompleteCards" (change)="toggleHideCompletedCards()">{{"client_dash.hide_completed_cards" | translate}}
              </mat-slide-toggle>
            </div>
          </mat-card>
          <!-- COMPLETING OB-->
          <div *ngIf="branch && admin && !hideCompleteObBanner">

            <mat-card class="mb-3" *ngIf="branch.fullyOnBoarded || branch.percent_complete_essential > 50">
              <app-branch-onboarding-complete [branch]="branch" [admin]="admin" (displaySurvey)="displaySurvey($event)" (hideStageHeaders)="hideStageHeaders($event)" [displayLocally]="displayLocally" (closeSurveyBanner)="closeSurveyBanner($event)">
              </app-branch-onboarding-complete>
              <div *ngIf="branch && admin">
                <app-onboarding-experience-survey [branch]="branch" (submittedSurveyDetail)="onSubmitSurvey($event)" [displaySurveyInput]=" displaySurveyInput">
                </app-onboarding-experience-survey>
              </div>
            </mat-card>

          </div>
          <!-- COMPLETING END-->

          <div *ngIf="!hideStageHeaderFlag">
            <app-essential-setup [validJobIds]="validJobIds" [branch]="branch"></app-essential-setup>
          </div>
          <div *ngIf="!hideStageHeaderFlag">
            <app-training-content *ngIf="branch.version === 'v2'" [validJobIds]="validJobIds" [branch]="branch">
            </app-training-content>
          </div>
        </div>
        <!-- stage header End -->
      </div>
    </div>
  </div>

  <ng-template #kb_add>
    <mat-card class="mb-2">
      <h4 class="web-status mt-1 mb-3 pl-3"><strong>{{"client_dash.meet_the_kb" | translate}}</strong></h4>
      <div class="d-flex align-items-center">
        <div class="d-none d-sm-block d-lg-none d-xl-block">
          <img src="https://www.glofox.com/wp-content/uploads/2020/04/footer-img.png" style="width: 100%;" alt="" srcset="">
        </div>
        <div class="pl-3">
          <div class="web-intro ">{{"client_dash.meet_the_kb.blurb" | translate}}</div>
          <a class="article-list-right__link" href="https://support.glofox.com/hc/en-us" target="_blank" style="cursor: pointer;">
            <span class="article-list-right__link-inner">
              <strong>{{"client_dash.meet_the_kb.check_it_out" | translate}}</strong></span>
          </a>
        </div>
      </div>
    </mat-card>
  </ng-template>

  <div *ngIf="!branch">
    <app-loading></app-loading>
  </div>
</div>