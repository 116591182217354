import { Admin } from './admin';
import { Track } from './track';
import { Job } from './job';
import { OBManager } from './ob-manager';
import { WebIntegration } from './web-integration';
import { StandAloneApp } from './standalone-app';

export class Branch {
  _id?: string;
  name: string;
  branchId: string;
  salesForceAccountId: string;
  plan?: number = 1;
  goLiveDate?: Date = new Date(Date.now());
  websiteUrl?: string;
  country?: string;
  lastOnlineAt?: Date;
  admins?: Admin[] = [];
  obmanager?: OBManager;
  _obmanager?: string;
  managerNote?: { seen: boolean, note: string, dismissed: boolean } = { seen: false, note: '', dismissed: false };
  disengaged? = false;
  fullyOnBoarded? = false;
  welcomeDismissed? = false;
  track?: Track;
  _track?: string;
  joblist?: { _id: string, job: string, status: boolean, review: number, additionalFeedbackSubmitted: boolean }[] = [];
  webIntegration: WebIntegration;
  standAloneApp: StandAloneApp;
  percent_complete: number;
  percent_complete_essential: number;
  percent_complete_non_essential: number;
  onboardingOptions?: string[] = [];
  version: string;
  hasSMT: boolean = false;
  hasStandAloneApp: boolean = false;

  //local only
  hideCompleteCards: boolean = true;

  public get smt(): boolean {
    return this.onboardingOptions.includes('5e305721105de93a5ccf8bba');
  }

  public get standaloneApp(): boolean {
    return this.onboardingOptions.includes('5e3057ce105de93a5ccf8cbd');
  }

  public get paymentMigration(): boolean {
    return this.onboardingOptions.includes('5e306296abfc285e6862019d');
  }

  public get memberMigration(): boolean {
    return this.onboardingOptions.includes('5e306220abfc285e68620175');
  }


  startDate?: Date;

  constructor() {

  }




}

export enum Plan {
  SILVER, GOLD, PLATINUM
}


