import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-web-team-navbar',
  templateUrl: './web-team-navbar.component.html',
  styleUrls: ['./web-team-navbar.component.sass']
})
export class WebTeamNavbarComponent implements OnInit {

  @Output() triggerLogout = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  logout() {
    this.triggerLogout.emit(true);
  }
}
