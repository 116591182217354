<div *ngIf="stage">
    <table style="width: 100%" #table mat-table [dataSource]="jobTableDS" class="mat-elevation-z8">


      
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> <div> Jobs: <strong>{{stage.title}}</strong></div></th>
          <td mat-cell *matCellDef="let job" [ngClass]="{'selected': job._id === selectedJob._id}"> {{job.description}} </td>
          <td mat-footer-cell *matFooterCellDef (click)="addJob()" class="addBtn clickable"> 
            <span class="material-icons" style="padding-left: 4%; padding-top: 4px;">
              add_circle_outline
              </span> 
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="jobTableDisplayedColumns"></tr>
        <tr mat-row (click)="selectJob(row)" class="clickable" *matRowDef="let row; columns: jobTableDisplayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="jobTableDisplayedColumns"></tr>
      </table>
</div>