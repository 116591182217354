import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { VideosService } from '../../services/videos.service';
import { log } from 'util';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Video } from '../../models/video';
import { Media } from '../../models/media';
import { Project } from '../../models/project';
import { FormGroup, FormControl } from '@angular/forms';
import { Admin } from 'src/models/admin';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit, OnDestroy {

  constructor(private videosService: VideosService, public translate: TranslateService) { }


  _selectedVideo: Video;

  @Input()
  public set selectedVideo(sv: Video) {
    this._selectedVideo = sv;

    if (this.player) {
      window['onYouTubeIframeAPIReady'] = () => this.cueVideoById(this._selectedVideo);
      this.player.cueVideoById(this._selectedVideo.id);
    }
  }

  public get selectedVideo(): Video {
    return this._selectedVideo;

  }

  @Input()
  playerWidth: number;

  @Output()
  selectedVideoChanged: EventEmitter<Video> = new EventEmitter();

  @Input()
  admin: Admin;


  likeVideo: boolean = false;
  dislikeVideo: boolean = false;
  clientHasWatched: boolean = false;

  _videos: Video[] = [];

  likedVideos: Video[] = []



  @Output()
  hasSeen: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public set videos(v: Video[]) {
    this._videos = v;
  }

  public get videos(): Video[] {
    return this._videos;
  }

  public get playerHeight(): number {
    return this.playerWidth / 16 * 9;
  }
  player: any;


  init() {

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.cueVideoById(this.selectedVideo);

    if (window['onYouTubeIframeAPIReady']) {
      this.cueVideoById(this.selectedVideo);
      return;
    }
  }

  ngOnInit() {
    this.init();

  }

  ngOnDestroy() {
    window['YT'] = null;
    if (this.player) {
      this.player.destroy();
    }
  }


  cueVideoById(selectedVideo) {
    this.player = new window['YT'].Player('player', {
      videoId: selectedVideo.id,
      playerVars: {
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1,
      },
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),

      }
    });


  }



  /* 4. It will be called when the Video Player is ready */
  onPlayerReady(event) {
    event.target.cueVideoById();
    ;
  }

  /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          // console.log('started ' + this.cleanTime());

          // this.videosService.hasWatchedVideo(this.selectedVideo._id, this.admin._id).subscribe(data => {
          //   // console.log('returned data', data);
          //   this.hasSeen.emit(this.selectedVideo.hasSeen = true)
          // })
          // console.log('has watched?', this.clientHasWatched)
        } else {
          // console.log('playing ' + this.cleanTime())
        };
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          // console.log('paused' + ' @ ' + this.cleanTime());
        };
        break;
      case window['YT'].PlayerState.ENDED:
        // console.log('ended ');
        break;
    }
  }

  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }

  hasNext(): boolean {
    return this.videos.findIndex(v => v.id === this.selectedVideo.id) < this.videos.length - 1;
  }

  hasPrev(): boolean {
    return this.videos.findIndex(v => v.id === this.selectedVideo.id) > 0;
  }

  playPrev() {
    let currentTime = this.player.getCurrentTime();
    currentTime = Math.round(currentTime);
    if (currentTime >= 30) {
      this.videosService.hasWatchedVideo(this.selectedVideo._id, this.admin._id).subscribe(data => {
        this.hasSeen.emit(this.selectedVideo.hasSeen = true)
        const index = this.videos.findIndex(v => v.id === this.selectedVideo.id);
        if (index < this.videos.length) {
          this.selectedVideo = this.videos[index - 1];
          this.player.cueVideoById(this.selectedVideo.id);
          this.selectedVideoChanged.emit(this.selectedVideo);
        }
      });
    } else {
      const index = this.videos.findIndex(v => v.id === this.selectedVideo.id);
      if (index < this.videos.length) {
        this.selectedVideo = this.videos[index - 1];
        this.player.cueVideoById(this.selectedVideo.id);
        this.selectedVideoChanged.emit(this.selectedVideo);
      }
    }
  }

  playNext() {
    let currentTime = this.player.getCurrentTime();
    currentTime = Math.round(currentTime);
    console.log(currentTime)
    if (currentTime >= 30) {
      this.videosService.hasWatchedVideo(this.selectedVideo._id, this.admin._id).subscribe(data => {
        this.hasSeen.emit(this.selectedVideo.hasSeen = true)
        const index = this.videos.findIndex(v => v.id === this.selectedVideo.id);
        if (index < this.videos.length) {
          this.selectedVideo = this.videos[index + 1];
          this.player.cueVideoById(this.selectedVideo.id);
          this.selectedVideoChanged.emit(this.selectedVideo);
        }
      });
    } else {
      const index = this.videos.findIndex(v => v.id === this.selectedVideo.id);
      if (index < this.videos.length) {
        this.selectedVideo = this.videos[index + 1];
        this.player.cueVideoById(this.selectedVideo.id);
        this.selectedVideoChanged.emit(this.selectedVideo);
      }
    }
  }




  submitVideoRating(rating) {
    if (rating == 'liked') {
      this.selectedVideo.hasLiked = true;
      this.selectedVideo.hasDisliked = false;

    }
    else if (rating == 'disliked') {
      this.selectedVideo.hasDisliked = true;
      this.selectedVideo.hasLiked = false;

    }
    this.videosService.rateVideo(this.selectedVideo._id, this.admin._id, rating).subscribe(data => {
      console.log('Rate video', data);


    })

  }

}
