<div class="manager_selection_container">
    <div class="ob_select_container">
        <form>
            <mat-form-field>
                <mat-label>Please Select a Manager</mat-label>
                <mat-select (selectionChange)="applyFilters($event.value)">
                    <mat-option *ngFor="let smbOM of smbOnboardingManagers; let i = index;" [value]="smbOM">
                        <div class="omSelectContainer">
                            <img *ngIf="smbOM.imageURL" src={{smbOM.imageURL}} alt="" class="imageTD">
                            <img *ngIf="!smbOM.imageURL" src='../../../../assets/images/profile_icon.png' alt=""
                                class="imageTD">

                            {{smbOM.firstName}} {{smbOM.lastName}}


                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div class="ob_card_container ml-3" *ngIf="selectedManager && _omSurveyAverageScores">
        <div class="ob_img">
            <div class="omSelectContainer">
                <img *ngIf="selectedManager.imageURL" src={{selectedManager.imageURL}} alt="" class="imageTD_banner">
                <img *ngIf="!selectedManager.imageURL" src='../../../../assets/images/profile_icon.png' alt=""
                    class="imageTD">
                <span>
                    {{selectedManager.firstName}} {{selectedManager.lastName}}
                </span>

            </div>
        </div>
        <div class="scores_container" *ngFor="let score of _omSurveyAverageScores">
            <div class="split">
                <div class="ob_info">
                    <div class="omSelectContainer">
                        <span>Avg OB Health Score:</span> {{avgHealthScore(score)}}%
                    </div>
                </div>
                <div class="ob_info">
                    <div class="omSelectContainer">
                        <span>Avg NPS Score:</span> {{score.avgNps| number : '1.2-2' }}
                    </div>
                </div>

            </div>
            <div class="split">
                <div class="ob_info">
                    <div class="omSelectContainer">
                        <span>Avg OM Score:</span>{{score.avgOmScore | number : '1.2-2'}}
                    </div>
                </div>
                <div class="ob_info">
                    <div class="omSelectContainer">
                        <span>Avg OB Experience Score:</span> {{score.avgObPScore | number : '1.2-2'}}
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<hr>
<table mat-table [dataSource]="dataSource" #adminTable class="mat-elevation-z8" style="width: 100%;"
    multiTemplateDataRows>
    <ng-container matColumnDef="expandedDetail" class="expanded_container">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="mb-5">
            <div class="example-element-detail" [@detailExpand]="element == expandedAdmin ? 'expanded' : 'collapsed'">
                <div class="ng-container">
                    <div class="border-container">
                        <div class="side-border"></div>
                    </div>
                    <div class="ob_health_score_container">
                        <div class="rating_container">
                            <!-- NPS -->
                            <div class="question_title_inline"><span>Net Promotor Score:&nbsp;</span>
                                <div class="answer_container"
                                    *ngIf="element.onboardingSurvey.response.netPromoterScore >= 8">
                                    {{element.onboardingSurvey.response.netPromoterScore}} - Promotor &nbsp; 🎊
                                </div>
                                <div class="rating"
                                    *ngIf="element.onboardingSurvey.response.netPromoterScore < 8 && element.onboardingSurvey.response.netPromoterScore >= 6">
                                    {{element.onboardingSurvey.response.netPromoterScore}} - Passive &nbsp;😐
                                </div>
                                <div class="rating" *ngIf="element.onboardingSurvey.response.netPromoterScore <= 5">
                                    {{element.onboardingSurvey.response.netPromoterScore}} - Detractor &nbsp;😔
                                </div>
                            </div>

                            <!-- OB EXPERIENCE-->
                            <div class="question_title_inline"><span>Onboarding Experience Score:&nbsp;</span>
                                <div class="answer_container"
                                    *ngIf="element.onboardingSurvey.response.rateGlofoxOnboardingProcess >= 8">
                                    {{element.onboardingSurvey.response.rateGlofoxOnboardingProcess}} - Promotor
                                    &nbsp;🎊

                                </div>
                                <div class="rating"
                                    *ngIf="element.onboardingSurvey.response.rateGlofoxOnboardingProcess < 8 && element.onboardingSurvey.response.rateGlofoxOnboardingProcess >= 6">
                                    {{element.onboardingSurvey.response.rateGlofoxOnboardingProcess}} - Passive &nbsp;😐
                                </div>
                                <div class="rating"
                                    *ngIf="element.onboardingSurvey.response.rateGlofoxOnboardingProcess <= 5">
                                    {{element.onboardingSurvey.response.rateGlofoxOnboardingProcess}} - Detractor
                                    &nbsp;😔
                                </div>
                            </div>
                            <div class="question_title_inline"> <span>Onboarding Manager Score:&nbsp;</span>
                                <div class="answer_container" *ngIf="element.onboardingSurvey.response.rateOBM >= 8">
                                    {{element.onboardingSurvey.response.rateOBM}} - Promotor &nbsp; 🎊
                                </div>
                                <div class="rating"
                                    *ngIf="element.onboardingSurvey.response.rateOBM < 8 && element.onboardingSurvey.response.rateOBM >= 6">
                                    {{element.onboardingSurvey.response.rateOBM}} - Passive &nbsp;😐
                                </div>
                                <div class="rating" *ngIf="element.onboardingSurvey.response.rateOBM <= 5">
                                    {{element.onboardingSurvey.response.rateOBM}} - Detractor &nbsp;😔
                                </div>
                            </div>
                            <div class="question_title_inline"><span>Admin Email:&nbsp;</span>
                                <div class="answer_container">
                                    {{ element.email }} &nbsp;

                                </div>
                            </div>
                            <div class="question_title_inline"><span>Date Submitted:&nbsp;</span>
                                <div class="answer_container">
                                    {{element.onboardingSurvey.date_submitted | date}}&nbsp;
                                    <!-- <mat-icon role="img"
                                        color="primary" class="mat-icon notranslate material-icons mat-icon-no-color"
                                        aria-hidden="true">book_online
                                    </mat-icon> -->
                                </div>
                            </div>
                            <div class="question_title_inline"><span> Method Submitted:&nbsp;</span>
                                <div class="answer_container">
                                    {{element.onboardingSurvey.how_submitted}}

                                </div>
                            </div>
                            <div class="question_title_inline"><span>After Sales Expectation Score:&nbsp;</span>
                                <div class="answer_container"
                                    *ngIf="element.onboardingSurvey.response.expectationsScore === 'Yes'">
                                    Pass
                                </div>
                                <div class="answer_container"
                                    *ngIf="element.onboardingSurvey.response.expectationsScore === 'No'">
                                    Fail
                                </div>
                            </div>
                            <div class="question_title_inline"><span> Onboarding Duration EST:&nbsp;</span>
                                <div class="answer_container">
                                    {{ calculateObDurationEst(element)}} Days
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FEED BACK CONTAINE -->
                    <div class="feeback_container">
                        <div class="improvement_container">
                            <div class="question_title">
                                Areas to improve the onboarding experience:
                            </div>
                            <div *ngFor=" let element of element.onboardingSurvey.response.improvements">
                                <div class="answer_container">
                                    <mat-icon color="primary" class="mr-1">fact_check</mat-icon>
                                    &nbsp;{{element}}
                                </div>
                            </div>
                        </div>
                        <div class="notes_container mt-4">
                            <div class="question_title">Glofox Platform Improvement Feedback:</div>
                            <div>
                                <div class="feedback-wrapper">
                                    <!-- <mat-icon color="primary" class=" mr-1">try</mat-icon> -->
                                    &nbsp;{{element.onboardingSurvey.response.otherFeedBack}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="health_score_graph">
                        <div class="question_title">OB Health Score:</div>
                        <div>
                            <div class="circle_wrapper">
                                <circle-progress [percent]="getPercent(element)" [radius]="50" [imageHeight]="25"
                                    [outerStrokeWidth]="6" [innerStrokeWidth]="4" [outerStrokeColor]="'#43D8C4'"
                                    [innerStrokeColor]="'#330F2E'" [animation]="true" [animationDuration]="300"
                                    [titleColor]="'#330F2E'" [subtitleColor]="'#330F2E'" [subtitle]="'Health Score'">
                                </circle-progress>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let admin">{{admin.firstName}} {{admin.lastName}} </td>
    </ng-container>

    <!-- Branch Column -->
    <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef> Branch </th>
        <td mat-cell *matCellDef="let admin"> {{admin.branch.name}} </td>
    </ng-container>

    <!-- OBM Column -->
    <ng-container matColumnDef="obmanager">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> OB Managers </th>
        <td mat-cell *matCellDef="let admin" style="text-align: center;"> {{admin.obmanager.firstName}} </td>
    </ng-container>

    <!-- NPS Score Column -->
    <ng-container matColumnDef="nps">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Net Promotor Score </th>
        <td mat-cell *matCellDef="let admin" style="text-align: center;">
            {{admin.onboardingSurvey.response.netPromoterScore}}
        </td>
    </ng-container>

    <!-- Health Score Column -->
    <ng-container matColumnDef="obhealthscore">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> OB Health Score </th>
        <td mat-cell *matCellDef="let admin" style="text-align: center;">
            {{getPercent(admin)}}%
        </td>
    </ng-container>
    <!-- Date Submitted Column -->
    <ng-container matColumnDef="dateSubmitted">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Date Submitted </th>
        <td mat-cell *matCellDef="let admin" style="text-align: center;">
            {{admin.onboardingSurvey.date_submitted | date}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedAdmin === row"
        class="example-element-row clickable" (click)="expandedAdmin = expandedAdmin === row ? null : row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<div *ngIf="!adminSurveyData.length">
    <mat-card>
        <div class="empty_state" style="margin: auto; font-weight: bold;">
            There are no survey responses associated with this Onboarding Manager.
        </div>
    </mat-card>
</div>

<!-- <div *ngFor="let admin of adminSurveyData">
    <div>{{adin.firstName}} </div>
</div> -->