import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MasterList } from 'src/models/masterlist';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OBManager } from 'src/models/ob-manager';
import { ManagerService } from 'src/modules/manager-portal/services/manager.service';
import { TrackService } from 'src/app/services/track.service';
import { Option } from 'src/models/option';
import { Track } from 'src/models/track';
import { Stage } from 'src/models/stage';
import { Job } from 'src/models/job';
import { FormControl } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TrackBuilderService } from '../../services/track-builder.service';
import { Subscription, Subject } from 'rxjs';
import { TestService } from 'src/app/services/test.service';
import { LaunchDemoDialogComponent } from '../dialogs/launch-demo-dialog/launch-demo-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-track-builder-parent',
  templateUrl: './track-builder-parent.component.html',
  styleUrls: ['./track-builder-parent.component.sass']
})
export class TrackBuilderParentComponent implements OnInit, OnDestroy {

  masterlist: MasterList;
  manager: OBManager;
  options: Option[] = [];

  selectedTrackControl: FormControl;
  selectedTrackLoading = false;
  selectedTrack: Track;
  selectedStage: Stage;
  selectedJob: Job;

  private unsubscribe$ = new Subject<void>();

  selectedStageSubscription: Subscription;
  newTestSubscription: Subscription;
  stageDeletedSubscription: Subscription;
  newStageAddedSubscription: Subscription;

  categorisedOptions: {}[] = [];

  @ViewChild('group') group: MatButtonToggleGroup;

  loading = false;

  

  constructor(private auth: AuthenticationService, private managerService: ManagerService, private trackService: TrackService, private trackBuilderService: TrackBuilderService, private testService: TestService, private dialog: MatDialog, private notificationService: NotificationService) { 
    this.selectedStageSubscription = this.trackBuilderService.joblistChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedStage.jobs = data;
    });

    this.newTestSubscription = this.trackBuilderService.newTestAdded$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedStage.test = data;
    });



    this.newStageAddedSubscription = this.trackBuilderService.newStageAdded$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedStage._id = data._id;
    })
   }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.loading = true;
      const user = this.auth.getUserDetails();
      this.managerService.getManager(user.email).subscribe((data: OBManager) => {
        this.manager = data;

        if (this.manager.market === 0) {
          this.trackService.getMasterlist('5dd81dc70a57b73f1c6d5632').subscribe(mlData => {
            this.masterlist = mlData;
            this.options = this.masterlist.options;
            this.trackService.getCategorisedOptions('5dd81dc70a57b73f1c6d5632').subscribe(data => {
              this.categorisedOptions = data;
              this.selectTrack(this.masterlist.tracks[0]);
    
  
              this.selectedTrackControl = new FormControl(this.selectedTrack);
              this.selectedTrackControl.valueChanges.subscribe(trk => {
                this.selectTrack(trk);
                // this.stageTableDS = this.selectedTrack.stages;
                // this.selectedStage = this.selectedTrack.stages[0];
                // this.jobTableDS = this.selectedStage.jobs;
                // this.selectJob(this.selectedStage.jobs[0]);
              })
         
              this.loading = false;
            })
          });
        } else if (this.manager.market === 1) {
          this.trackService.getMasterlist('5e39889453d23868583f9df1').subscribe(mlData => {
            this.masterlist = mlData;
            
            this.options = this.masterlist.options;
            this.selectTrack(this.masterlist.tracks[0]);


            this.selectedTrackControl = new FormControl(this.selectedTrack);
            this.selectedTrackControl.valueChanges.subscribe(trk => {
              this.selectTrack(trk);
              
              // this.stageTableDS = this.selectedTrack.stages;
              // this.selectStage(this.selectedTrack.stages[0]);
              // this.selectedStage = this.selectedTrack.stages[0];
              // this.jobTableDS = this.selectedStage.jobs;
              // this.selectJob(this.selectedStage.jobs[0]);
            })
            this.loading = false;
          });
        } else if (this.manager.market === 5) {
          this.trackService.getMasterlist('5e46b1b3f390b5165ebf7548').subscribe(mlData => {
            this.masterlist = mlData;
            this.options = this.masterlist.options;
            this.selectTrack(this.masterlist.tracks[0]);


            this.selectedTrackControl = new FormControl(this.selectedTrack);
            this.selectedTrackControl.valueChanges.subscribe(trk => {
              this.selectTrack(trk);
              // this.stageTableDS = this.selectedTrack.stages;
              // this.selectedStage = this.selectedTrack.stages[0];
              // this.jobTableDS = this.selectedStage.jobs;
              // this.selectJob(this.selectedStage.jobs[0]);

            })
            this.loading = false;
          });
        }

      });

 
    }
  }

  selectTrack(track: Track) {
    this.selectedTrackLoading = true;
    this.trackService.getTrack(track._id).subscribe(data => {
      this.selectedTrack = data;
      this.selectedTrackLoading = false;
    })
  }

  changeSelectedStage(stage: Stage) {
    this.selectedStage = stage;
    
    if (!this.selectedStage._id) {
      this.group.writeValue('details');
    }

    if (this.selectedStage.test) {
      this.testService.getTestForStage(this.selectedStage._id).subscribe(data => {
        this.selectedStage.test = data;
      })
    }
  }

  changeSelectedJob(job: Job) {
    this.selectedJob = job;
  }

  stageUpdated(stage: Stage) {
    this.selectedStage = stage;
    
  }

  resetDemoBranch() {
    this.trackService.resetDemoBranch(this.selectedTrack._id).subscribe(data => {
      this.notificationService.showSuccess(data.message)
    });
  }


  ngOnDestroy() {
    // this.newTestSubscription.unsubscribe();
    // this.selectedStageSubscription.unsubscribe();
    // this.stageDeletedSubscription.unsubscribe();
    // this.newStageAddedSubscription.unsubscribe()

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  trackHasTranslation(trans: string): boolean {
    return this.selectedTrack.translations.includes(trans);
  }

  updateStageTranslations(checked: boolean, trans: string) {
    if (checked) {
      this.selectedTrack.translations.push(trans);
    } else {
      this.selectedTrack.translations = this.selectedTrack.translations.filter(t => t !== trans);
    }
    this.trackService.updateTrackTranslations(this.selectedTrack._id, this.selectedTrack.translations).subscribe(_ => {
      
    })
  }

  openDemoDialog() {
    const dialogRef = this.dialog.open(LaunchDemoDialogComponent, {
      width: '600px',
      data: {
        track: this.selectedTrack,
        options: this.options
      }
    });

    dialogRef.afterClosed().subscribe(res => {
        this.trackService.createDemoBranch(this.selectedTrack._id, res).subscribe((data) => {
          this.selectedTrack.demoBranch = data._id;
          this.notificationService.showSuccess(`Demo branch for ${this.selectedTrack.title} track has been created.`);
        })   
    })
  }

}
