import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import * as jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private auth: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('glofox-token');
    // console.log(expectedRole);
    
    if (token) {
      const payload_token = jwtDecode(token);
      // console.log(payload_token['role'], expectedRole);
      
      if (!this.auth.isLoggedIn() || payload_token['role'] !== expectedRole) {
        this.router.navigate(['/']);
        return false;
      } else {
        return true;
      }
    }

    this.router.navigate(['/']);
    return false;

  }
}
