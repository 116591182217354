import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from 'src/models/article';
import { Category } from 'src/models/category';
import { Subcategory } from 'src/models/subcategory';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  readonly URL = 'https://glofox.zendesk.com/api/v2/help_center';
  validArticleIds: number[] = [];
  validArticles: Article[] = [];
  categories: CategoryJSON[] = [];

  constructor(private http: HttpClient) { }

  getArticle(id: number): Observable<any> {
    // make api call
    let currentLang = window.localStorage.getItem('currentLang');
    let locale = 'en-us';
    if (currentLang) {
        switch (currentLang) {
            case 'es':
                locale = 'es-es';
                break;
            case 'ja':
                locale = 'ja';
                break;
            default:
                locale = 'en-us';
        }
    }


    return this.http.get(`${this.URL}/${locale}/articles/${id}.json`);
}
getArticleAttachments(id: number): Observable<any> {
  return this.http.get(`${this.URL}/en-us/articles/${id}/attachments/inline.json`);
}

getImage(attachmentId: string): Observable<any> {
  return this.http.get(`${this.URL}/en-us/articles/attachment/${attachmentId}.json`);
}

getSection(sectionId: number): Observable<any> {
return this.http.get(`${this.URL}/en-us/sections/${sectionId}.json`);
}

getCategory(categoryId: number): Observable<any> {
return this.http.get(`${this.URL}/en-us/categories/${categoryId}.json`);
}

getCategories(): Observable<any> {
return this.http.get(`${this.URL}/categories.json?per_page=100`);
}

getSections(): Observable<any> {
return this.http.get(`${this.URL}/sections.json?per_page=100`);
}

getSectionsForCategory(categoryId: number): Observable<any> {
  return this.http.get(`${this.URL}/categories/${categoryId}/sections.json`)
}

getArticlesForCategory(categoryId: number): Observable<any> {
  return this.http.get(`${this.URL}/categories/${categoryId}/articles.json?page=1&per_page=100`)
}

getArticles(page: number): Observable<any> {
return this.http.get(`${this.URL}/articles.json?page=${page}&per_page=100`);
}

getAllArticles(page = 1): Observable<any> {
  return this.http.get(`${this.URL}/articles.json?page=${page}&per_page=100`);
}

searchArticles(query: string): Observable<any> {
  return this.http.get(`${this.URL}/articles/search.json?query=${query}`);
}

private categoriseArticles(articles: Article[]): Category[] {
  const categories: Category[] = [];
  let intersection: number[];
  let newCat: Category;
  let newSubCat: Subcategory;
  this.categories.forEach(cat => {
      newCat = new Category();
      newCat.name = cat.name;

      if (cat.articleIds) {
          intersection = this.validArticleIds.filter(aId => cat.articleIds.includes(aId));
          if (intersection && intersection.length) {
              intersection.forEach(artId => {
                  const artJSON = articles.find(a => a.id === artId);
                  if (artJSON) {
                      const newArt = new Article();
                      // tslint:disable-next-line:no-string-literal
                      newArt.id = artJSON.id;
                      newArt.title = artJSON.title;
                      newArt.body = artJSON.body;
                      newCat.articles.push(newArt);
                  }
              });
          }
      }

      if (cat.subcategories) {
          cat.subcategories.forEach(subcat => {
              intersection = this.validArticleIds.filter(aId => subcat.articleIds.includes(aId));
              if (intersection && intersection.length) {
                  newSubCat = new Subcategory();
                  newSubCat.name = subcat.name;
                  intersection.forEach(artId => {
                      const artJSON = articles.find(a => a.id === artId);
                      if (artJSON) {
                          const newArt = new Article();
                          // tslint:disable-next-line:no-string-literal
                          newArt.id = artJSON.id;
                          newArt.title = artJSON.title;
                          newArt.body = artJSON.body;
                          newSubCat.articles.push(newArt);
                      }
                  });
                  if (newSubCat.articles.length) {
                      // newCat.subcategories.push(newSubCat);
                  }
              }
          });
      }
      // if (newCat.articles.length || newCat.subcategories.length) {
      //     categories.push(newCat);
      // }
  });
  return categories;
}



filterArticlesAndCategorise(articles: Article[]): Category[] {
  const filteredArticles = articles;
  return this.categoriseArticles(filteredArticles);
}
}
interface CategoryJSON {
  name: string;
  articleIds?: number[];
  subcategories?: {
      name: string;
      articleIds: number[];
  }[];
}