import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public snackBar: MatSnackBar,  private zone: NgZone,) { }

  showSuccess(message: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['green-notification'];
    this.snackBar.open(message, 'close', config);
  }

  showError(message: string): void {
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    this.zone.run(() => {
      this.snackBar.open(message, 'close');
    })
  }
}
