<div class="container-fluid" style="min-height: 65vh; font-family: Soleil, sans-serif;" *ngIf="standaloneApp else loading">
    <div class="m-5">
        <app-standalone-progress-tracker [standaloneApp]="standaloneApp" ($changeStep)="changeStep($event)"></app-standalone-progress-tracker>
    </div>
    <div [ngSwitch]="userFlow">
        <div  *ngSwitchCase="'config-form'">
            <app-standalone-builder-config-form  [standaloneApp]="standaloneApp" (formSubmittedSuccesfully)="showAppIconBuilder($event)"></app-standalone-builder-config-form>
        </div>

        <div *ngSwitchCase="'app-icon'">
            <app-standalone-icon-builder [standaloneApp]="standaloneApp" (appIconSubmittedSuccessfully)="showSplashScreenBuilder($event)"></app-standalone-icon-builder>
        </div>
        <div *ngSwitchCase="'splash-screen'">
            <app-standalone-splashscreen-builder  [standaloneApp]="standaloneApp" (splashScreenSubmittedSucessfully)="showReview($event)"></app-standalone-splashscreen-builder> 
        </div>
        <div *ngSwitchCase="'review'">
            <app-standalone-app-review [(standaloneApp)]="standaloneApp" ></app-standalone-app-review>          
        </div>
        <p *ngSwitchDefault>No app found. Please contact your onboarding manager.</p>
    </div>
    
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>