import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faFilm, faCheck } from '@fortawesome/free-solid-svg-icons';
import { StandAloneApp } from 'src/models/standalone-app';

@Component({
  selector: 'app-standalone-progress-tracker',
  templateUrl: './standalone-progress-tracker.component.html',
  styleUrls: ['./standalone-progress-tracker.component.sass']
})
export class StandaloneProgressTrackerComponent implements OnInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output()
  $changeStep: EventEmitter<string> = new EventEmitter();

  tickIcon = faCheck;

  constructor() { }

  ngOnInit() {
  }

  changeStep(step: string) {
    this.$changeStep.emit(step);  
  }

  showReviewStep() {
    if (this.standaloneApp.iconHTMLCreated && this.standaloneApp.splashScreenHTMLCreated) {
      this.changeStep('review');
    }
  }
  

}
