import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page-parent',
  templateUrl: './landing-page-parent.component.html',
  styleUrls: ['./landing-page-parent.component.sass']
})
export class LandingPageParentComponent implements OnInit {

  auth: AuthenticationService;

  constructor(auth: AuthenticationService, private router: Router) {
    this.auth = auth;
  }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      if (this.auth.getUserDetails().role === 'client') {
        this.router.navigate(['client-dashboard']);
      } else if (this.auth.getUserDetails().role === 'manager') {
        this.router.navigate(['manager-dashboard']);
      } else if (this.auth.getUserDetails().role === 'webTeam') {
        this.router.navigate(['web-team-dashboard']);
      }
    }
  }
}
