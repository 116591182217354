import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebTeamDashboardComponent } from './components/web-team-dashboard/web-team-dashboard.component';
import { WebTeamPortalComponent } from './components/web-team-portal/web-team-portal.component';
import { WebTeamNavbarComponent } from './components/web-team-navbar/web-team-navbar.component';
import { RouterModule } from '@angular/router';
import { WebTeamPortalRoutingModule } from './web-team-portal-routing.module';
import { SharedModule } from '../shared/shared.module';
import { WebTeamService } from './services/web-team.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { WebIntDetailsComponent } from './components/web-int-details/web-int-details.component';
// import { AssignedWebIntsComponent } from './components/assigned-web-ints/assigned-web-ints.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntercomModule } from 'ng-intercom';
import { BranchesWithSmtComponent } from './components/branches-with-smt/branches-with-smt.component';

@NgModule({
  declarations: [WebTeamDashboardComponent, WebTeamPortalComponent, WebTeamNavbarComponent, WebIntDetailsComponent, BranchesWithSmtComponent],
  imports: [
    CommonModule,
    WebTeamPortalRoutingModule,
    SharedModule,
    RouterModule,
    MatTableModule,
    MatButtonModule,
    BrowserModule,
    MatTableModule,
    MatSortModule,
    BrowserAnimationsModule,
    CdkTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatInputModule,
    IntercomModule,
    MatPaginatorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonToggleModule
  ],
  providers: [
    WebTeamService
  ]
})
export class WebTeamPortalModule { }
