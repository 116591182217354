import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Branch } from 'src/models/branch';
import { WebIntegration } from 'src/models/web-integration';
import { Admin } from 'src/models/admin';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AdminService } from '../../services/admin.service';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { WebIntService } from 'src/modules/manager-portal/services/web-int.service';

@Component({
  selector: 'app-webint-interactive-job',
  templateUrl: './webint-interactive-job.component.html',
  styleUrls: ['./webint-interactive-job.component.sass']
})
export class WebintInteractiveJobComponent implements OnInit, AfterViewInit {


  branch: Branch;
  websiteIntegration: WebIntegration;

  admin: Admin;


  // form booleans
  showRequestForm = false;

  constructor(private auth: AuthenticationService, private adminService: AdminService,
    public branchService: BranchService, public webIntService: WebIntService) { }

  ngOnInit() {

    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        this.admin = adminData;
  
        this.adminService.getBranchForAdmin(this.admin).subscribe(branchData => {
          this.branchService.updateLastOnlineAt(branchData._id).subscribe(data => {
          });
          this.branch = Object.assign(new Branch(), branchData);



          if (this.branch.webIntegration) {

            this.branchService.getWebIntegration(this.branch.webIntegration._id).subscribe(webIntData => {
              this.websiteIntegration = webIntData;

            })
          }

        });
      });
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);

  }



  addWebIntDetails() {
    this.showRequestForm = true;
    window.scrollTo(0, 0);


  }
  back() {
    this.showRequestForm = false;
  }


  onRequestSucessfullySubmitted(event) {
    this.branch.webIntegration = event;
    this.showRequestForm = false;
  }

}
