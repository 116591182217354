<div class="loading" *ngIf="loading">
  <div>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<div *ngIf="!loading" class="container" style="height:100%;">
  <div class="row">
    <div class="col-12 intro-text">
      <h3>Integrating Glofox with your WIX Website</h3>
      <hr />
      <div class="pt-3  info_txt">Due to how WIX displays embedded code you will need to adjust the height and width for <strong>both</strong> the desktop and mobile versions of your website.</div>
      <div class="pt-3 pb-3 info_txt">Please contact <b><a href="mailto:webdevelopment@glofox.com?Subject=Web%20query" target="_top">webdevelopment@glofox.com</a></b> for further queries.</div>
      <button type="button" mat-raised-button (click)="scrollToFAQ()">Read our Wix Integration Guide</button>
      <a href="https://support.wix.com/en/the-wix-editor/editor-basics" target="_blank" class="ml-3"><button type="button" mat-raised-button>Visit the WIX Knowledge Base</button></a>
    </div>

    </div>


  <hr />
  <div class="row">
    <!-- <div class="wistia_responsive_wrapper" style="height:100%;left:0; top:0;width:100%;"><iframe
      src="https://fast.wistia.net/embed/iframe/34lqgnq4qn?videoFoam=true" title="wix Video" allowtransparency="true"
      frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen
      webkitallowfullscreen qualityMax oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script> -->

    <div style="width:100%;height:0px;position:relative;padding-bottom:53.125%;"><iframe src="https://streamable.com/e/5qunhd" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>

  </div>

  <div class="row mt-4" id="integration-tips">
    <div class="col-12 mt-3">
      <h4>Wix Integration Guide</h4>
      <hr>
    </div>

    <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Adding Glofox to your WIX Website.</h5>
      <div class="row">
        <div class="col-12">
          <div>
            <div class="pt-2 pb-2">Integrating Glofox with your WIX website is as simple as pasting a code snippet into WIX's iframe element.</div>
            <div>
              Just follow these steps:
            </div>
          </div>

          <ol class="mt-2">
            <li>Copy the relevant snippet from the Website Code Snippets page (see the menu on the left).</li>
            <li>Add a new page or select an existing page.</li>
            <li class="mb-3">Next,the click on the + symbol to add content. Click on 'More' and choose 'HTML iframe' from Custom Embeds.</li>
            <img style="max-width: 900px" src="../../../../assets/images/wix-embed.png" alt="">

            <li class="mt-3 mb-3">  Once WIX has added the element to page, click 'Enter Code', paste the code from step one into the textarea, making sure 'Code' is selected..</li>
            <img style="max-width: 500px; margin: auto; padding: .5rem;" src="../../../../assets/images/wix-add-code.png" alt="" srcset="">

          </ol>
        </div>

      </div>
    </div>
       
    <div class="col-12 p-3" style="background-color: #fff;">
        
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Setting the Height and Width</h5>
      <div class="pt-2 pb-2"> First, set the element's width by dragging the edges of the element left and right to fill the are between the vertical dashed lines. </div>
      <div class="pt-2 pb-2">WIX forces the iframe to have a fixed, or static, height. Because of this, you will need to allow enough space for the iframe to expand into depending on it's content.</div>
      <div class="pt-2 pb-2">You can adjust the height be dragging the the container downwards or by altering the height via the settings.</div>
      <img src="../../../../assets/images/resize-wix-height.png" style="width: 100%" alt="" srcset="">
    </div>

    <div class="col-12 mt-3 p-3" style="background-color: #fff;">
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Optimising for Mobile Devices</h5>
      <div class="pt-2 pb-2">Every page will need to be optimised for for mobile devices. To adjust the settings for mobile devices, click on the mobile icon in the navbar.</div>
      <div class="d-flex justify-content-center mt-3 mb-3">
        <img src="../../../../assets/images/wix-mobile.png" alt="">
      </div>
      <div class="pt-4">
        Next, you can drag the container to fit the necessary width and height, as you did for the desktop version.
      </div>

      <div class="pt-4">
        Finally, don't forget to publish your changes!
      </div>
    </div>
  </div>
</div>

