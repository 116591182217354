import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LeadCaptureData } from '../../models/lead-capture.model';


@Component({
  selector: 'app-lead-capture',
  templateUrl: './lead-capture.component.html',
  styleUrls: ['./lead-capture.component.sass']
})
export class LeadCaptureComponent implements OnInit {

  @Input() potato: LeadCaptureData;

  leadCaptureSnippets = [

    {title : 'Default (Recommended)', subtitle: 'This is most common version of Lead Capture.', feature: ''},
    {title : 'Presales', subtitle: 'User not given the option to book a class while signing up.', feature: '?skip_events=true'},
    {title : 'Skip Password', subtitle: 'Moves the password field from the first page to the last page.', feature: '?requires_password=false'},
    {title : 'Presales & Skip Password', subtitle: 'Skips the calendar and moves the password field from the first page to the last page.', feature: '?skip_events=true&requires_password=false'}

  ];
  contactCaptures = [
    {title : 'Contact Capture', subtitle: "Captures the users basic info - does not sign them up for a membership.", feature: ''}
  ];
  constructor(private toastr: ToastrService) { }

  showSuccess() {
    this.toastr.success('Copied to Clipboard!');
  }
  ngOnInit() { }

  generateLeadCapture(feature: string): string {
    const leadCaptureCode = `
    <div id="hero-form">
    <div id="iframecontainer">
       <link rel="stylesheet" type="text/css" href="https://lead-capture-stylesheet.s3-eu-west-1.amazonaws.com/leadcapstyles.css"/>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js"></script>
        <iframe
            class="glofox_iframe" id="glofox_${this.potato.branchId}"
            src="https://app.glofox.com/portal/#/branch/${this.potato.branchId.trim()}/lead-register/membership/${this.potato.membershipId}/plan/${this.potato.planId}${feature}"
            width="0" height="0" scrolling="yes" frameborder="0"></iframe>
        <script>iFrameResize({
                log: false,
                checkOrigin: false,
                tolerance: 0,
                sizeHeight: true,
                heightCalculationMethod: 'lowestElement',
                minHeight: 300,
                maxHeight: 1800,
                sizeWidth: true,
                widthCalculationMethod: 'bodyOffset',
                maxWidth: 980,
                scrolling: 'auto'
              }, '#glofox_${this.potato.branchId.trim()}')
              </script>
    </div>
    <p id="poweredbyGlofox">powered by <a href="https://www.glofox.com"><b>Glofox</b></a></p>
</div>
    `;
    return leadCaptureCode;
  }

  generateContactCapture(data: string): string {
    const contactCaptureCode = `

    <div id="hero-form">
    <div class="glofox_iframe" id="iframecontainer">
        <link rel="stylesheet" type="text/css" href="https://lead-capture-stylesheet.s3-eu-west-1.amazonaws.com/leadcapstyles.css"/>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js"></script>
        <iframe
            class="glofox_iframe"
            id="glofox_${this.potato.branchId}"
            src="https://app.glofox.com/portal/#/branch/${this.potato.branchId.trim()}/lead-register"
            width="0" height="0" scrolling="yes" frameborder="0"></iframe>
        <script>iFrameResize({
                log: false,
                checkOrigin: false,
                tolerance: 0,
                sizeHeight: true,
                heightCalculationMethod: 'lowestElement',
                minHeight: 300,
                maxHeight: 1800,
                sizeWidth: true,
                widthCalculationMethod: 'bodyOffset',
                maxWidth: 980,
                scrolling: 'auto'
            }, '#glofox_${this.potato.branchId}')
        </script>
    </div>
    <p id="poweredbyGlofox">powered by <a href="https://www.glofox.com"><b>Glofox</b></a></p>
</div>

    `;

    return contactCaptureCode;

  }

  copyToClipBoard(value: string) {
    const leadCodeBlock = document.createElement('textarea');
    leadCodeBlock.style.position = 'fixed';
    leadCodeBlock.style.left = '0';
    leadCodeBlock.style.top = '0';
    leadCodeBlock.style.opacity = '0';
    leadCodeBlock.value = value;
    document.body.appendChild(leadCodeBlock);
    leadCodeBlock.focus();
    leadCodeBlock.select();
    document.execCommand('copy');
    document.body.removeChild(leadCodeBlock);
    this.showSuccess();
  }

}
