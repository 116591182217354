import { Test } from './test';

export class Question {
    _id: string;
    question: string;
    options: {potentialAnswer: string, isCorrect: boolean, selected?: boolean}[] = [];
    gotCorrect?: boolean;
    followUpMsg: string;
    test: Test;
    translations: {language: string, translation: {question: string, options: string[]}}[] = [];
}