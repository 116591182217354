<form [formGroup]="onboardingExperienceSurvey" #ngForm="ngForm" class="form-container">

    <!-- section start -->
    <div class="form-element-full">
        <label class="mb-4 mt-3 ">{{"onboarding.survey.form_nps_question" | translate}}<span
                style="color: #f44336">*</span>
            <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('netPromoterScore').invalid}">
                {{"onboarding.survey.form_error_msg" | translate}} &nbsp; <mat-icon aria-hidden="false"
                    aria-label="Example home icon">error_outline
                </mat-icon>
            </div>
        </label>
        <mat-radio-group class="example-radio-group" formControlName="netPromoterScore" name="netPromoterScore">
            <div class=" mr-4 rating">{{"onboarding.survey.form_not_likely" | translate}}</div>
            <mat-radio-button class="example-radio-button" *ngFor="let netScore of score; let i = index"
                [value]="netScore">
                {{netScore}}
            </mat-radio-button>
            <div class="ml-4 rating">{{"onboarding.survey.form_likely" | translate}}</div>
        </mat-radio-group>
    </div>
    <span></span>

    <!-- section start -->
    <div class="form-element-full">
        <label class="mb-4 mt-3 ">{{"onboarding.survey.form_rate_ob_process" | translate}}<span
                style="color: #f44336">*</span>
            <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('rateGlofoxOnboardingProcess').invalid}">
                {{"onboarding.survey.form_error_msg" | translate}}&nbsp; <mat-icon aria-hidden="false"
                    aria-label="Example home icon">error_outline
                </mat-icon>
            </div>
        </label>
        <mat-radio-group class="example-radio-group" formControlName="rateGlofoxOnboardingProcess"
            name="rateGlofoxOnboardingProcess">
            <div class=" mr-4 rating">{{"onboarding.survey.form_poor" | translate}}</div>
            <mat-radio-button class="example-radio-button" *ngFor="let onboardingScore of score; let i = index"
                [value]="onboardingScore">
                {{onboardingScore}}
            </mat-radio-button>
            <div class="ml-4 rating">{{"onboarding.survey.form_seamless" | translate}}</div>
        </mat-radio-group>
    </div>
    <span></span>

    <!-- section start -->
    <div class="form-element-full">
        <label class="mb-4 mt-3 ">{{"onboarding.survey.form_rate_ob_manager" | translate }}<span
                style="color: #f44336">*</span>
            <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('rateOBM').invalid}">
                {{"onboarding.survey.form_error_msg" | translate}}&nbsp; <mat-icon aria-hidden="false"
                    aria-label="Example home icon">error_outline
                </mat-icon>
            </div>
        </label>
        <mat-radio-group class="example-radio-group" formControlName="rateOBM" name="rateOBM">
            <div class=" mr-4 rating">{{"onboarding.survey.form_poor" | translate}}</div>
            <mat-radio-button class="example-radio-button" *ngFor="let rateOBM of score; let i = index"
                [value]="rateOBM">
                {{rateOBM}}
            </mat-radio-button>
            <div class="ml-4 rating">{{"onboarding.survey.form_excellent" | translate}}</div>
        </mat-radio-group>
    </div>
    <span></span>
    <!-- section start -->

    <div class="form-element-full">
        <label class="mb-4 mt-3 ">{{"onboarding.survey.form_rate_expectation_after_sale" | translate }}<span
                style="color: #f44336">*</span>
            <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('expectationsScore').invalid}">
                {{"onboarding.survey.form_error_msg" | translate}} &nbsp; <mat-icon aria-hidden="false"
                    aria-label="Example home icon">error_outline
                </mat-icon>
            </div>
        </label>
        <mat-radio-group class="example-radio-group" formControlName="expectationsScore" name="expectationsScore">
            <!-- <div class=" mr-4 rating">Not effective or clear</div> -->
            <mat-radio-button class="example-radio-button" *ngFor="let expectationsScore of scoreBoolean; let i = index"
                [value]="expectationsScore" name="expectationsScore">
                {{expectationsScore}}
            </mat-radio-button>
            <!-- <div class="ml-4 rating">Clear and Effective</div> -->
        </mat-radio-group>
    </div>
    <span></span>
    <!-- section start -->

    <div class="form-element-full">
        <label class="mb-4 mt-3 ">{{"onboarding.survey.form_please_tick_boxes" | translate}}

            <!-- <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                        [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('improvements').invalid}">
                        *
                        Required&nbsp; <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline
                        </mat-icon>
                    </div> -->
        </label>
        <div *ngFor="let improvement of improvements; let i = index">
            <mat-checkbox class="example-margin" (change)="onChange($event)" [value]="improvement">
                {{improvement}}
            </mat-checkbox>
        </div>

    </div>
    <!-- section start -->
    <span></span>
    <div class="form-element-full">
        <label class=" mb-4 mt-3">{{"onboarding.survey.form_please_provide_feedback" | translate }}<span
                style="color: #f44336">*</span>
            <div class="d-flex mt-2 " *ngIf="ngForm.submitted && onboardingExperienceSurvey.invalid"
                [ngClass]="{'invalid': ngForm.submitted && onboardingExperienceSurvey.get('otherFeedBack').invalid}">
                {{"onboarding.survey.form_error_msg" | translate}} &nbsp; <mat-icon aria-hidden="false"
                    aria-label="Example home icon">error_outline
                </mat-icon>
            </div>
        </label>
        <mat-form-field>
            <textarea matInput placeholder="Ex.." formControlName="otherFeedBack"></textarea>
        </mat-form-field>
    </div>

    <mat-card-actions>
        <button (click)="saveForm()" mat-raised-button color="primary"
            style="color:#330F2E">{{"stage_details.feedback.submit" | translate}}</button>
    </mat-card-actions>
</form>