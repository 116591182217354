import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-add-developer',
  templateUrl: './add-developer.component.html',
  styleUrls: ['./add-developer.component.sass']
})
export class AddDeveloperComponent implements OnInit {
  addDeveloper: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {


  }

}
