<!-- <div class="container" *ngIf="!showAddAppDetailsForm"> -->
  <div class="container" >
    <div class="title-container">
      <h3 class="mt-5" class="title">Customise Your Android banner</h3>
      <div class="sub-title">On this page, you are going to design your app's splash screen! <br> This is the
        screen that will be displayed as the app loads upon being opened.</div>
    </div>
    <div class="row mt-4">
          <div class="step col">
            <h6 class="step-title">Step 1:<br> Upload your logo.</h6>
            <span style="color:red;" *ngIf="message" class="step-sub-title">{{message}}</span>
            <input #logoFiles type="file" mat-raised-button accept='image/*' (change)="preview(logoFiles.files)" />
          </div>
          <div class="step col bgColorUpdated">
            <h6 class="step-title">Step 2:<br>Choose your background color.</h6>
            <span class="step-sub-title">Click here to pick your color: </span>
            <input type="color" id="bannerAppSplashColor" (change)="bgColorUpdated()" name="bannerAppSplashColor" value="#eeeeee">
          </div>
          <div class="step col">
            <h6 class="step-title">Step 3:<br> Adjust the size of your logo.</h6>
            <form action="/action_page.php">
              <label class="mt-2 mr-4 step-sub-title" for="vol">Image Size:</label>
              <input type="range" id="bannerAppWidth" (change)="updateWidth()" name="width" value="150" min="150" max="350">
            </form>
          </div>
          <!-- <div class="step">
            <h6 class="step-title ">Step 4: Adjust the position of your logo.</h6>
            <div class="step-sub-title">Please drag the logo to position it on the screen.</div>
            <button class="mt-3" mat-raised-button (click)="centerLogo()">Recenter Logo horizontally</button>
          </div> -->
      </div>
      <div class="col-12">
        <div id="logoWrapper">
          <div id="bannerContainerDropZone" [ngStyle]="{'background-color': bannerSplashColor}"
            >
            <div id="appLogo" draggable="false">
              <img id="myLogoImg" [src]="imgURL" [width]="bannerAppLogoWidth" *ngIf="imgURL" alt="your image">
            </div>
          </div>
        </div>
        <div class="final_step mt-5" >
          <h6 class="step-title">Step 5: Submit to the Glofox App Team.</h6>
          <div class="step-sub-title">Thank you for designing your Android banner.</div>
          <button class="mt-3" mat-raised-button color="primary" (click)="submit()">Submit</button>
        </div>
      </div>


  </div>
