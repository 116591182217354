import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-new-branch-dialog',
  templateUrl: './new-branch-dialog.component.html',
  styleUrls: ['./new-branch-dialog.component.sass']
})
export class NewBranchDialogComponent implements OnInit {

  managerId: string;

  constructor(
    public dialogRef: MatDialogRef<NewBranchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.managerId = data.managerId;
     }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onBranchCreated(branch: Branch) {
    this.dialogRef.close(branch);
  }
}
