import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'webIntStatus'
})
export class WebIntStatusPipe implements PipeTransform {

  STATUS = ['Queued',  'Website Accessed', 'In Build', 'Complete', 'Disengaged', 'All'];
  transform(value: any, ...args: any[]): any {

    if ( value < this.STATUS.length) {
       return this.STATUS[value];
    }
    return value;
  }


}
