import { Component, OnInit, Input, EventEmitter, Output, AfterContentInit, AfterViewInit } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';

@Component({
  selector: 'app-standalone-icon-builder',
  templateUrl: './standalone-icon-builder.component.html',
  styleUrls: ['./standalone-icon-builder.component.sass', '../standalone-builder-parent/screen.css', '../standalone-builder-parent/sceen-apps.css']
})
export class StandaloneIconBuilderComponent implements OnInit, AfterViewInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output() 
  appIconSubmittedSuccessfully: EventEmitter<StandAloneApp> = new EventEmitter();

  public imagePathLogo;
  imgURL: any;
  public message: string;

  initMouseX;
  initMouseY;

  finalMouseX;
  finalMouseY;
  prevEvent: MouseEvent;

  logoFile: File;
  logoURL: string;

  splashColor: string;
  appLogoWidth = 250;

  heightCoefficient = 1;

  constructor(private staService: StandaloneAppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    if (this.standaloneApp.iconHTMLCreated) {
      document.getElementById('logoWrapper').innerHTML = this.standaloneApp.iconHTML;
      (document.getElementById('appSplashColor') as HTMLInputElement).value = this.standaloneApp.iconBackgroundColor;
      document.getElementById('customApp').style.backgroundColor = this.standaloneApp.iconBackgroundColor;
      console.log('W1', (document.getElementById('appWidth') as HTMLInputElement).value);
      let img = (document.getElementById('logoImg') as HTMLImageElement);
      if (img.height > img.width) {
        this.heightCoefficient = img.width / img.height;
        console.log(this.heightCoefficient);
      } else {
        this.heightCoefficient = 1;
      }
      
      (document.getElementById('appWidth') as HTMLInputElement).value = `${(document.getElementById('logoImg') as HTMLImageElement).width / this.heightCoefficient}`;
      console.log('W2', (document.getElementById('appWidth') as HTMLInputElement).value);

      this.splashColor = this.standaloneApp.iconBackgroundColor;
      (document.getElementById('customAppLogo') as HTMLImageElement ).src = (document.getElementById('logoImg') as HTMLImageElement).src;
      (document.getElementById('customAppLogo') as HTMLImageElement ).style.width = `${+(document.getElementById('appWidth') as HTMLInputElement).value * this.heightCoefficient * 0.15625}px`;
    } else {
      this.splashColor = '#ffffff';
      (document.getElementById('appSplashColor') as HTMLInputElement).value = this.splashColor;
      document.getElementById('logoContainerDropZone').style.backgroundColor = this.splashColor;
      document.getElementById('customApp').style.backgroundColor = this.splashColor;
    }
  }

  preview(logoFiles) {
    if (logoFiles.length === 0)
      return;

    var logoUpload  = logoFiles[0].type;
    if (logoUpload.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    
    var reader = new FileReader();
    this.imagePathLogo = logoFiles;
    reader.readAsDataURL(logoFiles[0]);
    console.log('hello');
    reader.onload = (_event) => {
      console.log('hello 2')
      this.imgURL = reader.result;
      console.log('hello 3')

      // console.log(this.imgURL);
      // img.style.display = 'flex';
      var img = document.getElementById('logoImg') as HTMLImageElement;
      console.log('hello 4', img);
      try {
        let myImg = new Image();
        this.appLogoWidth = 250;
        (document.getElementById('appWidth') as HTMLInputElement).value = '250';
        myImg.onload = function() {
          console.log(myImg.height, myImg.width);
          if (myImg.height > myImg.width) {
            let heightCoefficient = myImg.width / myImg.height;
            console.log(heightCoefficient);
            (document.getElementById('customAppLogo') as HTMLImageElement ).src = myImg.src;
            (document.getElementById('customAppLogo') as HTMLImageElement ).style.width = `${250 * heightCoefficient * 0.15625}px`;
            (document.getElementById('logoImg') as HTMLImageElement).src = myImg.src;
            (document.getElementById('logoImg') as HTMLImageElement ).style.width = '250px';
            
          } else {
            (document.getElementById('customAppLogo') as HTMLImageElement ).src = myImg.src;
            (document.getElementById('customAppLogo') as HTMLImageElement ).style.width = `${250  * 0.15625}px`;
            (document.getElementById('logoImg') as HTMLImageElement).src = myImg.src;
            (document.getElementById('logoImg') as HTMLImageElement ).style.width = '250px';
          }
        }

        myImg.src = this.imgURL;
        

      } catch (e) {
        console.log('hello 5', e)

      }
      
      console.log(window.getComputedStyle(img).width, window.getComputedStyle(img).height);
      if (img.naturalHeight > img.naturalWidth) {
        this.heightCoefficient = img.naturalWidth / img.naturalHeight;
        console.log(this.heightCoefficient);
        
        
      }
      // (document.getElementById('customAppLogo') as HTMLImageElement ).src = (document.getElementById('myLogoImg') as HTMLImageElement).src;
      // (document.getElementById('customAppLogo') as HTMLImageElement ).style.width = `${this.appLogoWidth * this.heightCoefficient * 0.15625}px`;
    };
  }

  bgColorUpdated() {
    const color = (document.getElementById('appSplashColor') as HTMLInputElement).value;
    this.splashColor = color;
    document.getElementById('logoContainerDropZone').style.backgroundColor = this.splashColor;
    document.getElementById('customApp').style.backgroundColor = this.splashColor;

  }

  updateWidth() {
    this.appLogoWidth = +(document.getElementById('appWidth') as HTMLInputElement).value;
    var img = document.querySelector('#logoImg') as HTMLImageElement;
    if (img.height > img.width) {
      this.heightCoefficient = img.width / img.height;
      console.log(this.heightCoefficient);
    } else {
      this.heightCoefficient = 1;
    }
    img.style.width = `${this.appLogoWidth * this.heightCoefficient }px`;
    (document.getElementById('customAppLogo') as HTMLImageElement ).style.width = `${this.appLogoWidth * this.heightCoefficient * 0.15625}px`
  }

 

  submit() {
    const appIconHTML = document.getElementById('logoWrapper').innerHTML;
    this.standaloneApp.iconHTML = appIconHTML;
    this.staService.uploadIconHTML(this.standaloneApp._id, appIconHTML, this.splashColor).subscribe(data => {
      this.standaloneApp = data;
      this.appIconSubmittedSuccessfully.emit(this.standaloneApp);
    })

  }

}
