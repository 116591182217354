import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackBuilderParentComponent } from './components/track-builder-parent/track-builder-parent.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StageListComponent } from './components/stage-list/stage-list.component';
import { JobListComponent } from './components/job-list/job-list.component';
import { SelectedJobDetailsComponent } from './components/selected-job-details/selected-job-details.component';
import { JobFormComponent } from './components/job-form/job-form.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ArticleSelectorDialogComponent } from '../manager-portal/components/dialogs/article-selector-dialog/article-selector-dialog.component';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { SharedModule } from '../shared/shared.module';
import { JobTranslatorComponent } from './components/job-translator/job-translator.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { EditStageComponent } from './components/edit-stage/edit-stage.component';
import { ConfirmJobDeletionDialogComponent } from './components/dialogs/confirm-job-deletion-dialog/confirm-job-deletion-dialog.component';
import { TestService } from 'src/app/services/test.service';
import { SelectedTestComponent } from './components/selected-test/selected-test.component';
import { QuestionListComponent } from './components/question-list/question-list.component';
import { NewTestComponent } from './components/new-test/new-test.component';
import { QuestionTranslatorComponent } from './components/question-translator/question-translator.component';
import { LaunchDemoDialogComponent } from './components/dialogs/launch-demo-dialog/launch-demo-dialog.component';
import { TranslatorService } from 'src/app/services/translator.service';
import { TrackBuilderService } from './services/track-builder.service';
import { StageIconService } from 'src/app/services/stage-icon.service';
import { LoadingComponent } from '../shared/components/loading/loading.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [TrackBuilderParentComponent, StageListComponent, JobListComponent, SelectedJobDetailsComponent, JobFormComponent, JobTranslatorComponent, EditStageComponent, ConfirmJobDeletionDialogComponent, SelectedTestComponent, QuestionListComponent, NewTestComponent, QuestionTranslatorComponent, LaunchDemoDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    CKEditorModule,
    MatSlideToggleModule,
    MatInputModule,
    MatCardModule,
    SharedModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    SharedModule
  ],
  providers: [TestService, TranslatorService, TrackBuilderService, StageIconService],
  entryComponents: [ConfirmJobDeletionDialogComponent, LaunchDemoDialogComponent]
})
export class TrackBuilderModule { }
