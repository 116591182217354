<div class="feature-container" style="background-color: #fff !important;">
    <div class="mb-2">
        Here are the code snippets for your studio. &nbsp;Just hit the <button mat-icon-button><mat-icon>file_copy</mat-icon></button> icon to copy the code.
      <!-- </div> -->
      </div>
      <div class="ml-1 mr-1">
        <mat-accordion  multi="true" >
          <mat-expansion-panel *ngFor="let featureSnippet of featureSnippets" hideToggle="true" #codeIcon>
            <mat-expansion-panel-header>
              <mat-panel-description>
                <div class="featureHeader" style="display: flex;
                  align-items: center;">
                  <div class="feature-heading">
                    <h6>{{ featureSnippet.title}}</h6>
                  </div>
                </div>
              </mat-panel-description>
              <mat-action-row style="margin-right:2rem;">
                <div class="d-flex align-items-center clickable">
                  <a target="_blank" style="margin-right: 2rem;" href={{getPreviewLink(featureSnippet.id,featureSnippet.feature)}}>Preview</a>
                </div>
                <!-- <button type="button" mat-stroked-button
                  (click)="copyToClipBoard(generateSnippet(featureSnippet.feature))">Copy to
                  Clipboard</button> -->
                  <button mat-icon-button style="color:#555; opacity:0.8;" aria-label="Copy to
                  Clipboard" type="button"
                  (click)="copyToClipBoard(generateSnippet(featureSnippet.id,featureSnippet.feature))">
                      <mat-icon>file_copy</mat-icon>
                  </button>
                  <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">code</mat-icon>
                  <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
              </mat-action-row>
            </mat-expansion-panel-header>
            <pre>
               <code [highlight]="generateSnippet(featureSnippet.id,featureSnippet.feature)"></code>
             </pre>
          </mat-expansion-panel>
        </mat-accordion>
      </div>


        <div class="mb-3 mt-4">
          <strong>NB:</strong> If you have a person who manages your website and you would link to share these resources with them, you can send them a link to a standalone version of this section. This will give them access to the code snippets for your studio and our guides on how to perform the integration.

        </div>

        <div class="ml-1">
          <button mat-raised-button [cdkCopyToClipboard]="standaloneLink">Copy Link to Standalone Website Integration Guide</button>
        </div>

        <span class="mb-2" data-iframe-height></span>


  </div>
  
  