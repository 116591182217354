import { Job } from './job';
import { Track } from './track';
import { Test } from './test';

export class Stage {
  _id: string;
  title: string;
  hoursFromEpoch: number;
  durationInHours: number;
  _next: string;
  _prev: string;
  track: Track;
  jobs: Job[] = [];
  translations: {language: string, translation: {title: string}}[] = []
  test: Test;
  postMigration: boolean;
  icon: string;
  essentialStage: boolean;


  public get stageEpoch(): Date {
    console.log('stage epoch', new Date(this.track.epoch.getTime() + (this.hoursFromEpoch * 60 * 60 * 1000)));
    return new Date(this.track.epoch.getTime() + (this.hoursFromEpoch * 60 * 60 * 1000));
  }

  public get stageEndDate(): Date {
    return new Date(this.stageEpoch.getTime() + (this.durationInHours * 60 * 60 * 1000));
  }


}
