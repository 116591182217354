<div class="container">
    <div class="d-flex justify-content-around align-items-center">
        <div class="node" (click)="changeStep('config-form')">
            <div class="icon complete" *ngIf="standaloneApp.appConfigCreated">
                <fa-icon [icon]="tickIcon"></fa-icon>
            </div>
            <div class="icon incomplete" *ngIf="!standaloneApp.appConfigCreated">
                1
            </div>
            <div class="name">{{"app.progress.app_details" | translate}}</div>
        </div>
        <div class="branch">

        </div>
      

        <div class="node" (click)="changeStep('app-icon')">
            <div class="icon complete" *ngIf="standaloneApp.iconHTMLCreated">
                <fa-icon [icon]="tickIcon"></fa-icon>
            </div>
            <div class="icon incomplete" *ngIf="!standaloneApp.iconHTMLCreated">
                2
            </div>
            <div class="name">{{"app.progress.icon" | translate}}</div>
        </div>
        <div class="branch">

        </div>
        <div class="node" (click)="changeStep('splash-screen')">
            <div class="icon complete" *ngIf="standaloneApp.splashScreenHTMLCreated">       
                <fa-icon [icon]="tickIcon"></fa-icon>
            </div>
            <div class="icon incomplete" *ngIf="!standaloneApp.splashScreenHTMLCreated">
                3
            </div>
            <div class="name">{{"app.progress.splash_screen" | translate}}</div>
        </div>
        <div class="branch">

        </div>
        <div class="node" [ngClass]="{'disabled': !standaloneApp.splashScreenHTMLCreated || !standaloneApp.iconHTMLCreated }" (click)="showReviewStep()">
            <div class="icon complete" *ngIf="standaloneApp.hasBeenSubmitted">       
                <fa-icon [icon]="tickIcon"></fa-icon>
            </div>
            <div class="icon incomplete" *ngIf="!standaloneApp.hasBeenSubmitted">
                4
            </div>
            <div class="name">{{"app.progress.review" | translate}}</div>
        </div>

    </div>
</div>