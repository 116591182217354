<div class="feature-container">
  <div class="row">
    <div class="col-12">
      <mat-accordion multi="true">
        <mat-expansion-panel *ngFor="let featureSnippet of featureSnippets" hideToggle="true" #codeIcon>
          <mat-expansion-panel-header>
            <mat-panel-description>
              <div class="featureHeader" style="display: flex; align-items: center">
                <div class="feature-heading">
                  <h6>{{ featureSnippet.title }}</h6>
                </div>
              </div>
            </mat-panel-description>
            <mat-action-row style="margin-right: 2rem">
              <div class="d-flex align-items-center clickable">
                <a target="_blank" style="margin-right: 2rem" href="{{ getPreviewLink(featureSnippet.id, featureSnippet.feature) }}">Preview</a>
              </div>
              <!-- <button type="button" mat-stroked-button
                (click)="copyToClipBoard(generateSnippet(featureSnippet.feature))">Copy to
                Clipboard</button> -->
              <button
                mat-icon-button
                style="color: #555; opacity: 0.8"
                aria-label="Copy to
                Clipboard"
                type="button"
                (click)="copyToClipBoard(generateSnippet(featureSnippet.id, featureSnippet.feature))"
              >
                <mat-icon>file_copy</mat-icon>
              </button>
              <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">code</mat-icon>
              <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
            </mat-action-row>
          </mat-expansion-panel-header>
          <pre>
             <code [highlight]="generateSnippet(featureSnippet.id,featureSnippet.feature)"></code>
             <!-- <code> {{generateSnippet(featureSnippet.feature)}}</code> -->
           </pre>
        </mat-expansion-panel>
      </mat-accordion>
     
    </div>
  </div>
</div>
