import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wix-integration',
  templateUrl: './wix-integration.component.html',
  styleUrls: ['./wix-integration.component.sass']
})
export class WixIntegrationComponent implements OnInit {

  loading = true;
  constructor() { }

  ngOnInit() {
    this.loading = false;
  }

  scrollToFAQ() {
    document.getElementById('integration-tips').scrollIntoView({
      behavior: 'smooth'
    })
  }

}
