import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Branch } from 'src/models/branch';
import { WebIntegration } from 'src/models/web-integration';
import { Track } from 'src/models/track';
import { Job, Role } from 'src/models/job';
import { OBManager } from 'src/models/ob-manager';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { TrackService } from 'src/app/services/track.service';
import { WebTeamService } from '../../../web-team-portal/services/web-team.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.sass']
})
export class BranchListComponent implements OnInit {
  dataSource: MatTableDataSource<Branch>;
  _branches: Branch[] = [];
  webIntegration: WebIntegration;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  public set branches(val: Branch[]) {
    this._branches = val;
    this.dataSource = new MatTableDataSource(this._branches);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.table) {
      this.applyFilters({});
      this.table.renderRows();
    }
  }

  public get branches(): Branch[] {
    return this._branches;
  }

  track: Track;
  branch: Branch;

  displayedColumns = ['status', 'name', 'hasLoggedIn', 'progress-essential', 'progress', 'webIntStatus', 'appStatus', 'goLiveDate'];
  stateList = ['Active', 'Disengaged', 'Fully Onboarded'];
  states = new FormControl(['Active']);

  constructor(private auth: AuthenticationService, private trackService: TrackService, private webTeamService: WebTeamService, private branchService: BranchService, private toastr: ToastrService) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.trackService.getTrackV2({ trackId: '5e20316f83d7cd00171e47e5', includeDraftJobs: false, includeStages: false }).subscribe(data => {
        this.track = data;
      });

      setTimeout(() => {
        (document.getElementsByClassName('mat-sort-header-container')[1] as HTMLElement).style.flexDirection = 'row-reverse';
      }, 200)
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterByStatus() {

    const filterToStatus = this.states.value;
    let res: Branch[] = [];

    if (filterToStatus.length === 3 || filterToStatus.length === 0) {
      return this._branches;
    } else {
      if (filterToStatus.includes('Active')) {
        res = res.concat(this.branches.filter(b => !b.disengaged && !b.fullyOnBoarded));
      }

      if (filterToStatus.includes('Disengaged')) {
        res = res.concat(this.branches.filter(b => b.disengaged));
      }

      if (filterToStatus.includes('Fully Onboarded')) {
        res = res.concat(this.branches.filter(b => b.fullyOnBoarded));
      }

      return res;
    }
  }

  applyFilters($event) {
    let res = this.filterByStatus();
    this.dataSource = new MatTableDataSource(res);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  getBranchStatus(branch): string {
    if (branch.fullyOnBoarded) {
      return 'branchStatus branchLive'
    } else if (branch.disengaged) {
      return 'branchStatus branchDisengaged'
    } else {
      return 'branchStatus branchActive'
    }
  }


}
