<div class="container">

    <div class="title-container">
        <h3 class="mt-5" class="title">{{"app.invite.title" | translate}}</h3>
        <div class="sub-title">
            If you have already invited Glofox to your Apple Developer Account you can skip this step and move onto designing your <span class="link" (click)="confirmInvite()">app's icon</span>.
        </div>
        <div class="sub-title mt-2">
            {{"app.invite.reminder_two" | translate}}
        </div>
      </div>
      
      <div class="d-flex flex-column">
        <div class="step">
          <h6 class="step-title">{{"app.invite.step_one" | translate}}</h6>
          <span class="step-sub-title"> <a href="https://developer.apple.com/account" target="_blank"> {{"app.invite.open_apple_dev_console" | translate}} </a></span>

        </div>


        <div class="step">
          <h6 class="step-title">{{"app.invite.stepo_two" | translate}}</h6>
          <img src="../../../../assets/images/glofox_invite_1.png" alt="">
        </div>
        <div class="step">
          <h6 class="step-title">{{"app.invite.step_three" | translate}}</h6>
        </div>
        <!-- <div class="step">
          <h6 class="step-title ">Step 4: Adjust the position of your logo.</h6>
          <div class="step-sub-title">Please drag the logo to position it on the screen.</div>
          <button class="mt-3" mat-raised-button (click)="centerLogo()">Recenter Logo horizontally</button>
        </div> -->
        <div class="step">
          <h6 class="step-title">{{"app.invite.step_four" | translate}}</h6>
            <img src="../../../../assets/images/glofox_invite_2.png" alt="">
        </div>

        <div class="step">
            <h6 class="step-title">{{"app.invite.step_five" | translate}}</h6>
          </div>
      </div>

      <div class="step">
        <h6 class="step-title">{{"app.invite.step_six" | translate}}</h6>
        <img src="../../../../assets/images/glofox_invite_3.png" style="width: 60%" alt="">
      </div>

      <div class="step">
        <h6 class="step-title">{{"app.invite.step_seven" | translate}}</h6>
      </div>


      <div class="step">
        <h6 class="step-title">{{"app.invite.step_eight" | translate}}</h6>
        <span class="step-sub-title"> 
            Immediately after sending the invitation, please email your Glofox Customer Onboarding Manager at <a href="mailto:customersuccess@glofox.com?subject=Invitation Sent&body=I have invited you to my Apple Developer Console." target="_blank">customersuccess@glofox.com</a> with the subject-line 'Invitation Sent' to let us know these steps have been taken.
        </span>
         
      </div>

      <div class="step">
        <button class="mt-3" mat-raised-button (click)="confirmInvite()">
          {{"app.invite.continue" | translate}}
        </button>
      </div>

</div>