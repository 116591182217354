<div class="modal_wrapper" *ngIf="stage && branch && admin">
  <div class="modal_container">
    <mat-dialog-content class="mat-typography">
      
      <div *ngIf="branch.percent_complete_essential < 25" class="dialog_title mt-3 mb-5">
        {{"stage_completion_dialog.great_start" | translate:{name: admin.firstName} }}
        
      </div>
      
      <div *ngIf="branch.percent_complete_essential > 25 && branch.percent_complete_essential <= 50" class="dialog_title mt-3 mb-5">
        {{"stage_completion_dialog.almost_halfway" | translate:{name: admin.firstName} }}
      </div>

      <div *ngIf="branch.percent_complete_essential > 50 && branch.percent_complete_essential <= 70" class="dialog_title mt-3 mb-5">
        {{"stage_completion_dialog.over_halfway" | translate:{name: admin.firstName} }}
        
      </div>

      <div *ngIf="branch.percent_complete_essential > 70 && branch.percent_complete_essential <= 90" class="dialog_title mt-3 mb-5">
        {{"stage_completion_dialog.almost_finished" | translate:{name: admin.firstName} }}
      </div>

      <div *ngIf="branch.percent_complete_essential > 90 && branch.percent_complete_essential <= 100" class="dialog_title mt-3 mb-5">
        {{"stage_completion_dialog.get_finished" | translate:{name: admin.firstName} }}
      </div>

      <div class="dialog_title mb-5" mat-dialog-title>
        {{"stage_completion_dialog.congrats_on_finishing_stage" | translate:{stage: stage.title} }}
      </div>

      <div *ngIf="branch.version == 'v2'">
        <div class="dialog_txt mt-3 mb-3">Here is a quick recap of what you have learnt and achieved from this stage:</div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbb8b334732a452e6b21bf1'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to get set up with your Payment Provider</li>
            <li class="mt-3 mb-3">What Business and Payment settings can be updated from the Glofox Dashboard</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbb8cead65813069432d44f'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to migrate your Member data into Glofox</li>
            <li class="mt-3 mb-3">The next steps after your Member Migration</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbb8ca6d65813069432ce40'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to create new staff members in Glofox and control their user permissions</li>
            <li class="mt-3 mb-3">An overview of Memberships, and Credit Packs</li>
            <li class="mt-3 mb-3">How to set up Classes, Courses and PT Sessions</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbbe4581e6b5b00178bea38'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to use Global Search</li>
            <li class="mt-3 mb-3">How to create a Member profile on the Glofox Dashboard and Glofox Pro App</li>
            <li class="mt-3 mb-3">An overview of E-agreements</li>
            <li class="mt-3 mb-3">A deeper dive into Memberships</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbb8c5fd65813069432c82f'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">What the Glofox Web Portal is and how you can customize it from your Glofox Dashboard</li>
            <li class="mt-3 mb-3">Best Practices for adding Glofox to your website and Social Media platforms</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '5fbb8c6cd65813069432c830'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to upload your logo and customise the colours for your app</li>
            <li class="mt-3 mb-3">You have given our App Team everything to build your app</li>
          </ul>
        </div>
        <div class="dialog_txt mt-3 mb-3" *ngIf="stage._id == '6012ea833007890017d070a2'">
          <ul class="mt-1 mb-1">
            <li class="mt-3 mb-3">How to get started with the Glofox Messages tab</li>
            <li class="mt-3 mb-3">How to send SMS and Push Notifications from the Glofox Dashboard</li>
            <li class="mt-3 mb-3">How to create community articles for your Member App</li>
          </ul>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- <button mat-button mat-dialog-close>Cancel</button>-->
      <div class="d-flex justify-content-end align-items-center mt-3">
        <div class="article-list__item">
          <a (click)="returnToDashboard()" class="article-list-right__link" style="cursor: pointer">
            <span class="article-list-right__link-inner"> <strong style="text-align: right">{{"app.review.back_to_onboarding" | translate}}</strong></span>
          </a>
        </div>
      </div>
    </mat-dialog-actions>
  </div>
</div>
