

<div class="container-fluid mt-4 mb-3" id="webinarsHome" *ngIf="filteredVideos.length else loading">
  <div class="video_categories mb-4" *ngIf="translate.currentLang === 'en'">
    <mat-chip-list  *ngFor="let videoCat of videoCategories" class="mb-3">
      <mat-chip [ngClass]="{'mat-chip-active': activePill === videoCat}" class="ml-3 mr-3 chip" (click)="selectedVideoCategory(videoCat)" >{{videoCat}}</mat-chip>
    </mat-chip-list>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-4">
      <app-video-list [_selectedVideo]="selectedVideo"  [videos]="filteredVideos" (selectedVideo)="selectVideo($event)" (hasSeen)="updateHasSeen($event)"  >
      </app-video-list>
    </div>
    <div class="col-xs-12 col-md-8" id="playerParent">
      <div *ngIf="selectedVideo">
        <app-player [selectedVideo]="selectedVideo" [videos]="filteredVideos" [playerWidth]="playerWidth"
          (selectedVideoChanged)="selectedVideoChanged($event)" [admin]="admin" ></app-player>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>