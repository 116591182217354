import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientDashboardComponent } from './components/client-dashboard/client-dashboard.component';
import { ClientPortalComponent } from './components/client-portal/client-portal.component';
import { RoleGuardService } from 'src/app/services/role-guard.service';
import { VideosHomeComponent } from '../videos/components/videos-home/videos-home.component';
import { WebintHomeComponent } from '../web-integration/components/webint-home/webint-home.component';
import { AddDeveloperComponent } from '../web-integration/components/add-developer/add-developer.component';
import { AddADeveloperWebIntComponent } from '../web-integration/components/add-a-developer-web-int/add-a-developer-web-int.component';
import { StandaloneBuilderParentComponent } from '../standalone-app-builder/components/standalone-builder-parent/standalone-builder-parent.component';
import { StageDetailComponent } from '../shared/components/stage-detail/stage-detail.component';
import { WebintInteractiveJobComponent } from './components/webint-interactive-job/webint-interactive-job.component';
import { ExternalSurveyPortalComponent } from '../shared/components/external-onboarding-survey/external-survey-portal/external-survey-portal.component';

const routes: Routes = [{
  path: 'client-dashboard',
  component: ClientPortalComponent,
  canActivate: [RoleGuardService],
  data: {
    'expectedRole': 'client'
  },
  children: [
    { path: '', component: ClientDashboardComponent },
    {
      path: 'stage/:Id',
      component: StageDetailComponent,
      children: [
        { path: 'request-web-developer', component: WebintInteractiveJobComponent, outlet: 'auxoutlet' },
        { path: 'create-app-assets', component: StandaloneBuilderParentComponent, outlet: 'auxoutlet' }
      ]
    },
    { path: 'videos', component: VideosHomeComponent },
    { path: 'web-integration', component: WebintHomeComponent },
    { path: 'splash-screen', component: StandaloneBuilderParentComponent },
    { path: 'web-integration/addDeveloper', component: AddDeveloperComponent },
    { path: 'web-integration/add-developer', component: AddADeveloperWebIntComponent },



  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientPortalRoutingModule { }
