<mat-card *ngIf="selectedQuestion">
    <mat-card-content>
      <div class="d-flex justify-content-between align-items-center">
        <small class="text-secondary mb-3">Question ID: {{selectedQuestion._id}}</small>
        <div>
          <button mat-raised-button (click)="deleteQuestion(selectedQuestion)" >Delete Question</button>
        </div>
      </div>
        <form *ngIf="createQuestionFG" class="mt-3" [formGroup]="createQuestionFG" (ngSubmit)="saveQuestion()">
            <mat-form-field style="width: 99%">
              <mat-label>Question</mat-label>
              <textarea type="text" matInput formControlName="question" placeholder="What is the question?"></textarea>
            </mat-form-field>
            <!-- <ckeditor  [editor]="editor" formControlName="followUpMsg"></ckeditor> -->
    
            <div formArrayName="options" class="mt-3">
              <div  *ngFor="let optionFG of getOptions().controls; let optIndex = index">
    
                <div class="d-flex align-items-center" [formGroup]="optionFG">
                  <mat-form-field style=" width: 80%">
                  <mat-label>Potential Answer {{getAnswerLabel(optIndex)}}</mat-label>
              <input type="text" matInput formControlName="potentialAnswer" placeholder="Possible answer">
            </mat-form-field>
            <mat-checkbox  class="ml-3" [checked]="optionFG.get('isCorrect').value" formControlName="isCorrect">Correct</mat-checkbox>
            <div>
                <button mat-button class="ml-3" (click)="removePotentialAnswer(optIndex)">Remove</button>
            </div>
                </div>
              </div>
            </div>
            <button mat-raised-button type="button" (click)="addPotentialAnswer()">Add Potential Answer</button>
            <button class="ml-3" mat-raised-button type="submit">Save Question</button>
            <!-- <button mat-raised-button class="ml-3" (click)="discardQuestionForm()">Cancel</button> -->
          </form>
    </mat-card-content>



</mat-card>

<div *ngIf="selectedQuestion && selectedQuestion._id">

  <mat-card class="mt-3" *ngIf="track.translations.length">
      <div style="width: 100%;" class="d-flex align-items-center justify-content-between">
        <h5>Translations</h5>
        <div>
          <button mat-raised-button class="mr-1" (click)="translateQuestion()">Auto Translate All</button>  
          <button mat-raised-button (click)="saveTranslation()">Save All Translations</button>
        </div>
      </div>
      <div *ngFor="let translation of track.translations">
        <div class="mb-3">
          <h6>{{translation | language }}</h6>
          <div>
            <app-question-translator [translation]="getTranslation(translation)" [question]="selectedQuestion"></app-question-translator>
          </div>
        </div>
        <hr>
      </div>
    </mat-card>
</div>