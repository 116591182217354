<div>
  <section class="d-flex align-items-center">
      <h6 class="mr-3">Please choose one of these options:</h6>
    <mat-radio-group (change)="changeCMS($event)" aria-label="Select an option">
      <mat-radio-button value="general" checked>General</mat-radio-button>
      <mat-radio-button value="wordpress">Wordpress</mat-radio-button>
      <mat-radio-button value="wix">Wix</mat-radio-button>
      <mat-radio-button value="squarespace">Squarespace</mat-radio-button>
    </mat-radio-group>
  </section>

  <section class="mt-2" [ngSwitch]="selectedCMS">
    <div *ngSwitchCase="'general'">
      <h6 class="cms_option">General Integration Guide</h6>
      <div class="video_explainer mt-3">If you do not see your CMS in the options above, please follow these general steps:</div>
      <ol>
          <li>Access the backend of your website.</li>
          <li>Either create a new page or select the existing page you wish to edit.</li>
          <li>You are going to be embedding HTML so look for the option to add 'code', 'html' or 'raw html'.</li>
          <li>Copy the code snippet for the feature you wish to add from the list at the top of this page.</li>
          <li>Repeat this for the other features you wish to add.</li>
          <li>Don't forget to test out your new/updated pages on a mobile device.</li>
      </ol>
    </div>
    <div *ngSwitchCase="'wordpress'">
      <h6 class="cms_option">Wordpress Integration Guide</h6>
      <div class="video_explainer">This video explains how to add Glofox features to your Wordpress website.</div>
      <div style="width: 100%; height: 0px; position: relative; padding-bottom: 53.125%">
        <iframe src="https://streamable.com/e/i6g5qq" frameborder="0" width="100%" height="100%" allowfullscreen style="width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; overflow: hidden"></iframe>
      </div>

      <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
        <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Adding Glofox to your WordPress Website.</h5>
        <div class="row">
          <div class="col-12">You can integrate Glofox with your Wordress by using our handy plugin or by embedding the code.</div>
          <div class="col-12">
            <br>
            <h6 style="font-weight: 600">Method One: Using the Glofox Plugin (Recommended)</h6>
            <br>
            <h6>Step 1. Install the Plugin.</h6>
            <ul>
              <li>From the menu of your Wordpress dashboard, click on 'Plugins' and then 'Add New'</li>
              <li>Search for 'Glofox</li>
              <li>You should see <strong>Glofox Shortcodes</strong> as the top result.</li>
              <li>Click 'Install'</li>
              <li>Click 'Activate'</li>
            </ul>
            <video width="100%" controls>
              <source src="../../../../assets/images/how_to_install_plugin.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <br>
            <h6 class="mt-3">Step 2. Generate your shortcodes.</h6>
            <ul>
              <li>Click on 'Settings' in the menu on the left of the screen.</li>
              <li>Click on 'Glofox'</li>
              <li>Enter your Branch ID into the form and click sumbit. Your Branch ID is: {{branchId}}</li>
              <li>Scroll down the page and you will see your shortcodes are now ready to be used.</li>
            </ul>
    
            <img src="../../../../assets/images/shortcodes.png" width="100%" >
    
            <br>
            <h6 class="mt-3">Step 3. Using the shortcodes.</h6>
            <div>There are a number of builders on Wordpress but you should be able to follow these steps to add the feature to your page:</div>
            <ul class="mt-2">
              <li>Copy the relevent shortcode by clicking on 'Copy Shortcode to Clipboard'</li>
              <li>Navigate to the page you want to add the feature to.</li>
              <li>If your builder has a 'Shortcodes' element/block use that. Otherwise select  a 'Text' or 'Text Block' element.</li>
              <li>If using a specific shortcode block, just paste in the shortcode you copied.</li>
              <li>If using a Text Block, make sure you click the 'Text' view (not 'Visual') and paste in the shortcode.</li>
              <li>Finally, preview the page and you should see the Glofox feature has been embedded.</li>
            </ul>
            <video width="100%" controls>
              <source src="../../../../assets/images/using_shortcode.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-12">
            <h6 style="font-weight: 600; margin-top: 3rem;">Method Two: Embedding the code directly.</h6>
            <div>
              <div class="pt-2 pb-2">There are many different builders on WordPress but the process is the same for each
                one.</div>
              <div>
                Just follow these steps:
              </div>
            </div>
    
            <ol class="mt-2">
              <li>
                <div>
                  Select the page that you wish to add Glofox to and click 'Edit'.
                </div>
                <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_1.png" alt="">
              </li>
              <li>
                <div>Next, find the element your builder uses to add HTML. This might be 'HTML', 'Code' or 'Raw HTML'.</div>
                <img class="pt-3 pb-3" src="../../../../assets/images/wp_2.png" alt="">
              </li>
              <li>
                <div>
                  Now you are going to paste the relevant code snippet from the options at the top of the page into the textarea. <br>
                </div>
                <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_3.png" alt="">
              </li>
              <li>
                <div>
                  And that's it! Dont forget to hit 'Publish' or 'Update'.
                </div>
                <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_4.png" alt="">
    
              </li>
    
    
            </ol>
          </div>
    
        </div>
    
      </div>
    
      <div class="col-12 p-3" style="background-color: #fff;">
    
        <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">My Glofox Integration is not displaying?</h5>
        <div class="pt-2 pb-2"> Some builders on WordPress will mistake the code you entered and try to format as text.
        </div>
        <div class="pt-2 pb-2"> To prevent this, please minify the code using a tool like this: <a
            href="https://www.willpeavy.com/tools/minifier/" target="_blank">Click Here</a> </div>
        <div class="pt-2 pb-2">Just copy the minified output into the 'HTML' element as above.</div>
      </div>
    </div>
    <div *ngSwitchCase="'wix'">
      <h6 class="cms_option">WIX Integration Guide</h6>
      <div class="video_explainer">This video explains how to add Glofox features to your WIX website.</div>
      <div class="video_explainer"><strong>NB: </strong>On WIX you need to resize both the desktop and mobile versions of the website.</div>
      <div style="width: 100%; height: 0px; position: relative; padding-bottom: 53.125%">
        <iframe src="https://streamable.com/e/5qunhd" frameborder="0" width="100%" height="100%" allowfullscreen style="width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; overflow: hidden"></iframe>
      </div>
      <div class="row mt-4" id="integration-tips">   
        <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
          <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Adding Glofox to your WIX Website.</h5>
          <div class="row">
            <div class="col-12">
              <div>
                <div class="pt-2 pb-2">Integrating Glofox with your WIX website is as simple as pasting a code snippet into WIX's iframe element.</div>
                <div>
                  Just follow these steps:
                </div>
              </div>
    
              <ol class="mt-2">
                <li>Copy the relevant snippet from options at the top of this page.</li>
                <li>Add a new page or select an existing page.</li>
                <li class="mb-3">Next,the click on the + symbol to add content. Click on 'More' and choose 'HTML iframe' from Custom Embeds.</li>
                <img style="max-width: 900px" src="../../../../assets/images/wix-embed.png" alt="">
    
                <li class="mt-3 mb-3">  Once WIX has added the element to page, click 'Enter Code', paste the code from step one into the textarea, making sure 'Code' is selected..</li>
                <img style="max-width: 500px; margin: auto; padding: .5rem;" src="../../../../assets/images/wix-add-code.png" alt="" srcset="">
    
              </ol>
            </div>
    
          </div>
        </div>
           
        <div class="col-12 p-3" style="background-color: #fff;">
            
          <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Setting the Height and Width</h5>
          <div class="pt-2 pb-2"> First, set the element's width by dragging the edges of the element left and right to fill the are between the vertical dashed lines. </div>
          <div class="pt-2 pb-2">WIX forces the iframe to have a fixed, or static, height. Because of this, you will need to allow enough space for the iframe to expand into depending on it's content.</div>
          <div class="pt-2 pb-2">You can adjust the height be dragging the the container downwards or by altering the height via the settings.</div>
          <img src="../../../../assets/images/resize-wix-height.png" style="width: 100%" alt="" srcset="">
        </div>
    
        <div class="col-12 mt-3 p-3" style="background-color: #fff;">
          <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Optimising for Mobile Devices</h5>
          <div class="pt-2 pb-2">Every page will need to be optimised for for mobile devices. To adjust the settings for mobile devices, click on the mobile icon in the navbar.</div>
          <div class="d-flex justify-content-center mt-3 mb-3">
            <img src="../../../../assets/images/wix-mobile.png" alt="">
          </div>
          <div class="pt-4">
            Next, you can drag the container to fit the necessary width and height, as you did for the desktop version.
          </div>
    
          <div class="pt-4">
            Finally, don't forget to publish your changes!
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'squarespace'">
        <h6 class="cms_option">Squarespace Integration Guide</h6>
        <div class="video_explainer">This video explains how to add Glofox features to your Squarespace website.</div>
      <div style="width: 100%; height: 0px; position: relative; padding-bottom: 53.125%">
        <iframe src="https://streamable.com/e/etcoq7" frameborder="0" width="100%" height="100%" allowfullscreen style="width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; overflow: hidden"></iframe>
      </div>

      <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
        <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Adding Glofox to your Squarespace Website.</h5>
        <div class="row">
          <div class="col-12">
            <div>
              <div class="pt-2 pb-2">Integrating Glofox with your Squarespace website is as simple as pasting a code snippet into Squarespace's code element.</div>
              <div>
                Just follow these steps:
              </div>
            </div>
  
            <ol class="mt-2">
              <li>
                <div>
                  Select the page that you wish to add Glofox to and click 'Edit' on the section where you wish to add the Glofox feature.
              </div>
              <img class="pt-3 pb-3" src="../../../../assets/images/ss_edit_page.png" alt="">
              </li>
              <li><div>Next, click the + icon, to add some content to the page.</div>
                <img class="pt-3 pb-3" style="width: 100%" src="../../../../assets/images/ss_add_content.png" alt="">
              </li>
              <li>
                <div>
                  Next, find the 'code' element by typing 'code' into the search field 
                </div>
                <img class="pt-3 pb-3" src="../../../../assets/images/ss_add_code.png" alt="">
              </li>
              <li>
                <div>
                  Now you are going to paste the relevant code snippet from the options at the top of the page into the textarea on Squarespace. <br>
                  Then hit 'Apply'.
                </div>
                <img class="pt-3 pb-3" src="../../../../assets/images/ss_apply_code.png" alt="">
  
              </li>
              <li><div>
                Finally hit 'Save'.
              </div>
              <img style="width: 100%" class="pt-3 pb-3" src="../../../../assets/images/ss_save_page.png" alt="">
            
            </li>
            <li>
              In order to preview the feature you added, please click onto another one of your pages and then back onto the page you just edited.
            </li>
              
            </ol>
          </div>
  
        </div>
        
      </div>

      <div class="col-12 p-3" style="background-color: #fff;">
        
        <h5 style="border-bottom: 2px solid #330F2E; padding-bottom: .6rem;">Disable AJAX Loading</h5>
        <div class="pt-2 pb-2"> In order to make sure that your Glofox integration displays correctly, please make sure 'Enable AJAX Loading' is not toggled on. </div>
        <div class="pt-2 pb-2"> You can find this setting at Design -> Site Styles -> Enable AJAX Loading </div>
  
        <div class="row mt-2">
          <div class="col">
            <img style="" src="../../../../assets/images/ss_ajax_1.png" alt="">
          </div>
          <div class="col">
            <img style="" src="../../../../assets/images/ss_ajax_2.png" alt="">
          </div>
          <div class="col">
            <img style="" src="../../../../assets/images/ss_ajax_3.png" alt="">
          </div>
        </div>
      </div>

    </div>
  </section>
  <div data-iframe-height></div>
</div>
