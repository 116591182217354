<div *ngIf="job && !jobloading && jobFormGroup">
  <form [formGroup]="jobFormGroup">
    <div class="d-flex justify-content-between">
      <small class="text-secondary">Job ID: {{job._id}}</small>
      <div>
        <button mat-raised-button class="mr-2" *ngIf="job._id" (click)="deleteJob()">Delete Job</button>
        <button mat-raised-button (click)="saveJob()">Save Job</button>
      </div>
    </div>
      <div class="d-flex mt-3">
        <mat-form-field style="flex-grow: 3; margin-right: .5rem;">
          <input matInput placeholder="Description" formControlName="description">
        </mat-form-field>
      </div>
      <div class="d-flex">
        <ckeditor [editor]="editor" style="width: 100%;" [config]="editorConfig" id="editor" formControlName="details" (ready)="onReady($event)"></ckeditor>
      </div>
      <div class="row mt-5">
        <mat-form-field class="col-6">
          <mat-label>Assignee</mat-label>
          <mat-select mat-native-control required [(value)]="role">
            <mat-option value="customer">Customer</mat-option>
            <mat-option value="obmanager">OB Manager</mat-option>
            <mat-option value="webteam">Web Team</mat-option>
            <mat-option value="appteam">App Team</mat-option>
            <mat-option value="migrations">Migrations Team</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-6">
          <div class="text-secondary" style="line-height: 16px;
            font-size: .8rem;">
            Time to complete
          </div>
          <div class="d-flex align-items-center">
            <mat-form-field class="mr-2" style="width: 3rem;">
              <input matInput type="number" formControlName="periodsToComplete" />
            </mat-form-field>
            <div>Hours</div>
            <!-- <mat-form-field style="width: 60%;">
              <mat-select mat-native-control required [(value)]="jobPeriodDuration">
                <mat-option value="hour">Hours</mat-option>
                <mat-option value="day">Days</mat-option>
                <mat-option value="week">Weeks</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
        </div>
      </div>
      <div class="mb-4">
        <h6 class="pb-0 mb-1"><strong>Filtering</strong></h6>
        <small class="mb-3">Jobs with no filters selected will show for all branches. <br> If a job has a filter, a branch must also have the corresponding filter selected for the job to be visible. </small>
        <div class="d-flex flex-wrap mt-2">
          <div  *ngFor="let optionCategory of categorisedOptions"  class="filterCategory mr-2 mb-3">
            <div class="mb-3" style="text-transform: capitalize;"><strong>{{optionCategory['name'] }}</strong></div>
            <div class="d-flex" *ngFor="let option of optionCategory['options']">
              <div style="margin-bottom: 5px;">
                <mat-slide-toggle [checked]="jobHasFilter(option)" (change)="toggleFilterOnJob(option)">
                </mat-slide-toggle>
              </div>
              <div class="mr-4 ml-2">{{option['name']}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 mb-1">
              <div class="d-flex  justify-content-between align-items-center">
                  <h6 class="mb-0">Articles</h6>
                  <button mat-raised-button  style="font-size: 0.8rem; line-height: 2.5; padding-right: 9px; padding-left: 9px;" (click)="openArticleSelectorDialog()">Choose Articles</button>
              </div>
          </div>
         
          <div class="col-12" *ngIf="articles.length">
            <div class="d-flex flex-wrap">
                  <div  *ngFor="let art of articles">
                      <div style="font-size: .8rem;" class="article d-flex align-items-center">
                          {{art.title}} <span class="material-icons ml-1 clickable" (click)="removeArticle(art.id)">
                            highlight_off
                            </span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-12" *ngIf="!articles.length">
            <div class="text-secondary">No articles selected.</div>
          </div>

          <div class="col-12" *ngIf="badArticleIds.length">
            Please Note: Articles with these ids no longer exist:
            <div *ngFor="let article of badArticleIds">{{article}}</div>
          </div>
        <!-- <mat-form-field style="width: 85%;" class="col example-chip-list">
          <mat-chip-list #articleIdList aria-label="Fruit selection">
            <mat-chip *ngFor="let artId of articleIds" [selectable]="selectable" [removable]="removable"
              (removed)="remove(artId, articleIds)">
              {{ artId }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Zendesk KB Ids..." [matChipInputFor]="articleIdList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event, articleIds)" />
          </mat-chip-list>
        </mat-form-field> -->
      </div>
  
      <div class="row mt-4">
        <div class="col-12">
          <h6>Videos</h6>
        </div>
        <!-- <div class="col">
          <mat-form-field style="width: 100%;">
            <mat-label>Wistia Module</mat-label>
            <mat-select mat-native-control required [(value)]="selectedModules" multiple>
              <mat-option *ngFor="let module of modules" [value]=module>{{module}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-12">
          <mat-form-field style="width: 85%;" class="example-chip-list">
            <mat-chip-list #videoIdList aria-label="Fruit selection">
              <mat-chip *ngFor="let vidId of job.videoIds" [selectable]="selectable" [removable]="removable"
                (removed)="remove(vidId, job.videoIds)">
                {{ vidId }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="YouTube Video Ids...." [matChipInputFor]="videoIdList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event, job.videoIds)" />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h6>External Link</h6>
        </div>
        <mat-form-field class="col-5">
          <input type="text" matInput formControlName="linkText" placeholder="Link text">
        </mat-form-field>
        <mat-form-field class="col-7">
          <input type="text" matInput formControlName="url" placeholder="Link URL">
        </mat-form-field>
      </div>
    </form>
</div>

