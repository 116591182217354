import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Test } from 'src/models/test';
import { TestService } from 'src/app/services/test.service';
import { Stage } from 'src/models/stage';
import { isString } from 'util';
import { Question } from 'src/models/question';
import { DataSource } from '@angular/cdk/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { TrackBuilderService } from '../../services/track-builder.service';
import { Subscription, Subject } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-selected-test',
  templateUrl: './selected-test.component.html',
  styleUrls: ['./selected-test.component.sass']
})
export class SelectedTestComponent implements OnInit, OnDestroy, AfterViewChecked {

  private _test: Test;

  @Input()
  public set test(v : Test) {
    this._test = v;
    this.questionsDS = this._test.questions;
    
    this.selectQuestion(this._test.questions[0]);
    this.testFG = new FormGroup({
      name: new FormControl(this.test.name, Validators.required)
    })
  }

  public get test(): Test {
    return this._test;
  }
  



  @Input()
  stage: Stage;

  questionTableDisplayedColumns = ['drag', 'question'];
  selectedQuestion: Question;
  questionsDS = [];
  @ViewChild('table') table: MatTable<Question>;

  private unsubscribe$ = new Subject<void>();

  selectedQuestionSubscription: Subscription;
  newQuestionAddedSubscription: Subscription;
  questionDeletedSubscription: Subscription;
  
  testFG: FormGroup;
  
  constructor(private testService: TestService, private trackBuilderService: TrackBuilderService, private toastr: ToastrService,  private cdRef: ChangeDetectorRef) { 
    this.selectedQuestionSubscription = this.trackBuilderService.selectedQuestionChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedQuestion = data;
    });

    this.newQuestionAddedSubscription = this.trackBuilderService.newQuestionAdded$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      console.log('in selected question', data);
      this.test.questions = this.test.questions.filter(q => q._id);
      this.test.questions.push(data);
      this.questionsDS = this._test.questions;
      this.selectQuestion(data);
      this.table.renderRows();
    });

    this.questionDeletedSubscription = this.trackBuilderService.questionDeleted$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.test.questions = this.test.questions.filter(q => q._id !== data._id);
      this.questionsDS = this._test.questions;
      this.selectQuestion(data);
      this.table.renderRows();
    })
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  selectQuestion(question: Question) {
    this.trackBuilderService.changeSelectedQuestion(question);
  }

  newQuestion() {
    let question = new Question();
    question.question = 'New Question';
    question.options = [
      { potentialAnswer: '', isCorrect: false},
      { potentialAnswer: '', isCorrect: false}
    ];
    this.test.questions.push(question);
    this.questionsDS = this._test.questions;
    this.table.renderRows();
    this.selectQuestion(question);
  }

  reorderQuestions(event: CdkDragDrop<Question[]>) {
    const previousIndex = this._test.questions.findIndex((row) => row === event.item.data);
    moveItemInArray(this.questionsDS, previousIndex, event.currentIndex);

    this.table.renderRows();
    this.testService.updateQuestionOrder(this.test._id, event.container.data.map(s => s._id)).subscribe(_ => {})
  }

  formatQuestionTitle(question: string): string {
    if (question.length > 54) {
      return question.slice(0, 55) + '...';
    } else {
      return question;
    }
  }

  saveTest() {
    this.test.name = this.testFG.controls.name.value;
    this.testService.updateTestName(this.test).subscribe(_ => {
      this.toastr.success('Review Updated.')
    })
  }

  deleteReview() {
    this.testService.deleteTest(this.test).subscribe(_ => {
      console.log(_);
      this.stage.test = undefined;
      
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
