import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AdminService } from "../../services/admin.service";
import { Admin } from "src/models/admin";
import { ManagerService } from "src/modules/manager-portal/services/manager.service";
import { OBManager } from "src/models/ob-manager";
import { Branch } from "src/models/branch";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-client-portal",
  templateUrl: "./client-portal.component.html",
  styleUrls: ["./client-portal.component.sass"],
})
export class ClientPortalComponent implements OnInit {
  admin: Admin;
  manager: OBManager;
  branch: Branch;

  constructor(
    private auth: AuthenticationService,
    private adminService: AdminService,
    private managerService: ManagerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe((adminData) => {
        this.admin = adminData;
        this.adminService.getBranchForAdmin(this.admin).subscribe((branchData) => {
          this.branch = Object.assign(new Branch(), branchData);

          if (`${this.admin.branch.track}` === "5f02df42d00d0260fe27195d") {
            localStorage.setItem("currentLang", "pt-br");
            this.translate.use("pt-br");
          } else {
            this.translate.use("en");
            localStorage.setItem("currentLang", "en");
          }
          const managerId = `${this.admin.branch.obmanager}`;
          this.managerService.getManagerById(managerId).subscribe((data) => {
            this.manager = data;
          });
        });
      });
    }
  }
}
