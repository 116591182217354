import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    const ROLES = ['Customer', 'Glofox OBM', 'Web Team', 'App Team', 'Migrations Team'];
    return ROLES[value];
  }

}
