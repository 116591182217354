import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandAloneAppUpsell } from 'src/models/standalone-app-upsell';

@Injectable({
  providedIn: 'root'
})
export class StandaloneAppService {

  readonly SERVER_URL = environment.SERVER_URL;


  constructor(private http: HttpClient) { }

  getStandAloneApp(app_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/apps/${app_id}`);
  }

  checkIfAppNameIsUnique(name: string): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/apps/check-availability`,  {name});
  }


  uploadSplashScreenHTML(_id: string, splashScreenHTML: string, bgcolor: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/apps/${_id}/uploadSplashScreen`, {splashScreenHTML: splashScreenHTML, splashScreenColor: bgcolor});
  }

  uploadIconHTML(_id: string, iconHTML: string, iconBackgroundColor: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/apps/${_id}/uploadAppIcon`, {iconHTML: iconHTML, iconBackgroundColor: iconBackgroundColor});
  }

  uploadAndroidBannerHTML( _id: string, androidBannerHTML: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/apps/${_id}/uploadAndroidBanner`, {androidBannerHTML: androidBannerHTML});
  }

  inviteConfirmed(_id: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/apps/${_id}/confirmedInvitation`, { inviteConfirmed: true });
  }

  submitAppDetails(app: any): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/apps/${app._id}/setConfig`, {app});
  }

  sendAssetsToAWS(app: StandAloneApp): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/apps/${app._id}/sendToAWS`);
  }

  updateAppChecklist(app: StandAloneApp): Observable<any> {
    let body = { hasDUNSNumber: app.hasDUNSNumber, hasAppleDevAccount: app.hasAppleDevAccount, invitationSentByApple: app.invitationSentByApple }
    return this.http.patch(`${this.SERVER_URL}/api/apps/${app._id}/updateChecklist`, {body});

  }

  newStandAloneAppUpsell(newStandAloneAppUpsell: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/apps/upsell`, newStandAloneAppUpsell);
  }

}
