<mat-card class="mb-2" *ngIf="admin">
    <div class="image-container">
        <h4 class="web-status mt-1 mb-3 pl-3"><strong>{{"client_dash.review_us_on_g2" | translate}}</strong></h4>
        <!-- <img src="https://images.g2crowd.com/uploads/report_medal/image/1335/medal.svg" alt="" srcset=""> -->
    </div>
    <div class="d-flex align-items-center">
        <div class="pl-3">
            <div class="web-intro ">{{"client_dash.review_us_on_g2.blurb" | translate:{name: admin.firstName} }}</div>

            <div class="article-list__item mt-3">
                <a class="article-list-right__link" style="cursor: pointer" [href]="admin.onboardingSurvey.survey_url" target="_blank" style="cursor: pointer;">
                    <span class="article-list-right__link-inner">
                        <strong>{{"client_dash.review_us_on_g2.link" | translate}}</strong></span>
                </a>
            </div>
        </div>
    </div>
</mat-card>