<!-- <p>webint-code-parent works!</p> -->
<div class="loading"  *ngIf="loading">
  <div>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<div *ngIf="branch && !loading">
  <div class="col-12">

    <div class="mb-3 text-box">
      <div>
        Here are all of the website integration code snippets you need to embed Glofox onto your website. &nbsp;Just hit the <button mat-icon-button><mat-icon>file_copy</mat-icon></button> icon to copy the code.
      <!-- </div> -->
      <hr>
      <!-- <div class="mt-3"> -->
        If you need to send these resources to the person who manages your site, <a style ="color:#3AD8C4;" [href]="standaloneLink" target="_blank">click here</a> to open the standalone version of this page.
      </div>
    </div>
    <app-feature-snippets [newBranchId]="branch.branchId"></app-feature-snippets>
  </div>
</div>
