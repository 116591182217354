import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TraceInterceptorService implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		const cloned = req.clone({
			headers: req.headers.set('Pathname', `${window.location.pathname}`)
		});
		return next.handle(cloned);
	}

	constructor() { }
}
