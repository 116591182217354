import { Article } from './article';
import { Subcategory } from './subcategory';
import { Section } from './section';

export class Category {

    id: number;
    name: string;
    articles: Article[] = [];
    // subcategories: Subcategory[] = [];
    sections: Section[] = [];
    description: string;

    sumOfArticles(): number {
        let sum = 0;
        sum += this.articles.length;
        if (this.sections) {
          this.sections.forEach(subcat => {
              sum += subcat.articles.length;
          });
        }
        return sum;
    }
}
