import { Component, OnInit, Input } from '@angular/core';
import { Branch } from "src/models/branch";
import { Membership } from '../../models/membership';

@Component({
  selector: 'app-glofox-api',
  templateUrl: './glofox-api.component.html',
  styleUrls: ['./glofox-api.component.sass']
})
export class GlofoxApiComponent implements OnInit {

  // branch: Branch;
 @Input() memberships: Membership[] = [];

  constructor() { }

  ngOnInit() {
  }

}
