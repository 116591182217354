import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Admin } from 'src/models/admin';
import { FormGroup, FormControl, FormControlName, Validators } from '@angular/forms';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-dialog',
  templateUrl: './admin-dialog.component.html',
  styleUrls: ['./admin-dialog.component.sass']
})
export class AdminDialogComponent implements OnInit {

  admin: Admin;
  mode: string;
  emailCheckPending = false;
  adminCreateForm: FormGroup;
  faLoading = faCircleNotch;

  constructor(
    public dialogRef: MatDialogRef<AdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private adminService: AdminService) {
    this.admin = data.admin;
    this.mode = data.mode;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  newAdminCreated(event) {

  }

  ngOnInit() {
    console.log('opened admin dialog');

    if (this.mode === 'new') {
      this.adminCreateForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email])
      })
    } else {
      this.adminCreateForm = new FormGroup({
        firstName: new FormControl(this.admin.firstName,[Validators.required]),
        lastName: new FormControl(this.admin.lastName, [Validators.required]),
        email: new FormControl(this.admin.email, [Validators.required, Validators.email])
      })
    }
  }

  addAdmin() {


    if (this.mode === 'new') {
      this.emailCheckPending = true;
      this.adminService.checkIfEmailIsUnique(this.adminCreateForm.controls.email.value).subscribe(data => {
        if (data.emailIsUnique) {
          this.admin = new Admin();
          this.admin.firstName = this.adminCreateForm.controls.firstName.value;
          this.admin.lastName = this.adminCreateForm.controls.lastName.value;
          this.admin.email = this.adminCreateForm.controls.email.value;
          this.dialogRef.close(this.admin);
          this.emailCheckPending = false;
        } else {
          this.emailCheckPending = false;
          this.adminCreateForm.controls.email.setErrors( { unavailable: true } );
        }

      });

    }

    if (this.mode === 'edit') {
      if (this.admin.email === this.adminCreateForm.controls.email.value) {
        this.admin.firstName = this.adminCreateForm.controls.firstName.value;
        this.admin.lastName = this.adminCreateForm.controls.lastName.value;
        this.admin.email = this.adminCreateForm.controls.email.value;
        this.dialogRef.close(this.admin);
      } else {
        this.adminService.checkIfEmailIsUnique(this.adminCreateForm.controls.email.value).subscribe(data => {
          if (data.emailIsUnique) {
            this.admin.firstName = this.adminCreateForm.controls.firstName.value;
            this.admin.lastName = this.adminCreateForm.controls.lastName.value;
            this.admin.email = this.adminCreateForm.controls.email.value;
            this.dialogRef.close(this.admin);
            this.emailCheckPending = false;
          } else {
            this.emailCheckPending = false;
            this.adminCreateForm.controls.email.setErrors( { unavailable: true } );
          }

        })
      }

    }
  }

}
