<div id="wrapper" *ngIf="loaded; else loading">
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column mt-3">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->

      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid" style="min-height: 80vh">
        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <div style="width: 80%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Search</mat-label>
              <input matInput autocomplete="off" [(ngModel)]="globalSearchTerm" (ngModelChange)="globalSearch()" placeholder="Search by Branch Id, Branch Name, Email..." />
            </mat-form-field>

            <mat-selection-list *ngIf="globalSearchResults.length && !globalSearching" style="position: absolute; background-color: #5d0e4dda; color: white; z-index: 1000; width: 75%; max-height: 400px; overflow: scroll" #shoes [multiple]="false">
              <mat-list-option *ngFor="let branch of globalSearchResults" [routerLink]="['/manager-dashboard/branches', branch._id]" style="color: #fff">
                {{ branch.name }}
              </mat-list-option>
            </mat-selection-list>

            <mat-selection-list *ngIf="globalSearching" style="position: absolute; background-color: #5d0e4dda; z-index: 1000; width: 75%; max-height: 400px; overflow: scroll" [multiple]="false">
              <mat-list-option class="d-flex align-items-center" style="color: #fff">
                <div class="ml-2">Seaching ...</div>
              </mat-list-option>
            </mat-selection-list>
          </div>

          <div>
            <a *ngIf="manager.superManager" mat-raised-button class="mr-3" style="color: #330f2e" (click)="triggerNewManagerDialog()"> New Manager</a>
            <a mat-raised-button style="color: #330f2e" (click)="triggerNewBranchDialog()"> New Branch</a>
          </div>
        </div>

        <!-- Content Row -->
        <div class="row">
          <!-- Earnings (Monthly) Card Example -->
          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="clearFilter()">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Branches Assigned</div>
                    <div style="justify-self: flex-end" class="h5 mb-0 font-weight-bold text-gray-800">{{ managerStats?.totalBranches }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-calendar fa-2x" style="background-color: red"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Earnings (Monthly) Card Example -->
          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="filterToActiveBranches()">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Actively Onboarding Branches</div>
                    <div style="justify-self: flex-end" class="h5 mb-0 font-weight-bold text-gray-800">{{ managerStats?.activeBranches }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="filterToFullyOnBoardedBranches()">
            <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Fully Onboarded</div>
                    <div style="justify-self: flex-end" class="h5 mb-0 font-weight-bold text-gray-800">{{ managerStats?.fullyOnBoardedBranches }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Earnings (Monthly) Card Example -->
          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="filterToGoingLiveInNext7DaysBranches()">
            <div class="card border-left-warning shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Going Live In Next 7 Days</div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto" style="justify-self: flex-end">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ managerStats?.liveInNext7DaysBranches }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Pending Requests Card Example -->
          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="filterToOverdueBranches()">
            <div class="card border-left-danger shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">Overdue Branches</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ managerStats?.overdueActiveBranches }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-comments fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-md-4 mb-4 clickable" (click)="filterToNotLoggedInWithin72hrs()">
            <div class="card border-left-secondary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center" style="height: 100%">
                  <div class="col mr-2 statCardContent">
                    <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">Not Logged In Within 72hrs</div>
                    <div style="justify-self: flex-end" class="h5 mb-0 font-weight-bold text-gray-800">{{ managerStats?.notLoggedInWithin72HrsBranches }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-comments fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Content Row -->

        <div class="row">
          <mat-card class="col-9" style="height: fit-content">
            <mat-card-header>
              <div class="managerHeader d-flex align-items-center"><mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto">supervised_user_circle</mat-icon>Your Branches</div>
            </mat-card-header>
            <mat-card-content>
              <app-branch-list [branches]="branches"></app-branch-list>
            </mat-card-content>
          </mat-card>

          <div class="col-xl-3 col-lg-3">
            <mat-card>
              <mat-card-header class="mb-4">
                <div class="managerHeader d-flex align-items-center"><mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto">group</mat-icon>Other Managers</div>
              </mat-card-header>
              <mat-card-content>
                <mat-table [dataSource]="otherManagers">
                  <ng-container matColumnDef="icon">
                    <mat-header-cell *matHeaderCellDef style="width: 25%"> </mat-header-cell>
                    <mat-cell *matCellDef="let manager" style="width: 25%; padding: 6px">
                      <img *ngIf="manager.imageURL" src="{{ manager.imageURL }}" alt="" class="imageTD" />
                      <img *ngIf="!manager.imageURL" src="../../../../assets/images/profile_icon.png" alt="" class="imageTD" />
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef style="width: 75%"> </mat-header-cell>
                    <mat-cell *matCellDef="let manager" style="width: 75%; flex-grow: 3"> {{ manager.firstName }} {{ manager.lastName }} </mat-cell>
                  </ng-container>
                  <mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['manager', row._id]" class="clickable hoverable"></mat-row>
                </mat-table>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <!-- Content Row -->
        <div class="row">
          <div class="col"></div>
          <!-- Content Column -->
          <div class="col-lg-6 mb-4"></div>

          <div class="col-lg-6 mb-4"></div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer bg-white">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span></span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" data-dismiss="modal" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>