import { Video } from '../models/video';

export abstract class VideoList {

  public static videos: Video[] =
    [
      { name: 'Welcome to Glofox', id: '_1xkqacHSTI'},
      { name: 'Payment Integration Setup', id: 'F_vC2g0A3M4' },
      { name: 'The Member App', id: 'b0e78r3puso' },
      { name: 'The Web Integration', id: '9wx5aofCbhI' },
      { name: 'Social Booking', id: 'N6KGasUGN18' },
      { name: 'Create A Facility', id: '5U2eavlItE0' },
      { name: 'Staff Roles', id: 'MToc5lNi_aI' },
      { name: 'Creating Staff', id: 'WgdSXwcQtKg' },
      { name: 'Intro to Memberships', id: 'S7BmvaMReJg' },
      { name: 'Single Payment Memberships', id: 'lBDqmcD0Lcg' },
      { name: 'Recurring Memberships', id: 'bx_QgfXqfNU' },
      { name: 'Service Credit Packs', id: '1yn7kQOCYTw' },
      { name: 'Creating a Class', id: '3xyBHW_0kBE' },
      { name: 'Pricing and Eligibility', id: '3SMRURP_lcQ' },
      { name: 'Creating a Course', id: 'NdlMQF029UU' },
      { name: 'Classes and Courses Settings', id: 'QAr8385XvfA' },
      { name: 'Facility Bookings', id: 'JizMQVz-kqc' },
      { name: 'Trainer Appointments', id: '415NTg37z1Y' },
      { name: 'Appointment Settings', id: 'L7x-JifwLwk' },
      { name: 'Creating a Member Profile', id: 'BlkE-aolajU' },
      { name: 'The Member Profile', id: '2l06aA8_mTc' },
      { name: 'Assigning a Membership', id: 'Fe-DJWOI8O0' },
      { name: 'The Class Slideout In Action', id: 'wtkBI1lhK0Y' },
      { name: 'Navigating the Dashboard', id: '59uNkDCnpBk' },
      { name: 'Navigating the Calendar', id: 'N4vRXjcgEN4' },
      { name: 'Global Search', id: 'LBykDQwUPao' },
      { name: 'Booking and Buying via Global Search', id: 'nCjx34thJuU' },
      { name: 'Using the Access and Store Tabs', id: 'T4FDGAr3u_A' },
      { name: 'Barcode Scanning', id: '-7TgYWyuZkc' },
      { name: 'The Kiosk App', id: 'mZTqs14a6XU' },
      { name: 'Settings', id: 'jQ-IRI5b8t4' },
      { name: 'Waivers, Terms and Conditions', id: 'WU3GxJB6mVk' },
      { name: 'Connecting with Your Members', id: '3AIapcpszWc' },
      { name: 'The Revenue Report', id: 'jzG9I2p7c1I' },
      { name: 'Report List', id: 'HCWDFdSFQ5w' },
    ];

    // public static allVideos: Video[] =
    // [
    //   { name: 'Welcome to Glofox', id: '_1xkqacHSTI'},
    //   { name: 'Payment Integration Setup', id: 'F_vC2g0A3M4' },
    //   { name: 'The Member App', id: 'b0e78r3puso' },
    //   { name: 'The Web Integration', id: '9wx5aofCbhI' },
    //   { name: 'Social Booking', id: 'N6KGasUGN18' },
    //   { name: 'Create A Facility', id: '5U2eavlItE0' },
    //   { name: 'Staff Roles', id: 'MToc5lNi_aI' },
    //   { name: 'Creating Staff', id: 'WgdSXwcQtKg' },
    //   { name: 'Intro to Memberships', id: 'S7BmvaMReJg' },
    //   { name: 'Single Payment Memberships', id: 'lBDqmcD0Lcg' },
    //   { name: 'Recurring Memberships', id: 'bx_QgfXqfNU' },
    //   { name: 'Service Credit Packs', id: '1yn7kQOCYTw' },
    //   { name: 'Creating a Class', id: '3xyBHW_0kBE' },
    //   { name: 'Pricing and Eligibility', id: '3SMRURP_lcQ' },
    //   { name: 'Creating a Course', id: 'NdlMQF029UU' },
    //   { name: 'Classes and Courses Settings', id: 'QAr8385XvfA' },
    //   { name: 'Facility Bookings', id: 'JizMQVz-kqc' },
    //   { name: 'Trainer Appointments', id: '415NTg37z1Y' },
    //   { name: 'Appointment Settings', id: 'L7x-JifwLwk' },
    //   { name: 'Creating a Member Profile', id: 'BlkE-aolajU' },
    //   { name: 'The Member Profile', id: '2l06aA8_mTc' },
    //   { name: 'Assigning a Membership', id: 'Fe-DJWOI8O0' },
    //   { name: 'The Class Slideout In Action', id: 'vLUgib4rzoM' },
    //   { name: 'Navigating the Dashboard', id: '59uNkDCnpBk' },
    //   { name: 'Navigating the Calendar', id: 'N4vRXjcgEN4' },
    //   { name: 'Global Search', id: 'LBykDQwUPao' },
    //   { name: 'Booking and Buying via Global Search', id: 'nCjx34thJuU' },
    //   { name: 'Using the Access and Store Tabs', id: 'T4FDGAr3u_A' },
    //   { name: 'Barcode Scanning', id: '-7TgYWyuZkc' },
    //   { name: 'The Kiosk App', id: 'mZTqs14a6XU' },
    //   { name: 'Settings', id: 'jQ-IRI5b8t4' },
    //   { name: 'Waivers, Terms and Conditions', id: 'WU3GxJB6mVk' },
    //   { name: 'Connecting with Your Members', id: '3AIapcpszWc' },
    //   { name: 'The Revenue Report', id: 'jzG9I2p7c1I' },
    //   { name: 'Report List', id: 'HCWDFdSFQ5w' },
    //   { name: 'Aulas e Horários Das Aulas', 'id': 'RgDaidsmyqI'},
    //   { name: 'Glofox - Pagamentos', 'id': 'yJPGyk93bQE'},
    //   { name: 'Como vender Planos e Pacotes de Créditos', 'id': 'Yz9jPo9d_Ak'},
    //   { name: 'Iniciando com as Planos', 'id': 'J_tDLe7nLVo'},
    //   { name: 'Funcionarios e Permissões', 'id': 'VJV4FV7JvCI'},
    // ];

    public static portugueseVideos = [
      { name: 'Aulas e Horários Das Aulas', _id: '', category: '', 'id': 'RgDaidsmyqI'},
      { name: 'Glofox - Pagamentos',  _id: '', category: '', 'id': 'yJPGyk93bQE'},
      { name: 'Como vender Planos e Pacotes de Créditos',  _id: '', category: '', 'id': 'Yz9jPo9d_Ak'},
      { name: 'Iniciando com as Planos',  _id: '', category: '', 'id': 'J_tDLe7nLVo'},
      { name: 'Funcionarios e Permissões',  _id: '', category: '', 'id': 'VJV4FV7JvCI'},
    ]

//     public static sections: Section[] = [
//         {
//           title: 'Welcome and Payment Set Up',
//           videos: [
//             { name: 'Welcome to Glofox', id: '22xh6y5r49'},
//             { name: 'Stripe Setup', id: '6yqy5fvxb7' },
//           ]
//         },
//         {
//           title: 'Staff  Users',
//           videos: [
//              { name: 'User Roles', id: '5joa186blk' },
//               { name: 'Creating your User Profiles', id: 'aoqzr3cmi4' },
//           ]
//         },
//         {
//           title: 'Memberships and Payment Plans',
//           videos: [
//             { name: 'Intro to Memberships', id: 'i06pqmwk62' },
//             { name: 'The Free Trial', id: 'rd4m7mrm1g' },
//             { name: 'Fixed Term Plans', id: 'cesm4tp6a1' },
//             { name: 'Recurring Payment Plans', id: '85g6oqukuq' },
//             { name: 'Class Pass Memberships', id: 'zh39wtnfgj' },
//           ]
//         },
//         {
//           title: 'Classes',
//           videos: [
//             { name: 'Creating Your Regular Schedule', id: 'pklrtwanpf' },
//             { name: 'Creating One Off Classes', id: 'tjkabivvo2' },
//             { name: 'Booking Settings Part 1', id: 'mx7k00pu03' },
//             { name: 'Booking Settings Part 2', id: '2kf5l8t2c6' },
//           ]
//         },
//         {
//           title: 'The Glofox Basics',
//           videos: [
//             { name: 'Creating Members', id: 'g0eoed3oaa' },
//             { name: 'Reviewing Member Profiles', id: 'afqtnedhhj' },
//             { name: 'The Dashboard', id: '4t3o48yuc1' },
//             { name: 'The Calendar', id: 'vp8oa4hcp2' },
//           ]
//         },
//         {
//           title: 'Managing Members',
//           videos: [
//             { name: 'Waivers and T&Cs', id: 'aotcc0z6m3' },
//             { name: 'Web Portal', id: 'lbu2sfh2tf' },
//             { name: 'Check-in Kisosk', id: 't9c8ihfuqt' },
//             { name: 'Connect With Messages', id: 'h3638rtand' },
//           ]
//         },
//         {
//           title: 'Engaging Members',
//           videos: [
//             { name: 'Sending Push Notifications', id: 'ic5ymxiw1y' },
//             { name: 'Integration with Memberships', id: 'ht79dvhb64' },
//           ]
//         }

//       ];
// }

  }