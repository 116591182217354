import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManagerPortalModule } from 'src/modules/manager-portal/manager-portal.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LoginComponent } from './components/landing-page/login/login.component';
import { LandingPageParentComponent } from './components/landing-page/landing-page-parent/landing-page-parent.component';
import { AuthenticationService } from './services/authentication.service';
import { ClientPortalModule } from 'src/modules/client-portal/client-portal.module';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { VideosModule } from 'src/modules/videos/videos.module';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { WelcomeComponent } from './components/landing-page/welcome/welcome.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GlobalErrorHandler } from './services/global-error-handler';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MarketPipe } from './shared/pipes/market.pipe';
import { WebTeamPortalModule } from 'src/modules/web-team-portal/web-team-portal.module';
import { RequestPasswordResetEmailComponent } from './components/request-password-reset-email/request-password-reset-email.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntercomModule } from 'ng-intercom';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { TraceInterceptorService } from './services/trace-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageParentComponent,
    LoginComponent,
    PasswordResetComponent,
    WelcomeComponent,
    MarketPipe,
    RequestPasswordResetEmailComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ManagerPortalModule,
    ClientPortalModule,
    WebTeamPortalModule,
    HttpClientModule,
    VideosModule,
    FontAwesomeModule,
    MatSnackBarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IntercomModule.forRoot({
      appId: 'whyvsv0c', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })
  ],
  providers: [
    AuthenticationService,
    {
      provide: ErrorHandler, useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceInterceptorService,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '615177448230-0ikotslo3tcneva72p2f3i8fgaob72nr.apps.googleusercontent.com'
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}