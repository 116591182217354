import { Injectable } from '@angular/core';

const INTERACTIVE_JOBS: {stageId: string, title: string, auxPath: string, required: boolean, job?: string, filterOn?: string}[] = [
    { stageId: '5fbb8c5fd65813069432c82f', title: 'Stuck? Request Glofox Perform Your Integration', auxPath: 'request-web-developer', required: false},
    { stageId: '5fbb8c6cd65813069432c830', title: 'Create Your Assets and Submit for Build', auxPath: 'create-app-assets', required: true, job: '5fcbee5d872490285befaacc', filterOn: '5e3057ce105de93a5ccf8cbd'},
    { stageId: '5f92b160c4e14301db3ce4e9', title: 'Create Your Assets and Submit for Build', auxPath: 'create-app-assets', required: true, job: '5fcbee5d872490285befaacc', filterOn: '5e3057ce105de93a5ccf8cbd'},
    { stageId: '60a66d03e5968b0017ee2dfa', title: 'Crie seu aplicativo', auxPath: 'create-app-assets', required: true, job: '60a66d72e5968b0017ee2e01', filterOn: '5e3057ce105de93a5ccf8cbd'}
]

@Injectable({
    providedIn: 'root'
  })
  export class InteractiveJobService {


    getInteractiveJobs(stageId: string) {
        return INTERACTIVE_JOBS.filter(interactiveJob => interactiveJob.stageId === stageId);
    }
  }