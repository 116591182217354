import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WebTeamService } from '../../services/web-team.service';
import { Branch } from 'src/models/branch';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Admin } from 'src/models/admin';
import { WebIntegration } from 'src/models/web-integration';


@Component({
  selector: 'app-branches-with-smt',
  templateUrl: './branches-with-smt.component.html',
  styleUrls: ['./branches-with-smt.component.sass']
})
export class BranchesWithSmtComponent implements OnInit {

  branches: Branch[];
  admins: Admin[] = [];

  @Output()
  selectWebInt$ = new EventEmitter();

  displayedColumns = ['gld', 'name', 'portal', 'onboarding_progress', 'website', 'qaCheckPassed', 'smtConfirmed', 'contactedToOfferHelp'];

  constructor(private webTeamService: WebTeamService) { }

  ngOnInit() {
    this.webTeamService.getBranchesWhoNeverRequestedHelp().subscribe(data => {
      this.branches = data.branches;
      this.admins = data.admins;
    })
  }

  getSeachUrl(name: string): string {
    return `https://www.google.com/search?q="${encodeURIComponent(name)}"`;
  }

  hasSMT(branch: Branch): boolean {
    return branch.onboardingOptions.includes('5e305721105de93a5ccf8bba');
  }

  updateContactedAboutFailedQA(event: MatCheckboxChange, webIntegration) {
    this.webTeamService.updateWebIntQAStatus(webIntegration._id, {'contactedAboutFailedQA': event.checked}).subscribe();
  }

  updateQAPassed(event: MatCheckboxChange, webIntegration) {
    this.webTeamService.updateWebIntQAStatus(webIntegration._id, {'passedQA': event.checked}).subscribe();
  }

  updateSMTSetUp(event: MatCheckboxChange, webIntegration) {
    this.webTeamService.updateWebIntQAStatus(webIntegration._id, {'LCSetUp': event.checked}).subscribe();
  }

  getEmailDomain(branch: Branch) {
    const genericEmailProviders = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'zoho'];
    let admins = this.admins.filter(admin => `${admin.branch}` === branch._id);
    if (admins && admins.length) {
      let adminEmail = admins[0].email;
      let domain = adminEmail.split('@')[1];
      let isCustomDomain = genericEmailProviders.every(provider => !domain.toLowerCase().includes(provider.toLowerCase()));
      if (isCustomDomain) {
        return `https://www.${domain}`;
      }
    }
  }

  getPortalLink(branch) {
    return `https://app.glofox.com/portal/#/branch/${branch.branchId}/classes-week-view`;
  }

  selectBranch(branch) {
    let webInt = branch.webIntegration;
    webInt.branch = branch;
    this.selectWebInt$.emit(webInt);
  }

}
