import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';

@Component({
  selector: 'app-standalone-builder-config-form',
  templateUrl: './standalone-builder-config-form.component.html',
  styleUrls: ['./standalone-builder-config-form.component.sass']
})
export class StandaloneBuilderConfigFormComponent implements OnInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output()
  formSubmittedSuccesfully: EventEmitter<StandAloneApp> = new EventEmitter();

  appDetailsFG: FormGroup;

  appNameChecked = false;
  checkingIfNameIsUnique = false;
  uniqueAppName = true;

  constructor(private staService: StandaloneAppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.appDetailsFG = new FormGroup({
      appName: new FormControl(this.standaloneApp.name, [Validators.required]),
      teamId: new FormControl(this.standaloneApp.team_id, [Validators.required, Validators.minLength(5)]),
      appleDeveloperAccountName: new FormControl(this.standaloneApp.apple_dev_acc_name, [Validators.required, Validators.minLength(5)])
    })
  }

  checkAppNameIsUnique() {
    this.checkingIfNameIsUnique = true;
    this.staService.checkIfAppNameIsUnique(this.appDetailsFG.controls.appName.value).subscribe(data => {
      console.log(data);
      this.uniqueAppName = data.available;
      this.checkingIfNameIsUnique = false;
      this.appNameChecked = true;
    });
  }

  submitAppDetails() {
    this.standaloneApp.name = this.appDetailsFG.controls.appName.value;

    this.standaloneApp.team_id = this.appDetailsFG.controls.teamId.value.trim();
    this.standaloneApp.apple_dev_acc_name = this.appDetailsFG.controls.appleDeveloperAccountName.value.trim();
    // this.toastr.success('Details Sent to App Team!');
    
    let app = {'_id': this.standaloneApp._id, 'name': this.standaloneApp.name, 'team_id': this.standaloneApp.team_id, 'apple_dev_acc_name': this.standaloneApp.apple_dev_acc_name};

    this.staService.submitAppDetails(app).subscribe(data => {
      this.standaloneApp = data;
      // this.showAddAppDetailsForm = false;
      console.log(this.standaloneApp);
      this.formSubmittedSuccesfully.emit(this.standaloneApp);
    })
  }

}
