<div class="container mb-5">
    <div class="btn_container mb-2 mt-5">
        <button [disabled]="" *ngIf="!addVideo" (click)="toggleTable()"mat-raised-button color="primary" type="submit">Add New Video &nbsp;<mat-icon aria-hidden="false" color="#fff">add_circle</mat-icon>
        </button>
        <button [disabled]="!returnedVideoFromServer.length" *ngIf="addVideo"  (click)="toggleTable()" mat-stroked-button color="warn" type="submit">Cancel &nbsp; <mat-icon aria-hidden="false">close</mat-icon></button>
    </div>
    <div *ngIf="loading"  class="loading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
        <div class="add_form_container" *ngIf="addVideo">
            <mat-card style="width:100%">
                <div class="video_sub_title mt-2 mb-5">Add Video to Libray</div>
                <form [formGroup]="addVideoFG" class="add_video_form" (ngSubmit)="onAddVideo()">
                    <div class="form-field ">
                        <div class="form-field-value pl-4 pr-4" style="width: 100% !important;">
                            <mat-form-field>
                                <mat-label>Enter a Video title</mat-label>
                                <input matInput placeholder="Your video title.." formControlName="name" value=""
                                    >
                                <mat-error *ngIf="
                            !addVideoFG.get('name').valid &&
                            addVideoFG.get('name').touched
                          ">Please enter a Video Title</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field-value pl-4 pr-4" style="width: 100% !important;">
                           <mat-form-field >
                                <mat-label>Enter a Youtube URL</mat-label>
                                <input matInput placeholder="Your Youtube url.." formControlName="id" value="">
                                <mat-error *ngIf="
                            !addVideoFG.get('id').valid &&
                            addVideoFG.get('id').touched
                          ">Please enter a Youtube URL</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-field-value pl-4 pr-4" style="width: 100% !important;">
                           <mat-form-field >
                                <mat-label>Select a Category</mat-label>
                                <mat-select formControlName="category">
                                    <mat-option *ngFor="let cat of categories" [value]="cat.category">
                                        {{cat.category}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="
                            !addVideoFG.get('category').valid &&
                            addVideoFG.get('category').touched
                          ">Please select a category</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="pl-4 mb-4 mt-2 webinarSubmit">
                        <button [disabled]="addVideoFG.invalid" mat-raised-button color="primary" type="submit">Submit</button>
                    </div>
                </form>
            </mat-card>
       </div>
       <div *ngIf="returnedVideoFromServer.length">
           <app-video-table [returnedVideoFromServer]="updatedReturnedVideoFromServer" (displayForm)="displayForm($event)"></app-video-table>
       </div>

    </div>