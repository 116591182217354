import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Job } from 'src/models/job';
import { Question } from 'src/models/question';
import { Test } from 'src/models/test';
import { Stage } from 'src/models/stage';

@Injectable({
  providedIn: 'root'
})
export class TrackBuilderService {


    // Observable string sources
    private joblistSource = new Subject<Job[]>();
    private missionConfirmedSource = new Subject<string>();
    private selectedQuestionSource = new Subject<Question>();
    private newTestSource = new Subject<Test>();
    private newQuestionAddedSource = new Subject<Question>();
    private questionDeletedSource = new Subject<Question>();
    private newStageAddedSource = new Subject<Stage>();
    private stageDeletedSource = new Subject<Stage>();
  
    // Observable string streams
    joblistChanged$ = this.joblistSource.asObservable();
    missionConfirmed$ = this.missionConfirmedSource.asObservable();
    selectedQuestionChanged$ = this.selectedQuestionSource.asObservable();
    newTestAdded$ = this.newTestSource.asObservable();
    newQuestionAdded$ = this.newQuestionAddedSource.asObservable();
    questionDeleted$ = this.questionDeletedSource.asObservable();
    newStageAdded$ = this.newStageAddedSource.asObservable();
    stageDeleted$ = this.stageDeletedSource.asObservable();

    // Service message commands
    changeJobList(jobList: Job[]) {
      this.joblistSource.next(jobList);
    }

    changeSelectedQuestion(question: Question) {
      this.selectedQuestionSource.next(question);
    }
  
    confirmMission(astronaut: string) {
      this.missionConfirmedSource.next(astronaut);
    }

    addNewTest(test: Test) {
      this.newTestSource.next(test);
    }

    addNewQuestion(question: Question) {
      this.newQuestionAddedSource.next(question);
    }

    deleteQuestion(question: Question) {
      this.questionDeletedSource.next(question);
    }

    addNewStage(stage: Stage) {
      this.newStageAddedSource.next(stage);
    }

    deleteStage(stage: Stage) {
      this.stageDeletedSource.next(stage);
    }


}
