import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-standalone-app-review',
  templateUrl: './standalone-app-review.component.html',
  styleUrls: ['./standalone-app-review.component.sass', '../standalone-builder-parent/screen.css', '../standalone-builder-parent/sceen-apps.css']
})
export class StandaloneAppReviewComponent implements OnInit, AfterViewInit {

  @Input()
  standaloneApp: StandAloneApp;

  screenView = 'icons';
  waitingForResponse = false;
  assetsSent = false;

  constructor(private staService: StandaloneAppService, private toastr: ToastrService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit () {
    document.getElementById('customApp').innerHTML = this.standaloneApp.iconHTML;
    let img  = document.getElementById('logoImg') as HTMLImageElement;
    console.log(img);
    
    document.getElementById('customApp').innerHTML = '<img src="" alt="" id="customAppLogo">';
    console.log('here: ',img.style.width.replace( /(\D.+)/g, ""));
    document.getElementById('customApp').style.backgroundColor = this.standaloneApp.iconBackgroundColor;
    (document.getElementById('customAppLogo') as HTMLImageElement).src = img.src;
    (document.getElementById('customAppLogo') as HTMLImageElement).style.width =  `${+img.style.width.replace( /(\D.+)/g, "") * 0.15625 }px `
  }

  showSplashScreen() {
    this.screenView = 'splashscreen';
    document.getElementById('phoneWrapper').innerHTML = this.standaloneApp.splashScreenHTML;
  }

  showIcons() {
    this.screenView = 'icons';
  }

  sendToAppTeam() {
    this.waitingForResponse = true;
    this.staService.sendAssetsToAWS(this.standaloneApp).subscribe(data => {
      this.waitingForResponse = false;
      this.toastr.success('Assets sent to app team.', 'Good Job!');
      this.assetsSent = true;
      this.standaloneApp = data;
    })
  }

}
