import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { StandAloneApp } from 'src/models/standalone-app';

@Component({
  selector: 'app-glofox-invite',
  templateUrl: './glofox-invite.component.html',
  styleUrls: ['./glofox-invite.component.sass']
})
export class GlofoxInviteComponent implements OnInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output()
  inviteConfirmed: EventEmitter<any> = new EventEmitter();

  constructor(private staService: StandaloneAppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  confirmInvite() {
    this.staService.inviteConfirmed(this.standaloneApp._id).subscribe(data => {
      this.standaloneApp = data;
      this.inviteConfirmed.emit(this.standaloneApp);
    })
  }

}
