<div id="loginFormWrapper" class="loginWrapper container-fluid d-flex flex-column align-items-center justify-content-center" style="height: 100vh; background-color: #1d2c38">
  <div>
    <div id="branding" style="display: flex; align-items: center; flex-direction: column">
      <div id="logoWrapper" class="d-flex flex-direction-column mr-2 mb-5">
        <img id="logo" src="../../../../assets/images/glofox_login.png" alt="" srcset="" />
      </div>
    </div>
    <div *ngIf="!checkingToken else loading">
      <div *ngIf="!attemptMade && validToken">
        <div *ngIf="admin" style="color: #fff; text-align: center; font-family: Soleil, sans-serif">
          <h2 style="font-weight: 600" class="mb-4">Hello {{ admin.firstName }}!</h2>
  
          <h4 style="text-align: center; color: #fff; font-family: Soleil, sans-serif" class="mb-3">Welcome to the Glofox Onboarding Portal.</h4>
  
          <h5>Let's get started by setting up your password.</h5>
        </div>
        <form id="loginForm" [formGroup]="resetPasswordFG" class="d-flex flex-column" (ngSubmit)="onFormSubmit()">
          <!-- PASSWORD FIELD -->
          <mat-form-field>
            <input matInput [type]="hidePW ? 'password' : 'text'" formControlName="password" required style="color: white !important" />
            <mat-placeholder style="color: #fff">Password</mat-placeholder>
  
            <mat-icon matSuffix (click)="hidePW = !hidePW" style="color: white !important; cursor: pointer"> {{ hidePW ? "visibility_off" : "visibility" }}</mat-icon>
          </mat-form-field>
  
          <!-- CONFIRM PASSWORD FIELD -->
          <mat-form-field>
            <input matInput [type]="hideCPW ? 'password' : 'text'" formControlName="confirmPassword" required style="color: white !important" />
            <mat-placeholder style="color: #fff">Confirm Password</mat-placeholder>
  
            <mat-icon matSuffix (click)="hideCPW = !hideCPW" style="color: white !important; cursor: pointer"> {{ hideCPW ? "visibility_off" : "visibility" }}</mat-icon>
          </mat-form-field>
  
          <div class="input-group d-flex justify-content-center flex-direction-column">
            <button id="setPassword" type="submit" [disabled]="resetPasswordFG.invalid || !doPasswordsMatch()" style="width: 200px; color: #330f2e" class="glofoxBtn glofoxBtnCyan">Set Password</button>
          </div>
        </form>
      </div>
  
      <div *ngIf="!validToken">
        <div class="container" style="text-align: center; color: #fff; font-family: Soleil, sans-serif">
          <h3 style="font-weight: 600; margin-top: 2rem; margin-bottom: 2rem">Ooops! Token is invalid.....</h3>
          <app-request-password-reset-email style="width: 100%" [allowCancel]="false" (cancelPasswordReset)="login()"> </app-request-password-reset-email>
  
          <h6>Already set your password? <a class="loginLink clickable" (click)="redirectToLogin()">Login</a></h6>
        </div>
      </div>
    </div>

    <div *ngIf="attemptMade">
      <div class="mt-3" *ngIf="successfulReset" style="color: white; text-align: center; display: flex; justify-content: center; flex-direction: column">
        <h4 class="mb-4" style="font-weight: 600; font-family: Soleil, sans-serif; margin-bottom: 2rem">Password was set successfully!</h4>
        <button class="glofoxBtn glofoxBtnCyan mt-3" style="width: 200px; margin: 0 auto; color: #330f2e" (click)="login()" routerLinkActive="router-link-active">Log In</button>
      </div>

      <div style="color: white; text-align: center" *ngIf="!successfulReset">
        <h6 class="mb-3" style="font-weight: 600; font-family: Soleil, sans-serif">Password reset failed!</h6>
        <p>{{ feedbackMsg }}</p>
      </div>
    </div>
  </div>
</div>


<ng-template #loading >
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>