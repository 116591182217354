<mat-card class="mt-2 mb-3">
    <mat-card-content>
        <h6>Create New Review</h6>
        <form [formGroup]="testFG" *ngIf="testFG" class="d-flex align-items-center mt-4" (ngSubmit)="saveTest()">
            <mat-form-field style="width: 100%">
                <mat-label>Review Title</mat-label>
                <input type="text" matInput formControlName="name"  placeholder="Stage Three Review">
              </mat-form-field>
              <div class="ml-4">
                  <button mat-raised-button [disabled]="testFG.invalid" type="submit">Save</button>
              </div>
        </form>
    </mat-card-content>
</mat-card>