<div class="d-flex flex-column justify-content-between" style="width: 100%">
    <div id="currentNote" *ngIf="!updateNoteMode"  class="mb-3 noteBody">
    {{displayNote()}}
    </div>
    <form [formGroup]="noteFG">
        <ckeditor *ngIf="updateNoteMode && noteFG" id="noteEditor" formControlName="note" [editor]="editor"  [config]="editorConfig" 
           >
        </ckeditor>
    </form>
    
    <div *ngIf="!updateNoteMode" class="buttons d-flex">
        <button mat-raised-button  class="mr-1" (click)="addNewNote()">Send New Note</button>
        <button mat-raised-button  class="ml-1"  *ngIf="currentNote.length" (click)="editCurrentNote()">Edit Existing Note</button>
    </div>
    
    <div *ngIf="updateNoteMode" class="buttons d-flex">
        <button mat-raised-button  class="mt-3 mr-1" (click)="saveAndSend()">Save & Send</button>
        <button mat-stroked-button class="mt-3 mr-1" (click)="save()">Save</button>
        <button mat-stroked-button  class="mt-3 mr-1" (click)="cancel()">Cancel</button>
    </div>
</div>
