
<mat-card class="mt-2 mb-3">
    <mat-card-content>
        <div class="d-flex align-items-center justify-content-between">
            <small class="text-secondary ">Test ID: {{test._id}}</small>
            <div><button mat-raised-button style="font-size: 0.8rem; line-height: 2.5; padding-right: 9px; padding-left: 9px;" (click)="deleteReview()">Delete Review</button></div>
        </div>
        <form [formGroup]="testFG" class="d-flex align-items-center mt-4" (ngSubmit)="saveTest()">
            <mat-form-field style="width: 100%">
                <mat-label>Review Title</mat-label>
                <input type="text" matInput formControlName="name"  placeholder="Stage Three Review">
              </mat-form-field>
              <div class="ml-4">
                  <button mat-raised-button type="submit">Save</button>
              </div>
        </form>
    </mat-card-content>
</mat-card>
        
      <table cdkDropList  #table
      (cdkDropListDropped)="reorderQuestions($event)" style="width: 100%" mat-table [dataSource]="questionsDS" [cdkDropListData]="questionsDS" class="mat-elevation-z8">
  
      <ng-container matColumnDef="drag">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell style="padding-left: 14px;" *matCellDef="let question" [ngClass]="{'selected': question._id === selectedQuestion._id}">
              <!-- <mat-icon cdkDragHandle svgIcon="dragVertical"></mat-icon> -->
              <i cdkDragHandle style="padding-top: 6px; cursor:grab" class="material-icons">drag_handle</i>
          </td>
          <td mat-footer-cell *matFooterCellDef class="addBtn"> </td>
  
      </ng-container>
  
          <!-- Title Column -->
          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef> Questions </th>
            <td mat-cell *matCellDef="let question" [ngClass]="{'selected': question._id === selectedQuestion._id}"> {{formatQuestionTitle(question.question)}} </td>
            <td mat-footer-cell *matFooterCellDef (click)="newQuestion()" class="addBtn clickable"> 
              <span class="material-icons" style="padding-left: 4%; padding-top: 4px;">
              add_circle_outline
              </span> 
            </td>
  
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="questionTableDisplayedColumns"></tr>
          <tr mat-row (click)="selectQuestion(row)" class="clickable" *matRowDef="let row; columns: questionTableDisplayedColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row"></tr>
          <tr mat-footer-row *matFooterRowDef="questionTableDisplayedColumns"></tr>
        </table>


