import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AdminService } from "../../services/admin.service";
import { Admin } from "src/models/admin";
import { Branch } from "src/models/branch";
import { Step } from "src/models/steps";
import { MatDialog } from "@angular/material/dialog";
import { ActionList } from "src/models/action-list";
import { BranchService } from "src/modules/manager-portal/services/branch.service";
import { Job, Role } from "src/models/job";
import { Stage } from "src/models/stage";
import { faChevronUp, faChevronDown, faTimesCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { ClientGuideDialogComponent } from "../dialogs/client-guide-dialog/client-guide-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { StandAloneApp } from "src/models/standalone-app";
import { StandaloneAppService } from "../../services/standalone-app.service";
import { WebIntegration } from "src/models/web-integration";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { SurveyService } from "src/app/services/survey.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-client-dashboard",
  templateUrl: "./client-dashboard.component.html",
  styleUrls: ["./client-dashboard.component.sass"],
  animations: [
    trigger("openClose", [state("true", style({ height: "*" })), state("false", style({ height: "0px" })), transition("false <=> true", [animate(300)])]),
    trigger("rotatedState", [state("default", style({ transform: "rotate(0)" })), state("rotated", style({ transform: "rotate(-180deg)" })), transition("rotated => default", animate("400ms ease-out")), transition("default => rotated", animate("400ms ease-in"))]),
  ],
})
export class ClientDashboardComponent implements OnInit, AfterViewInit {
  admin: Admin;
  branch: Branch;
  steps: Step[] = [];
  actionlist: ActionList;

  today = new Date();

  upIcon = faChevronUp;
  downIcon = faChevronDown;
  closeIcon = faTimesCircle;
  commentIcon = faCommentDots;

  tickIcon = faCheck;

  hideHiddenSteps = false;
  validJobIds: string[] = [];

  standAloneApp: StandAloneApp;
  websiteIntegration: WebIntegration;

  displaySurveyInput: boolean = false;
  hideStageHeaderFlag = false;
  displayViaStorage = true;

  displayLocally = false;

  hideCompleteObBanner = false;

  lang = 'en';

  // animation bools
  showStage = true;
  state = "default";
  boxShadow = true;

  stage: Stage;
  constructor(
    private auth: AuthenticationService,
    private adminService: AdminService,
    public branchService: BranchService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private staService: StandaloneAppService,
    private surveyService: SurveyService,
    private toastr: ToastrService
  ) { };

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe((adminData) => {
        this.admin = adminData;
        this.adminService.getBranchForAdmin(this.admin).subscribe((branchData) => {
          this.branchService.updateLastOnlineAt(branchData._id).subscribe((data) => { });
          this.branch = Object.assign(new Branch(), branchData);

          this.lang = window.localStorage.getItem('currentLang') ? window.localStorage.getItem('currentLang') : 'en';
          this.translate.use(this.lang);

          this.validJobIds = this.branch.joblist.map((jobItem) => jobItem.job);
          if (this.branch.standaloneApp) {
            this.staService.getStandAloneApp(`${this.branch.standAloneApp}`).subscribe((staData) => {
              this.branch.standAloneApp = staData;
            });
          }

          if (this.branch.webIntegration) {
            this.branchService.getWebIntegration(this.branch.webIntegration._id).subscribe((webIntData) => {
              this.websiteIntegration = webIntData;
            });
          }

          if (this.branch.track._id === "5f02df42d00d0260fe27195d") {
            this.translate.setDefaultLang("pt-br");
          } else if (this.admin.branch.name.includes('UFC')) {
            this.translate.setDefaultLang("ja");

          } else {
            this.translate.setDefaultLang("en");
          }

          if (typeof this.branch.startDate === "string") {
            this.branch.startDate = new Date(this.branch.startDate);
          }
          let customerJobs = [];
          this.branch.track.stages.forEach((stage) => {
            this.stage = stage;
            customerJobs = customerJobs.concat(
              stage.jobs.filter((j) => {
                return j.role === Role.CUSTOMER;
              })
            );
          });

          customerJobs.forEach((j) => {
            if (!j.articles) {
              j.articles = [];
            }
            if (!j.medias) {
              j.medias = [];
            }
          });
          setTimeout(() => {
            this.branchService.updateLastOnlineAt(this.branch._id).subscribe((data) => { });
          }, 500);
        });
      });

    }

    let x = localStorage.getItem('hideCompleteObBanner');
    if (x) {
      this.hideCompleteObBanner = true;
    } else {
      this.hideCompleteObBanner = false;
    }

  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  useLanguage() {
    window.localStorage.setItem('currentLang', this.lang);
    this.translate.use(this.lang);
  }


  onSubmitSurvey(event) {
    event.how_submitted = 'Internal';
    this.surveyService.onAddSurvey(event, this.admin._id)
      .subscribe(surveyRes => {
        if (surveyRes.message === 'Survey Added Succesfully') {
          // this.adminUpdated.emit(surveyRes.admin);
          this.admin = surveyRes.admin;
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          console.log(surveyRes)
          this.hideStageHeaderFlag = false;
          this.displayLocally = true;
          localStorage.removeItem("displayForm");
          this.toastr.success(`Thanks ${this.admin.firstName}, Your Survey has been Submitted`)
        } else if (surveyRes.message === "Error") {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          this.toastr.error('Error')
        }

      });

  }

  closeSurveyBanner($event) {
    this.hideCompleteObBanner = $event;
    localStorage.setItem('hideCompleteObBanner', "true")
  }

  displaySurvey($event) {
    this.displaySurveyInput = $event;
  }
  hideStageHeaders($event) {
    console.log('hide stage', this.hideStageHeaderFlag, $event)
    this.hideStageHeaderFlag = $event;
  }



  toggleStage(): void {
    console.log(this.showStage);
    this.showStage = !this.showStage;
    this.state = this.state === "default" ? "rotated" : "default";
    // this.boxShadow = (this.boxShadow === 'open' ? 'close' : 'open');
    this.boxShadow = !this.boxShadow;
    // console.log('box', this.boxShadow )
  }

  openClientGuideDialog(): void {
    const dialogRef = this.dialog.open(ClientGuideDialogComponent);
  }

  injectJobHTML(job: Job) {
    if (job.innerHTML) {
      document.getElementById(job._id).innerHTML = job.innerHTML;
    }
  }

  openSurvey() {
    window.open(this.branch.obmanager.surveyURL);
  }

  getStageProgress(stage: Stage): number {
    const jobItems = this.branch.joblist;
    const jobs = stage.jobs.filter((j) => j.role === Role.CUSTOMER);
    let res: Job[] = [];
    jobs.forEach((job) => {
      if (!job.filterOn.length) {
        res.push(job);
      } else if (job.filterOn.filter((element) => this.branch.onboardingOptions.includes(element)).length) {
        res.push(job);
      }
    });
    const jobIds = res.map((j) => j._id);
    const finishedJobs = jobItems.filter((ji) => ji.status && jobIds.includes(ji.job));
    return (finishedJobs.length / res.length) * 100;
  }

  isCurrentStage(stage: Stage): boolean {
    if (this.branch.joblist) {
      let res: Stage;
      const stages = new Array(...this.branch.track.stages);
      stages.reverse().forEach((stage, index) => {
        if (this.getStageProgress(stage) < 100) {
          res = stage;
        }
      });
      return res._id === stage._id;
    }
  }

  getJobDeadline(job: Job, stage: Stage) {
    if (this.branch.startDate) {
      return new Date(this.branch.startDate.getTime() + (stage.hoursFromEpoch + job.hoursToComplete) * 3600000);
    }
  }

  getNIncompleteStageJobs(stage: Stage): string {
    const stageJobs = this.filterJobsForCustomer(stage.jobs, true);
    const overdueJobs = stageJobs.filter((j) => this.getJobDeadline(j, stage) < new Date(Date.now()));
    return `${overdueJobs.length}`;
  }

  getJobItem(job: Job): any {
    return this.branch.joblist.find((j) => j.job === job._id);
  }

  filterJobsForCustomer(jobs: Job[], incomplete = false): Job[] {
    let res: Job[] = [];
    let validJobIds = [];
    if (incomplete) {
      let completejobs = this.branch.joblist.filter((ji) => !ji.status);
      validJobIds = completejobs.map((jobItem) => jobItem.job);
    } else {
      validJobIds = this.branch.joblist.map((jobItem) => jobItem.job);
    }
    jobs = jobs.filter((j) => validJobIds.includes(j._id));
    jobs.forEach((job) => {
      if (!job.filterOn.length) {
        res.push(job);
      } else if (job.filterOn.filter((element) => this.branch.onboardingOptions.includes(element)).length) {
        res.push(job);
      }
    });
    return res.filter((job) => job.role === 0).sort(this.sortByDeadline);
  }

  isValidStage(stage: Stage): boolean {
    let userJobs = this.branch.joblist.map((j) => j.job);
    let stageJobsFiltered: Job[];
    if (this.branch.onboardingOptions.length) {
      stageJobsFiltered = stage.jobs.filter((j) => this.branch.onboardingOptions.every((option) => j.filterOn.includes(option)));
    } else {
      stageJobsFiltered = stage.jobs;
    }
    let stageJobs = stageJobsFiltered.map((j) => j._id);
    let intersection = stageJobs.filter((j) => userJobs.includes(j));
    return intersection.length > 0;
  }

  updateAppChecklist(event) {
    console.log(event);
    this.staService.updateAppChecklist(this.standAloneApp).subscribe((data) => {
      console.log(data);
    });
  }

  toggleHideCompletedCards() {
    console.log(this.branch.hideCompleteCards);
    this.branchService.toggleHideCompletedCards(this.branch).subscribe();
  }

  sortByDeadline(a: Job, b: Job) {
    // Use toUpperCase() to ignore character casing
    const deadlineA = a.hoursToComplete;
    const deadlineB = b.hoursToComplete;

    let comparison = 0;
    if (deadlineA > deadlineB) {
      comparison = 1;
    } else if (deadlineA < deadlineB) {
      comparison = -1;
    }
    return comparison;
  }
}
