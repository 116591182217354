import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanPipe } from 'src/app/shared/pipes/plan.pipe';
import { StudioEmoticonPipe } from 'src/app/shared/pipes/studio-emoticon.pipe';
import { UserRolePipe } from 'src/app/shared/pipes/user-role.pipe';
import { HourPipe } from 'src/app/shared/pipes/hour.pipe';
import { JobTableComponent } from './components/job-table/job-table.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ProgressPipe } from 'src/app/shared/pipes/progress.pipe';
import { AccountStatusPipe } from 'src/app/shared/pipes/account-status.pipe';
import { WistiaTitlePipe } from 'src/app/shared/pipes/wistia-title.pipe';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebIntStatusPipe } from 'src/app/shared/pipes/web-int-status.pipe';
import { ToastrService } from 'ngx-toastr';
import { JobThumbComponent } from '../client-portal/components/job-thumb/job-thumb.component';
import { StageDetailsComponent } from './components/stage-details/stage-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { StageThumbnailComponent } from './components/stage-thumbnail/stage-thumbnail.component';
import { StageDetailComponent } from './components/stage-detail/stage-detail.component';
import { StageIconsPipe } from 'src/app/shared/pipes/stage-icons.pipe';
import { ArticlesService } from 'src/app/services/articles.service';
import { MaxstringlengthPipe } from 'src/app/shared/pipes/maxstringlength.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { StageCompletionDialogComponent } from './components/stage-completion-dialog/stage-completion-dialog.component';
import { LeadCaptureGeneratorComponent } from './components/lead-capture-generator/lead-capture-generator.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { HighlightModule } from 'ngx-highlightjs';
import { CodeSnippetsComponent } from './components/code-snippets/code-snippets.component';
import { CmsGuidesComponent } from './components/cms-guides/cms-guides.component';
import { MatRadioModule } from '@angular/material/radio';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OnboardingExperienceSurveyComponent } from './components/onboarding-experience-survey/onboarding-experience-survey.component';
import { ExternalSurveyPortalComponent } from './components/external-onboarding-survey/external-survey-portal/external-survey-portal.component';
import { ExternalOnboardingExperienceSurveyComponent } from './components/external-onboarding-survey/external-onboarding-experience-survey/external-onboarding-experience-survey.component';



@NgModule({
  declarations: [
    PlanPipe,
    StudioEmoticonPipe,
    UserRolePipe,
    HourPipe,
    JobTableComponent,
    LoadingComponent,
    ProgressPipe,
    AccountStatusPipe,
    WistiaTitlePipe,
    BranchListComponent,
    WebIntStatusPipe,
    JobThumbComponent,
    StageDetailsComponent,
    LanguagePipe,
    StageIconsPipe,
    StageThumbnailComponent,
    StageDetailComponent,
    MaxstringlengthPipe,
    StageCompletionDialogComponent,
    LeadCaptureGeneratorComponent,
    CodeSnippetsComponent,
    CmsGuidesComponent,
    OnboardingExperienceSurveyComponent,
    ExternalSurveyPortalComponent,
    ExternalOnboardingExperienceSurveyComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    CdkTableModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatProgressBarModule,
    FontAwesomeModule,
    MatButtonModule,
    FormsModule,
    MatCardModule,
    TranslateModule,
    MatExpansionModule,
    HighlightModule,
    MatRadioModule,
    ClipboardModule
  ],
  exports: [
    PlanPipe,
    StudioEmoticonPipe,
    UserRolePipe,
    HourPipe,
    JobTableComponent,
    LoadingComponent,
    ProgressPipe,
    AccountStatusPipe,
    WistiaTitlePipe,
    BranchListComponent,
    WebIntStatusPipe,
    JobThumbComponent,
    StageDetailsComponent,
    LanguagePipe,
    StageIconsPipe,
    StageDetailComponent,
    StageThumbnailComponent,
    OnboardingExperienceSurveyComponent,
    ExternalSurveyPortalComponent,
    ExternalOnboardingExperienceSurveyComponent,
    MaxstringlengthPipe,

  ],
  providers: [ArticlesService],
  entryComponents: [StageCompletionDialogComponent]


})
export class SharedModule { }
