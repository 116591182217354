import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plan'
})
export class PlanPipe implements PipeTransform {

  PLANS = ['Silver', 'Gold', 'Platinum'];

  transform(value: number, ...args: any[]): any {
    if (value < this.PLANS.length) {
      return this.PLANS[value];
    }
    return value;
  }

}
