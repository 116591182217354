import { Component, OnInit, Input } from '@angular/core';
import { Stage } from 'src/models/stage';
import { Role, Job } from 'src/models/job';
import { Branch } from 'src/models/branch';
import { faChevronUp, faChevronDown, faTimesCircle, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-stage-details',
  templateUrl: './stage-details.component.html',
  styleUrls: ['./stage-details.component.sass']
})
export class StageDetailsComponent implements OnInit {

  @Input() stage: Stage;
  @Input() branch: Branch;
  @Input() role: number;
  @Input() initiallyOpened: boolean = false;

  upIcon = faChevronUp;
  downIcon = faChevronDown;
  closeIcon = faTimesCircle;
  commentIcon = faCommentDots;



  constructor() { }

  ngOnInit() {
    console.log(this.initiallyOpened);
    
    if (this.initiallyOpened) {
      this.toggleStage(this.stage._id)
    }
  }

  toggleStage(id: string): void {
    try {
      const stage = document.getElementById(id);
      if (stage.classList.contains('show')) {
        stage.classList.remove('show');
        document.getElementById(`up${id}`).style.display = 'none';
        document.getElementById(`down${id}`).style.display = 'block';
      } else {
        stage.classList.add('show');
        stage.classList.add('collapse');
        document.getElementById(`down${id}`).style.display = 'none';
        document.getElementById(`up${id}`).style.display = 'block';

      }
    } catch (err) {}
  }

  getStageProgress(stage: Stage): number {
    const jobItems = this.branch.joblist;
    const jobs = stage.jobs.filter(j => j.role === this.role);
    let res: Job[] = [];
    jobs.forEach(job => {
      if (!job.filterOn.length) {
        res.push(job);
      } else if (job.filterOn.filter(element => this.branch.onboardingOptions.includes(element)).length) {
        res.push(job);
      }
    });
    const jobIds = res.map(j => j._id);
    const finishedJobs = jobItems.filter(ji => ji.status && jobIds.includes(ji.job));
    return (finishedJobs.length / res.length) * 100;
  }

  getNIncompleteStageJobs(stage: Stage): string {
    const stageJobs = this.filterJobsForCustomer(stage.jobs, true);
    const overdueJobs = stageJobs.filter(j => this.getJobDeadline(j, stage) < new Date(Date.now()))
    return `${overdueJobs.length}`
  }

  getJobDeadline(job: Job, stage: Stage) {
    if (this.branch.startDate) {
      return new Date(new Date(this.branch.startDate).getTime() + (stage.hoursFromEpoch + job.hoursToComplete) * 3600000);
    }
  }

  filterJobsForCustomer(jobs: Job[], incomplete = false): Job[] {
    let res: Job[] = [];
    let validJobIds = [];
    if (incomplete) {
      let completejobs = this.branch.joblist.filter(ji => !ji.status);
      validJobIds = completejobs.map(jobItem => jobItem.job);
    } else {
      validJobIds = this.branch.joblist.map(jobItem => jobItem.job);
    }
    jobs = jobs.filter(j => validJobIds.includes(j._id));
    jobs.forEach(job => {
      
      if (!job.filterOn.length) {
        res.push(job);
      } else if (job.filterOn.filter(element => this.branch.onboardingOptions.includes(element)).length) {
        res.push(job);
      }
    });
    return res.filter(job => job.role === this.role).sort(this.sortByDeadline);
  }

  getJobItem(job: Job): any {
    return this.branch.joblist.find(j => j.job === job._id);
  }

  sortByDeadline(a: Job, b: Job) {
    // Use toUpperCase() to ignore character casing
    const deadlineA = a.hoursToComplete;
    const deadlineB = b.hoursToComplete;

    let comparison = 0;
    if (deadlineA > deadlineB) {
      comparison = 1;
    } else if (deadlineA < deadlineB) {
      comparison = -1;
    }
    return comparison;
  }

}
