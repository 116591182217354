import { Component, OnInit, Input } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { WebIntegration } from 'src/models/web-integration';

@Component({
  selector: 'app-standalone-app-progress-tracker',
  templateUrl: './standalone-app-progress-tracker.component.html',
  styleUrls: ['./standalone-app-progress-tracker.component.sass']
})
export class StandaloneAppProgressTrackerComponent implements OnInit {




  @Input()
  standAloneApp: StandAloneApp;

  tickIcon = faCheck;

  constructor() { }

  ngOnInit() {
  }

}
