<div class="mb-2 background" *ngIf="!displayViaStorage || displayLocally ">

    <div *ngIf="!admin.onboardingSurvey.has_submitted">
        <div class="title_container">
            <div class="completion_title">
                {{"client_dash.fully_onbaoarded_banner.title" | translate}}
            </div>
        </div>
        <div class="subtitle_container">
            <div class="survey-msg">
                {{"client_dash.fully_onbaoarded_banner.subtitle" | translate}}
            </div>
        </div>
        <div class="btn_container" *ngIf="!admin.onboardingSurvey.has_submitted">

            <button mat-raised-button style="color:#330F2E;" (click)="openSurvey()" target="_blank"
                class="m-1"><strong>{{"onboarding.survey.open_btn" | translate}}</strong>&nbsp;
                <mat-icon aria-hidden="false" aria-label=" ">reviews</mat-icon>

            </button>
        </div>
    </div>


    <div *ngIf=" admin && admin.onboardingSurvey.has_submitted">
        <div *ngIf="admin.onboardingSurvey.response.netPromoterScore < 9">
            <div style="color:#fff; display:flex; justify-content: flex-end; cursor: pointer;" (click)="closeBanner()"
                target="_blank" class="mr-1">
                <mat-icon>close</mat-icon>
            </div>
            <div class="title_container">
                <div class="completion_title">
                    {{"onboarding.survey.thank_you_negative_nps" | translate}}
                </div>
            </div>
            <div class="subtitle_container">
                <div class="survey-msg">
                    {{"onboarding.survey.thank_you_negative_nps_subtitle" | translate}}
                </div>
            </div>

        </div>


        <div *ngIf="admin.onboardingSurvey.response.netPromoterScore >= 9">
            <div style="color:#fff; display:flex; justify-content: flex-end; cursor: pointer;" (click)="closeBanner()"
                target="_blank" class="mr-1">
                <mat-icon>close</mat-icon>
            </div>
            <div class="title_container">
                <div class="completion_title">
                    {{"onboarding.survey.thank_you_positive_nps" | translate}}
                </div>
            </div>
            <div class="subtitle_container">
                <div class="survey-msg">
                    {{"onboarding.survey.thank_you_positive_nps_subtitle" | translate:{name:admin.firstName} }}
                </div>
            </div>
            <div class="btn_container">
                <button mat-raised-button style="color:#330F2E;" (click)="leaveReview()" target="_blank"
                    class="ml-1"><strong>{{"onboarding.survey.open__review_btn" | translate}}</strong>
                </button>
            </div>


        </div>



    </div>
</div>