import { Component, OnInit, Input } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AdminService } from "src/modules/client-portal/services/admin.service";
import { Branch } from "src/models/branch";
import { LeadCaptureData } from "../../models/lead-capture.model";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-webint-code-parent",
  templateUrl: "./webint-code-parent.component.html",
  styleUrls: ["./webint-code-parent.component.sass"]
})
export class WebintCodeParentComponent implements OnInit {


  @Input() branch: Branch;
  loading = false;
  leadCapData: LeadCaptureData;

  standaloneLink;

  constructor() {}

  ngOnInit() {
    this.standaloneLink = `https://glofox-website-integration.web.app/${this.branch.branchId}`    
  }

  onFormSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const urlSections = form.controls.userInput.value.split("/");
    this.leadCapData = new LeadCaptureData (
      urlSections[6],
      urlSections[8],
      urlSections[10]
    );
    form.resetForm();

    // console.log(data.controls.userInput.value);
  }

  hasSMT(): boolean {
    return this.branch.onboardingOptions.length && this.branch.onboardingOptions.includes('5e305721105de93a5ccf8bba');
  }
}
