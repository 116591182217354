<div class="container">
  <div class="row">
    <div class="col-6">
      <div *ngIf="!standaloneApp.hasBeenSubmitted">
        <h3 class="mb-5">Thank you for designing the assets for your app.</h3>
        <div class="mb-5 mt-3">
          <p>Please take a moment to review your designs.</p>
          <p>To view your splash screen just click on your app's icon.</p>
          <p>If you are happy, click below to send them to the app team.</p>
        </div>
        <div class="mb-5">
          <button mat-raised-button (click)="sendToAppTeam()">
            <div *ngIf="waitingForResponse" style="min-width: 100px"><img class="loadingIcon" src="https://img.icons8.com/material-two-tone/24/000000/loading.png" /></div>
            <div *ngIf="!waitingForResponse" style="min-width: 100px">Send To App Team</div>
          </button>
          <div class="mt-2 text-secondary" *ngIf="waitingForResponse">It may take a minute to process the images. Thank you for your patience.</div>
        </div>
      </div>

      <div class="mb-5" *ngIf="standaloneApp.hasBeenSubmitted">
        <h3 class="d-inline">Good Job.</h3>
        <br />
        <dl class="mt-5">
          <dt class="mb-2">What happens next?</dt>
          <dd>1. Our app team will take the information and assets you have created today and use them to build your Andoid and iOS apps.</dd>
          <dd>2. These apps will be submitted for review.</dd>
          <dd>3. You will recieve an email notifying you of the result of these reviews.</dd>
        </dl>

        <button mat-raised-button [routerLink]="['/']">Back to Onboarding</button>
      </div>
    </div>
    <div class="col-6">
      <div class="mb-5 d-flex justify-content-center">
        <div class="marvel-device iphone-x">
          <div class="notch">
            <div class="camera"></div>
            <div class="speaker"></div>
          </div>
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="bottom-bar"></div>
          <div class="volume"></div>
          <div class="overflow">
            <div class="shadow shadow--tr"></div>
            <div class="shadow shadow--tl"></div>
            <div class="shadow shadow--br"></div>
            <div class="shadow shadow--bl"></div>
          </div>
          <div class="inner-shadow"></div>
          <div class="screen">
            <!-- Content goes here -->
            <div id="phoneWrapper" >
              <div style="padding: 30px; padding-top: 60px">
                <div class="container">
                  <div class="row appRow">
                    <div class="col-3 appWrapper">
                      <div class="app" id="whatsapp"></div>
                      <div class="appName">WhatsApp</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="twitter"></div>
                      <div class="appName">Twitter</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="facebook"></div>
                      <div class="appName">Facebook</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="instagram"></div>
                      <div class="appName">Instagram</div>
                    </div>
                  </div>
                  <div class="row appRow">
                    <div class="col-3 appWrapper">
                      <div class="app" id="glofox"></div>
                      <div class="appName">Glofox</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="glofoxPro"></div>
                      <div class="appName">Glofox Pro</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="spotify"></div>
                      <div class="appName">Spotify</div>
                    </div>

                    <div class="col-3 appWrapper">
                      <div class="app" id="netflix"></div>
                      <div class="appName">Netflix</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 pl-0 pr-0">
                      <div class="appWrapper">
                        <div class="app" id="customApp" (click)="showSplashScreen()" style="cursor: pointer"></div>
                      </div>
                      <div class="appName">
                        {{ standaloneApp.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
