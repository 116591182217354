import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Admin } from 'src/models/admin';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-branch-onboarding-complete',
  templateUrl: './branch-onboarding-complete.component.html',
  styleUrls: ['./branch-onboarding-complete.component.sass']
})
export class BranchOnboardingCompleteComponent implements OnInit {

  public gtCrowdUrl = "https://www.g2.com/products/glofox/reviews/start";
  constructor() { }

  @Input()
  branch: Branch;

  @Input()
  admin: Admin;

  @Output()
  displaySurvey = new EventEmitter<Boolean>();

  @Output()
  hideStageHeaders = new EventEmitter<Boolean>();

  displayViaStorage = false;

  @Input()
  displayLocally;

  @Output()
  closeSurveyBanner = new EventEmitter<Boolean>();


  ngOnInit() {
    let x = localStorage.getItem('displayForm');

    if (x) {
      this.displayViaStorage = true;
    } else {
      // tslint:disable-next-line: no-unused-expression
      this.displayViaStorage;
    }
  }

  leaveReview() {
    window.open(this.admin.onboardingSurvey.survey_url);
  }

  openSurvey() {
    let x = localStorage.setItem('displayForm', 'true');
    this.displaySurvey.emit(true);
    this.hideStageHeaders.emit(true);
    this.displayViaStorage = true;

  }


  closeBanner() {
    this.closeSurveyBanner.emit(true);
  }
}
