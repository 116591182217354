<div class="loading" *ngIf="loading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="stage">
  <div class="container-fluid mb-5">
    <div class="row">
      <!-- sticky header -->
      <div class="col-xs-12 col-md-12 col-lg-3 side-bar-container mt-5">
        <div [class.sticky]="stickyFlag" #stickyNav>
          <div class="link-container">
            <div class="article-list__item">
              <a [routerLink]="['/client-dashboard/']" class="article-list__link">
                <span class="article-list__link-inner"
                  ><strong>{{ "stage_details.dashboard" | translate }}</strong></span
                >
              </a>
            </div>
            <!-- <a [routerLink]="['/client-dashboard/']" class="pl-5 link-to-homepage">{{stage.title}}</a> -->
          </div>
          <ng-container *ngFor="let job of stage.jobs">
            <div class="side-nav-container" *ngIf="isValidJob(job) && !isInteractiveJobProxy(job)">
              <div class="side-bar-element" style="margin-left: 0px !important" id="switchActive" name="fontStyle">
                <span *ngIf="selectedJob && selectedJob._id === job._id" style="width: 5px; height: 100%; background-color: #330f2e"></span>
                <div class="button-text" (click)="switchJob(job)">
                  <span class=" pl-3 truncate"  translate [translateParams]="{ job: getTranslation(job) }">job.description</span>
                </div>
                <mat-checkbox class="check-box" [(ngModel)]="getJobItem(job).status" (change)="updateStatus($event, getJobItem(job))"></mat-checkbox>
              </div>
            </div>
          </ng-container>
          <div class="side-nav-container" *ngFor="let job of interactiveJobs">
            <div class="side-bar-element" style="margin-left: 0px !important" *ngIf="showInteractiveJob(job)" id="switchActive" name="fontStyle">
              <span *ngIf="!selectedJob" style="width: 5px; height: 100%; background-color: #330f2e"></span>
              <span class="button-text pl-3" (click)="selectInteractiveJob(job)">{{ job.title }}</span>
              <mat-checkbox class="check-box" *ngIf="job.required" [checked]="isInteractiveJobComplete(job)" disabled> </mat-checkbox>
            </div>
          </div>
          <div class="side-nav-quizz-container" *ngIf="stage.test">
            <div class="side-bar-element pl-3 pr-3">
              <a [routerLink]="['/client-dashboard/stage-review', stage.test._id]" class="article-list-right__link">
                <span class="article-list-right__link-inner">{{ "stage_details.test_your_knowledge" | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- description and details -->
      <div class="mt-5 col-xs-12 col-md-12 col-lg-9 pl-sm-0 pr-sm-0" *ngIf="selectedJob && isValidJob(selectedJob) && !interactiveJobMode" style="background-color: #f7f6f7">
        <div class="container">
          <div class="progress-bar-container row align-items-center ml-0">
            <div class="status-header pl-4 col-5">{{ getStageTranslation(stage).title }}</div>
            <!-- progress bar -->
            <div class="col-7">
              <span class="check-box-status ml-2 mr-2" style="font-size: 0.85irem">{{ "stage_details.progress" | translate }}</span>
              <div class="d-flex align-items-center">
                <mat-progress-bar mode="determinate" class="progress-bar" mode="determinate" [color]="getStageProgress(stage) === 100 ? 'primary' : 'warn'" value="{{ getStageProgress(stage) }}"> </mat-progress-bar>
                <span class="stage-percent ml-3">{{ getStageProgress(stage) | number: "1.0-0" }}%</span>
              </div>
            </div>
          </div>
          <mat-card style="padding: 2rem !important">
            <div class="job-details-container mb-2">
              <div class="selected-job-title" style="margin-bottom: 44px" translate [translateParams]="{ job: getTranslation(selectedJob) }">job.description</div>
              <div #jobdetails  class="ck-content">loading...</div>

              <a class="article-list-right__link" *ngIf="selectedJob.externalLink.url" [href]="selectedJob.externalLink.url" target="_blank" style="cursor: pointer; width: fit-content;">
                <span class="article-list-right__link-inner"> <strong>{{selectedJob.externalLink.text}}</strong></span>
              </a>

              <div class="d-flex justify-content-center mb-5 mt-5">
                <!-- aticles container -->

                <div *ngIf="selectedJob.articles.length" class="job-articles-container">
                  <div>
                    <div class="articles-header">
                      {{ "stage_details.articles" | translate }}
                    </div>
                    <div class="article-list">
                      <div *ngFor="let id of selectedJob.articleIds">
                        <!-- <div class="link-container"> -->
                        <div class="article-list__item">
                          <a [href]="'https://support.glofox.com/hc/en-us/articles/' + id" target="_blank" class="article-list-right__link">
                            <span class="article-list-right__link-inner" target="_blank">{{ getArticle(id).title }}</span>
                          </a>
                        </div>
                        <!-- <a [routerLink]="['/client-dashboard/']" class="pl-5 link-to-homepage">{{stage.title}}</a> -->
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
                <!-- video container -->
                <div *ngIf="selectedJob.videoIds.length" class="job-articles-container">
                  <div class="articles-header">{{"stage_details.video_content_available" | translate}}</div>
                  <div class="article-list">
                    <div class="article-list__item">
                      <a (click)="goToVideos(selectedJob.videoIds)" class="article-list-right__link" style="cursor: pointer" target="_blank">
                        <span class="article-list-right__link-inner">{{"stage_details.go_to_videos" | translate}}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 2rem">
                <div class="col-7 offset-2">
                  <div class="f-flex flex-column justify-content-center align-items-center">
                    <div>
                      <div class="mb-3">
                        <div style="text-align: center">{{"stage_details.feedback.please_review" | translate}}</div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <div class="sentiment-icon-wrapper">
                          <mat-icon (click)="reviewAJob(0)" [ngClass]="{ selectedReview: isSelectedReview(0) }"> sentiment_very_dissatisfied</mat-icon>
                          <div class="mt-2">{{"stage_details.feedback.terrible" | translate}}</div>
                        </div>
                        <div class="sentiment-icon-wrapper">
                          <mat-icon (click)="reviewAJob(1)" [ngClass]="{ selectedReview: isSelectedReview(1) }"> sentiment_dissatisfied</mat-icon>
                          <div class="mt-2">{{"stage_details.feedback.bad" | translate}}</div>
                        </div>
                        <div class="sentiment-icon-wrapper">
                          <mat-icon (click)="reviewAJob(2)" [ngClass]="{ selectedReview: isSelectedReview(2) }"> sentiment_satisfied</mat-icon>
                          <div class="mt-2">{{"stage_details.feedback.good" | translate}}</div>
                        </div>
                        <div class="sentiment-icon-wrapper">
                          <mat-icon (click)="reviewAJob(3)" [ngClass]="{ selectedReview: isSelectedReview(3) }"> sentiment_very_satisfied</mat-icon>
                          <div class="mt-2">{{"stage_details.feedback.excellent" | translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4" *ngIf="hasReviewed()">
                      <h6 *ngIf="!showAdditionalFeedbackForm && !additionalFeedbackSubmitted" style="text-align: center">{{"stage_details.feedback.thank_you" | translate}} <a class="toggleFeedback" (click)="showFeedbackForm()" style="color: #3bd8c3 !important">{{"stage_details.feedback.leave_additional_feedback" | translate}}</a></h6>
                      <div class="d-block" *ngIf="showAdditionalFeedbackForm && !additionalFeedbackSubmitted">
                        <mat-form-field style="width: 600px" appearance="fill">
                          <mat-label>{{"stage_details.feedback.feedback" | translate}}</mat-label>
                          <textarea matInput id="feedbackInput"></textarea>
                        </mat-form-field>
                        <button class="d-block" mat-raised-button (click)="submitAdditionalFeedback()">{{"stage_details.feedback.submit" | translate}}</button>
                      </div>
                      <div *ngIf="!showAdditionalFeedbackForm && additionalFeedbackSubmitted" style="text-align: center">
                        <h6>{{"stage_details.feedback.feedback_submitted" | translate}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 d-flex justify-content-end align-items-center mt-3">
                  <div class="article-list__item" *ngIf="hasNextJob()">
                    <a (click)="nextJob()" class="article-list-right__link" style="cursor: pointer">
                      <span class="article-list-right__link-inner"> <strong>{{"stage_details.next" | translate}}</strong></span>
                    </a>
                  </div>
                  <div class=" article-list__item" *ngIf="!hasNextJob()">
                 
                    <a (click)="openStageCompleteDialog(stage)" class="article-list-right__link" style="cursor: pointer;">
                      <span class="article-list-right__link-inner"> <strong style="text-align: right;">{{"stage_details.review_stage" | translate}}</strong></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="mt-5 col-xs-12 col-md-12 col-lg-9" style="background-color: #f7f6f7" *ngIf="interactiveJobMode">
        <div class="container">
          <div class="progress-bar-container row align-items-center ml-0">
            <div class="status-header pl-4 col-5">{{ getStageTranslation(stage).title }}</div>
            <!-- progress bar -->
            <div class="col-7">
              <span class="check-box-status ml-2 mr-2" style="font-size: 0.85irem">{{ "stage_details.progress" | translate }}</span>
              <div class="d-flex align-items-center">
                <mat-progress-bar mode="determinate" class="progress-bar" mode="determinate" [color]="getStageProgress(stage) === 100 ? 'primary' : 'warn'" value="{{ getStageProgress(stage) }}"> </mat-progress-bar>
                <span class="stage-percent ml-3">{{ getStageProgress(stage) | number: "1.0-0" }}%</span>
              </div>
            </div>
          </div>
          <mat-card>
            <mat-card-content>
              <div class="selected-job-title" style="margin-bottom: 44px">{{ selectedInteractiveJob.title }}</div>

              <router-outlet name="auxoutlet"></router-outlet>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
