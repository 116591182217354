<div class="stageHeader clickable" style="display: flex; justify-content: space-between; align-items: center;" data-toggle="collapse" (click)="toggleStage(stage._id)">
  <div class="d-flex align-items-center">
    <h4 class="stageTitle mr-3" style="">{{ stage.title }}</h4>
    <div class="align-items-center d-none d-sm-flex" *ngIf="getStageProgress(stage) === 100">
      <div class="mr-2" style="height: 1rem; width: 1rem; border-radius: 100px; background-color: #44D8C3"></div>
      {{'stage_details.complete' | translate}}
    </div>
    <div class="align-items-center d-none d-sm-flex" *ngIf="getStageProgress(stage) !== 100 && getStageProgress(stage) !== 0">
      <div class="mr-2" style="height: 1rem; width: 1rem; border-radius: 100px; background-color: #F4963B"></div>
      {{'stage_details.in_progress' | translate}}
    </div>
    <div class="align-items-center d-none d-sm-flex" style="width: 100px" *ngIf="getStageProgress(stage) === 0">

    </div>
    <div style="margin-left: 75px;">
      <div *ngIf="getNIncompleteStageJobs(stage) !== '0'">
        <div matBadge="{{getNIncompleteStageJobs(stage)}}" matBadgeColor="warn" matBadgePosition="after" matBadgeOverlap="false">
          <span class="mr-1"><span class="pr-2 pb-2"> <img src="https://img.icons8.com/ios-glyphs/24/000000/break.png" /></span><span style="padding-top: 2px">{{'stage_details.overdue_jobs' | translate}}</span></span>
        </div>
      </div>
      <div *ngIf="getNIncompleteStageJobs(stage) === '0' && getStageProgress(stage) !== 100 && getStageProgress(stage) !== 0">
        <span class="mr-1"><span class="pr-2 pb-2"> <img src="https://img.icons8.com/material/24/000000/checked-2--v2.png" /></span><span style="padding-top: 2px">{{'stage_details.on_schedule' | translate}}</span></span>
      </div>
    </div>
  </div>
  <div class="clickable toggleIcon" style="display: none;" [attr.id]="'up' + stage._id">
    <fa-icon size="lg" [icon]="upIcon"></fa-icon>
  </div>
  <div class="clickable toggleIcon" [attr.id]="'down' + stage._id">
    <fa-icon size="lg" [icon]="downIcon"></fa-icon>
  </div>
</div>
<div class="progressBar mb-3">
  <!-- <div *ngIf="getStageProgress(stage) !== 100" class="progressTitle">
  PROGRESS
</div>
<div *ngIf="getStageProgress(stage) === 100" class="progressTitle complete">
  COMPLETE
</div> -->
  <mat-progress-bar class="progress-bar" mode="determinate" [color]="getStageProgress(stage) === 100 ? 'primary' : 'warn' " value="{{ getStageProgress(stage) }}">
  </mat-progress-bar>
</div>
<div class="container-fluid collapse" id="{{ stage._id }}" style="padding: 0">
  <!-- <app-job-table [jobs]="filterJobsForCustomer(stage.jobs)" [branchStartDate]="branch.startDate" [stageHoursFromEpoch]="stage.hoursFromEpoch" [joblist]="branch.joblist" ></app-job-table> -->
  <div class="row" *ngFor="let job of filterJobsForCustomer(stage.jobs)">
    <app-job-thumb class="col-12" [job]="job" [deadline]="getJobDeadline(job, stage)" [jobItem]="getJobItem(job)" [branchId]="branch._id"></app-job-thumb>
  </div>
</div>