import { Media } from 'src/modules/videos/models/media';

export class Job {
  _id?: string;
  role: Role;
  hoursToComplete: number;
  description: string;
  details: string;
  videoIds: string[] = [];
  articleIds: string[] = [];
  modulesToWatch: string[] = [];
  innerHTML: string;
  status: boolean;
  stage: string;
  externalLink: { text: string, url: string };
  translations: {language: string, translation: {details: string, description: string}}[] = [];
  articles = [];
  filterOn: string[] = [];
  reviews: [{_id: string, admin: string, date_created: Date, rating: number, feedback: string}]



  medias: Media[] = [];
}

export enum Role {
  CUSTOMER, OBMANAGER, WEB_DEV, APP_DEV
}
