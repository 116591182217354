import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Video } from 'src/modules/videos/models/video';
import { VideosService } from 'src/modules/videos/services/videos.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-video-form',
  templateUrl: './add-video-form.component.html',
  styleUrls: ['./add-video-form.component.sass']
})
export class AddVideoFormComponent implements OnInit {

  addVideoFG: FormGroup;
  addVideo: boolean = false;

  loading = false;

  categories = [
    {category: 'All'},
    {category: 'Payments'},
    {category: 'Memberships'},
    {category: 'Web Integration'},
    {category: 'The Dashboard'},
    {category: 'Classes'},
    {category: 'Courses'},
    {category: 'Managing your Staff'},
    {category: 'Facilities'}



  ];

  // @Output()
  // outputNewVideo: EventEmitter<Video> = new EventEmitter<Video>();
 


  constructor(private videoService: VideosService, private toastr: ToastrService) { }


  returnedVideoFromServer: any[] = [];
  updatedReturnedVideoFromServer: Video[] = [];

  

  ngOnInit() {
    this.loading = true;
    this.addVideoFG = new FormGroup({
      name: new FormControl('', Validators.required),
      id: new FormControl( '', Validators.required),
      category: new FormControl('', Validators.required)
    });
    this.videoService.getAllVideos().subscribe(data => {
      this.returnedVideoFromServer = data;
      this.loading = false;
      if ( !this.returnedVideoFromServer.length) {
        this.addVideo = true;
  
      }

    });

   
  }

  toggleTable() {
    this.addVideo = !this.addVideo;
  }

  displayForm($event){
    this.addVideo = $event;

  }

  onAddVideo() {
    const newVideo = new Video();
    newVideo.name = this.addVideoFG.controls.name.value;
    newVideo.category = this.addVideoFG.controls.category.value;
    const url = this.addVideoFG.controls.id.value;
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const videoId = url.match(regExp);

    newVideo.id = videoId[1];
    // this.outputNewVideo.emit(newVideo);
    this.videoService.addNewVideo(newVideo).subscribe((data: {message?:string, video?:Video} )  => {
      if (data.message == 'Video Already Exists') {
        this.toastr.error('Video already exists');
       
      } else  {
        this.addVideoFG.reset();
        this.returnedVideoFromServer.push(data.video);
        // this.updatedReturnedVideoFromServer = [...this.returnedVideoFromServer];
        this.updatedReturnedVideoFromServer = [...this.returnedVideoFromServer];

        this.addVideo = false;
        this.toastr.success('Video Added')
      }


    });
    
      

  } 

}
