import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { WebIntegration } from 'src/models/web-integration';
import { WebIntService } from 'src/modules/manager-portal/services/web-int.service';

@Component({
  selector: 'app-webint-request-form',
  templateUrl: './webint-request-form.component.html',
  styleUrls: ['./webint-request-form.component.sass']
})
export class WebintRequestFormComponent implements OnInit {

  @Input()
  webInt: WebIntegration;

  @Output()
  requestSubmitted: EventEmitter<boolean> = new EventEmitter();

  @Input()
  hasSMT: boolean = false;


  loading = true;
  pendingServerResponse = false;
  webintRequestFG: FormGroup;

  CMSAccessOptions = [
    'I added Glofox as a website contributor from the backend of my website',
    'I sent login credentials for my website via email'
  ];

  cmsOptions = ['WordPress', 'Weebly', 'Squarespace', 'WIX', 'Webflow', 'GoDaddy', 'Other/Not Sure'];
  publicationOptions = [
    'As soon as possible',
    'I will make the integration public myself when I am ready',
    'At a later date',
  ];

  jiraPublicationOptions: string[] = [
    'ASAP',
    'Customer To Action',
    'At Date'
  ]

  publicationOptionMsgs = [
    '- typically within 3 business days',
    '',
    ' - the pages will be made public within 24 hours of the date requested'
  ]

  minPublicationDate = this.twoDaysFromNow();
  noWeekendFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(private fb: FormBuilder, private webIntService: WebIntService) { }

  ngOnInit() {
    let _webInt = new WebIntegration();
    this.webInt =  Object.assign(_webInt, this.webInt);
    this.webInt.features = [
      {requested: true, feature: 'Class Schedule', pageTitle: 'Schedule'},
      {requested: true, feature: 'Memberships', pageTitle: 'Pricing'},
      {requested: true, feature: 'Courses', pageTitle: 'Courses'},
      {requested: true, feature: 'Trainers', pageTitle: 'Personal Trainers'},
      {requested: true, feature: 'Facilities', pageTitle: 'Rent our Space'}
    ]
    this.webintRequestFG = new FormGroup({
      methodOfCmsAccess: new FormControl('', Validators.required),
      websiteUrl: new FormControl(''),
      websiteCms: new FormControl('', Validators.required),
      features: this.fb.array([]),
      publicationDate: new FormControl(),
      pagePublicationMethod: new FormControl(this.publicationOptions[0], Validators.required),
      leadCaptureURL: new FormControl(),
      note: new FormControl('')
    });

    this.webInt.features.forEach(feature => {
      console.log(feature.feature);
      
      (this.webintRequestFG.controls.features as FormArray).push(new FormGroup({
         requested: new FormControl(feature.requested),
         pageTitle: new FormControl(feature.pageTitle, [Validators.required, Validators.minLength(3)])
       }))
     })
     this.loading = false;
     console.log(this.webintRequestFG.controls['features']);
  }

  resetPublicationDate(event) {
    if (event.checked) {
      this.webintRequestFG.controls.publicationDate.setValue(undefined);
    }
  }

  submitWebIntRequest() {
    this.pendingServerResponse = true;
    this.webInt.methodOfCmsAccess = this.webintRequestFG.controls.methodOfCmsAccess.value;
    this.webInt.websiteUrl = this.webintRequestFG.controls.websiteUrl.value;
    this.webInt.cms = this.webintRequestFG.controls.websiteCms.value;
    this.webInt.pagePublicationMethod = this.jiraPublicationOptions[this.publicationOptions.indexOf(this.webintRequestFG.controls.pagePublicationMethod.value)];
    this.webInt.publicationDate = this.webInt.pagePublicationMethod === 'At Date' ? this.webintRequestFG.controls.publicationDate.value : undefined;
    this.webInt.note = this.webintRequestFG.controls.note.value;
    this.webInt.membershipPlanForLeadCapture = this.webintRequestFG.controls.leadCaptureURL.value;
    for (let index = 0; index < this.webInt.features.length; index++) {
      const feature = this.webInt.features[index];
      const featureFG = (this.webintRequestFG.controls.features as FormArray).controls[index] as FormGroup;
      feature.requested = featureFG.controls.requested.value;
      feature.pageTitle = featureFG.controls.pageTitle.value;
    };

    console.log('submitted web request', this.webInt);
    
    this.webIntService.makeWebIntRequest(this.webInt).subscribe(data => {
      this.requestSubmitted.emit(data);
    })
  }

  private twoDaysFromNow(): Date {
    let today = new Date(Date.now());
    return new Date(today.setDate(today.getDate() + 2));
  }

}
