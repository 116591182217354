import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progress'
})
export class ProgressPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 0) { return '-'; } else if (value > 0 && value < 100) {
      return `${Math.round(value)} %`;
    } else {
      return '✔️';
    }
  }

}
