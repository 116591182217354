import { Component, OnInit, Input } from '@angular/core';
import { Test } from 'src/models/test';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Stage } from 'src/models/stage';
import { TestService } from 'src/app/services/test.service';
import { TrackBuilderService } from '../../services/track-builder.service';

@Component({
  selector: 'app-new-test',
  templateUrl: './new-test.component.html',
  styleUrls: ['./new-test.component.sass']
})
export class NewTestComponent implements OnInit {

  @Input()
  stage: Stage;

  test: Test;

  testFG: FormGroup;

  constructor(private testService: TestService, private trackBuilderService: TrackBuilderService) { }

  ngOnInit() {
    this.testFG = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)])
    })
  }

  saveTest() {
    let test = new Test();
    test.name = this.testFG.controls.name.value;
    this.testService.newTest(this.stage._id, test.name).subscribe(data => {
      this.trackBuilderService.addNewTest(data);
    });
  }

}
