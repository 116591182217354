import { Component, OnInit, Input } from '@angular/core';
import { Job } from 'src/models/job';
import { log } from 'util';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-job-table',
  templateUrl: './job-table.component.html',
  styleUrls: ['./job-table.component.sass']
})
export class JobTableComponent implements OnInit {

  @Input()
  jobs: Job[] = [];

  @Input()
  branchStartDate: Date;

  @Input()
  stageHoursFromEpoch: number;

  @Input()
  joblist: any[] = [];

  @Input()
  options: string[] = [];

  constructor() { }

  ngOnInit() {
    if (typeof this.branchStartDate === 'string') {
      this.branchStartDate = new Date(this.branchStartDate);
    }
  }

  filterJobs(jobs: Job[]): Job[] {
    const res: Job[] = [];
    jobs.forEach(job => {
      if (job.filterOn.length === 0) {
        res.push(job);
      } else {
        const intersection = job.filterOn.filter(element => this.options.includes(element));
        if (intersection.length) {
          res.push(job);
        }
      }
    })
    return res;
  }


  public getJobStatus(job: Job): boolean {
    return this.joblist.find(j => j.job === job._id).status;
  }

  getJobItem(job: Job): any {
    return this.joblist.find(j => j.job === job._id);
  }

  isOverdue(deadline: Date): boolean {
    return deadline.getTime() < new Date().getTime();
  }
  getJobDeadline(job: Job) {
    if (this.branchStartDate) {
      return new Date(this.branchStartDate.getTime() + (this.stageHoursFromEpoch + job.hoursToComplete) * 3600000);
    }
  }

}
