import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageParentComponent } from './components/landing-page/landing-page-parent/landing-page-parent.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { WelcomeComponent } from './components/landing-page/welcome/welcome.component';
import { StageTestComponent } from 'src/modules/client-portal/components/stage-test/stage-test.component';
import { LeadCaptureGeneratorComponent } from 'src/modules/shared/components/lead-capture-generator/lead-capture-generator.component';
import { CodeSnippetsComponent } from 'src/modules/shared/components/code-snippets/code-snippets.component';
import { CmsGuidesComponent } from 'src/modules/shared/components/cms-guides/cms-guides.component';
import { ExternalSurveyPortalComponent } from 'src/modules/shared/components/external-onboarding-survey/external-survey-portal/external-survey-portal.component';

const routes: Routes = [
  { path: '', component: LandingPageParentComponent },
  { path: 'reset-password', component: PasswordResetComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'testing-demo', component: StageTestComponent },
  { path: 'lc-generator', component: LeadCaptureGeneratorComponent },
  { path: 'code-snippets', component: CodeSnippetsComponent },
  { path: 'cms_guides', component: CmsGuidesComponent },
  { path: 'onboarding-survey/:external_survey_token', component: ExternalSurveyPortalComponent },
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule { }
