import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    if (value === 1) {
      return '1 hour';
    } else if (value > 1 && value < 48) {
      return `${value} hours`;
    } else {
      const ndays = Math.floor(value / 24);
      return `${ndays} days`;
    }
  }

}
