import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FeatureSnippetData } from '../../models/feature-snippet.model';

@Component({
  selector: 'app-feature-snippets',
  templateUrl: './feature-snippets.component.html',
  styleUrls: ['./feature-snippets.component.sass']
})
export class FeatureSnippetsComponent implements OnInit {
  @Input() newBranchId: string;


  featureSnippets = [
    { title: 'Class Schedule', id: '1', feature: 'classes-week-view?header=classes' },
    { title: 'Memberships', id: '2', feature: 'memberships?header=memberships' },
    { title: 'Courses', id: '3', feature: 'courses?header=courses' },
    { title: 'Trainer Booking', id: '4', feature: 'trainers?header=trainers' },
    { title: 'Facility Rental', id: '5', feature: 'facilities?header=facilities' },
    { title: 'All features in one', id: '6', feature: 'classes-week-view' }
  ];


  constructor(private toastr: ToastrService) { }

  showSuccess() {
    this.toastr.success('Copied to Clipboard!');
  }
  ngOnInit() { }

  getPreviewLink(id: string, feature: string): string {
    return `https://app.glofox.com/portal/#/branch/${this.newBranchId}/${feature}`;
  }

  generateSnippet(id: string, feature: string): string {

    const snippet = `
    <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js"></script>
    <iframe id="glofox_${id}" src="https://app.glofox.com/portal/#/branch/${this.newBranchId.trim()}/${feature}"
      width="100%" height="0" scrolling="no" frameborder="0"></iframe>
    <div style="display:flex; justify-content:center; text-decoration:none;font-family: Arial, Helvetica, sans-serif;">
      powered by<a style="text-decoration: none; font-family: Arial, Helvetica, sans-serif; "
        href="https://www.glofox.com"><b>&nbsp;Glofox</b></a></div>
      <script>
        iFrameResize({
          log: false,
          checkOrigin: false,
          tolerance: 10,
          sizeHeight: true,
          heightCalculationMethod: 'lowestElement',
          minHeight: 300,
          scrolling: 'auto'
        },
          '#glofox_${id}')
      </script>
          `;

    return snippet.toString();
  }

  copyToClipBoard(value: string) {
    const leadCodeBlock = document.createElement('textarea');
    leadCodeBlock.style.position = 'fixed';
    leadCodeBlock.style.left = '0';
    leadCodeBlock.style.top = '0';
    leadCodeBlock.style.opacity = '0';
    leadCodeBlock.value = value;
    document.body.appendChild(leadCodeBlock);
    leadCodeBlock.focus();
    leadCodeBlock.select();
    document.execCommand('copy');
    document.body.removeChild(leadCodeBlock);
    this.showSuccess();

  }


}
