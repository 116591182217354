<div>Create Demo Branch for the <strong>{{track.title}}</strong> track</div>

<div class="mt-3">
    <form [formGroup]="branchFG" (ngSubmit)="launchDemo()">
        <mat-form-field style="width: 100%">
            <mat-label>Branch Name</mat-label>
            <input type="text" formControlName="name" matInput placeholder="Mega Gym">
        </mat-form-field>
        <div style="width: 100%">
          <div  class="d-flex">
            <mat-form-field>
                <mat-label>Admin First Name</mat-label>
                <input type="text" formControlName="adminFirstName" matInput placeholder="Mega Gym">
            </mat-form-field>
            <mat-form-field class="ml-2">
                <mat-label>Admin Last Name</mat-label>
                <input type="text" formControlName="adminLastName" matInput placeholder="Mega Gym">
            </mat-form-field>
          </div>
            <mat-form-field style="width: 100%">
              <mat-label>Admin Email</mat-label>
              <input type="text" formControlName="adminEmail" matInput placeholder="demo@example.com">
          </mat-form-field>
        </div>

        <div class="mb-4" *ngIf="optionsFG">
            <h6>Apply Filter</h6>
            <div class="d-flex flex-wrap">
              <div *ngFor="let option of options; let i = index" class="d-flex mr-3">
                <div>
                  <mat-slide-toggle (change)="updateOptions($event, option)">
                  </mat-slide-toggle>
                </div>
                <div class="mr-4 ml-2">{{options[i].name}}</div>
              </div>
            </div>
          </div>

        <button mat-raised-button type="submit">Create Demo Branch</button>
    </form>
</div>
