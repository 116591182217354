import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Admin } from 'src/models/admin';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  SERVER_URL = environment.SERVER_URL;

  constructor(private http: HttpClient) { }


  onAddSurvey(survey: any, adminId: string): Observable<any> {
    return this.http.post<{ message: string, admin: Admin }>(`${this.SERVER_URL}/api/survey/${adminId}/newSurvey`, survey);
  }

  getAdminSurveyResponses(): Observable<any> {
    return this.http.get<{ message: string, admin: Admin }>(`${this.SERVER_URL}/api/all/admins/survey/results`);

  }

  getManagerSurveyResponses(managerId: string): Observable<any> {
    console.log(managerId)
    return this.http.get<{ message: string, admin: Admin }>(`${this.SERVER_URL}/api/all/${managerId}/survey/results`);
  }


}
