import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuardService } from 'src/app/services/role-guard.service';
import { WebTeamDashboardComponent } from './components/web-team-dashboard/web-team-dashboard.component';
import { WebTeamPortalComponent } from './components/web-team-portal/web-team-portal.component';

const  routes: Routes  = [{
  path: 'web-team-dashboard',
  component: WebTeamPortalComponent,
  canActivate: [RoleGuardService],
  data: {
    'expectedRole': 'webTeam'
  },
  children: [
    { path: '', component: WebTeamDashboardComponent },
    // { path: 'knowledge-base', component: FaqHomeComponent},
    // { path: 'knowledge-base/articles/:id', component: ArticleDetailsComponent },
    // { path: 'videos', component: VideosHomeComponent },
    // { path: 'web-integration', component: WebintHomeComponent  }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebTeamPortalRoutingModule { }
