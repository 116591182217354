import { Component, OnInit, Input } from '@angular/core';
import * as Editor from '../../../../assets/js/ckeditor';

@Component({
  selector: 'app-job-translator',
  templateUrl: './job-translator.component.html',
  styleUrls: ['./job-translator.component.sass']
})
export class JobTranslatorComponent implements OnInit {
  _translation: {language: string, translation: {details: string, description: string}};

  @Input()
  public set translation(v : {language: string, translation: {details: string, description: string}}) {
    this._translation = v;
    console.log('setter');
  }
  
  
  public get translation() :  {language: string, translation: {details: string, description: string}} {
    return this._translation;
  }
  

  editor = Editor;
  editorConfig = {
    placeholder: 'Add some details to the job here.',
  };

  constructor() { }

  ngOnInit() {
  }

}
