import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnDestroy } from '@angular/core';
import { Stage } from 'src/models/stage';
import { Job } from 'src/models/job';
import { MatTable } from '@angular/material/table';
import { Subscription, Subject } from 'rxjs';
import { TrackBuilderService } from '../../services/track-builder.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.sass']
})
export class JobListComponent implements OnInit, OnDestroy {

  private _stage: Stage;

  private unsubscribe$ = new Subject<void>();

  @Input()
  public set stage(v : Stage) {
    this._stage = v;
    this.jobTableDS = this.stage.jobs.sort(this.sortByDeadlin);
    this.selectJob(this._stage.jobs[0]);
  }

  
  public get stage() : Stage {
    return this._stage;
  }

  @Output()
  selectedJobChanged$: EventEmitter<any> = new EventEmitter();
  
  jobTableDS = [];
  jobTableDisplayedColumns: string[] = ['title'];

  selectedJob: Job;

  @ViewChild('table') table: MatTable<Job>;

  constructor(private trackBuilderService: TrackBuilderService) { 
    this.trackBuilderService.joblistChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {     
      let existing_jobs = this._stage.jobs.map(j => j._id); 
      this._stage.jobs = data;
      if (!this.selectedJob._id) {
        this._stage.jobs.forEach(j => {
          if (existing_jobs.includes(j._id)){
            this.selectJob(j);
          }
        })
      }
      console.log(data);
      
      this.jobTableDS = this._stage.jobs.sort(this.sortByDeadlin);
      this.table.renderRows();
      // this.selectJob(this._stage.jobs[0]);
    })
   }

  ngOnInit() {
  }

  selectJob(job: Job): void {
    this.selectedJob = job;
    this.selectedJobChanged$.emit(job);
  }

  sortByDeadlin(a: Job, b: Job) {
    // Use toUpperCase() to ignore character casing
    const deadlineA = a.hoursToComplete;
    const deadlineB = b.hoursToComplete;

    let comparison = 0;
    if (deadlineA > deadlineB) {
      comparison = 1;
    } else if (deadlineA < deadlineB) {
      comparison = -1;
    }
    return comparison;
  }

  addJob() {
    let job = new Job();
    job.description = 'New Job';
    job.articleIds = [];
    job.videoIds = [];
    job.externalLink = { url: '', text: '' };
    job.filterOn = [];
    job.role = 0;
    job.hoursToComplete = 24;
    this.stage.jobs.push(job);
    this.table.renderRows();
    this.selectJob(job);
    
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
