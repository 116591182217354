import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { id } from '@swimlane/ngx-charts';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from 'src/app/services/survey.service';
import { Admin } from 'src/models/admin';
import { AdminService } from 'src/modules/client-portal/services/admin.service';

@Component({
  selector: 'app-external-onboarding-experience-survey',
  templateUrl: './external-onboarding-experience-survey.component.html',
  styleUrls: ['./external-onboarding-experience-survey.component.sass']
})
export class ExternalOnboardingExperienceSurveyComponent implements OnInit {
  score = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  scoreBoolean = ['Yes', 'No'];

  improvements = ['More one to one meetings with my onboarding manager', 'Better resources and direction on onboarding portal', 'Would have liked to know more about Glofox before signing up', 'Would have liked to see more features in my Glofox dashboard', 'Better support from the web integrations team']

  how_submitted = '';

  onboardingExperienceSurvey: FormGroup;
  @Output()
  submittedSurveyDetail = new EventEmitter<any>();

  @Input()
  admin: Admin;

  surveyDetail: {
    has_submitted: boolean,
    obmanager: string,
    date_submitted: Date,
    how_submitted?: string,
    branchId: string,
    response: {
      netPromoterScore: any,
      rateGlofoxOnboardingProcess: any,
      rateOBM: any,
      expectationsScore: any,
      improvements: [],
      otherFeedBack: any,
    };
  };
  constructor(private formBuilder: FormBuilder, private adminService: AdminService, private surveyService: SurveyService, private toastrService: ToastrService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.onboardingExperienceSurvey = this.formBuilder.group({
      netPromoterScore: ['', [Validators.required]],
      rateGlofoxOnboardingProcess: ['', [Validators.required]],
      rateOBM: ['', [Validators.required]],
      expectationsScore: ['', [Validators.required]],
      improvements: this.formBuilder.array([]),
      otherFeedBack: ['', [Validators.required]],
    });

  }


  onChange(event) {
    const improvements = <FormArray>this.onboardingExperienceSurvey.get('improvements') as FormArray;
    if (event.checked) {
      improvements.push(new FormControl(event.source.value));
    } else {
      const i = improvements.controls.findIndex(x => x.value === event.source.value);
      improvements.removeAt(i);
    }
  }

  saveForm() {
    if (this.onboardingExperienceSurvey.valid) {
      const netPromoterScore = this.onboardingExperienceSurvey.value.netPromoterScore;
      const rateGlofoxOnboardingProcess = this.onboardingExperienceSurvey.value.rateGlofoxOnboardingProcess;
      const rateOBM = this.onboardingExperienceSurvey.value.rateOBM;
      const expectationsScore = this.onboardingExperienceSurvey.value.expectationsScore;
      const improvements = this.onboardingExperienceSurvey.value.improvements;
      const otherFeedBack = this.onboardingExperienceSurvey.value.otherFeedBack;
      this.surveyDetail = {
        has_submitted: false,
        obmanager: '',
        branchId: '',
        date_submitted: new Date(),
        how_submitted: this.how_submitted,
        response: {
          netPromoterScore,
          rateGlofoxOnboardingProcess,
          rateOBM,
          expectationsScore,
          improvements,
          otherFeedBack
        }
      }
      console.log('FORM', this.surveyDetail);
      this.submittedSurveyDetail.emit(this.surveyDetail);
      window.scrollTo({
        top: 175,
        behavior: 'smooth'
      });
    }
    else if (this.onboardingExperienceSurvey.invalid) {
      window.scrollTo({
        top: 175,
        behavior: 'smooth'
      });
      return;
    }


  }

}
