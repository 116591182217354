import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Url } from 'url';
import { Branch } from 'src/models/branch';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { GlofoxService } from '../../services/glofox.service';
import { Membership } from '../../models/membership';
import { ActivatedRoute } from '@angular/router';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';

@Component({
  selector: 'app-webint-home',
  templateUrl: './webint-home.component.html',
  styleUrls: ['./webint-home.component.sass']
})
export class WebintHomeComponent implements OnInit, AfterViewInit {


  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private adminService: AdminService,
    private branchService: BranchService ) {
  }
  branch: Branch;
  memberships: Membership[] = [];
  loading = false;
  showWebInt = false;
  selectedPage: Component;
  switchOn: string;
  stickyFlag: boolean = false;
  webIntDataFlag: boolean = false;

  menuPosition: any;
  activePage: string;



  @ViewChild('stickyNav', { static: true } ) menuElement: ElementRef;


  switchPage(data: string) {
    this.switchOn = data;
    this.activePage = data;
    window.scroll(0, 0);

  }

  ngOnInit() {
    this.loading = true;
    this.activePage = 'code-snippet'
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        const admin = adminData;
        this.branchService.getBranch(admin.branch._id).subscribe(bdata => {
          this.branch = bdata;
          this.loading = false;
        })
        this.activatedRoute.queryParams.subscribe((data) => {

          if (data.page) {
            this.switchPage(data.page);
          }
        });
      });
    }

  }
  ngAfterViewInit() {
    this.menuPosition = this.menuElement.nativeElement.offsetTop;
    console.log(this.menuPosition);
    
      window.scrollTo(0, 0);
      this.menuPosition = this.menuElement.nativeElement.offsetTop;

}

hasSMT(): boolean {
  return this.branch.onboardingOptions.length && this.branch.onboardingOptions.includes('5e305721105de93a5ccf8bba');
}

@HostListener('window:scroll', [])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        if (windowScroll >= this.menuPosition) {
            this.stickyFlag = true;
        } else {
            this.stickyFlag = false;
        }
    }

}
