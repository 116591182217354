import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VideoList } from './video-list';
import { Video } from '../models/video';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideosService {



  SERVER_URL = environment.SERVER_URL;

  videos: Video[] = VideoList.videos;
  // sections: Section[] = VideoList.sections;
  // allVideos: Video[] = VideoList.allVideos;
  portugueseVideos: any[] = VideoList.portugueseVideos;
  allVideos: Video[] = [];

  constructor(private http: HttpClient) { }


  addNewVideo(video): Observable<any> {
    console.log('hits: ', video);
    return this.http.post(`${this.SERVER_URL}/api/videos/new`, video);

  }

  hasWatchedVideo(selectedVideo: string, adminId: string): Observable<any> {
    console.log('hits service', selectedVideo, adminId)
    return this.http.post(`${this.SERVER_URL}/api/video/${selectedVideo}/clientHasWatched`, {adminId});
  }
 
  deleteVideo(video): Observable<any> {
    console.log('hits delete: ', video);
    return this.http.delete(`${this.SERVER_URL}/api/video/${video}/delete`);
  }

  
  getVideos(adminId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/videos/admin/${adminId}` );
  }

  getAllVideos(): any {
    return this.http.get(`${this.SERVER_URL}/api/videos/` );
  }

  getPortugueseVideos(): any {
    return this.portugueseVideos;
  }

  getWatchedVideos(adminId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/videos/${adminId}`);
  }

  rateVideo(selectedVideoId:string, adminId:string, rating: string): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/video/${selectedVideoId}/rateVideo`, {adminId, rating})
  }

  updateVideoOrder(video: string[]): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/videos/updateVideoOrder`, video);
  }

  
  

}
