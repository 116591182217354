import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/models/branch';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Admin } from 'src/models/admin';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { log } from 'util';

@Component({
  selector: 'app-standalone-builder-parent',
  templateUrl: './standalone-builder-parent.component.html',
  styleUrls: ['./standalone-builder-parent.component.sass']
})
export class StandaloneBuilderParentComponent implements OnInit {

  branch: Branch;
  standaloneApp: StandAloneApp;
  admin: Admin;



  userFlow;

  constructor(private staService: StandaloneAppService, private auth: AuthenticationService, private adminService: AdminService, private branchService: BranchService) { }

  ngOnInit() {

    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        this.admin = adminData;

        this.adminService.getBranchForAdmin(this.admin).subscribe(branchData => {

          this.branchService.updateLastOnlineAt(branchData._id).subscribe(data => {

          });
          this.branch = Object.assign(new Branch(), branchData);
          console.log(branchData.standAloneApp);
          if (branchData.standAloneApp) {
            this.staService.getStandAloneApp(`${branchData.standAloneApp}`).subscribe(data => {
              this.standaloneApp = data;
   

          if (!this.standaloneApp.appConfigCreated) {
            this.userFlow = 'config-form';
          } else if (!this.standaloneApp.iconHTMLCreated) {
            this.userFlow = 'app-icon'
          } else if (!this.standaloneApp.splashScreenHTMLCreated) {
            this.userFlow = 'splash-screen'
          } else {
            this.userFlow = 'review'
          }
            });
          }
        })
      })
    }

  }

  showSplashScreenBuilder(event) {
    this.userFlow = 'splash-screen';
    this.standaloneApp = event;
  }

  showAppIconBuilder(event) {
    this.userFlow = 'app-icon';
    this.standaloneApp = event;

  }

  showAndroidBannerBuilder(event) {
    this.userFlow = 'android-banner';
    this.standaloneApp = event;
  }


  showReview(event) {
    this.userFlow = 'review';
    this.standaloneApp = event;
  }

  changeStep(event) {
    this.userFlow = event;
  }

}
