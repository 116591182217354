import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ObmDashboardComponent } from "./components/obm-dashboard/obm-dashboard.component";
import { HttpClientModule } from "@angular/common/http";
import { MatStepperModule } from "@angular/material/stepper";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { NgCircleProgressModule } from "ng-circle-progress";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RouterModule } from "@angular/router";
import { BranchDetailsComponent } from "./components/branch-details/branch-details.component";
import { MatChipsModule } from "@angular/material/chips";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ManagerService } from "./services/manager.service";
import { CreateAdminComponent } from "./components/new-branch/create-admin/create-admin.component";
import { CreateBranchComponent } from "./components/new-branch/create-branch/create-branch.component";
import { NewBranchParentComponent } from "./components/new-branch/new-branch-parent/new-branch-parent.component";
import { SharedModule } from "../shared/shared.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { NewBranchDialogComponent } from "./components/dialogs/new-branch-dialog/new-branch-dialog.component";
import { OtherManagerComponent } from "./components/other-manager/other-manager.component";
import { ManagerPortalComponent } from "./components/manager-portal/manager-portal.component";
import { ManagerPortalRoutingModule } from "./manager-portal-routing.module";
import { ManagerNavmenuComponent } from "./components/manager-navmenu/manager-navmenu.component";
import { MyBranchesComponent } from "./components/my-branches/my-branches.component";
import { ManagerProfileComponent } from "./components/manager-profile/manager-profile.component";
import { ConfirmBranchDeletionComponent, DialogConfirmDeletionDialog } from "./components/dialogs/confirm-branch-deletion/confirm-branch-deletion.component";
import { ManagerNoteComponent } from "./components/manager-note/manager-note.component";
import { BranchListComponent } from "../shared/components/branch-list/branch-list.component";
import { AdminDialogComponent } from "./components/dialogs/admin-dialog/admin-dialog.component";
import { UpdateBranchDialogComponent } from "./components/dialogs/update-branch-dialog/update-branch-dialog.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatTreeModule } from "@angular/material/tree";
import { TrackBuilderModule } from "../track-builder/track-builder.module";
import { StandaloneAppUpsellFormComponent } from "./components/stand-alone-app-upsell/standalone-app-form/standalone-app-upsell-form.component";
import { NewStandaloneAppUpsellDialogComponent } from "./components/dialogs/new-standalone-app-upsell-dialog/new-standalone-app-upsell-dialog.component";
import { AddVideoFormComponent } from "./components/videos/add-video-form/add-video-form.component";
import { VideoTableComponent } from "./components/videos/video-table/video-table.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ArticleSelectorDialogComponent } from "./components/dialogs/article-selector-dialog/article-selector-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContentFeedbackComponent } from "./components/content-feedback/content-feedback.component";
import { FeedbackService } from "./services/feedback.service";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatListModule } from "@angular/material/list";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { AdminsSurveyResponseDataComponent } from "./components/survey-results/admins-survey-response-data/admins-survey-response-data.component";
import { SurveyPortalComponent } from "./components/survey-results/survey-portal/survey-portal.component";
import { ReassignOmDialogComponent } from "./components/dialogs/reassign-om-dialog/reassign-om-dialog.component";
import { NewManagerDialogComponent } from "./components/dialogs/new-manager-dialog/new-manager-dialog.component";

@NgModule({
  declarations: [
    ObmDashboardComponent,
    CreateBranchComponent,
    BranchDetailsComponent,
    CreateAdminComponent,
    NewBranchParentComponent,
    NewBranchDialogComponent,
    NewManagerDialogComponent,
    OtherManagerComponent,
    ManagerPortalComponent,
    ManagerNavmenuComponent,
    MyBranchesComponent,
    ManagerProfileComponent,
    ConfirmBranchDeletionComponent,
    DialogConfirmDeletionDialog,
    ManagerNoteComponent,
    AdminDialogComponent,
    UpdateBranchDialogComponent,
    StandaloneAppUpsellFormComponent,
    NewStandaloneAppUpsellDialogComponent,
    AddVideoFormComponent,
    VideoTableComponent,
    ArticleSelectorDialogComponent,
    ContentFeedbackComponent,
    AdminsSurveyResponseDataComponent,
    SurveyPortalComponent,
    ReassignOmDialogComponent,
  ],
  imports: [
    CommonModule,
    ManagerPortalRoutingModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    DragDropModule,
    MatMenuModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatDividerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    FlexLayoutModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatBadgeModule,
    MatIconModule,
    MatTableModule,
    MatSelectModule,
    CKEditorModule,
    MatTreeModule,
    TrackBuilderModule,
    ClipboardModule,
    MatTooltipModule,
    MatListModule,
    MatButtonToggleModule,
    NgxChartsModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 8,
      innerStrokeWidth: 4,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
  ],
  providers: [ManagerService, FeedbackService],
  entryComponents: [
    NewBranchDialogComponent,
    NewManagerDialogComponent,
    ArticleSelectorDialogComponent,
    NewStandaloneAppUpsellDialogComponent,
    DialogConfirmDeletionDialog,
    AdminDialogComponent,
    UpdateBranchDialogComponent
  ],
})
export class ManagerPortalModule { }
