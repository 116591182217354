<div *ngIf="job && jobItem" class="jobThumb">
  <div class="row d-flex justify-content-between jobHeader">
    <h6 class="col-9 col-lg-10">{{job.description}}</h6>
    <div class="col-3 col-lg-2" style="text-align: center" *ngIf="jobItem &&!jobItem.status">
      <div [class.overdue]="isOverdue(deadline)">{{deadline| date: 'MMM dd'}}</div>
    </div>
  </div>
  <div class="row d-flex justify-content-between align-items-center m-2">
    <div class="col-11 col-lg-11 mt-3">
      <div class="jobDetails ck-content mb-4" *ngIf="job.details" [innerHTML]="job.details">
      </div>
      <div class="d-sm-flex " style="flex-grow: 5; align-items: stretch;">
        <div *ngIf="job.articles && job.articles.length" class="resource">

          <div class="MatIcon d-flex">
            <mat-icon aria-hidden="false" aria-label="Example subject icon" class="align-items-center">subject
            </mat-icon><span class="resource-header pl-1" style="font-size: 1rem;">{{'stage_details.articles' | translate}}</span>
          </div>
          <hr class="separator">
          <div class="resource-list d-flex flex-wrap">
            <a class="resource-item" target="_blank" *ngFor="let article of job.articles"
              (click)="goToKBArticle(article.id)">{{article.title}}</a>
          </div>
        </div>

        <div *ngIf="(job.videoIds && job.videoIds.length)" class="resource">
          <div class="MatIcon d-flex">
            <mat-icon aria-hidden="false" aria-label="Example video_library icon" class="align-items-center">
              video_library</mat-icon><span class="resource-header pl-1" style="font-size: 1rem;">{{'stage_details.video_library' | translate}}</span>
          </div>

          <hr class="separator">
          <div class="resource-list d-flex flex-wrap">
            <div class="resource-item" *ngFor="let vidId of job.videoIds" ><div *ngIf="getVideo(vidId)" (click)="goToMedia(vidId)">{{getVideo(vidId).name}}</div></div>
            <!-- <div class="m-2 d-flex flex-wrap" *ngIf="job.modulesToWatch.length">
              <a class="resource-item" *ngFor="let module of job.modulesToWatch" (click)="goToModule(module)">Open the
                <strong>{{module | wistiaTitle}}</strong> Module</a>
            </div> -->
          </div>
        </div>
      </div>

      <a *ngIf="job.externalLink" href="{{job.externalLink.url}}" target="_blank">{{job.externalLink.text}}</a>
    </div>
    <div class="col-12 col-sm-1 mt-3 d-flex justify-content-center">
      <div class="d-sm-none mr-5 mb-1" style="font-weight: 700">Complete?</div>
      <section class="example-section">
        <mat-checkbox [(ngModel)]="jobItem.status" (change)="updateStatus($event, jobItem)"></mat-checkbox>
      </section>
    </div>
  </div>
</div>
