import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';

import { Admin } from 'src/models/admin';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.sass']
})
export class CreateAdminComponent implements OnInit {

  adminCreateForm: FormGroup;
  emailCheckPending = false;
  faLoading = faCircleNotch;


  @Output() newAdminAccount = new EventEmitter<Admin>();

  constructor(private adminService: AdminService) { }

  ngOnInit() {

    this.adminCreateForm = new FormGroup({
      firstName: new FormControl(null, [Validators.required, Validators.minLength(3) ]),
      lastName: new FormControl(null, [Validators.required, Validators.minLength(3) ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  onAddAdmin() {
    this.emailCheckPending = true;
    const admin = new Admin ();

    admin.firstName =  this.adminCreateForm.controls.firstName.value;
    admin.lastName = this.adminCreateForm.controls.lastName.value;
    admin.email = this.adminCreateForm.controls.email.value;
    admin.canBookCalls = true;

    this.adminService.checkIfEmailIsUnique(admin.email).subscribe(data => {
      if (data.emailIsUnique) {
        this.newAdminAccount.emit(admin);
        this.emailCheckPending = false;
      } else {
        this.emailCheckPending = false;
        this.adminCreateForm.controls.email.setErrors( { unavailable: true } );
      }
    });

    // console.log(admin);
  }
}

