import { Component, OnInit, Input } from '@angular/core';
import { Branch } from 'src/models/branch';
import { Admin } from 'src/models/admin';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
declare var Calendly: any;

@Component({
  selector: 'app-obmanager-detail-card',
  templateUrl: './obmanager-detail-card.component.html',
  styleUrls: ['./obmanager-detail-card.component.sass']
})
export class ObmanagerDetailCardComponent implements OnInit {


  @Input()
    branch: Branch;

  @Input()
    admin: Admin;

  showManagerNote = false;


  constructor(private branchService: BranchService) { }

  ngOnInit() {
    
    // document.getElementById('managerNote').innerHTML = this.branch.managerNote.note;

  }

  openCalendlyPopUp() {
    Calendly.initPopupWidget({url: this.branch.obmanager.calendlyURL});
  }


  onDisplayMessage() {
    this.showManagerNote = !this.showManagerNote;
    document.getElementById('managerNote').innerHTML = this.branch.managerNote.note;
  }

  updateNoteAsSeen() {
    this.branchService.updateNoteAsSeen(this.branch._id).subscribe( data => {
    this.branch.managerNote.seen = data;
    this.showManagerNote = false;
    })
  }
  
}
