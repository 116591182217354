import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandaloneBuilderParentComponent } from './components/standalone-builder-parent/standalone-builder-parent.component';
import { StandaloneBuilderConfigFormComponent } from './components/standalone-builder-config-form/standalone-builder-config-form.component';
import { StandaloneSplashscreenBuilderComponent } from './components/standalone-splashscreen-builder/standalone-splashscreen-builder.component';
import { StandaloneAppService } from '../client-portal/services/standalone-app.service';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StandaloneIconBuilderComponent } from './components/standalone-icon-builder/standalone-icon-builder.component';
import { StandaloneAndroidBannerBuilderComponent } from './components/standalone-android-banner-builder/standalone-android-banner-builder.component';
import { RouterModule } from '@angular/router';
import { StandaloneProgressTrackerComponent } from './components/standalone-progress-tracker/standalone-progress-tracker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StandaloneAppReviewComponent } from './components/standalone-app-review/standalone-app-review.component';
import { GlofoxInviteComponent } from './components/glofox-invite/glofox-invite.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { LoadingComponent } from '../shared/components/loading/loading.component';



@NgModule({
  declarations: [StandaloneBuilderParentComponent, StandaloneBuilderConfigFormComponent, StandaloneSplashscreenBuilderComponent, StandaloneIconBuilderComponent, StandaloneAndroidBannerBuilderComponent, StandaloneProgressTrackerComponent, StandaloneAppReviewComponent, GlofoxInviteComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [StandaloneAppService]
})
export class StandaloneAppBuilderModule { }
