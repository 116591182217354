import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { log } from 'util';
import { faChevronDown, faChevronUp, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { VideosService } from '../../services/videos.service';
import { Video } from '../../models/video';
import { Project } from '../../models/project';
import { Media } from '../../models/media';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css']
})
export class VideoListComponent implements OnInit {

  _videos: Video[] = [];

  @Input()
  public set videos(v: Video[]) {
    this._videos = v;
  };

  public get videos(): Video[] {
    return this._videos;
  }


  @Input()
  _selectedVideo: Video;





  @Output()
  selectedVideo: EventEmitter<Video> = new EventEmitter();

  constructor(private videosService: VideosService) { }

  ngOnInit() {

    
  }

  // getMediaForSection(name: string): Media[] {
  //   return this.project.medias.filter(media => media.section === name);
  // }


  selectVideo(video: Video) {
    this._selectedVideo = video;
    this.selectedVideo.emit(video);
    const player = document.getElementById('player');
    player.scrollIntoView({behavior: 'smooth'});
    // window.scrollTo(0, 100);
  }
}
