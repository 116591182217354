<div class="mb-2 background">

    <div *ngIf=" admin && !admin.onboardingSurvey.has_submitted">
        <div class="title_container">
            <div class="completion_title">
                {{"client_dash.fully_onbaoarded_banner.title" | translate}}
            </div>
        </div>
        <div class="subtitle_container">
            <div class="survey-msg">
                {{"client_dash.fully_onbaoarded_banner.subtitle" | translate}}
            </div>
        </div>
        <div class="btn_container">

        </div>
    </div>
    <div *ngIf=" admin && admin.onboardingSurvey.has_submitted">

        <div *ngIf="admin.onboardingSurvey.response.netPromoterScore < 8">

            <div class="title_container">
                <div class="completion_title">
                    {{"onboarding.survey.thank_you_negative_nps" | translate}}
                </div>
            </div>
            <div class="subtitle_container">
                <div class="survey-msg">
                    {{"onboarding.survey.thank_you_negative_nps_subtitle" | translate}}
                </div>
            </div>

        </div>


        <div *ngIf="admin.onboardingSurvey.response.netPromoterScore >= 8">

            <div class="title_container">
                <div class="completion_title">
                    {{"onboarding.survey.thank_you_positive_nps" | translate}}
                </div>
            </div>
            <div class="subtitle_container">
                <div class="survey-msg">

                    {{"onboarding.survey.thank_you_positive_nps_subtitle" | translate:{name: admin.firstName} }}


                </div>
            </div>
            <div class="btn_container">
                <button mat-raised-button style="color:#330F2E;" (click)="leaveReview()" target="_blank"
                    class="ml-1"><strong>{{"onboarding.survey.open_btn" | translate}}</strong>
                </button>
            </div>


        </div>



    </div>
</div>
<div class="container mb-5">
    <div class="row" *ngIf="admin && !admin.onboardingSurvey.has_submitted">
        <app-external-onboarding-experience-survey [admin]="admin" (submittedSurveyDetail)="onSubmitSurvey($event)">
        </app-external-onboarding-experience-survey>
    </div>
</div>