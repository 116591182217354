<div class="container">

  <div class="row d-flex align-items-center">
    <div class="col-6">

      <div class="title-container">
        <h3 class="mt-5" class="title">Design your app's icon</h3>
        <div class="sub-title">
          This is what a user will tap on to open your app.
        </div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="step">
          <h6 class="step-title">Step 1: Upload your logo.</h6>
          <div class="pb-2">Accepted formats: PNG, JPG, SVG. Max size 1Mb.</div>
          <span style="color:red;" *ngIf="message" class="step-sub-title">{{message}}</span>
          <button mat-stroked-button type="button" (click)="logoFiles.click()">Choose Image</button>
          <input #logoFiles type="file" mat-raised-button accept='image/*' (change)="preview(logoFiles.files)" />
        </div>


        <div class="step">
          <h6 class="step-title">Step 2: Choose your background color.</h6>
          <span class="step-sub-title">Click here to pick your color: </span>
          <input type="color" id="appSplashColor" (change)="bgColorUpdated()" name="appSplashColor" value="#eeeeee">
        </div>
        <div class="step">
          <h6 class="step-title">Step 3: Adjust the size of your logo.</h6>
          <form action="/action_page.php">
            <label class="mt-2 mr-4 step-sub-title" for="vol">Image Size:</label>
            <input type="range" id="appWidth" (change)="updateWidth()" name="width" value="250" min="150" max="380">
          </form>
        </div>
        <div class="step">
          <h6 class="step-title">Step 4: Save your design.</h6>
          <div class="step-sub-title">Next up you will design your app's splash screen.</div>
          <button class="mt-3" mat-raised-button (click)="submit()">Save and Continue</button>
        </div>
      </div>
    </div>
    <div class="col-6 align-items-center d-flex flex-column">
      <div id="logoWrapper" style="border: dotted #ccc 2px;">
        <div id="logoContainerDropZone" [ngStyle]="{'background-color': splashColor}" style="width: 384px; height: 384px;  margin: auto; display: flex; justify-content: center; align-items: center">
          <div id="appLogo" style="width: 384px; height: 384px; display: flex; justify-content: center; align-items: center" draggable="false">
            <img id="logoImg" [src]="imgURL" *ngIf="imgURL" [width]="appLogoWidth" alt="your image">
          </div>
        </div>
      </div>
      <div style="height: 350px; overflow: hidden;" class="mt-3">


        <div class="marvel-device iphone-x">
          <div class="notch">
            <div class="camera"></div>
            <div class="speaker"></div>
          </div>
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="bottom-bar"></div>
          <div class="volume"></div>
          <div class="overflow">
            <div class="shadow shadow--tr"></div>
            <div class="shadow shadow--tl"></div>
            <div class="shadow shadow--br"></div>
            <div class="shadow shadow--bl"></div>
          </div>
          <div class="inner-shadow"></div>
          <div class="screen">
            <!-- Content goes here -->
            <div style="padding: 30px; padding-top: 60px;">
              <div class="container">
                <div class="row appRow">
                  <div class="col-3 appWrapper">
                    <div class="app" id="glofox"></div>
                    <div class="appName">
                      Glofox
                    </div>
                  </div>

                  <div class="col-3 appWrapper">
                    <div class="app" id="glofoxPro"></div>
                    <div class="appName">
                      Glofox Pro
                    </div>
                  </div>

                  <div class="col-3 appWrapper">
                    <div class="app" id="spotify"></div>
                    <div class="appName">
                      Spotify
                    </div>
                  </div>

                  <div class="col-3 appWrapper">
                    <div class="app" id="netflix"></div>
                    <div class="appName">
                      Netflix
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 pl-0 pr-0">
                    <div class="appWrapper">
                      <div id="customApp" [ngStyle]="{'background-color': splashColor}">
                        <img src="" alt="" id="customAppLogo">
                      </div>

                    </div>
                    <div class="appName">
                      {{standaloneApp.name}}
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>