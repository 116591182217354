import { Component, OnInit, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { FeedbackService } from '../../services/feedback.service';
import { Job } from 'src/models/job';
import { Stage } from 'src/models/stage';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-content-feedback',
  templateUrl: './content-feedback.component.html',
  styleUrls: ['./content-feedback.component.sass', './content.css', './image-styles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContentFeedbackComponent implements OnInit {

  reviewedTracks: {_id: string, title: string, nReviews: number, averageScore: number}[];
  selectedTrack: {_id: string, title: string, nReviews: number, averageScore: number, stages?: [{_id: string, title: string, nReviews: number, averageScore: number, jobs: Job[]}] };
  selectedStage: Stage;
  selectedJob: Job;

  admins: {_id?: string; count?: number; averageScore?: number, lastName?: string, firstName?: string, branch?: {name: string, _id: string} }[];
  _expandedAdmin: {_id: string; count?: number; averageScore?: number, lastName?: string, firstName?: string, branch?: {name: string, _id: string}, reviewedJobs?: [] } | null = {_id: 'abc'}
  adminLoaded = false

  @ViewChild(MatTable, {static: false}) adminTable: MatTable<any>; 

  
  public set expandedAdmin(v) {
    this.adminLoaded = false;
    this._expandedAdmin = v;
    this.feedbackService.getAdminFeedback('5dd81dc70a57b73f1c6d5632', this._expandedAdmin._id).subscribe(data => {
      this._expandedAdmin.reviewedJobs = data;
      this.barChartData[0].value = data.filter(job => job.review.rating === 0).length;
      this.barChartData[1].value = data.filter(job => job.review.rating === 1).length;
      this.barChartData[2].value = data.filter(job => job.review.rating === 2).length;
      this.barChartData[3].value = data.filter(job => job.review.rating === 3).length;
      this.adminLoaded = true;
    })
      
  }

  
  public get expandedAdmin() : {_id: string; count?: number; averageScore?: number, lastName?: string, firstName?: string, branch?: {name: string, _id: string}, reviewedJobs?: [] }  | null {
    
    return this._expandedAdmin;
  }
  
  

  displayedColumns = ['name', 'branch', 'count', 'averageScore']
  viewJobMode = false;
  colorScheme = {
    domain: ['#330F2E', '#EB7631', '#FFBD46', '#38baa9']
  };

  barChartData =   [{
    "name": "Terrible",
    "value": 0
  },
  {
    "name": "Bad",
    "value": 0
  },
  {
    "name": "Good",
    "value": 0
  },
    {
    "name": "Excellent",
    "value": 0
  }
];

  constructor(private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.feedbackService.getAllReviews('5dd81dc70a57b73f1c6d5632').subscribe(data => {
      this.reviewedTracks = data;
    });

    this.feedbackService.getAdminFeedbackAll('5dd81dc70a57b73f1c6d5632').subscribe(data => {
      this.admins = data.admins;
    })
  }

  selectTrack(trackSummary): void {
    this.selectedTrack = trackSummary;
    this.feedbackService.getTrackFeedback(trackSummary._id).subscribe(data => {
      this.selectedTrack.stages = data;
      this.selectedStage = undefined;
      this.selectedJob = undefined;
      this.viewJobMode = false;
      this.selectedJob = undefined;
      this.selectJob(this.selectedTrack.stages[0].jobs.find(job => job.reviews && job.reviews.length))
    })
  }

  selectStage(stageSummary): void {
    this.selectedStage = stageSummary;
    this.selectedJob = undefined;
    this.viewJobMode = false;
  }

  selectJob(job): void {
    this.selectedJob = undefined;
    this.feedbackService.getJobFeedback(job._id).subscribe(data => {
      this.barChartData[0].value = data.n0.length;
      this.barChartData[1].value = data.n1.length;
      this.barChartData[2].value = data.n2.length;
      this.barChartData[3].value = data.n3.length;
      this.viewJobMode = false;
      this.selectedJob = data;

    })
  }

  getAverageScore(job): number {
    let ratings =  job.reviews.map(rev => rev.rating);
    return ratings.reduce((a,b) => a + b) / ratings.length;
  }

  viewJob() {
    this.viewJobMode = true;
  }

  viewRatings() {
    this.viewJobMode = false;
  }

  deleteFeedbackForAdmin(admin): void {
    this.feedbackService.deleteFeedbackForAdmin(admin._id).subscribe(data => {
      this.admins = this.admins.filter(adm => adm._id !== admin._id);
      console.log(this.admins);
      
      this.adminTable.renderRows();
    })
  }

}
