<div class="container" style="height:100%;">
  <div class="row">
    <div class="col-12 intro-text">
      <h3>Integrating Glofox with your Wordpress Website</h3>
      <hr />
      <!-- <div class="pt-3">
        The video below outlines how to build a fully dynamic Glofox class schedule into your WordPress Website. 
      </div> -->
      <div class="pt-3 info_txt">As there are multiple different builders available for Wordpress the backend for your website may look slightly different to the images below. </div>
      <div class="pt-3 info_txt">Despite this, the same steps apply and you should be able to follow along. Please contact  <b>
        <a href="mailto:webdevelopment@glofox.com?Subject=Web%20query" target="_top">webdevelopment@glofox.com</a></b>
        for further queries.</div>
        <div class="mt-3 mb-3">
          
          <button type="button" mat-raised-button (click)="scrollToFAQ()">Read our WordPress Integration Guide</button>
          <a href="https://wordpress.com/support/pages/" target="_blank" class="ml-3"><button type="button"
              mat-raised-button>Visit the WordPress Knowledge Base</button></a>
        </div>

  </div>
</div>
<hr />
<div class="row">

  <div style="width:100%;height:0px;position:relative;padding-bottom:53.125%;"><iframe src="https://streamable.com/e/i6g5qq" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>

</div>
<div class="row mt-4" id="integration-tips">
  <div class="col-12">
    <h4>WordPress Integration Guide</h4>
    <hr>
  </div>

  <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
    <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Adding Glofox to your WordPress Website.</h5>
    <div class="row">
      <div class="col-12">You can integrate Glofox with your Wordress by using our handy plugin or by embedding the code.</div>
      <div class="col-12">
        <br>
        <h6 style="font-weight: 600">Method One: Using the Glofox Plugin (Recommended)</h6>
        <br>
        <h6>Step 1. Install the Plugin.</h6>
        <ul>
          <li>From the menu of your Wordpress dashboard, click on 'Plugins' and then 'Add New'</li>
          <li>Search for 'Glofox</li>
          <li>You should see <strong>Glofox Shortcodes</strong> as the top result.</li>
          <li>Click 'Install'</li>
          <li>Click 'Activate'</li>
        </ul>
        <video width="100%" controls>
          <source src="../../../../assets/images/how_to_install_plugin.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <br>
        <h6 class="mt-3">Step 2. Generate your shortcodes.</h6>
        <ul>
          <li>Click on 'Settings' in the menu on the left of the screen.</li>
          <li>Click on 'Glofox'</li>
          <li>Enter your Branch ID into the form and click sumbit. Your Branch ID is: {{branch.branchId}}</li>
          <li>Scroll down the page and you will see your shortcodes are now ready to be used.</li>
        </ul>

        <img src="../../../../assets/images/shortcodes.png" width="100%" >

        <br>
        <h6 class="mt-3">Step 3. Using the shortcodes.</h6>
        <div>There are a number of builders on Wordpress but you should be able to follow these steps to add the feature to your page:</div>
        <ul class="mt-2">
          <li>Copy the relevent shortcode by clicking on 'Copy Shortcode to Clipboard'</li>
          <li>Navigate to the page you want to add the feature to.</li>
          <li>If your builder has a 'Shortcodes' element/block use that. Otherwise select  a 'Text' or 'Text Block' element.</li>
          <li>If using a specific shortcode block, just paste in the shortcode you copied.</li>
          <li>If using a Text Block, make sure you click the 'Text' view (not 'Visual') and paste in the shortcode.</li>
          <li>Finally, preview the page and you should see the Glofox feature has been embedded.</li>
        </ul>
        <video width="100%" controls>
          <source src="../../../../assets/images/using_shortcode.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="col-12">
        <h6 style="font-weight: 600; margin-top: 3rem;">Method Two: Embedding the code directly.</h6>
        <div>
          <div class="pt-2 pb-2">There are many different builders on WordPress but the process is the same for each
            one.</div>
          <div>
            Just follow these steps:
          </div>
        </div>

        <ol class="mt-2">
          <li>
            <div>
              Select the page that you wish to add Glofox to and click 'Edit'.
            </div>
            <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_1.png" alt="">
          </li>
          <li>
            <div>Next, find the element your builder uses to add HTML. This might be 'HTML', 'Code' or 'Raw HTML'.</div>
            <img class="pt-3 pb-3" src="../../../../assets/images/wp_2.png" alt="">
          </li>
          <li>
            <div>
              Now you are going to paste the relevant code snippet from the 'Website Code Snippets' page (see the menu
              on the left), into the textarea. <br>
            </div>
            <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_3.png" alt="">
          </li>
          <li>
            <div>
              And that's it! Dont forget to hit 'Publish' or 'Update'.
            </div>
            <img class="pt-3 pb-3" style="max-width: 100%" src="../../../../assets/images/wp_4.png" alt="">

          </li>


        </ol>
      </div>

    </div>

  </div>

  <div class="col-12 p-3" style="background-color: #fff;">

    <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">My Glofox Integration is not displaying?</h5>
    <div class="pt-2 pb-2"> Some builders on WordPress will mistake the code you entered and try to format as text.
    </div>
    <div class="pt-2 pb-2"> To prevent this, please minify the code using a tool like this: <a
        href="https://www.willpeavy.com/tools/minifier/" target="_blank">Click Here</a> </div>
    <div class="pt-2 pb-2">Just copy the minified output into the 'HTML' element as above.</div>
  </div>
</div>
</div>