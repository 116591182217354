import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WebTeamService } from '../../services/web-team.service';

@Component({
  selector: 'app-web-team-portal',
  templateUrl: './web-team-portal.component.html',
  styleUrls: ['./web-team-portal.component.sass']
})
export class WebTeamPortalComponent implements OnInit {

  webTeamMember: any;
  

  constructor(private auth: AuthenticationService) { }

  ngOnInit() {

  }

  logout(event) {
    this.auth.logout();
  }

}
