<div class="container">

  <div class="row mt-4">
    <div class="col-6">

      <div class="title-container">
        <h3 class="mt-5" class="title">Design Your App's Splash Screen</h3>
        <div class="sub-title">This is the
          screen that will be displayed as the app loads upon being opened.</div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="step">
          <h6 class="step-title">Step 1: Upload your logo.</h6>
          <span style="color:red;" *ngIf="message">{{message}}</span>
          <div class="pb-2">Accepted formats: PNG, JPG, SVG. Max size 1Mb.</div>
          <input #file type="file" mat-raised-button accept='image/*' (change)="preview(file.files)" />
        </div>
        <div class="step">
          <h6 class="step-title">Step 2: Choose your background color.</h6>
          <span>Click here to pick your color: </span>
          <input type="color" id="splashColor" (change)="bgColorUpdated()" name="splashColor" value="#eeeeee">
        </div>
        <div class="step">
          <h6 class="step-title">Step 3: Adjust the size of your logo.</h6>
          <form action="/action_page.php">
            <label class="mt-2 mr-4" for="vol">Image Size:</label>
            <input type="range" id="width" (change)="updateWidth()" name="width" value="150" min="50" max="300">
          </form>
        </div>
        <div class="step">
          <h6 class="step-title">Step 4: Adjust the position of your logo.</h6>
          <div>Please drag the logo to position it on the screen.</div>
          <button class="mt-3" mat-raised-button (click)="centerLogo()">Recenter Logo Horizontally</button>
        </div>
        <div class="step">
          <h6 class="step-title">Step 5: Save your design.</h6>
          <div>All set! Next up, review your designs and send them to our app team.</div>
          <button class="mt-3" mat-raised-button (click)="submitSplashScreen()">Save and continue</button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%">
        <!-- New Phone Design -->
        <div class="marvel-device iphone-x">
          <div class="notch">
            <div class="camera"></div>
            <div class="speaker"></div>
          </div>
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="bottom-bar"></div>
          <div class="volume"></div>
          <div class="overflow">
            <div class="shadow shadow--tr"></div>
            <div class="shadow shadow--tl"></div>
            <div class="shadow shadow--br"></div>
            <div class="shadow shadow--bl"></div>
          </div>
          <div class="inner-shadow"></div>
          <div class="screen">
            <!-- Content goes here -->
            <div id="phoneWrapper">
              <div id="phone" [ngStyle]="{'background-color': splashColor}" style="width: 374px; height: 812px; position: absolute;" (dragover)="onDragOver($event);" (drop)="onDrop($event);">
                <div id="logo" draggable="true" style="margin-top: 155px; margin-left: 70px;" (dragstart)="onDragStart($event);">
                  <img id="myImg" [src]="imgURL" [width]="logoWidth" *ngIf="imgURL" alt="your image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>

  </div>