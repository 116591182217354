import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-cms-guides',
  templateUrl: './cms-guides.component.html',
  styleUrls: ['./cms-guides.component.sass']
})
export class CmsGuidesComponent implements OnInit {

  selectedCMS: string;
  branchId;

  constructor() { }

  ngOnInit(): void {
    this.selectedCMS = 'general';
    this.branchId = window.localStorage.getItem('branchId');
  }

  changeCMS(event) {
    this.selectedCMS = event.value;
  }

}
