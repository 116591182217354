import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { Admin } from 'src/models/admin';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.sass']
})
export class WelcomeComponent implements OnInit {

  admin: Admin;

  hidePW = true;
  hideCPW = true;

  resetPasswordFG: FormGroup;
  resetToken: string;

  validToken = false;

  attemptMade = false;
  successfulReset = false;

  feedbackMsg: string;
  checkingToken = false;

  constructor(private route: ActivatedRoute, private adminsService: AdminService, private auth: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.resetToken = params.token;
      this.checkingToken = true;
      this.adminsService.checkIfTokenIsValid(this.resetToken).subscribe(data => {
        this.validToken = data.isValid;
        this.checkingToken = false;
      })

      this.adminsService.getAdminByResetToken(this.resetToken).subscribe(data => {
          this.admin = data.admin;
      });
    });

    localStorage.removeItem('glofox-token');
    this.resetPasswordFG = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  doPasswordsMatch(): boolean {
    return this.resetPasswordFG.controls['password'].value === this.resetPasswordFG.controls['confirmPassword'].value;
  }

  onFormSubmit() {
    const password = this.resetPasswordFG.controls['password'].value;
    const confirmation = this.resetPasswordFG.controls['confirmPassword'].value;
    try {
      this.adminsService.updatePassword(password, this.resetToken).subscribe(data => {
        this.attemptMade = true;
        this.successfulReset = data.status;
        this.feedbackMsg = data.message;
      });
    } catch (err) {
      // console.log('err: ', err);
    }
  }

  redirectToLogin() {
    this.router.navigate(['/']);
  }

  login() {
    const password = this.resetPasswordFG.controls.password.value;
    this.auth.login({ email: this.admin.email, password }).subscribe(data => {
      const user = this.auth.getUserDetails();
      if (user.email.includes('@glofox.com')) {
        this.router.navigate(['/manager-dashboard'])
      } else {
        this.router.navigate(['/client-dashboard']);
      }
    });
  }


}
