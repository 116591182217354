<div class="container-fluid mt-4" *ngIf="actionlist" >
  <div class="container">
    <mat-card>
      <mat-card-content>
        <div class="d-flex justify-content-between">
          <div class="p-2 mt-3">
            <h5>🗒️ Notes from your On Boarding Manager</h5>
            <p>{{actionlist.obManagerNotes}}</p>
          </div>
          <div class="p-2">
            <ul class="list-group list-group-flush" style="width: 20vw;">
              <li class="list-group-item list-group-item-primary"><h5>Action Items</h5></li>
              <li class="list-group-item" [class.finished]="task.status" [routerLink]="['step', task.step]" routerLinkActive="router-link-active"  *ngFor="let task of actionlist.tasks"> 🎯 {{task.action}}</li>
            </ul>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
