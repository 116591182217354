import { Component, OnInit } from '@angular/core';
import { OBManager } from 'src/models/ob-manager';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerService } from '../../services/manager.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-manager-profile',
  templateUrl: './manager-profile.component.html',
  styleUrls: ['./manager-profile.component.sass']
})
export class ManagerProfileComponent implements OnInit {

  manager: OBManager;

  resetPasswordMode = false;
  resetPasswordFG: FormGroup;

  updateCalendlyLinkMode = false;
  updateSurveyLinkMode = false;
  updateWelcomeMsgMode = false;
  updateProfilePicMode = false;
  passwordResetFeedback: { status: boolean, msg: string };

  constructor(private auth: AuthenticationService, private managerService: ManagerService) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.managerService.getManager(user.email).subscribe((data: OBManager) => {
        this.manager = data;
      });
    }
    this.resetPasswordFG = new FormGroup({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(7)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(7)]),
      confirmNewPassword: new FormControl('', [Validators.required, , Validators.minLength(7)])
    });
  }

  toggleUpdateProfilePicMode() {
    this.updateProfilePicMode = !this.updateProfilePicMode;
  }

  updateProfilePic() {
    this.managerService.changeProfilePicture(this.manager._id, this.manager.imageURL).subscribe(data => {
      this.manager = data;
      this.toggleUpdateProfilePicMode();
    });
  }

  toggleResetPasswordMode(): void {
    this.resetPasswordMode = !this.resetPasswordMode;
    this.passwordResetFeedback = undefined;
  }

  isPasswordResetFormValid(): boolean {
    if (this.resetPasswordFG.valid && this.resetPasswordFG.controls.newPassword.value ===  this.resetPasswordFG.controls.confirmNewPassword.value) {
      return true;
    }
    else {
      return false;
    }
  }

  resetPassword() {
    console.log(this.resetPasswordFG.controls['newPassword'].value);
    const oldPassword = this.resetPasswordFG.controls['oldPassword'].value;
    const newPassword = this.resetPasswordFG.controls['newPassword'].value;
    this.managerService.changePassword(this.manager._id, oldPassword, newPassword ).subscribe(data => {
      console.log(data);
      this.toggleResetPasswordMode();
      this.passwordResetFeedback = data;
    });
  }

  toggleUpdateCalendlyLinkMode() {
    this.updateCalendlyLinkMode = !this.updateCalendlyLinkMode;
  }

  toggleUpdateWelcomeMsgMode() {
    this.updateWelcomeMsgMode = !this.updateWelcomeMsgMode;
  }

  toggleUpdateSurveyLinkMode() {
    this.updateSurveyLinkMode = !this.updateSurveyLinkMode;
  }

  updateSurveyLink() {
    this.managerService.updateSurveyURL(this.manager._id, this.manager.surveyURL).subscribe(data => {
      this.manager = new OBManager();
      this.manager = data;
      this.toggleUpdateSurveyLinkMode();
    });
  }

  updateCalendlyLink() {
    this.managerService.updateCalendlyURL(this.manager._id, this.manager.calendlyURL).subscribe(data => {
      this.manager = data;
      this.toggleUpdateCalendlyLinkMode();
    });

  }

  updateCustomWelcomeMsg() {
    this.managerService.updateCustomWelcomeMsg(this.manager._id, this.manager.customWelcomeMsg).subscribe(data => {
      this.manager = data;
      this.toggleUpdateWelcomeMsgMode();
    });

  }

}
