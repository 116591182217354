<div class="player-wrapper">
      <ul   class="list-group list-group-flush ">
      <li class="list-group-item video" style="cursor: pointer;"
      *ngFor="let video of videos" [ngClass]="{'active': _selectedVideo.id == video.id}" (click)="selectVideo(video)">
      <div class="title_row">
        <mat-icon [ngClass]="{'selectedIcon':_selectedVideo.id == video.id, 'icon': _selectedVideo.id !== video.id }">video_library</mat-icon>
        <div class="title_hasSeen">
          <span class="videoTitle mr-3" [ngClass]="{'selectionTitle':_selectedVideo.id == video.id, 'videoTitle': _selectedVideo.id !== video.id }">{{video.name}}</span>
          <div *ngIf="video.hasSeen">
           <mat-icon style="color:#b5b4b4 !important">remove_red_eye</mat-icon>

          </div>
        </div>
      </div>
    </li>
    </ul>
</div>


