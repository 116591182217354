<mat-card>
    <div *ngIf="branch && !branch.webIntegration.integrationRequestSubmitted">
        <div class="mb-3">
            <h4 class="web-status mt-3 pl-3"><strong>Adding Glofox to Your Website</strong></h4>
            <div class="web-intro pl-3">
                <div class="pb-2">Looking to drive class bookings and membership sales from your website and social media platforms? </div>
                
                <div class="pb-2">The <a [routerLink]="['stage/5fbb8c5fd65813069432c82f']">Website Integration card</a> on the right
                    has all the resources you need!</div>
                    
                <div class="pb-2">If you have any questions please email <strong>webdevelopment@glofox.com</strong>. </div>
                <div> Or, if you need us to do this on your behalf, please <a [routerLink]="['stage/5fbb8c5fd65813069432c82f']" [queryParams]="{ job: 'web-request'}"> submit a request</a> from the web integration
                    card.
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="branch && branch.webIntegration.integrationRequestSubmitted">
        <app-webintegration-progress-tracker [webIntegration]="branch.webIntegration">
        </app-webintegration-progress-tracker>
    </div>

    <div *ngIf="branch && branch.hasStandAloneApp && branch.standAloneApp.hasBeenSubmitted">
        <hr>
        <app-standalone-app-progress-tracker [standAloneApp]="branch.standAloneApp">
        </app-standalone-app-progress-tracker>
    </div>
</mat-card>