<div *ngIf="branch" class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-9 pl-0">
      <!-- BRANCH HEADER CARD -->
      <mat-card class="branch-card">
        <mat-card-header style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; margin-top: .3rem; margin-bottom: .75rem;">
          <div style="display:  flex; flex-wrap: wrap; justify-content: flex-start">
            <div class="mr-5">
              <mat-card-title class="mb-0 d-flex align-items-center" style="font-weight: 700; font-size: 2rem;">
                <mat-icon style="height: auto; width:auto; margin-right: .7rem; font-size: 3rem;">supervised_user_circle
                </mat-icon>{{ branch.name }}
              </mat-card-title>
            </div>

          </div>

          <div class=" my-lg-0 ">
            <a *ngIf="branch.salesForceAccountId" mat-raised-button target="_blank" href="https://glofox.lightning.force.com/lightning/r/Account/{{branch.salesForceAccountId}}/view" class="mr-2"><img src="../../../../assets/images/card_icons/salesforce.svg" style="width: 35px;" alt="" srcset=""></a>
            <button mat-raised-button class="mr-2" (click)="openUpdateBranchDialog()">Edit</button>
            <button mat-raised-button class="" (click)="openDialog()">
              Delete
            </button>
          </div>
        </mat-card-header>
        <mat-card-content>

          <div class="d-flex justify-content-between align-items-center mt-2">
            <div class="align-self-end">

              <mat-card-subtitle class="d-flex align-items-center" style="margin-bottom: .3rem;">OM: <strong style="color: #000; padding-left: 0.4rem;">{{branch.obmanager.firstName}} {{branch.obmanager.lastName}}</strong>
                <mat-icon class="ml-2 clickable" #tooltip="matTooltip" [matTooltip]="'Reassign OM'" [matTooltipPosition]="'right'" style="font-size: 1.25rem; height: auto; width: auto;" (click)="openReassignOMDialog()">manage_accounts</mat-icon>
              </mat-card-subtitle>
              <mat-card-subtitle class="d-flex align-items-center" style="margin-bottom: .3rem;">Branch ID: <strong style="color: #000; padding-left: 0.4rem;">{{branch.branchId}}</strong>
                <mat-icon class="ml-2 clickable" #tooltip="matTooltip" [matTooltip]="'Copy'" [matTooltipPosition]="'right'" style="font-size: 1rem; height: auto; width: auto;" [cdkCopyToClipboard]="branch.branchId">content_copy</mat-icon>
              </mat-card-subtitle>
              <mat-card-subtitle class="mb-0" *ngIf="branch.lastOnlineAt">Last online: <strong style="color: #000; padding-left: 0.4rem;">{{branch.lastOnlineAt | date:'MMM d, y, h:mm a'}}</strong>
              </mat-card-subtitle>

            </div>

            <div class="d-flex mt-3">
              <div style="align-self: center; margin-right: 2rem;" *ngIf="branch.startDate">
                <small class="text-secondary">Start Date:</small>
                <div class="d-flex align-items-center">
                  <h6 class="mb-0 pr-1">
                    {{ branch.startDate | date }}
                  </h6>
                  <mat-icon class="clickable" (click)="editStartDate()" style="height: auto; width: auto;">today
                  </mat-icon>
                </div>
              </div>

              <div style="align-self: center; margin-right: 2rem;" *ngIf="!branch.startDate">
                <mat-form-field>
                  <input matInput [matDatepicker]="picker" placeholder="Set Start Date" (dateChange)="setStartDate('change', $event)" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div style="align-self: center;" *ngIf="branch.goLiveDate">
                <small class="text-secondary">Go Live Date:</small>
                <div class="d-flex align-items-center">
                  <h6 class="mb-0 pr-1">
                    {{ branch.goLiveDate | date }}
                  </h6>
                  <mat-icon class="clickable" (click)="editGoLiveDate()" style="height: auto; width: auto;">today
                  </mat-icon>

                </div>
              </div>

              <div style="align-self: center;" *ngIf="!branch.goLiveDate">
                <mat-form-field>
                  <input matInput [matDatepicker]="gldPicker" placeholder="Set Go Live Date" (dateChange)="setGoLiveDate('change', $event)" />
                  <mat-datepicker-toggle matSuffix [for]="gldPicker"></mat-datepicker-toggle>
                  <mat-datepicker #gldPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex align-items-center mr-2">

              <mat-icon class="mr-2" style="font-size: 3.4rem; margin-top: 5px; height: auto; width: auto;">schedule
              </mat-icon>

              <div>
                <small>Going live in </small>
                <div style="font-size: 1.4rem; font-weight: 700">{{ goingLiveIn() }} Days</div>
              </div>
            </div>


          </div>
        </mat-card-content>
      </mat-card>
      <!-- END OF BRANCH HEADER CARD -->


      <!-- ACCOUNT OVERVIEW CARD -->
      <mat-card class="mb-3 mt-3">
        <mat-card-header class="d-flex justify-content-between align-items-center mb-2">
          <div class="managerHeader d-flex align-items-center">
            <mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto;">assessment</mat-icon>Overview of
            Account
          </div>

          <mat-radio-group class="d-flex align-items-center" [value]="accountStatus" (change)="updateAccountStatus($event)" aria-label="Select an option">
            <mat-radio-button class="pr-4" value="1">Fully Onboarded</mat-radio-button>
            <mat-radio-button class="pr-4" value="2">In Progress</mat-radio-button>
            <mat-radio-button value="3">Disengaged</mat-radio-button>
          </mat-radio-group>

        </mat-card-header>
        <mat-card-content>
          <div class="p-2">
            <span>Progress: {{branch.percent_complete | number:'1.0-0'}} %</span>
            <mat-progress-bar class="mb-2 mt-2" [value]="branch.percent_complete"></mat-progress-bar>
          </div>
          <div>
            <div class="accountProgress my-3 my-lg-0">
              <div class="d-flex flex-wrap">
                <div class="stage-card" *ngFor="let stage of branch.track.stages">
                  <div class="stageTitle truncate">{{ stage.title }}</div>
                  <div class="stage-progress">{{getStageProgress(stage) | number:'1.0-0'}}%</div>
                  <mat-progress-bar [value]="getStageProgress(stage)"></mat-progress-bar>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <!-- END OF ACCOUNT OVERVIEW CARD -->

      <div class="box d-flex flex-wrap justify-content-between">

        <!-- STANDALONE APP CARD -->
        <mat-card class="mb-3 boxChild" style="width: 49.5%;" *ngIf="branch && branch.onboardingOptions.includes('5e3057ce105de93a5ccf8cbd') && branch.standAloneApp">
          <mat-card-header class="mb-2">
            <div class="managerHeader d-flex align-items-center">
              <mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto;">stay_current_portrait
              </mat-icon>Standalone App
            </div>
          </mat-card-header>
          <mat-card-content class="pl-1" style="height: 70%; display: flex; align-items: stretch;">
            <div class=" d-flex flex-column justify-content-center" style="height:100%" *ngIf="!branch.standAloneApp.hasBeenSubmitted">

              <div>DUNS No:
                <span *ngIf="branch.standAloneApp.hasDUNSNumber; else noDunsNum">
                  Yes
                </span>
                <ng-template #noDunsNum>
                  No
                </ng-template>
              </div>
              <div>Apple Dev A/C:
                <span *ngIf="branch.standAloneApp.hasAppleDevAccount; else noAppleDevAc">
                  Yes
                </span>
                <ng-template #noAppleDevAc>
                  No
                </ng-template>
              </div>
              <div>Invite sent to Glofox:
                <span *ngIf="branch.standAloneApp.invitationSentByApple; else noAppleInvite">
                  Yes
                </span>
                <ng-template #noAppleInvite>
                  No
                </ng-template>
              </div>
              <div>Submitted for build:
                <span *ngIf="branch.standAloneApp.hasBeenSubmitted; else assetsNotSubmitted">
                  Yes
                </span>
                <ng-template #assetsNotSubmitted>
                  No
                </ng-template>
              </div>

            </div>
            <div class="d-flex flex-column justify-content-around" style="height: 100%" *ngIf="branch.standAloneApp.hasBeenSubmitted">
              <div></div>
              <div class="mt-2">
                <div>App has been submitted to the App Team.</div>
                <div>Status: <strong>{{branch.standAloneApp.status}}</strong></div>
              </div>
              <div>
                <button class="mt-3" mat-raised-button (click)="openAppJira()">Open Jira
                  {{branch.standAloneApp.jiraKey}} <mat-icon>launch</mat-icon></button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <!-- END OF STANDALONE APP CARD -->

        <!-- WEBSITE INTEGRATION -->
        <mat-card class="mb-3 boxChild" style="width: 49.5%;" *ngIf="branch.webIntegration">
          <mat-card-header class="mb-2 ">
            <div class="managerHeader d-flex align-items-center">
              <mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto;">computer</mat-icon>Website
              Integration
            </div>
          </mat-card-header>
          <mat-card-content style="height: 70%; display: flex; align-items: stretch;">

            <div *ngIf="!branch.webIntegration.integrationRequestSubmitted" style="height: 100%;">
              <div class="d-flex flex-column justify-content-around b-2 mt-3" style="height: 100%;">
                <div class="mb-3"><strong>Please note</strong>: Web Integration cannot be started until Dashboard set up
                  is almost complete.</div>
                <div>
                  <button mat-raised-button (click)="createWebIntegration()" class="mr-3">Request Web Team
                    Assistance</button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-around" style="height: 100%" *ngIf="branch.webIntegration.integrationRequestSubmitted">
              <div></div>
              <div class="mt-2">
                <div>Web Integration Request has been submitted.</div>
                <div>Status: <strong>{{branch.webIntegration.status | webIntStatus}}</strong></div>
              </div>
              <div>
                <button class="mt-3" mat-raised-button (click)="openWebIntJira()">Open Jira
                  {{branch.webIntegration.jiraIssueKey}} <mat-icon>launch</mat-icon></button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <!-- END OF WEBSITE INTEGRATION CARD -->
        <!-- MANAGER NOTE -->
        <mat-card class="mb-3">
          <mat-card-header class="mb-2 d-flex justify-content-between">
            <div class="managerHeader d-flex align-items-center">
              <mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto;">comment</mat-icon>Manager Note
            </div>
            <div *ngIf="branch.managerNote && branch.managerNote.note.length">
              <span *ngIf="branch.managerNote.seen && !branch.managerNote.dismissed" class="noteSubHeader">Seen</span>
              <span *ngIf="!branch.managerNote.seen" class="noteSubHeader">Unseen</span>
              <span *ngIf="branch.managerNote.seen && branch.managerNote.dismissed" class="noteSubHeader">Seen &
                Dismissed</span>
            </div>
          </mat-card-header>

          <mat-card-content style=" display: flex; align-items: stretch;">
            <app-manager-note style="width: 100%" [currentNote]="branch.managerNote.note" (processNote)="updateNote($event)"></app-manager-note>
          </mat-card-content>
        </mat-card>
        <!-- END OF MANAGER NOTE -->

      </div>
      <!-- ADMINS -->
      <mat-card class="mb-3 boxChild">
        <mat-card-header class="mb-2 d-flex justify-content-between align-items-center">
          <div class="managerHeader d-flex align-items-center">
            <mat-icon class="mr-2" style="font-size: 2rem; height: auto; width: auto;">account_circle</mat-icon>Admins
          </div>
          <button mat-raised-button (click)="openAdminDialog('new')">Add Admin </button>
        </mat-card-header>
        <mat-card-content class="mt-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" style="padding: .75rem 0.1rem;" *ngFor="let admin of branch.admins">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div style="max-width: 124px;">
                  <h6 class="pb-0 mb-0">{{ admin.firstName }} {{ admin.lastName }}</h6>
                </div>
                <div style="font-size: 1rem;" class="d-flex align-items-center">
                  <mat-icon class="mr-3">forward_to_inbox</mat-icon>
                  <div style="line-height: 1.5rem; display: inline;">
                    {{ admin.email }}
                  </div>
                  <mat-icon class="ml-1 clickable ml-2" #tooltip="matTooltip" [matTooltip]="'Copy'" [matTooltipPosition]="'right'" style="font-size: 1rem; height: auto; width: auto; color: #939393;" [cdkCopyToClipboard]="admin.email">content_copy</mat-icon>
                </div>
                <div *ngIf="admin.phoneNumber">📱 {{ admin.phoneNumber }}</div>
                <div class="d-flex align-items-center">
                  <mat-icon class="mr-3">book_online</mat-icon>
                  <mat-slide-toggle [(ngModel)]="admin.canBookCalls" [checked]="admin.canBookCalls" (click)="updateBookCallStatus(admin)">
                  </mat-slide-toggle>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div *ngIf="!admin.resetLink">
                    <button mat-raised-button color="primary" (click)="requestPasswordReset(admin)">Request Password
                      Reset Link
                      <mat-icon>link</mat-icon>
                    </button>
                  </div>
                  <div *ngIf="admin.resetLink" [attr.id]="admin.resetLink">
                    <button mat-stroked-button color="primary" (click)="copyLinkToClipBoard(admin)"> Copy Link To
                      Clipboard
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  </div>
                  <div class="ml-3">
                    <div *ngIf="!admin.onboardingSurvey.has_submitted && !surveyLinkCopied">
                      <div *ngIf="surveyLink == undefined">
                        <button mat-stroked-button color="primary" (click)="generateSurveyLink(admin)">Generate Survey
                          Link</button>
                      </div>
                      <div *ngIf="surveyLink !== undefined">

                        <mat-icon class="ml-1 clickable ml-2" #tooltip="matTooltip" [matTooltip]="'Copy'" [matTooltipPosition]="'right'" (click)="changeTooltipToCopied()" style=" font-size: 1rem; height: auto; width: auto; color: #939393; display:flex; border: solid 1px #eee;
                          padding: 0.7rem; border-radius: 5px; align-items: center;" [cdkCopyToClipboard]="surveyLink">
                          <span>Copy Survey Link &nbsp;</span>
                          content_copy
                        </mat-icon>
                      </div>
                    </div>
                    <div *ngIf="surveyLinkCopied">
                      <mat-icon color="primary" style=" font-size: 1rem; height: auto; width: auto; display:flex; border: solid 1px #eee;
                          padding: 0.7rem; border-radius: 5px; align-items: center;"><span>Copied!
                          &nbsp;</span>assignment_turned_in</mat-icon>
                    </div>
                  </div>
                  <div class="ml-4">
                    <fa-icon class="clickable" (click)="openAdminDialog('edit', admin)" [icon]="editIcon" size="lg">
                    </fa-icon>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
      <!-- END OF ADMINS -->

      <div class="card p-3">
        <div class="d-flex  flex-column justify-content-between mb-2">
          <h5 class="managerHeader">Onboarding Progress</h5>
        </div>
        <hr>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-secondary roleSelector" (click)="setSelectedRole(-1)">
            <input type="radio" name="options" id="option1" autocomplete="off" />
            All
          </label>
          <label class="btn btn-secondary active roleSelector" (click)="setSelectedRole(0)">
            <input type="radio" name="options" id="option2" autocomplete="off" checked />
            Customer
          </label>
          <label class="btn btn-secondary roleSelector" (click)="setSelectedRole(1)">
            <input type="radio" name="options" id="option3" autocomplete="off" />
            Manager
          </label>
          <label class="btn btn-secondary roleSelector" (click)="setSelectedRole(2)">
            <input type="radio" name="options" id="option4" autocomplete="off" />
            Web
          </label>
          <label class="btn btn-secondary roleSelector" (click)="setSelectedRole(3)">
            <input type="radio" name="options" id="option5" autocomplete="off" />
            App
          </label>
        </div>
        <div *ngIf="branch" style="background-color: #fff; padding-top: .75rem;">
          <div *ngFor="let stage of branch.track.stages">
            <div class="d-flex stageTitle align-items-center" data-toggle="collapse">
              <h5 class="pt-2 pl-2" style="font-weight: 700">{{ stage.title }}</h5>
              <div class="ml-2 link clickable" (click)="linkCopied()" [cdkCopyToClipboard]="getLinkToJob(stage)">Copy
                Link to Stage <mat-icon style="font-size: 18px;">content_copy</mat-icon>
              </div>
            </div>
            <div class="" id="{{ stage._id }}">
              <div class=" row d-flex align-items-center jobItem" *ngFor="let job of filterByRole(stage.jobs, 0)">
                <div style="width: 28px" class="d-flex justify-content-center align-items-baseline"><span class="d-flex" *ngIf="isJobOverdue(job, stage)">
                    <mat-icon style="color: #FFBD46">assignment_late</mat-icon>
                  </span></div>
                <div class="col-10 jobDescription pl-1" style="font-size: .9rem; min-height: 1.2rem;">
                  {{ job.description }}
                </div>

                <div class="col-1 jobCheckBox pr-0">
                  <mat-checkbox [ngModel]="getJobItem(job).status" (change)="something($event, job)"></mat-checkbox>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <!-- END OF LEFT COLUMN -->

    <!-- RIGHT COLUMN -->
    <div class="col-12 col-md-3 pr-0">
      <mat-card>
        <mat-card-header>
          <div class="managerHeader d-flex align-items-center">
            <mat-icon class="mr-2" style="font-size: 1.8rem; height: auto; width: auto;">tune</mat-icon>Onboarding
            Options
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="d-flex flex-column flex-wrap mt-3" *ngIf="categorisedOptions">


            <div class="filterCategory  mr-2 mb-3">
              <div class="mb-3" style="text-transform: capitalize;"><strong>Plans</strong></div>
              <mat-radio-group class="d-flex flex-column" aria-label="Select an option" *ngIf="plansOption">
                <mat-radio-button *ngFor="let option of plansOption.options" class="pb-1" [value]="option" (change)="toggleOption(option)" [checked]="branchHasOption(option)">{{option.name}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div *ngFor="let optionCategory of categorisedOptions" class="filterCategory mr-2 mb-3">
              <div class="mb-3" style="text-transform: capitalize;"><strong>{{optionCategory['name'] }}</strong></div>
              <div class="d-flex" *ngFor="let option of optionCategory['options']">
                <div style="margin-bottom: 5px;">
                  <mat-slide-toggle [checked]="branchHasOption(option)" (change)="toggleOption(option)">
                  </mat-slide-toggle>
                </div>
                <div class="mr-4 ml-2">{{option['name']}}</div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- END OF RIGHT COLUMN -->
  </div>

  <div class="row"></div>
  <div>
  </div>
</div>