// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // SERVER_URL: 'http://localhost:3000',
  SERVER_URL: 'https://onb-portal.herokuapp.com',
  production: false,
  WISTIA_API_KEY: '6f0ea3064a04e7c3a684a42fa5b2efbc5eb1313be9b1fa73ec65721b03cd452c'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
