import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/models/branch';
import { Admin } from 'src/models/admin';

import { BranchService } from '../../services/branch.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerService } from '../../services/manager.service';
import { OBManager } from 'src/models/ob-manager';
import { MatDialog } from '@angular/material/dialog';
import { NewBranchDialogComponent } from '../dialogs/new-branch-dialog/new-branch-dialog.component';
import { NewStandaloneAppUpsellDialogComponent } from '../dialogs/new-standalone-app-upsell-dialog/new-standalone-app-upsell-dialog.component';
import { NewManagerDialogComponent } from '../dialogs/new-manager-dialog/new-manager-dialog.component';

type ManagerStats = {
  totalBranches: number,
  activeBranches: number,
  disengagedBranches: number,
  fullyOnBoardedBranches: number,
  liveInNext7DaysBranches: number,
  overdueActiveBranches: number,
  notLoggedInWithin72HrsBranches: number;
}

@Component({
  selector: 'app-obm-dashboard',
  templateUrl: './obm-dashboard.component.html',
  styleUrls: ['./obm-dashboard.component.sass', './sb-admin-2.css']
})
export class ObmDashboardComponent implements OnInit {
  branches: Branch[] = [];
  allBranches: Branch[] = [];
  manager: OBManager;
  otherManagers: OBManager[] = [];

  loaded = false;
  globalSearchTerm = '';
  globalSearchResults: Branch[] = [];
  globalSearching = false;

  displayedColumns = ["icon", "name"];

  admins: Admin[] = [];
  managerStats: ManagerStats = {
    totalBranches: 0,
    activeBranches: 0,
    disengagedBranches: 0,
    fullyOnBoardedBranches: 0,
    liveInNext7DaysBranches: 0,
    overdueActiveBranches: 0,
    notLoggedInWithin72HrsBranches: 0
  };

  onAdminAdded(admin: Admin) {
    this.admins.push(admin);
  }

  public get smbManagers(): OBManager[] {
    return this.otherManagers.length ? this.otherManagers.filter(m => m.market === 0).sort(sortByLastName) : [];
  }

  constructor(private branchService: BranchService, private auth: AuthenticationService,
    private managerService: ManagerService, public dialog: MatDialog) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.managerService.getManager(user.email).subscribe((data: OBManager) => {
        this.manager = Object.assign(new OBManager(), data);

        this.managerService.getStats(this.manager._id).subscribe(data => {
          this.managerStats = data;
        })

        this.managerService.getBranchesForManager(this.manager._id).subscribe(branchData => {
          const _branches = branchData;
          this.allBranches = _branches;
          this.branches = _branches;
          this.loaded = true;
        });

        this.managerService.getOBManagers().subscribe(managers => {
          this.otherManagers = managers;
          this.otherManagers = this.otherManagers.filter(m => m._id !== this.manager._id && m.market === this.manager.market);
        });
      });
    }

  }

  logout() {
    this.auth.logout();
  }

  clearFilter() {
    this.branches = this.allBranches;
  }

  filterToActiveBranches() {
    this.branches = this.allBranches.filter(b => !b.fullyOnBoarded && !b.disengaged);
  }

  filterToFullyOnBoardedBranches() {
    this.branches = this.allBranches.filter(b => b.fullyOnBoarded);
  }

  filterToGoingLiveInNext7DaysBranches() {
    const sevenDaysTime = new Date(new Date(Date.now()).getTime() + 7 * 24 * 3600000);
    this.branches = this.allBranches.filter(b => {
      return !b.disengaged && !b.fullyOnBoarded && new Date(b.goLiveDate) < sevenDaysTime && new Date(b.goLiveDate) > new Date(Date.now())
    });
  }

  filterToOverdueBranches() {
    this.branches = this.allBranches.filter(b => {
      return !b.disengaged && !b.fullyOnBoarded && new Date(b.goLiveDate) < new Date(Date.now());
    });
  }

  filterToNotLoggedInWithin72hrs() {
    this.branches = this.allBranches.filter(b => {
      return !b.disengaged && !b.fullyOnBoarded && b.lastOnlineAt === undefined;
    })
  }

  triggerNewBranchDialog() {
    const dialogRef = this.dialog.open(NewBranchDialogComponent, {
      width: '800px',
      data: {
        managerId: this.manager._id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onBranchCreated(result);
      }
    });
  }

  triggerNewManagerDialog() {
    const dialogRef = this.dialog.open(NewManagerDialogComponent, {
      width: '800px',
      data: {
        managerId: this.manager._id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onBranchCreated(result);
      }
    });
  }

  globalSearch() {
    if (this.globalSearchTerm.length > 3) {
      this.globalSearching = true;
      this.branchService.search(this.globalSearchTerm).subscribe(data => {
        this.globalSearchResults = data.branches;
        this.globalSearching = false;
      })
    } else {
      this.globalSearching = false;
      this.globalSearchResults = [];
    }
  }

  triggerStandAloneAppUpsellDialog() {
    const dialogRef = this.dialog.open(NewStandaloneAppUpsellDialogComponent, {
      width: '800px',
      data: {
        managerId: this.manager._id
      }
    });

    dialogRef.afterClosed().subscribe();
  }


  onBranchCreated(branch: Branch) {
    this.branches.push(branch);
  }
}


function sortByLastName(obm1: OBManager, obm2: OBManager): number {
  let comparison = 0;

  if (obm1.lastName.charAt(0) > obm2.lastName.charAt(0)) {
    comparison = 1;
  } else if (obm1.lastName.charAt(0) < obm2.lastName.charAt(0)) {
    comparison = -1;
  }
  return comparison;
}

