<div *ngIf="webInt && !loading">
    <form [formGroup]="webintRequestFG" (ngSubmit)="submitWebIntRequest()">
        <div style="width: 100%">


            <div class="mt-4">
              <div class="unable_to_add_container">
                <div class="sub-warn">
                  If you are unable to add a contributor to your website please contact us at
                  <div class="email" href="webdevelopment@glofox.com"><span>webdevelopment@glofox.com</span></div>
                </div>
              </div>
            </div>

            <div class="checkbox_container mt-4">
              <mat-label class="pr-3 pl-3 mb-3"><strong>How did you send the access for your website? (required)</strong> </mat-label>
              <mat-radio-group style="width: 100%; display: flex; flex-direction: column;" formControlName="methodOfCmsAccess" required matTooltip="Please choose one of the following options" matTooltipPosition="bottom">
                <mat-radio-button *ngFor="let option of CMSAccessOptions; let i = index" style="width: 50%" class="pl-3" [value]="option" required>
                  <div class="selected_item" required>{{ option }}</div>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="mt-4">
              <mat-form-field class="pr-3 pl-3" style="width: 50%" appearance="fill">
                <mat-label class="mt-4">Please enter your website URL</mat-label>
                <input matInput formControlName="websiteUrl" placeholder="Ex. https://your-website.com" />
                <mat-error *ngIf="!webintRequestFG.get('websiteUrl').valid && webintRequestFG.get('websiteUrl').touched">Please enter your websites URL</mat-error>
              </mat-form-field>

              <mat-form-field class="pr-3 pl-3" style="width: 50%" appearance="fill">
                <mat-label class="mt-4">What is your website built with? (required)</mat-label>
                <mat-select formControlName="websiteCms" matTooltip="Which Content Management System your website is built with." matTooltipPosition="bottom">
                  <mat-option *ngFor="let cms of cmsOptions" [value]="cms">
                    {{ cms }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mt-4">
              <mat-label class="pr-3 pl-3"><strong>When would you like the integration to be visible to the public?</strong></mat-label>
              <mat-radio-group class="pr-3 pl-3 " style="width: 100%" aria-label="Select an option" formControlName="pagePublicationMethod">
  
                  <div class="goLiveOptions">
                    <mat-radio-button class="pr-3" *ngFor="let option of publicationOptions; let i = index" [value]="option" >
                      <div class="selected_item">{{ option }} {{publicationOptionMsgs[i]}}</div>
                    </mat-radio-button>
                 </div>
                 <div class="pl-2">

                   <div *ngIf="webintRequestFG.controls['pagePublicationMethod'].value === 'At a later date'"  class="mt-2 pl-4 d-flex align-items-center">
                     <div>
                       <div ><strong>If you know the date: </strong> </div>
                       <mat-form-field class="pr-3" appearance="fill">
                         <input matInput [matDatepicker]="picker" [matDatepickerFilter]="noWeekendFilter" [min]="minPublicationDate" formControlName="publicationDate">
                         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                         <mat-datepicker #picker ></mat-datepicker>
                       </mat-form-field>
                     </div>
 
                     <div class="ml-4 mr-4"><strong>- OR - </strong></div>
                     <div class="ml-2">
                       <div class="pb-2" ><strong>If you are unsure on the date:</strong></div>
                       <mat-checkbox (change)="resetPublicationDate($event)" [checked]="!webintRequestFG.controls.publicationDate.value">I will email webdevelopment@glofox.com to confirm a date when I know</mat-checkbox>
                     </div>
                   </div>
                 </div>

              </mat-radio-group>
            </div>
            <div class="mt-4  pl-3">
              <div class="pb-2">
                <strong>Please select the Glofox features you want embedded into your website and what you would like the page to be called</strong>
              </div>

            <div class="d-flex flex-column">
              <div class="pt-2"  *ngFor="let featureFG of webintRequestFG.controls['features']['controls']; index as i">
                  <form [formGroup]="featureFG">
                      <div class="d-flex  align-items-center" >
                        <div class="mr-3">
                          <mat-checkbox class="mr-3"  formControlName="requested"></mat-checkbox>
                          <div style="min-width: 150px; display: inline-block;">
                            <strong>{{webInt.features[i].feature}}</strong>
                          </div>
                        </div>
                        <div style="width: 40%">
                          <mat-form-field  style="width: 100%" appearance="fill">
                            <mat-label>{{webInt.features[i].feature}} Page Title</mat-label>
                            <input type="text" matInput formControlName="pageTitle">
                          </mat-form-field>
                        </div>
                      </div>
                  </form>
  
              </div>

            </div>
          </div>

          <div class="mt-4" *ngIf="hasSMT">
            <div class="pr-3 pl-3 mb-2">
              <strong>Lead Capture Form</strong>
            </div>
            <div class="pl-3">As you have the Sales and Marketing tools add on, we also need to set up your Lead Capture form. For more information on the lead capture form, please see the <strong>'Setting Up Your Lead Capture Form'</strong> section in the menu on the left.</div>
            <div class="pl-3 pt-2">If you have not set up the plan you want to use for the form yet, you should do so now. You can review <a href="https://support.glofox.com/hc/en-us/articles/360016437693-How-to-Set-up-a-Trial-Membership" target="_blank">this article</a> which explains how to set up a trial membership plan. Once you have the plan set up, please enter the URL in the field below.</div>
            <div class="pl-3 pt-2">To get the URL for your plan:
              <ul>
                <li>From the Glofox dashboard, click ‘Manage’</li>
                <li>Click Memberships</li>
                <li>Click ‘Edit’ on the desired membership</li>
                <li>Click on ‘Plans’</li>
                <li>Finally click the copy icon. <img src="https://res.cloudinary.com/dnyzjwnpe/image/upload/v1606154616/SMB%20/Screenshot_2020-11-23_at_18.00.49_vvmesm.png" alt=""></li>
              </ul>
            </div>
            <div class="d-flex">
              <mat-form-field class="pr-3 pl-3" style="width: 100%" appearance="fill">
                <mat-label>Membership Plan URL</mat-label>
                <input matInput formControlName="leadCaptureURL" placeholder="https://app.glofox.com/portal/#/branch/5dc53181a2b3c4d5e6f/memberships/5eec869f78f1a2b3c4d5e6f/plan/159123456543/buy" >
              </mat-form-field>
            </div>
          </div>

            <div class="mt-4">
              <div class="pr-3 pl-3 mb-2">
                <strong>Additional Information</strong>
              </div>
              <mat-form-field class="pr-3 pl-3" style="width: 100%" appearance="fill">
                <mat-label>Send a note to our website integrations team</mat-label>
                <textarea matInput formControlName="note" placeholder="Eg. Hi.." ></textarea>
              </mat-form-field>
            </div>
            <div class="mt-4 pr-3 pl-3">
              <div class="info-text">We aim to complete your website integration within 3 business days. The status of your integration is visible from your onboarding dashboard. A Glofox Web developer will contact you once the integration is complete.</div>
            </div>

            <div class="d-flex justify-content-center mt-4 mb-4">
              <button color="accent" [disabled]="webintRequestFG.invalid" mat-raised-button type="submit">
                  <div *ngIf="!pendingServerResponse">Submit</div>
                  <div *ngIf="pendingServerResponse" style="width: 100px">
                    <img class="loadingIcon" src="https://img.icons8.com/material-two-tone/24/000000/loading.png" />
                  </div>
              </button>
            </div>

        </div>
      </form>
</div>