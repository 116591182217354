<div class="mb-3">
  <div class="mb-3 text-box">
    <div>
      Here are the code snippets for your lead capture form. <hr> There are a few variations you can choose from - for more information on the different versions please see the 'Adding Lead Capture to your site' tab on the left.

      <!-- <br>Just click the <button mat-icon-button><mat-icon>file_copy</mat-icon></button> icon to copy the code. -->
    </div>
  </div>
    <!-- <mat-card>
      <h6>
        Here are the code snippets for your lead capture form. <br/> There are a few variations you can choose from - for more information on the different versions please see the 'Adding Lead Capture to your site' tab on the left.
      </h6>
    </mat-card> -->
      <mat-accordion multi="true">
        <mat-expansion-panel class="example-card" *ngFor="let leadCaptureSnippet of leadCaptureSnippets"
          hideToggle="true" #codeIcon >
          <mat-expansion-panel-header >
            <mat-panel-title style="flex-grow: unset;" class="align-items-center">  {{leadCaptureSnippet.title}}</mat-panel-title>
            <mat-panel-description  class="align-items-center">{{leadCaptureSnippet.subtitle}}</mat-panel-description>
            <!-- <mat-panel-description class="pt-2 pb-2">
              <div class="featureHeader" style="display: flex;
                  align-items: center;">
                <div class="feature-heading">
                  <h6>
                    {{leadCaptureSnippet.title}}
                  </h6>
                  <div></div>
                </div>
              </div>
            </mat-panel-description> -->
            <mat-action-row style="margin-right:2rem; align-self: flex-end;">
              <button mat-icon-button style="color:#555; opacity:0.8;" aria-label="Copy to
                  Clipboard" type="button" (click)="copyToClipBoard(generateLeadCapture(leadCaptureSnippet.feature))">
                <mat-icon>file_copy</mat-icon>
              </button>
              <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">code</mat-icon>
              <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
            </mat-action-row>
          </mat-expansion-panel-header>
          <pre>
              <code id="leadCaptureBlock" [highlight]="generateLeadCapture(leadCaptureSnippet.feature)"></code>
          </pre>
        </mat-expansion-panel>
        <mat-expansion-panel *ngFor="let contactCapture of contactCaptures" hideToggle="true" #codeIcon>
          <mat-expansion-panel-header>
          <mat-panel-title  class="align-items-center">  {{contactCapture.title}}</mat-panel-title>
          <mat-panel-description  class="align-items-center">{{contactCapture.subtitle}}</mat-panel-description>
            <!-- <mat-panel-description>
              <div class="featureHeader" style="display: flex;
                  align-items: center;">
                <div class="feature-heading">
                  <h6>
                    {{contactCapture.title}}
                  </h6>
                </div>
              </div>
            </mat-panel-description> -->
            <mat-action-row style="margin-right:2rem;">
              <button mat-icon-button style="color:#555; opacity:0.8;" aria-label="Copy to
                  Clipboard" type="button" (click)="copyToClipBoard(generateContactCapture(contactCapture.feature))">
                <mat-icon>file_copy</mat-icon>
              </button>
              <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">code</mat-icon>
              <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
            </mat-action-row>
          </mat-expansion-panel-header>
          <pre>
            <code id="leadCaptureBlock"  [highlight]="generateContactCapture(contactCapture.feature)"></code>
          </pre>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

