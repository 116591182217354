import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Track } from 'src/models/track';
import { Stage } from 'src/models/stage';
import { TouchSequence } from 'selenium-webdriver';
import { Job } from 'src/models/job';
import { Option } from 'src/models/option';
import { Branch } from 'src/models/branch';

@Injectable({
  providedIn: 'root'
})
export class TrackService {

  SERVER_URL = environment.SERVER_URL;

  constructor(private http: HttpClient) { }

  getTrack(trackId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/track/${trackId}`);
  }

  getTrackV2(args: { trackId: string, includeDraftJobs: boolean, includeStages: boolean }): Observable<any> {
    const { trackId, includeDraftJobs, includeStages } = args;
    const queryParam = `include_draft_jobs=${includeDraftJobs}&include_stages=${includeStages}`
    return this.http.get(`${this.SERVER_URL}/api/v2/track/${trackId}?${queryParam}`);
  }

  createTrack(masterlistId: string, track: Track): Observable<any> {
    const body = { track };
    return this.http.post(`${this.SERVER_URL}/api/masterlists/${masterlistId}/tracks/new`, body);
  }

  createOption(masterlistId: string, option: Option): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/masterlists/${masterlistId}/options/new`, { option });
  }

  getOptions(masterlistId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/masterlist/${masterlistId}/options`);
  }

  updateTrack(track: Track): Observable<any> {
    const body = { track };
    return this.http.put(`${this.SERVER_URL}/api/track/${track._id}`, body);
  }

  deleteTrack(trackId: string): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/track/${trackId}`);
  }

  getOptionalTracks(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/tracks/optional`);
  }

  getMasterlist(masterlistId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/v2/masterlists/${masterlistId}`);
  }

  createStage(trackId: string, stage: Stage): Observable<any> {
    const body = { stage };
    return this.http.post(`${this.SERVER_URL}/api/track/${trackId}/stages/new`, body);
  }

  getStage(stageId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/stage/${stageId}`);
  }

  updateStage(stage: Stage): Observable<any> {
    const body = { stage };
    return this.http.put(`${this.SERVER_URL}/api/stage/:id`, body);
  }

  deleteStage(stageId: string): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/stage/${stageId}`);
  }

  createJob(stageId: string, job: Job): Observable<any> {
    const body = { job };
    return this.http.post(`${this.SERVER_URL}/api/stage/${stageId}/jobs/new`, body);
  }

  deleteJob(jobId: string): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/job/${jobId}`);
  }

  updateJob(job: Job): Observable<any> {
    const body = { job };
    return this.http.put(`${this.SERVER_URL}/api/job/${job._id}`, body);
  }

  saveJobTranslation(jobId: string, translations): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/jobs/${jobId}/updateTranslations`, { translations });
  }

  updateStageOrder(track_id: string, stages: string[]): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/tracks/${track_id}/updateStageOrder`, { stages: stages });

  }

  updateTrackTranslations(track_id: string, translations: string[]): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/tracks/${track_id}/translations`, { translations });
  }

  resetDemoBranch(track_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/tracks/${track_id}/resetDemoBranch`);
  }

  createDemoBranch(track_id: string, branch: Branch): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/tracks/${track_id}/createDemoBranch`, branch);

  }

  getCategorisedOptions(masterlist_id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/masterlists/${masterlist_id}/optionsByCategory`);
  }

  getOptionCategory(category: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/masterlists/optionsByCategory/${category}`);
  }



}
