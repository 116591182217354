<mat-card class="mt-4">
    <mat-card-content>
      <div class="d-flex justify-content-between">
        <h5>Edit Stage</h5>
        <button mat-raised-button (click)="deleteStage()" >Delete</button>
      </div>
      <small class="text-secondary">Stage ID: {{stage._id}}</small>
      <form class="mt-4">
        <mat-form-field style="flex-grow: 3; margin-right: .5rem; width: 100%;">
          <input matInput placeholder="Title" [value]="stage.title" (input)="stage.title = $event.target.value">
        </mat-form-field>
        <mat-checkbox [checked]="stage.essentialStage" (change)="updateEssentialStageStatus($event)">Essential Stage?</mat-checkbox>

        <mat-form-field class="mt-3" style="width: 100%">
          <mat-label>Stage Icon</mat-label>

        <mat-select [(value)]="stage.icon">
          <mat-option *ngFor="let icon of icons" [value]="icon.name">
            <div class="d-flex" >
              <img src="{{icon.path}}" alt="" style="width: 20px">
              <div class="ml-2">{{icon.name}}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
        <div *ngIf="track.translations.length">
          <div class="d-flex justify-content-between align-items-center mt-3">
            <h6 class="mt-3">Translations</h6>
            <button mat-raised-button (click)="translateStage()">Auto Translate</button>
          </div>
          <div *ngFor="let translation of track.translations">
            <mat-form-field style="flex-grow: 3; margin-right: .5rem; width: 100%;">
              <input matInput placeholder="{{translation | language}}" [value]="getStageTranslation(translation).title" (input)="getStageTranslation(translation).title = $event.target.value">
            </mat-form-field>
          </div>
        </div>
      </form>
      <button mat-raised-button (click)="updateStage()">Save</button>
    </mat-card-content>
  </mat-card>