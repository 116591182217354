import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Branch } from 'src/models/branch';
import { TrackService } from 'src/app/services/track.service';
import { Track } from 'src/models/track';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';

@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.sass']
})


export class CreateBranchComponent implements OnInit {

  branchCreateForm: FormGroup;
  plans: number[] = [0, 1, 2];
  tracks: Track[] = [];
  options = [];

  branchCheckPending = false;

  faLoading = faCircleNotch;

  @Output() newBranchSubmit = new EventEmitter<Branch>();

  constructor(private trackService: TrackService, private branchService: BranchService) { }

  ngOnInit() {
    this.trackService.getOptionCategory('plans').subscribe(data => {
      this.options = data;

      this.branchCreateForm = new FormGroup({
        studioName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
        branchID: new FormControl(null, Validators.required),
        plan: new FormControl(data[1]),
        track: new FormControl('5e20316f83d7cd00171e47e5')
        // smt: new FormControl(null),
        // standAloneApp: new FormControl(null),
        // classPass: new FormControl(null),
        // goLiveDate: new FormControl(null, Validators.required)
      });
    });

  }
  // onAddForm(form: NgForm) {
  onAddForm() {
    this.branchCheckPending = true;
    const branch = new Branch();

    branch.name = this.branchCreateForm.controls.studioName.value;
    branch.branchId = this.branchCreateForm.controls.branchID.value.trim();
    branch.onboardingOptions.push(this.branchCreateForm.controls.plan.value._id);
    // branch.smt = this.branchCreateForm.controls.smt.value;
    // branch.standaloneApp = this.branchCreateForm.controls.standAloneApp.value;
    // branch.classPass = this.branchCreateForm.controls.classPass.value;
    // branch.goLiveDate = this.branchCreateForm.controls.goLiveDate.value === '' ? new Date(Date.now()) : this.branchCreateForm.controls.goLiveDate.value;
    branch.startDate = new Date(Date.now());
    branch.goLiveDate = new Date(new Date(Date.now()).setMonth(branch.startDate.getMonth() + 1));
    branch.joblist = [];
    branch._track = this.branchCreateForm.controls.track.value;
    console.log(branch);

    // console.log(branch);

    this.branchService.checkIfUnique(branch.name, branch.branchId).subscribe(data => {
      if (data.uniqueName && data.uniqueBranchId) {
        this.newBranchSubmit.emit(branch);
        this.branchCheckPending = false;
      } else {
        if (!data.uniqueName) {
          this.branchCreateForm.controls.studioName.setErrors({ unavailable: !data.uniqueName });
        }
        if (!data.uniqueBranchId) {
          this.branchCreateForm.controls.branchID.setErrors({ unavailable: !data.uniqueBranchId });
        }
        this.branchCheckPending = false;
      }
    });

  }


}
