
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { Branch } from 'src/models/branch';
import { faDumbbell, faUser, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Admin } from 'src/models/admin';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { Step } from 'src/models/steps';
import { ActionList } from 'src/models/action-list';
import { Task } from 'src/models/task';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TrackService } from 'src/app/services/track.service';
import { Job } from 'src/models/job';
import { Track } from 'src/models/track';
import { Stage } from 'src/models/stage';
import { AccountStatus } from 'src/models/account-status';
import { Option } from 'src/models/option';
import { DialogConfirmDeletionDialog } from '../dialogs/confirm-branch-deletion/confirm-branch-deletion.component';
import { ToastrService } from 'ngx-toastr';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { AdminDialogComponent } from '../dialogs/admin-dialog/admin-dialog.component';
import { WebIntegration } from 'src/models/web-integration';
import { UpdateBranchDialogComponent } from '../dialogs/update-branch-dialog/update-branch-dialog.component';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';
import { WebIntService } from '../../services/web-int.service';
import { ReassignOmDialogComponent } from '../dialogs/reassign-om-dialog/reassign-om-dialog.component';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.sass']
})
export class BranchDetailsComponent implements OnInit {
  branch: Branch;
  steps: Step[] = [];
  actionList: ActionList;
  dumbbellIcon = faDumbbell;
  upIcon = faChevronUp;
  downIcon = faChevronDown;
  editIcon = faEdit;
  userIcon = faUser;
  track: Track;
  optionalTracks: Track[];
  jobs: Job[] = [];
  options: Option[] = [];
  surveyLink: string;
  selectedRole = 0;
  surveyLinkCopied = false;
  customiseStepsMode = false;
  editActionListMode = false;
  categorisedOptions: {}[] = [];
  plansOption;
  addStepMode = false;
  updateNoteMode = false;
  date = new Date(Date.now());
  primary = 'red';
  webIntegrationStatus: WebIntegration;
  webIntegration: WebIntegration;

  public get accountStatus(): string {
    if (this.branch) {
      if (this.branch.disengaged) {
        return "3";
      } else if (this.branch.fullyOnBoarded) {
        return "1";
      } else {
        return "2";
      }
    }
    return "2";
  }


  constructor(private route: ActivatedRoute, private branchService: BranchService, private toastr: ToastrService, private staService: StandaloneAppService,
    private adminService: AdminService, private trackService: TrackService, private router: Router, public dialog: MatDialog, private webIntService: WebIntService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params.id;
      this.branchService.getBranch2(id).subscribe(data => {
        this.branch = data;
        this.branch.track.epoch = new Date(Date.now());
        this.branchService.getBranchAdmins(id).subscribe(admins => {
          this.branch.admins = admins;
          const ghostElements = Array.from(document.getElementsByClassName('mat-card-header-text'));
          ghostElements.forEach(element => {
            (element as HTMLElement).style.display = 'none';
          });

          this.trackService.getCategorisedOptions(this.branch.track.masterlist).subscribe(data => {
            this.categorisedOptions = data.filter(cat => cat.name !== 'plans');
            this.plansOption = data.find(cat => cat.name === 'plans');

          })
          // this.toggleStage(this.branch.track.stages[this.getExpectedWeek() - 1]._id);
          this.trackService.getOptions(this.branch.track.masterlist).subscribe(data => {
            this.options = data;
          });
          if (!this.branch.managerNote) {
            this.branch.managerNote = { seen: false, note: '', dismissed: false };
          }
          if (this.branch.standAloneApp) {
            this.staService.getStandAloneApp(`${this.branch.standAloneApp}`).subscribe(data => {
              this.branch.standAloneApp = data;

            });
          }
        });
      });
    });



  }



  createWebIntegration() {

    const webIntegration = new WebIntegration();
    webIntegration.innitialHelpFromWebTeam = true;

    this.webIntService.requestWebTeamAssistance(this.branch.webIntegration._id, webIntegration).subscribe(webIntData => {
      this.branch.webIntegration = webIntData;
      this.toastr.success('Sent to Web Team!');
    });
  }



  setStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.events.push(`${type}: ${event.value}`);
    this.branch.startDate = event.value;
    this.branchService.updateBranchStartDate(this.branch._id, event.value).subscribe();
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmDeletionDialog, {
      width: '500px',

    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.delete) {
        this.deleteBranch();
      }

    });
  }

  updateAccountStatus(event) {
    let value = event.value;
    let body = { disengaged: false, fullyOnBoarded: false };
    if (value === "1") {
      body.fullyOnBoarded = true;
      body.disengaged = false;
    } else if (value === "3") {
      body.fullyOnBoarded = false;
      body.disengaged = true;
    }

    this.branchService.updateBranchStatus(this.branch._id, body).subscribe();


  }

  openUpdateBranchDialog() {
    const dialogRef = this.dialog.open(UpdateBranchDialogComponent, {
      width: '600px',
      data: { branch: this.branch }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.branchService.updateBranch(this.branch._id, result.name, result.branchId).subscribe(data => {
        this.toastr.success('Good Job! Branch updated.')
      })

    });

  }

  openAdminDialog(mode: string, admin?: Admin) {
    const dialogRef = this.dialog.open(AdminDialogComponent, {
      width: '600px',
      data: { mode: mode, admin: admin }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (mode === 'edit') {
        this.adminService.updateAdmin(result).subscribe(data => {

        });
      } else if (mode === 'new') {
        if (result) {
          this.branchService.addAdminToBranch(this.branch._id, result).subscribe(data => {
            this.branch.admins.push(data);
          });
        }
      }

    });

  }

  openReassignOMDialog(): void {
    const dialogRef = this.dialog.open(ReassignOmDialogComponent, {
      data: {
        currentOM: this.branch.obmanager,
        branch: this.branch
      },
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.branch.obmanager = result;
      this.toastr.success('Manager Updated.')
    })
  }

  editStartDate() {
    this.branch.startDate = undefined;
  }

  editGoLiveDate() {
    this.branch.goLiveDate = undefined;
  }

  branchHasOption(option) {
    return this.branch.onboardingOptions.includes(option._id);
  }

  toggleOption(option: Option) {
    if (!this.branch.onboardingOptions) {
      this.branch.onboardingOptions = [];
    }
    if (this.branch.onboardingOptions.includes(option._id)) {
      this.branch.onboardingOptions = this.branch.onboardingOptions.filter(opt => opt !== option._id);
      this.branchService.updateOnboardingOptions(this.branch._id, option._id, 'remove').subscribe(data => {
        this.branch.onboardingOptions = data;
      });
    } else {
      this.branch.onboardingOptions.push(option._id);
      this.branchService.updateOnboardingOptions(this.branch._id, option._id, 'add').subscribe(data => {
        this.branch.onboardingOptions = data;
      });
    }

    // update options

  }

  setGoLiveDate(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.events.push(`${type}: ${event.value}`);
    this.branch.goLiveDate = event.value;
    this.branchService.updateBranchGoLiveDate(this.branch._id, event.value).subscribe();
  }


  getStage(id: string): Stage {
    return this.branch.track.stages.find((s: Stage) => s._id === id);
  }

  getExpectedWeek(): number {
    const today = new Date(Date.now());
    const startDate = new Date(this.branch.startDate);
    const delta = today.getTime() - startDate.getTime();
    const weeks = Math.ceil(delta / (7 * 24 * 3600000));
    return weeks;
  }

  getActualWeek(): number {
    let res = 0;
    if (this.branch.track) {
      const stages = new Array(...this.branch.track.stages);
      // stages.reverse();
      stages.forEach((stage, index) => {
        if (this.getStageProgress(stage) < 100) {
          res = this.branch.track.stages.findIndex(s => s._id === stage._id) + 1;
        }
      });
      return res;
    }
  }

  updateNote(event: { note: string, send: false }) {
    this.updateNoteMode = false;
    this.branchService.updateNote(this.branch._id, event.note, event.send).subscribe(data => {
      this.branch.managerNote = data;
      event.send ? this.toastr.success('The client has recieved an email notifiying them of a new message.', 'Email Sent!') : this.toastr.success(`Good job!`, 'Note Saved');
    });
  }

  cancelNoteUpdate() {
    this.updateNoteMode = false;
  }

  getStageProgress(stage: Stage): number {
    let customerJobs = this.filterByRole(stage.jobs, 0)
    const validJobIds = this.branch.joblist.map(j => j.job);
    customerJobs = customerJobs.filter(j => validJobIds.includes(j._id));
    const customerJobIds = customerJobs.map(j => j._id);
    const jobList = this.branch.joblist.filter(j => customerJobIds.includes(j.job));
    return (jobList.filter(j => j.status).length / customerJobs.length) * 100;
  }

  getAccountStatus(): AccountStatus {
    if (this.branch.fullyOnBoarded) {
      return AccountStatus.FullyOnBoarded;
    } else if (this.branch.disengaged) {
      return AccountStatus.Disengaged;
    } else if (this.getActualWeek() < this.getExpectedWeek()) {
      return AccountStatus.BehindSchedule;
    } else {
      return AccountStatus.OnSchedule;
    }
  }

  goingLiveIn() {
    const today = new Date(Date.now());
    const gld = new Date(this.branch.goLiveDate);
    const delta = gld.getTime() - today.getTime();
    return Math.ceil(delta / (24 * 3600000));
  }

  isInActionlist(task: Task): boolean {
    return this.actionList.tasks.find(t => t._id === task._id) !== undefined;
  }

  updateDisengagedStatus($event): void {
    this.branchService.updateDisengagedStatus(this.branch._id, $event.checked).subscribe(branchData => {
      this.branch.disengaged = branchData.disengaged;
    });
  }

  updateFullyOnBoardedStatus($event): void {
    this.branchService.updateFullyOnBoardedStatus(this.branch._id, $event.checked).subscribe(branchData => {
      this.branch.fullyOnBoarded = branchData.fullyOnBoarded;
    });
  }

  deleteBranch() {
    this.branchService.deleteBranch(this.branch).subscribe(() => {
      this.router.navigate(['/manager-dashboard']);
    });
  }

  updateAdmin(admin: Admin) {

  }

  createAdmin(admin: Admin) {

  }

  getJobDeadline(job: Job, stage: Stage): Date {
    if (this.branch.startDate) {
      return new Date(new Date(this.branch.startDate).getTime() + (stage.hoursFromEpoch + job.hoursToComplete) * 3600000);
    }
  }

  isJobOverdue(job: Job, stage: Stage): boolean {
    return !this.getJobStatus(job) && new Date(Date.now()) > this.getJobDeadline(job, stage);
  }

  updateBookCallStatus(admin: Admin) {
    this.adminService.updateAdminBookCallStatus(admin).subscribe();
  }

  setSelectedRole(role: number) {
    this.selectedRole = role;
  }

  filterByRole(jobs: Job[], role?: number): Job[] {
    const validJobIds = this.branch.joblist.map(j => j.job);
    jobs = jobs.filter(j => validJobIds.includes(j._id));
    if (!role) {
      role = this.selectedRole;
    }
    if (role > -1) {
      jobs = jobs.filter(j => j.role === role).sort(this.sortByDeadline);

      const res: Job[] = [];
      jobs.forEach(job => {
        if (job.filterOn.length === 0) {
          res.push(job);
        } else {
          const intersection = job.filterOn.filter(element => this.branch.onboardingOptions.includes(element));
          if (intersection.length) {
            res.push(job);
          }
        }
      })
      return res;

    }
    return jobs.sort(this.sortByDeadline);
  }

  public getJobStatus(job: Job): boolean {
    return this.branch.joblist.find(j => j.job === job._id).status;
  }

  something($event, job): void {
    const jobItem = this.getJobItem(job);
    this.branchService.updateJobStatus(this.branch._id, jobItem._id, $event.checked).subscribe(branch => {
      this.branch.joblist = branch.joblist;
    });
  }
  getJobItem(job: Job): any {
    return this.branch.joblist.find(j => j.job === job._id);
  }

  toggleStage(id: string): void {
    const stage = document.getElementById(id);
    if (stage.classList.contains('collapse')) {
      stage.classList.remove('collapse');
      document.getElementById(`up${id}`).style.display = 'none';
      document.getElementById(`down${id}`).style.display = 'block';

    } else {
      stage.classList.add('collapse');
      document.getElementById(`down${id}`).style.display = 'none';
      document.getElementById(`up${id}`).style.display = 'block';
    }
  }

  getId(id: string, flag: number) {
    return `${id}_${flag}`;
  }

  requestPasswordReset(admin: Admin) {
    this.adminService.getPasswordResetLink(admin.email).subscribe(data => {
      admin.resetLink = data.link;
    });
  }

  generateSurveyLink(admin: Admin) {
    const adminId: string = admin._id;
    const obmanager = this.branch.obmanager;
    console.log('manager', obmanager)
    this.adminService.generateExternalSurveyLink(adminId, obmanager).subscribe(adminData => {
      let external_survey_token = adminData.external_survey_token;

      let domain = window.location.host;
      this.surveyLink = `${domain}/onboarding-survey/${external_survey_token}`;
      return;
    })
  }

  changeTooltipToCopied() {
    this.surveyLinkCopied = true;

  }

  copyLinkToClipBoard(admin: Admin) {
    const leadCodeBlock = document.createElement('textarea');
    leadCodeBlock.style.position = 'fixed';
    leadCodeBlock.style.left = '0';
    leadCodeBlock.style.top = '0';
    leadCodeBlock.style.opacity = '0';
    leadCodeBlock.value = admin.resetLink;
    document.body.appendChild(leadCodeBlock);
    leadCodeBlock.focus();
    leadCodeBlock.select();
    document.execCommand('copy');
    document.body.removeChild(leadCodeBlock);
    document.getElementById(admin.resetLink).innerHTML = '<h6>Copied!</h6>';
  }

  openAppJira() {
    window.open(`https://glofox.atlassian.net/browse/${this.branch.standAloneApp.jiraKey}`, '_blank');
  }

  openWebIntJira() {
    window.open(`https://glofox.atlassian.net/browse/${this.branch.webIntegration.jiraIssueKey}`, '_blank');
  }

  getLinkToJob(stage: Stage): string {
    return `${window.location.origin}/client-dashboard/stage/${stage._id}`
  }

  linkCopied() {
    this.toastr.success('Link copied!');
  }

  sortByDeadline(a: Job, b: Job) {
    // Use toUpperCase() to ignore character casing
    const deadlineA = a.hoursToComplete;
    const deadlineB = b.hoursToComplete;

    let comparison = 0;
    if (deadlineA > deadlineB) {
      comparison = 1;
    } else if (deadlineA < deadlineB) {
      comparison = -1;
    }
    return comparison;
  }
}


