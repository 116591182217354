<div *ngIf="job">
    <mat-card>
        <app-job-form [(job)]="job" [(stage)]="stage" [categorisedOptions]="categorisedOptions" [options]="options" (jobDeleted$)="deleteJob($event)"></app-job-form>
    </mat-card>

    <mat-card class="mt-3" *ngIf="job._id && track.translations.length">
        <div style="width: 100%;" class="d-flex align-items-center justify-content-between">
          <h5>Translations</h5>
          <div>
            <button mat-raised-button class="mr-1" (click)="translateJob()">Auto Translate</button>  
            <button mat-raised-button (click)="saveTranslation(job)">Save Translations</button>
          </div>
        </div>
        <div *ngFor="let translation of track.translations">
          <div class="mb-3">
            <h6>{{translation | language }}</h6>
            <app-job-translator  [translation]="getTranslation(translation)" ></app-job-translator>
          </div>
          <hr>
        </div>
      </mat-card>
</div>