import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Admin } from 'src/models/admin';
import { OBManager } from 'src/models/ob-manager';
import { log } from 'util';
import { MenuItem } from 'src/models/menu-item';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { WebintHomeComponent } from 'src/modules/web-integration/components/webint-home/webint-home.component';
import { filter } from 'rxjs/operators';
import {
  Observable, Subject, asapScheduler, pipe, of, from,
  interval, merge, fromEvent
} from 'rxjs';
import { Branch } from 'src/models/branch';
import { TranslateService } from '@ngx-translate/core';

declare var Calendly: any;

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.sass']
})
export class NavmenuComponent implements OnInit {

  @Input()
  admin: Admin;

  @Input()
  manager: OBManager;

  @Input()
  callLink: string;

  @Input()
  branch: Branch; 

  menuItems: MenuItem[] = [
    {
      label: "client_nav.onboarding",
      icon: 'dashboard',
      url: '/client-dashboard',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
      hideCompletely: false
    },
    {
      label: "client_nav.videos",
      icon: 'video_library',
      url: 'videos',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
      hideCompletely: false

    },
    {
      label: 'Website Integration',
      icon: 'code',
      url: 'web-integration',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
      hideCompletely: false

    },
    {
      label: "client_nav.knowledge_base",
      icon: 'help',
      url: 'k-b',
      externalRoute: true,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
      hideCompletely: false

    },
    // {
    //   label: "client_nav.whats_new",
    //   icon: 'new_releases',
    //   url: 'k-b',
    //   externalRoute: false,
    //   showOnMobile: false,
    //   showOnTablet: false,
    //   showOnDesktop: true,
    //   hideCompletely: false

    // },
    {
      label: "client_nav.log_out",
      icon: 'follow_the_signs',
      url: 'logout',
      externalRoute: true,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: false,
      hideCompletely: false

    }
  ];
 



  constructor(private auth: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute, public translate: TranslateService) {


  }




  ngOnInit() {

    console.log(this.translate.currentLang);
    
    if (this.branch.version == "v1" && this.translate.currentLang === 'en') {
      this.menuItems[2].hideCompletely = false;
    } else {
      this.menuItems[2].hideCompletely = true;
    }

  }

  navigate(item) {
    if (item.url === 'k-b') {
      window.open('https://support.glofox.com/hc/en-us', '_blank');
    }
    else if (item.url === 'logout') {
      this.logout();
    }
  }
  openCalendlyPopUp() {
    this.collapseMenu();
    Calendly.initPopupWidget({ url: this.manager.calendlyURL });
  }

  collapseMenu() {
    (document.querySelector('.navbar-toggler') as HTMLButtonElement).click();
  }

  logout(): void {
    this.auth.logout();
  }

}
