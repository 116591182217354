import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ObmDashboardComponent } from './components/obm-dashboard/obm-dashboard.component';
import { ManagerPortalComponent } from './components/manager-portal/manager-portal.component';
import { BranchDetailsComponent } from './components/branch-details/branch-details.component';
import { OtherManagerComponent } from './components/other-manager/other-manager.component';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { RoleGuardService } from 'src/app/services/role-guard.service';
import { MyBranchesComponent } from './components/my-branches/my-branches.component';
import { ManagerProfileComponent } from './components/manager-profile/manager-profile.component';
import { TrackBuilderParentComponent } from '../track-builder/components/track-builder-parent/track-builder-parent.component';
import { AddVideoFormComponent } from './components/videos/add-video-form/add-video-form.component';
import { ContentFeedbackComponent } from './components/content-feedback/content-feedback.component';
import { SurveyPortalComponent } from './components/survey-results/survey-portal/survey-portal.component';
import { AdminsSurveyResponseDataComponent } from './components/survey-results/admins-survey-response-data/admins-survey-response-data.component';

const routes: Routes = [{
  path: 'manager-dashboard',
  component: ManagerPortalComponent,
  canActivate: [RoleGuardService],
  data: {
    'expectedRole': 'manager'
  },
  children: [
    { path: '', component: ObmDashboardComponent },
    { path: 'track-builder', component: TrackBuilderParentComponent },
    { path: 'branches', component: MyBranchesComponent },
    { path: 'branches/:id', component: BranchDetailsComponent },
    { path: 'manager/:id', component: OtherManagerComponent },
    { path: 'profile', component: ManagerProfileComponent },
    { path: 'videos', component: AddVideoFormComponent },
    { path: 'content-feedback', component: ContentFeedbackComponent },
    { path: 'survey-feedback', component: SurveyPortalComponent },
    { path: 'survey-feedback/:managerId', component: AdminsSurveyResponseDataComponent }



  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagerPortalRoutingModule { }
