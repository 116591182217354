import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StandAloneApp } from 'src/models/standalone-app';
import { StandaloneAppService } from 'src/modules/client-portal/services/standalone-app.service';

@Component({
  selector: 'app-standalone-android-banner-builder',
  templateUrl: './standalone-android-banner-builder.component.html',
  styleUrls: ['./standalone-android-banner-builder.component.sass']
})
export class StandaloneAndroidBannerBuilderComponent implements OnInit {

  @Input()
  standaloneApp: StandAloneApp;

  @Output()
  sucessfullySubmittedAndroidBanner: EventEmitter<StandAloneApp> = new EventEmitter();

  public imagePathLogo;
  imgURL: any;
  public message: string;



  logoFile: File;
  logoURL: string;

  bannerSplashColor: string;
  bannerAppLogoWidth = 500;

  constructor(private staService: StandaloneAppService) { }

  ngOnInit() {
  }

  preview(logoFiles) {
    if (logoFiles.length === 0)
      return;

    var logoUpload  = logoFiles[0].type;
    if (logoUpload.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var img = document.querySelector('#myLogoImg') as HTMLImageElement;

    var reader = new FileReader();
    this.imagePathLogo = logoFiles;
    reader.readAsDataURL(logoFiles[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // console.log(this.imgURL);
      img.style.display = 'block';
      img.src = this.imgURL;


    };
  }

  bgColorUpdated() {
    const color = (document.getElementById('bannerAppSplashColor') as HTMLInputElement).value;
    console.log(color)
    this.bannerSplashColor = color;
  }

  updateWidth() {
    this.bannerAppLogoWidth = +(document.getElementById('bannerAppWidth') as HTMLInputElement).value;
    console.log(this.bannerAppLogoWidth);
  }



  submit() {
    console.log('gitsubmit');
    const androidBannerHTML = document.getElementById('logoWrapper').innerHTML;
    this.standaloneApp.androidBannerHTML = androidBannerHTML;
    this.staService.uploadAndroidBannerHTML(this.standaloneApp._id, androidBannerHTML).subscribe(data => {
      this.standaloneApp = data;
      this.sucessfullySubmittedAndroidBanner.emit(this.standaloneApp);
    });
  }

}
