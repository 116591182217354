<mat-card *ngIf="selectedWebInt.branch" class="example-card" style="border: 1px solid rgba(0, 0, 0, 0.09);">
  <!-- <mat-card-header> -->
  <div class="headerTitle">
    Web Integration Details</div>
  <div class="branchName">
    {{selectedWebInt.branch.name}}</div>
    <div class="small pl-2">Branch ID:  {{selectedWebInt.branch.branchId}}</div>
  <!-- </mat-card-header> -->
  <hr>
  <mat-card-content>
    <div class="webIntDetail" style="display: flex; flex-direction: column;">
      <div class="headerTitle">
        Account Details</div>
      <div class="split">
        <div class="info_text">
          <strong>OB Manager:&nbsp;</strong>{{selectedWebInt.branch.obmanager.firstName }}
        </div>
        <div class="info_text">
          <strong>Go Live
            Date:&nbsp;</strong>{{selectedWebInt.branch.goLiveDate |  date:'d, MMMM'}}
        </div>
      </div>
      <div class="split">
        <div class="info_text"><strong>Account Admin:</strong>&nbsp;{{admins[0].firstName}} {{admins[0].lastName}}
        </div>
        <div class="info_text"><strong>Admin Email:</strong>&nbsp;{{admins[0].email}}
        </div>
      </div>
      <div class="hr"></div>
      <div class="headerTitle">
        Web Integration Details
      </div>
      <div class="split">
        <div class="info_text"><strong>CMS:</strong>&nbsp;{{selectedWebInt.cms}}</div>
        <!--
          <mat-checkbox class="web_feature" value="{{getSmtForBranch()}}"><strong>Sales & Marketing
              Tools:</strong></mat-checkbox> -->

        <mat-checkbox class="web_feature" [(ngModel)]="hasSmt"><strong>Sales & Marketing
            Tools:</strong></mat-checkbox>
      </div>
      <div class="split">
        <div class="info_text">
          <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.isCurrentlyIntegrated"><strong>Currently
              Integrated?</strong>
          </mat-checkbox>
        </div>
        <div class="info_text"><strong>Integrated with:</strong>&nbsp;{{selectedWebInt.softwareProvider}}</div>
      </div>
      <div class="split">
        <div class="info_text d-flex mt-3" style="width: 100%" >
          <mat-form-field style="width: 80%">
            <mat-label>Website URL</mat-label>
            <input type="text" matInput name="" [(ngModel)]="selectedWebInt.websiteUrl" id="">
          </mat-form-field>
          <div>
            <button mat-raised-button class="ml-2" (click)="updateWebsiteUrl()">Save</button>
          </div>
        </div>
        <!-- <div class="info_text">Lead Capture Membership Url:&nbsp;{{selectedWebInt.websiteUrl}}</strong></div> -->

      </div>
      <div class="split">
        <div class="info_text"><strong>Method of Access:</strong>&nbsp;<br><span
            class="mt-2">{{selectedWebInt.methodOfCmsAccess}}</span></div>
      </div>

      <div class="hr"></div>
      <div class="split">
        <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.featuresRequested.classSchedule"><strong>Class
            Schedule</strong></mat-checkbox>
        <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.featuresRequested.memberships">
          <strong>Memberships</strong></mat-checkbox>


      </div>
      <div class="split">
        <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.featuresRequested.courses">
          <strong>Courses</strong></mat-checkbox>
        <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.featuresRequested.trainers">
          <strong>Trainers</strong></mat-checkbox>
      </div>
      <div class="split">
        <mat-checkbox class="web_feature" [(ngModel)]="selectedWebInt.featuresRequested.facilities">
          <strong>Facilites</strong></mat-checkbox>
      </div>
      <div class="hr"></div>
      <div class="info_text"><strong>Notes from the Client:</strong>&nbsp;<br><span
          class="mt-2">{{selectedWebInt.note}}</span></div>
      <div class="hr"></div>


      <div class="info_text"><strong>Internal Notes:</strong>&nbsp;<br>
        <pre class="mt-2 info_text">{{selectedWebInt.internalNote}}
          </pre>
        <div class="hr"></div>
        <form [formGroup]="internalNoteFG" (ngSubmit)="assignInternalNoteToWebInt()">
          <div class="mt-3">
            <mat-form-field style="width: 100%;">
              <textarea matInput placeholder="Make a note" style="height: 3rem;" [value]="selectedWebInt.internalNote" formControlName="internalNote"></textarea>
            </mat-form-field>
          </div>
          <button mat-stroked-button type="submit" color="primary">Save</button>
          <!-- <button mat-stroked-button (click)="onEditFrom(selectedWebInt.internalNote)" color="warn">Edit</button> -->

        </form>

      </div>
      <!-- <span class="mt-2">{{selectedWebInt.note}}</span> -->
      <!-- <div class="buttons d-flex">
        <button mat-flat-button color="primary" class="mt-3 mr-1" (click)="save()">Save</button>
        <button mat-flat-button class="mt-3 mr-1" (click)="edit()">Edit</button>
      </div> -->
    </div>
  </mat-card-content>
</mat-card>
