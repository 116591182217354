import { Branch } from './branch';
import { WebIntegration } from './web-integration';

export class OBManager {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  imageURL?: string;
  calendlyURL?: string;
  customWelcomeMsg?: string;
  surveyURL?: string;
  market: number;
  base: string;
  slackId: string;
  webIntegration?: WebIntegration;
  branches?: Branch[] = [];
  branchCount: number;
  superManager: boolean;

  get fullName() { return `${this.firstName} ${this.lastName}`; }


}
