<div  class="input-container pl-2 pr-2 mt-2">
    <div class="mb-3  text-box">
      <div>
        To generate your Lead Capture code blocks, please enter a valid membership plan URL into the input field below.
        <!-- <br>Just click the <button mat-icon-button><mat-icon>file_copy</mat-icon></button> icon to copy the code. -->
      </div>
    </div>
    <div class="input-container mb-3">
      <form #websiteIntegrationFG="ngForm">
        <mat-card>
          <mat-form-field>
            <input matInput type="text" ngModel name="userInput"
              placeholder="Enter a valid Membership URL" required minlength="24"
              #formInput="ngModel" (keyup.enter)="onFormSubmit(websiteIntegrationFG)"
              (blur)="onFormSubmit(websiteIntegrationFG)"><br>
            <mat-error *ngIf="formInput.invalid">Please enter a Membership Plan Url</mat-error>
          </mat-form-field>
          <div class="pt-2 d-flex">
            <a mat-button class="mr-2" style= "color:#848584;" href="https://app.glofox.com/dashboard/#/memberships" target="_blank">Go to Memberships</a>
            <button type="reset" (click)="onFormSubmit(websiteIntegrationFG)" type="button" mat-raised-button
            style="background-color:#3AD8C4; color:#fff;"
            > Submit
            </button>
          </div>
        </mat-card>
      </form>
    </div>
  </div>
  <div *ngIf="leadCapData" class="pl-2 pr-2">
    <div class="mb-3">
        <div class="mb-3 text-box">
          <div>
            Here are the code snippets for your lead capture form. <hr> There are a few variations you can choose from - for more information on the different versions please see the 'Adding Lead Capture to your site' tab on the left.
          </div>
        </div>
            <mat-accordion multi="true">
              <mat-expansion-panel class="example-card" *ngFor="let leadCaptureSnippet of leadCaptureSnippets"
                hideToggle="true" #codeIcon >
                <mat-expansion-panel-header >
                  <mat-panel-title style="flex-grow: unset;" class="align-items-center">  {{leadCaptureSnippet.title}}</mat-panel-title>
                  <mat-panel-description  class="align-items-center">{{leadCaptureSnippet.subtitle}}</mat-panel-description>
  
                  <mat-action-row class="align-items-center">
                    <button mat-icon-button style="color:#555; opacity:0.8;" aria-label="Copy to
                        Clipboard" type="button" (click)="copyToClipBoard(generateLeadCapture(leadCaptureSnippet.feature))">
                      <mat-icon>file_copy</mat-icon>
                    </button>
                    <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">arrow_drop_up</mat-icon>
                    <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
                  </mat-action-row>
                </mat-expansion-panel-header>
                <pre>
                    <code id="leadCaptureBlock" [highlight]="generateLeadCapture(leadCaptureSnippet.feature)"></code>
                </pre>
              </mat-expansion-panel>
              <mat-expansion-panel *ngFor="let contactCapture of contactCaptures" hideToggle="true" #codeIcon>
                <mat-expansion-panel-header>
                <mat-panel-title style="flex-grow: unset;" class="align-items-center">  {{contactCapture.title}}</mat-panel-title>
                <mat-panel-description  class="align-items-center">{{contactCapture.subtitle}}</mat-panel-description>
       
                  <mat-action-row class="align-items-center">
                    <button mat-icon-button style="color:#555; opacity:0.8;" aria-label="Copy to
                        Clipboard" type="button" (click)="copyToClipBoard(generateContactCapture(contactCapture.feature))">
                      <mat-icon>file_copy</mat-icon>
                    </button>
                    <mat-icon class="code-icon" *ngIf="!codeIcon.expanded">arrow_drop_up</mat-icon>
                    <mat-icon class="code-icon-rotate" *ngIf="codeIcon.expanded">arrow_drop_down</mat-icon>
                  </mat-action-row>
                </mat-expansion-panel-header>
                <pre>
                  <code id="leadCaptureBlock"  [highlight]="generateContactCapture(contactCapture.feature)"></code>
                </pre>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div data-iframe-height></div>
</div>