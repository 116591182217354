<footer class="page-footer text-light pt-4 mt-5">
    <div class="container text-center text-md-left pt-2 pb-2">
      <div class=" row d-flex justify-content-center">

        
        <div class="col-md-3 mb-md-0 mb-3">
          <div style="text-align: center;">{{'footer.questions' | translate}}
            <p style="text-decoration: none; color: #fff; font-weight: 700" class="mt-3">{{'footer.email' | translate}}</p>
          </div>
          </div>
      </div>
    </div>
  </footer>

