import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { WebIntegration } from 'src/models/web-integration';
import { WebTeamMember } from 'src/models/web-team-member';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebTeamService } from '../../services/web-team.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { Branch } from 'src/models/branch';
import { state } from '@angular/animations';
import { Intercom } from 'ng-intercom';
import { Admin } from 'src/models/admin';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { WebIntService } from 'src/modules/manager-portal/services/web-int.service';




@Component({
  selector: 'app-web-team-dashboard',
  templateUrl: './web-team-dashboard.component.html',
  styleUrls: ['./web-team-dashboard.component.sass']
})
export class WebTeamDashboardComponent implements OnInit, AfterViewInit {
  admin: Admin;
  branch: Branch;
  webTeamMember: WebTeamMember;
  unassigneds: WebIntegration[] = [];
  assignedWebInts: WebIntegration[] = [];
  displayedUnassignedColumns: string[] = ['branch.name', 'plan', 'take'];
  // displayedAssignedColumns: string[] = [ 'plan', 'status', 'action', 'upDateStatus'];
  displayedAssignedColumns: string[] = ['name', 'plan', 'assigned.status', 'action', 'upDateStatus'];

  searchWebInt = '';
  dataSource: MatTableDataSource<WebIntegration>;

  assignedDataSource: MatTableDataSource<WebIntegration>;
  updatedStatusDataSource: MatTableDataSource<WebIntegration>;
  welcomeEmailSent = false;
  selectedWebInt: WebIntegration;

  stateList = [0, 1, 2, 3, 4, 5];
  states = new FormControl([0]);

  // public intercom: Intercom
  constructor(private auth: AuthenticationService,  private adminService: AdminService, public branchService: BranchService, private webTeamService: WebTeamService, private toastr: ToastrService, private webIntService: WebIntService, public intercom: Intercom) {
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sorter2: MatSort;


  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        this.admin = adminData;

        // console.log('admin', adminData)

      });
      this.webTeamService.getWebTeamMemberByEmail(this.auth.getUserDetails().email).subscribe(data => {
        this.webTeamMember = data;
        this.webTeamService.getUnassignedWebInts().subscribe( data => {
          this.unassigneds = data;
          this.dataSource = new MatTableDataSource(this.unassigneds.reverse());
          console.log(data);
        });
        this.webTeamService.getWebIntsForWTM(this.webTeamMember._id).subscribe( data => {
          this.assignedWebInts = data;
          // data.forEach(d => {
          //   console.log(d._id);
          //   console.log('HI' + ' ' + d.branch.name);
          // })

         const filteredWebints = this.assignedWebInts.filter( webInt => webInt.status <= 2 );
          this.assignedDataSource = new MatTableDataSource(filteredWebints.reverse());


          this.selectedWebInt = this.assignedWebInts[0];
        });

        this.intercom.boot({
          app_id: 'whyvsv0c',
        });

      });

    }

  }


  ngAfterViewInit() {
    // const filteredWebints = this.assignedWebInts.filter( webInt => webInt.status <= 2 );
    // this.assignedDataSource = new MatTableDataSource(filteredWebints.reverse());


    this.selectedWebInt = this.assignedWebInts[0];
    this.assignedDataSource.paginator = this.paginator;
    this.assignedDataSource.sort = this.sorter2;

    // console.log('paginator', this.paginator)
    // console.log('sort', this.sorter2)

  }

  takeWebIntegration(webIntegration: WebIntegration) {
    const webIntId = webIntegration._id;
    const wtmId = this.webTeamMember._id;

    this.webTeamService.takeWebInt(wtmId, webIntId).subscribe(data => {
      if (data.status === 'success') {
        this.unassigneds = this.unassigneds.filter(wi => wi._id !== webIntId);
        this.assignedWebInts.push(webIntegration);
        this.assignedDataSource = new MatTableDataSource(this.assignedWebInts);
        // this.assignedDataSource.sort = this.sorter2;
        this.assignedDataSource.paginator = this.paginator;
      } else {
        this.unassigneds = this.unassigneds.filter(wi => wi._id !== webIntId);
      }
      this.dataSource = new MatTableDataSource(this.unassigneds);
      // this.dataSource.sort = this.sorter2;
    });

  }

  sendWelcomeEmail(webIntegration: WebIntegration) {
    const branchId = webIntegration.branch._id;
    let webIntStatus = webIntegration.status;
    webIntegration.status = 0;
    this.webTeamService.sendWelcomeEmail(branchId).subscribe(data => {
      if (data.status === 'success') {
        this.toastr.success('Email Sent!');
        webIntStatus = 1;
        // console.log('Message sent ' + branchI
      } else {
        // console.log('FAIL');
        this.toastr.error('Email Failed to Send!');
        webIntStatus = 0;
        webIntegration.status = 0;
      }
    });
  }

  incrementStatus(webIntegration: WebIntegration) {
    webIntegration.status = webIntegration.status + 1;
    if (webIntegration.status <= 4) {
      this.webTeamService.updateWebIntStatus(webIntegration).subscribe(data => {
        webIntegration = data;
      });
    } else {
      webIntegration.status = webIntegration.status - 1;
    }

  }


  decrementStatus(webIntegration: WebIntegration) {
    webIntegration.status = webIntegration.status - 1;
    if (webIntegration.status >= 0) {
      this.webTeamService.updateWebIntStatus(webIntegration).subscribe(data => {
        webIntegration = data;
      });
    } else {
      webIntegration.status = webIntegration.status + 1;
    }
  }

  selectWebInt(webIntegration) {
    console.log('called', webIntegration);
    this.selectedWebInt = webIntegration;
  }


  viewDetails(webIntegration) {
    console.log('called');

    this.selectedWebInt = webIntegration;
  }

  getBranchStatus(webIntegration): string {
    if (webIntegration.branch.fullyOnBoarded) {
      return 'branchStatus branchLive'
    } else if (webIntegration.branch.disengaged) {
      return 'branchStatus branchDisengaged'
    } else {
      return 'branchStatus branchActive'
    }
  }

  applyFilters($event: Event) {
    const filterToStatus = this.states.value;
    let filteredAssignedInts: WebIntegration[] = [];
    if (filterToStatus === 0) {
      filteredAssignedInts = filteredAssignedInts.concat(this.assignedWebInts.filter(webInt => webInt.status === 0));
    } else if (filterToStatus === 1) {
      filteredAssignedInts = filteredAssignedInts.concat(this.assignedWebInts.filter(webInt => webInt.status === 1));
    } else if (filterToStatus === 2) {
      filteredAssignedInts = filteredAssignedInts.concat(this.assignedWebInts.filter(webInt => webInt.status === 2));
    } else if (filterToStatus === 3) {
      filteredAssignedInts = filteredAssignedInts.concat(this.assignedWebInts.filter(webInt => webInt.status === 3));
    } else if (filterToStatus === 4) {
      filteredAssignedInts = filteredAssignedInts.concat(this.assignedWebInts.filter(webInt => webInt.status === 4));
    } else if (filterToStatus === 5) {
      filteredAssignedInts = this.assignedWebInts;
    }
    else {
      return this.unassigneds.reverse();
    }
    this.assignedDataSource = new MatTableDataSource(filteredAssignedInts.reverse());
    return filteredAssignedInts.reverse();
  }


  // onRemoveSelectedWebInt( id: string) {
  //   console.log('web id', id);

  //   this.webIntService.deleteSelectedWebInt(id).subscribe( data => {
  //     console.log(data);
  //   });
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filteredWeb = this.assignedWebInts;
    const res = filteredWeb.filter(item => item.branch.name.includes(filterValue));
    this.assignedDataSource = new MatTableDataSource(res);
    this.assignedDataSource.paginator = this.paginator;

    console.log('cob', res);
  }



  onChangePage(pageData: PageEvent) {

    console.log(pageData);

  }
}



