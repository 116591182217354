<div class="row">
  <table  class="table col-12">
    <thead>
      <tr class="row">
        <th class="col-10">Description</th>
        <th style="text-align: center;" class="col-2">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr class="row" *ngFor="let job of filterJobs(jobs)" >
        <td class="col-10 jobDescription">{{job.description}}</td>
        <td class="col-2 d-flex flex-column justify-content-center" style="text-align: center" *ngIf="job && getJobItem(job) && getJobItem(job).status">✔️</td>
        <td style="text-align: center;" class="col-2 d-flex flex-column justify-content-center" *ngIf="!getJobStatus(job) && branchStartDate">
          <span *ngIf="isOverdue(getJobDeadline(job))" style="font-size: .65rem; line-height: .5rem;">Overdue!</span>
          <div [class.overdue]="isOverdue(getJobDeadline(job))">{{getJobDeadline(job) | date: 'MMM dd'}}</div>
        </td>
        <td class="col-2" *ngIf="!branchStartDate"></td>
      </tr>
      <tr class="row" *ngIf="!jobs.length">
        <td class="col-12 jobDescription" style="text-align: center;">No jobs!</td>
      </tr>
    </tbody>
  </table>
</div>
