import { Component, OnInit, Input } from '@angular/core';
import { Branch } from 'src/models/branch';
import { Admin } from 'src/models/admin';

@Component({
  selector: 'app-onboarding-welcome-banner',
  templateUrl: './onboarding-welcome-banner.component.html',
  styleUrls: ['./onboarding-welcome-banner.component.sass']
})
export class OnboardingWelcomeBannerComponent implements OnInit {


  @Input()
  branch: Branch;

  @Input()
  admin: Admin;
  constructor() { }

  ngOnInit() {
  }

}
