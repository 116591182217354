import { Component, OnInit } from '@angular/core';
import { Video } from '../../models/video';
import { VideosService } from '../../services/videos.service';
import { Project } from '../../models/project';
import { Media } from '../../models/media';
import { ActivatedRoute } from '@angular/router';
import { log } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { Branch } from 'src/models/branch';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { Admin } from 'src/models/admin';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';

@Component({
  selector: 'app-vides-home',
  templateUrl: './videos-home.component.html',
  styleUrls: ['./videos-home.component.css']
})
export class VideosHomeComponent implements OnInit {
  selectedVideo: Video;
  requestedMedia: string;
  project: Project;
  filterBySection: string;
  playerWidth: number;

  videos: Video[] = [];
  filteredVideos: Video[] = [];
  selected: false;

  videoCategories: string[] = [
    'All',
    'The Dashboard',
    'Payments',
    'Memberships',
    'Web Integration',
    'Classes',
    'Courses',
    'Member App',
    'Managing your Staff',
    'Facilities'

  ];

  admin: Admin;
  branch: Branch;
  loading = true;

  updateHasSeenEventEmiter: boolean;
  activePill = this.videoCategories[0];

  constructor(private auth: AuthenticationService, private adminService: AdminService, private branchService: BranchService, private videoService: VideosService, private route: ActivatedRoute, public translate: TranslateService) { }


  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.adminService.getAdminByEmail(user.email).subscribe(adminData => {
        this.admin = adminData;
        this.adminService.getBranchForAdmin(this.admin).subscribe(branchData => {
          this.branchService.updateLastOnlineAt(branchData._id).subscribe(data => {
          });
          this.branch = Object.assign(new Branch(), branchData);
          if (this.branch.track._id === "5f02df42d00d0260fe27195d") {
            this.translate.setDefaultLang('pt-br');
            this.videos = this.videoService.getPortugueseVideos();
            this.filteredVideos = this.videos;
            this.selectedVideo = this.filteredVideos[0];
            const requestedVideos = this.route.snapshot.queryParams.videos;

            if(requestedVideos && requestedVideos.length) {
              this.filteredVideos = this.videos.filter(video => requestedVideos.includes(video.id))
              this.selectedVideo = this.filteredVideos[0];
            } 
          } else {
            this.videoService.getVideos(this.admin._id).subscribe(data => {
              this.loading = false;
              this.videos = data;
              
              const requestedVideos = this.route.snapshot.queryParams.videos;

              if(requestedVideos && requestedVideos.length) {
                this.filteredVideos = this.videos.filter(video => requestedVideos.includes(video.id))
                this.selectedVideo = this.filteredVideos[0];
              } 
              else {
                this.selectedVideoCategory('All');
                this.selectedVideo = this.videos[0];
              }



            });
          }
        


        });

      });

    }

   


    const pparent = document.getElementById('playerParent').offsetWidth;
    this.playerWidth = pparent - 30;

    window.addEventListener('resize', () => {
      this.playerWidth = document.getElementById('playerParent').offsetWidth - 30;
    });


    if (this.requestedMedia || this.filterBySection) {
      setTimeout(() => {
        document.getElementById('player').scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    }

    // });
  }

  selectVideo(event) {
    this.selectedVideo = event;
    // console.log(this.selectedVideo.name);
  }

  selectedVideoChanged(vid) {
    this.selectedVideo = vid;
  }

  updateHasSeen($event){
      this.updateHasSeenEventEmiter = $event;
  }

  selectedVideoCategory(videoCat) {
    this.activePill = videoCat;
    if (videoCat === 'All') {
      this.filteredVideos = this.videos;
    } else {
      this.filteredVideos = this.videos.filter(v => v.category == videoCat);
    }

    if (this.filteredVideos.length) {
      this.selectedVideo = this.filteredVideos[0];
    }

    // console.log('filtered Vid', this.videos)










  }

}
