import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebintHomeComponent } from './components/webint-home/webint-home.component';
import { FeatureSnippetsComponent } from './components/feature-snippets/feature-snippets.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HighlightModule } from 'ngx-highlightjs';
import { LeadCaptureComponent } from './components/lead-capture/lead-capture.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { WebintCodeParentComponent } from './components/webint-code-parent/webint-code-parent.component';
import { AddDeveloperComponent } from './components/add-developer/add-developer.component';
import { WordpressIntegrationComponent } from './components/wordpress-integration/wordpress-integration.component';
import { SquarespaceIntegrationComponent } from './components/squarespace-integration/squarespace-integration.component';
import { WixIntegrationComponent } from './components/wix-integration/wix-integration.component';
import { LeadcaptureIntegrationComponent } from './components/leadcapture-integration/leadcapture-integration.component';
import { GlofoxService } from './services/glofox.service';
import { GlofoxApiComponent } from './components/glofox-api/glofox-api.component';
import {  RouterModule } from '@angular/router';
import { AddADeveloperWebIntComponent } from './components/add-a-developer-web-int/add-a-developer-web-int.component';
import { IframeResizerDirective } from './directives/iframe-resizer.directive';


@NgModule({
  declarations: [
    WebintHomeComponent,
    FeatureSnippetsComponent,
    LeadCaptureComponent,
    WebintCodeParentComponent,
    AddDeveloperComponent,
    WordpressIntegrationComponent,
    SquarespaceIntegrationComponent,
    WixIntegrationComponent,
    LeadcaptureIntegrationComponent,
    GlofoxApiComponent,
    AddADeveloperWebIntComponent,
    IframeResizerDirective,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatExpansionModule,
    ReactiveFormsModule,
    HighlightModule,
    MatIconModule,
    MatButtonToggleModule,
    MatListModule,
    RouterModule
  ],
  providers: [
    GlofoxService
  ]
})
export class WebIntegrationModule { }
