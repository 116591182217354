<div class="row d-flex flex-column justify-content-center align-items-center">
    <h4 id="requestPasswordH2" class="col-12" style="color: #fff; text-align: center; font-size:1rem;">Request a Password Reset Email</h4>
    <div id="resetEmailInput" class="col-12">
      <div class="d-flex justify-content-between mt-3 mb-2">
        <form [formGroup]="emailFG" (ngSubmit)="requestPasswordReset()" style="width: 100%; flex-direction: column;
        justify-content: center;
        align-items: center;
    " class="d-flex mt-2 mb-2">
            <mat-form-field  style="color: #fff !important; width: 100%;">
              <input matInput type="email" formControlName="email" required style="color: white !important;">
              <mat-placeholder style="color:#fff">Email Address</mat-placeholder>

          </mat-form-field>

          <button  class="glofoxBtn glofoxBtnCyan ml-2" [disabled]="emailFG.invalid" style="width: 150px; height: 50px; padding: 0.3rem 0.2rem; margin-bottom: 0rem; font-size: .8rem; color: #330F2E; margin-top: 1.5rem;" type="submit" >Submit</button>
        </form>
    </div>
    <div class="mt-4 mb-3 d-flex justify-content-center clickable forgotPasswordLink" *ngIf="allowCancel" type="button" style=" text-decoration: underline;" (click)="cancel()">Cancel</div>
    </div>
    <div id="resetFeedback" style="font-weight: 600;"></div>
  </div>
