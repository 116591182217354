<!-- <p>glofox-api works!</p> -->

<div class="row">
  <div class="membership col-6" *ngFor=" let membership of memberships ">
    <h3>
      {{membership.name}}
    </h3>
    <!-- {{membership._id}} -->
    <div class="plan col-6" *ngFor="let plan of membership.plans">
      {{ plan.name }}
      {{ plan.code}}
      <button >Generate Lead Capture</button>

    </div>
  </div>
</div>
