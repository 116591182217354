<table mat-table style="width: 100%;" [dataSource]="branches" class="mat-elevation-z8 mt-2 assignedWebInt"
matSort  #sorter2="matSort">
<ng-container matColumnDef="name">
  <th  mat-header-cell *matHeaderCellDef mat-sort-header   style="width: 30% !important;">Name</th>
  <td mat-cell *matCellDef="let branch">
    <div class="d-flex align-items-center">
      <!-- <div [class]="getBranchStatus(assigned)"></div> -->
      <div contenteditable> {{branch.name}}</div>
    </div>
  </td>
</ng-container>


<ng-container matColumnDef="website">
    <th mat-header-cell *matHeaderCellDef style="width: 22% !important; text-align: center"> Website</th>
    <div class="cetered">
      <td mat-cell *matCellDef="let branch" > 
          <div *ngIf="branch.webIntegration && branch.webIntegration.websiteUrl" style="display: flex; justify-content: center; align-items: center;">{{branch.webIntegration.websiteUrl}}</div>
          <div *ngIf="!branch.webIntegration || !branch.webIntegration.websiteUrl" style="display: flex; justify-content: center; align-items: center;">
            <a [href]="getSeachUrl(branch.name)" target="_blank" mat-raised-button>Search</a> 
            <a [href]="getEmailDomain(branch)" mat-raised-button class="ml-1" target="_blank" *ngIf="getEmailDomain(branch)">Try Email</a>
          </div>
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>


  <ng-container matColumnDef="portal">
    <th mat-header-cell *matHeaderCellDef style="width: 10% !important; text-align: center"> Portal</th>
    <div class="cetered">
      <td mat-cell *matCellDef="let branch" > 
          <div  style="display: flex; justify-content: center; align-items: center;">
            <a [href]="getPortalLink(branch)" target="_blank" mat-raised-button>Open</a>
           
          </div>
          
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="onboarding_progress">
    <th mat-header-cell *matHeaderCellDef style="width: 10% !important; text-align: center"> OB Progress</th>
    <div class="cetered">
      <td mat-cell *matCellDef="let branch" > 
          <div style="display: flex; justify-content: center; align-items: center;">{{branch.percent_complete | number:'1.0'}}%</div>
        <!-- con-->
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="qaCheckPassed">
    <th mat-header-cell *matHeaderCellDef style="width: 10% !important;text-align: center">Passed QA</th>
    <div class="cetered" style="align-items: center;">
      <td mat-cell  *matCellDef="let branch"> 
          <div *ngIf="branch.webIntegration" style="display: flex; justify-content: center; align-items: center;">
            <mat-checkbox class="example-margin" [checked]="branch.webIntegration.passedQA" (change)="updateQAPassed($event, branch.webIntegration)"></mat-checkbox>
          </div>
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="gld">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10% !important;">Go Live</th>
    <div class="cetered" style="align-items: center;">
      <td mat-cell *matCellDef="let branch">
          {{branch.goLiveDate | date:'dd/MM'}}
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="smtConfirmed">
    <th mat-header-cell *matHeaderCellDef style="width: 10% !important; text-align: center"> 
        <div style="text-align: center" class="d-flex justify-content-center">LC Set Up</div>
    </th>
    <div class="cetered" style="align-items: center;">
      <td mat-cell *matCellDef="let branch"> 
          <div style="display: flex; justify-content: center; align-items: center;" *ngIf="branch.webIntegration && hasSMT(branch)">
            <mat-checkbox class="example-margin" [checked]="branch.webIntegration.LCSetUp" (change)="updateSMTSetUp($event, branch.webIntegration)"></mat-checkbox>
          </div>
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="contactedToOfferHelp">
    <th mat-header-cell *matHeaderCellDef style="width: 10% !important; text-align: center">Contacted</th>
    <div class="cetered" style="align-items: center;">
      <td mat-cell *matCellDef="let branch"> 
          <div *ngIf="branch.webIntegration" style="display: flex; justify-content: center; align-items: center;">
            <mat-checkbox class="example-margin" [checked]="branch.webIntegration.contactedAboutFailedQA" (change)="updateContactedAboutFailedQA($event, branch.webIntegration)"></mat-checkbox>
          </div>
        <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
        <!-- con-->
      </td>
    </div>
  </ng-container>

<!-- <ng-container matColumnDef="action">
  <th mat-header-cell *matHeaderCellDef style="width: 14%; text-align:center;">Action</th>
  <td mat-cell *matCellDef="let webIntegration" style="text-align:center;">
    <button mat-stroked-button color="primary" *ngIf="webIntegration.status === 0"
      (click)="sendWelcomeEmail(webIntegration)">Send
      Email</button>
    <p *ngIf="webIntegration.status === 1" class="email-sent">
      {{ webIntegration.dateOfFirstContact | date:'d, MMMM' }}</p>
    <p *ngIf="webIntegration.status === 2" class="email-sent"><button mat-flat-button
        (click)="viewDetails(webIntegration)">Open CMS</button></p>
    <p *ngIf="webIntegration.status === 3" class="email-sent">Complete 🎉</p>
    <p *ngIf="webIntegration.status === 4" class="email-sent"><button mat-stroked-button style="color:#F44335"
        (click)="viewDetails(webIntegration)">Delete</button></p>
  </td>
</ng-container> -->
<!-- <ng-container matColumnDef="upDateStatus">
  <th mat-header-cell *matHeaderCellDef style="width: 11% !important; text-align: center;">
    Update</th>
  <div class="cetered" style="text-align:center;">
    <td mat-cell *matCellDef="let assigned" style="text-align: center;">
      <mat-icon aria-hidden="false" id="udpdate-btn" style="text-align: center;" class="udpdate-btn"
        (click)="decrementStatus(assigned)" aria-label="Example remove icon">remove</mat-icon>
      <mat-icon aria-hidden="false" id="udpdate-btn2" style="text-align: center;" class="udpdate-btn"
        (click)="incrementStatus(assigned)" aria-label="Example add  icon">add</mat-icon>
    </td>
  </div>
</ng-container> -->
<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer" (click)="selectBranch(row)"></tr>
</table>