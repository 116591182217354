import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxstringlength'
})
export class MaxstringlengthPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if (value.length < 22){
      return value;
    } else {
      let words = value.split(' ');
      if (words.every(word => word.length < 22)) {
        return value;
      } else {
        return value.substr(0, 19) + '...';
      }
    }
  }

}
