import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientDashboardComponent } from './components/client-dashboard/client-dashboard.component';
import { AdminService } from './services/admin.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavmenuComponent } from './components/navmenu/navmenu.component';
import { BranchBannerComponent } from './components/branch-banner/branch-banner.component';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ClientFooterComponent } from './components/client-footer/client-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { ClientPortalRoutingModule } from './client-portal-routing.module';
import { ClientPortalComponent } from './components/client-portal/client-portal.component';
import { ClientActionlistThumbComponent } from './components/client-actionlist-thumb/client-actionlist-thumb.component';
import { TestCompComponent } from './components/test-comp/test-comp.component';
import { WebIntegrationModule } from '../web-integration/web-integration.module';
import { ClientGuideDialogComponent } from './components/dialogs/client-guide-dialog/client-guide-dialog.component';
import { StageTestComponent } from './components/stage-test/stage-test.component';
import { StandaloneAppBuilderModule } from '../standalone-app-builder/standalone-app-builder.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManagerNoteComponent } from './components/manager-note/manager-note.component';
import { ObmanagerDetailCardComponent } from './components/obmanager-detail-card/obmanager-detail-card.component';
import { TrainingContentComponent } from './components/training-content/training-content.component';
import { EssentialSetupComponent } from './components/essential-setup/essential-setup.component';
import { WebintRequestFormComponent } from './components/webint-request-form/webint-request-form.component';
import { WebintInteractiveJobComponent } from './components/webint-interactive-job/webint-interactive-job.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { StandaloneAppProgressTrackerComponent } from './components/standalone-app-progress-tracker/standalone-app-progress-tracker.component';
import { BranchOnboardingCompleteComponent } from './components/branch-onboarding-complete/branch-onboarding-complete.component';
import { OnboardingWelcomeBannerComponent } from './components/onboarding-welcome-banner/onboarding-welcome-banner.component';
import { ProgressTrackerComponent } from './components/progress-tracker/progress-tracker.component';
import { WebintegrationProgressTrackerComponent } from './components/webintegration-progress-tracker/webintegration-progress-tracker.component';
import { SubmitReviewComponent } from './components/submit-review/submit-review.component';


@NgModule({
  declarations: [
    ClientDashboardComponent,
    NavmenuComponent,
    BranchBannerComponent,
    ClientFooterComponent,
    VideoDialogComponent,
    ClientPortalComponent,
    ClientActionlistThumbComponent,
    TestCompComponent,
    ClientGuideDialogComponent,
    StageTestComponent,
    ManagerNoteComponent,
    ObmanagerDetailCardComponent,
    TrainingContentComponent,
    EssentialSetupComponent,
    WebintRequestFormComponent,
    WebintInteractiveJobComponent,
    StandaloneAppProgressTrackerComponent,
    BranchOnboardingCompleteComponent,
    OnboardingWelcomeBannerComponent,
    ProgressTrackerComponent,
    WebintegrationProgressTrackerComponent,
    SubmitReviewComponent,
  ],
  imports: [
    CommonModule,
    ClientPortalRoutingModule,
    MatProgressBarModule,
    MatChipsModule,
    MatExpansionModule,
    SharedModule,
    FontAwesomeModule,
    MatCardModule,
    MatInputModule,
    RouterModule,
    MatCheckboxModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 8,
      innerStrokeWidth: 4,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatCardModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    WebIntegrationModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatBadgeModule,
    BrowserAnimationsModule,
    MatSelectModule,
    StandaloneAppBuilderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AdminService,
    AuthenticationService,
  ],
  entryComponents: [
    VideoDialogComponent,
    ClientGuideDialogComponent
  ]
})
export class ClientPortalModule { }
