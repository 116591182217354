import { Component, OnInit, Input } from '@angular/core';
import { Branch } from 'src/models/branch';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';

@Component({
  selector: 'app-manager-note',
  templateUrl: './manager-note.component.html',
  styleUrls: ['./manager-note.component.sass']
})
export class ManagerNoteComponent implements OnInit {

  @Input()
  branch: Branch;

  @Input()
  managerNote: string;

  constructor( private branchService: BranchService) { }

  ngOnInit() {
    document.getElementById('managerNote').innerHTML = this.branch.managerNote.note;
    console.log('manager note', this.branch.managerNote.note);
  }



  dismissManagerNote() {
    this.branchService.dismissManagerNote(this.branch._id).subscribe(status => {
      this.branch.managerNote.dismissed = status;
    });
  }


}
