<mat-card *ngIf="branch && branch.track" class="mb-2">
  <div class="branchInfo_container pt-1">
    <div class="branch_name_container ">
      <h2 lang="de" class="branch_name">{{branch.name}}</h2>
    </div>
    <div class="branch_detail_wrapper">
      <div class="go_live_wrapper">
        <div *ngIf="goingLiveIn() > 0" class="go_live_status">
          <div class="overdue_by">{{'banner.going_live_in' | translate}}</div>
          <h1 id="daysUntilLive" class="mt-2 days">{{goingLiveIn()}} {{'banner.days' | translate}}</h1>
        </div>
        <div *ngIf="goingLiveIn() < 0" class="go_live_status">
          <div class="overdue_by">{{'banner.overdue_by' | translate}}</div>
          <h1 id="daysUntilLive" class="days">{{goingLiveIn() * -1}} {{'banner.days' | translate}}</h1>
        </div>
      </div>
      <div class="circle_wrapper">
        <circle-progress [percent]="branch.percent_complete_essential" [radius]="60" [imageHeight]="30" [outerStrokeWidth]="8"
          [innerStrokeWidth]="4" [outerStrokeColor]="'#43D8C4'" [innerStrokeColor]="'#330F2E'" [animation]="true"
          [animationDuration]="300" [titleColor]="'#330F2E'" [subtitleColor]="'#330F2E'" [subtitle]="'branch_card.complete' | translate"></circle-progress>
      </div>
    </div>
  </div>
</mat-card>




<!-- <div class="container-fluid d-none d-sm-block"  *ngIf="branch && branch.track">
  <div class="d-flex justify-content-center" >
    <app-overall-progress [stages]="branch.track.stages" [joblist]="branch.joblist"></app-overall-progress>
  </div>
</div> -->