import { Component, OnInit, Input } from '@angular/core';
import { Branch } from 'src/models/branch';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { Step } from 'src/models/steps';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-branch-banner',
  templateUrl: './branch-banner.component.html',
  styleUrls: ['./branch-banner.component.sass']
})
export class BranchBannerComponent implements OnInit {

  @Input()
  branch: Branch;


  boltIcon = faBolt;
  lang = "en"


  constructor(private translate: TranslateService ) {
    let currentLang = window.localStorage.getItem('currentLang');
    if (!currentLang) {
        window.localStorage.setItem('currentLang', this.lang);
        currentLang = this.lang;
    } else {
      this.lang = currentLang;
    }

    translate.setDefaultLang(this.lang);
}

  ngOnInit() {
  }

  goingLiveIn(): number {
    const today = new Date(Date.now());
    const gld = new Date(this.branch.goLiveDate);
    const oneDay = 1000 * 60 * 60 * 24;

    const delta = gld.getTime() - today.getTime();
    const days = Math.floor(delta / oneDay);
    return days;
  }

  isStandardFormat() {
    return `${this.branch.track._id}` !== '5f92b080c4e14301db3ce4e6';
  }
}
