import { Component, OnInit, Input, ViewChild, Output, EventEmitter  } from '@angular/core';
import { Video } from 'src/modules/videos/models/video';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { VideosService } from 'src/modules/videos/services/videos.service';
import { ToastrService } from 'ngx-toastr';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-video-table',
  templateUrl: './video-table.component.html',
  styleUrls: ['./video-table.component.sass']
})
export class VideoTableComponent implements OnInit {

  _returnedVideoFromServer: Video[] = [];
  loading = false;

  netScore: number;

  @Output()
  displayForm = new EventEmitter<boolean>();


  @Input()
  public set returnedVideoFromServer(v: Video[]) {
    this._returnedVideoFromServer = v;
    this.dataSource = new MatTableDataSource(this._returnedVideoFromServer);
  };

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public get returnedVideoFromServer(): Video[] {
    return this._returnedVideoFromServer;
  }
  
  dataSource: MatTableDataSource<Video>;

  displayedColumns: string[] = ['videoName', 'videoId', 'category','nViews', 'likes', 'dislikes', 'netScore', 'actions'];

 
  constructor(private videoService: VideosService, private toastr: ToastrService) { }

  ngOnInit() {
    this.loading = true;
    this.videoService.getAllVideos().subscribe(
      data => {
        this.loading = false;
        this.returnedVideoFromServer = data;
        this.dataSource = new MatTableDataSource(this.returnedVideoFromServer);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;


    });


  }

  deleteVideo(video) {
    this.videoService.deleteVideo(video._id).subscribe( videoData => {

      this._returnedVideoFromServer = this._returnedVideoFromServer.filter( videoData => videoData.id !== video.id);
      
      this.dataSource = new MatTableDataSource(this._returnedVideoFromServer);
      this.toastr.success(`${video.name} has been deleted`)

      if(!this._returnedVideoFromServer.length) {
        this.displayForm.emit(true)

      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousIndex = this.dataSource.data.findIndex(row => row === event.item.data);
    moveItemInArray(this.dataSource.data,previousIndex, event.currentIndex);
    this.dataSource.data = this.dataSource.data.slice();
  }


  

  // calculateNetScore(videos) {


  //   videos.forEach( id => {
  //     let netScore = 0;
  //     let likes = id.adminsWhoHaveLikedTheVideo.length;
  //     let dislikes = id.adminsWhoHaveDislikedTheVideo.length;
  //     let net  = dislikes - likes;
  //     netScore = Math.floor( net) ;

  //   })

  // }


}
