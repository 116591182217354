import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from 'src/app/services/survey.service';
import { Admin } from 'src/models/admin';
import { AdminService } from 'src/modules/client-portal/services/admin.service';

@Component({
  selector: 'app-external-survey-portal',
  templateUrl: './external-survey-portal.component.html',
  styleUrls: ['./external-survey-portal.component.sass']
})
export class ExternalSurveyPortalComponent implements OnInit {

  admin: Admin;
  branchId: string;
  obmanagerId: string;
  externalSurveyToken: string;

  public gtCrowdUrl = "https://www.g2.com/products/glofox/reviews/start";
  constructor(private surveyService: SurveyService, private toastrService: ToastrService, private adminService: AdminService, private toastr: ToastrService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.externalSurveyToken = this.route.snapshot.params['external_survey_token']

    this.adminService.getAdminBySurveyToken(this.externalSurveyToken)
      .subscribe(adminData => {
        console.log('test', adminData)
        this.admin = adminData.admin;
        this.branchId = adminData.branchId;
        this.obmanagerId = adminData.obmanagerId;

      })
  }

  onSubmitSurvey($event) {
    $event.how_submitted = 'External';
    $event.branchId = this.branchId;
    $event.obmanager = this.obmanagerId;
    $event.has_submitted = true;
    this.surveyService.onAddSurvey($event, this.admin._id)
      .subscribe(surveyRes => {
        if (surveyRes.message === 'Survey Added Succesfully') {
          // this.adminUpdated.emit(surveyRes.admin);
          this.admin = surveyRes.admin;
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          this.toastr.success(`Thanks ${this.admin.firstName}, Your Survey has been Submitted`)
        } else if (surveyRes.message === "Error") {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          this.toastr.error('Error')
        }

      });
  }
  leaveReview() {
    window.open(this.admin.onboardingSurvey.survey_url);
  }
}
