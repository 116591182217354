import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Editor from '../../../../assets/js/ckeditor';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-manager-note',
  templateUrl: './manager-note.component.html',
  styleUrls: ['./manager-note.component.sass']
})
export class ManagerNoteComponent implements OnInit {

  @Input()
  currentNote: string = '';

  newNote = '   ';

  noteFG: FormGroup;

  updateNoteMode= false;
  editor = Editor;

  @Output()
  processNote = new EventEmitter< { note: string, send: boolean } >()

  original: string;

  editorConfig = {
    placeholder: 'Add your note here....',
  };
  constructor() { }

  ngOnInit() {
  }

  displayNote() {

    if (this.currentNote.length) {
      document.getElementById('currentNote').innerHTML = this.currentNote;
    } else {
      document.getElementById('currentNote').innerHTML = 'No Note';
    }

  }

  save() {
    this.updateNoteMode = false;
    this.processNote.emit( { note: this.noteFG.controls.note.value, send: false } );
    this.newNote = undefined;
    this.displayNote();
  }

  saveAndSend() {
    this.updateNoteMode = false;
    this.processNote.emit( { note: this.noteFG.controls.note.value, send: true } );
    this.newNote = undefined;
    this.displayNote();
  }

  cancel() {
    this.updateNoteMode = false;
  }

  editCurrentNote() {
    this.updateNoteMode = true;

    this.noteFG = new FormGroup({
      note: new FormControl(this.currentNote)
    })
  }

  addNewNote() {
    this.updateNoteMode = true;
    // this.newNote = ' Add new note  ';
    this.noteFG = new FormGroup({
      note: new FormControl('')
    })

  }

  

}
