import { Component, OnInit, Input } from '@angular/core';
import { OBManager } from 'src/models/ob-manager';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerService } from '../../services/manager.service';
import { MenuItem } from 'src/models/menu-item';
import { FlexLayoutModule } from "@angular/flex-layout";

@Component({
  selector: 'app-manager-navmenu',
  templateUrl: './manager-navmenu.component.html',
  styleUrls: ['./manager-navmenu.component.sass']
})
export class ManagerNavmenuComponent implements OnInit {

  @Input() manager: OBManager;

  constructor(private auth: AuthenticationService, private managerService: ManagerService) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.managerService.getManager(user.email).subscribe((data: OBManager) => {
        this.manager = data;
      });
    }
  }


  menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'dashboard',
      url: '/manager-dashboard',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Branches',
      icon: 'account_tree',
      url: 'branches',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Track Builder',
      icon: 'construction',
      url: 'track-builder',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Videos',
      icon: 'video_library',
      url: 'videos',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Content Feedback',
      icon: 'grading',
      url: 'content-feedback',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Survey Feedback',
      icon: 'auto_graph',
      url: 'survey-feedback',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true
    },
    {
      label: 'Profile',
      icon: 'account_circle',
      url: 'profile',
      externalRoute: false,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: false
    },
    {
      label: 'Log out',
      icon: 'follow_the_signs',
      url: 'logout',
      externalRoute: true,
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: false
    }
  ];
  logout() {
    this.auth.logout();
  }

  navigate(item) {
    if (item.url === 'logout') {
      this.logout();
    }
  }

  toggleClosed() {
    (document.getElementById('navbarDropDown') as HTMLButtonElement).click();
  }

}
