import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountStatus'
})
export class AccountStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 0:
        return 'On Schedule';
      case 1:
        return 'Behind Schedule';
      case 2:
        return 'Disengaged';
      case 3:
        return 'Ahead of Schedule';
      case 4:
        return 'Fully On-Boarded';
    }
  }

}
