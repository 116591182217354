import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stage } from 'src/models/stage';
import { AdminService } from 'src/modules/client-portal/services/admin.service';
import { Branch } from 'src/models/branch';
import { Job } from 'src/models/job';
import { Router } from '@angular/router';
import { Admin } from 'src/models/admin';

@Component({
  selector: 'app-stage-completion-dialog',
  templateUrl: './stage-completion-dialog.component.html',
  styleUrls: ['./stage-completion-dialog.component.sass']
})
export class StageCompletionDialogComponent implements OnInit {
  
  stage: Stage;
  branch: Branch;
  admin: Admin;



  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  constructor(
    public dialogRef: MatDialogRef<StageCompletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private adminService: AdminService, private router: Router) {

      this.stage = data.stage;
      this.branch = data.branch;
      this.admin = data.admin;
      console.log(this.branch)
    }

    

  onNoClick(): void {
    this.dialogRef.close();
  }


  returnToDashboard() {
    this.router.navigate(['/client-dashboard']);
    this.dialogRef.close();

  }
 
  

}
