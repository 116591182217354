import { Component, Input } from '@angular/core';
import { Admin } from 'src/models/admin';
@Component({
  selector: 'app-submit-review',
  templateUrl: './submit-review.component.html',
  styleUrls: ['./submit-review.component.sass']
})
export class SubmitReviewComponent {
  @Input()
  admin: Admin;

  constructor() { }

  leaveReview() {
    window.open(this.admin.onboardingSurvey.survey_url);
  }
}
