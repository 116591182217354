
import { Branch } from './branch';
import { WebTeamMember } from './web-team-member';

export class WebIntegration {
  _id: string;
  branch: Branch;
  cms: string;
  websiteUrl: string;
  status?: number;
  welcomeEmailSent: boolean;
  dateOfInnitalContact: Date;
  note: string;
  internalNote: string;
  webTeamMember: WebTeamMember;
  isCurrentlyIntegrated: string;
  softwareProvider: string;
  methodOfCmsAccess: string;
  featuresRequested: { classSchedule: boolean, memberships: boolean, courses: boolean, trainers: boolean, facilities: boolean, } = { classSchedule: false, memberships: false, courses: false, trainers: false, facilities: false };
  membershipPlanForLeadCapture: string;
  hasWebsite: boolean = false;
  hasSubmittedBasicInfo: boolean = false;
  selfIntegrating: boolean = false;
  sentToWebTeam: boolean = false;
  contactedAboutFailedQA: boolean;
  passedQA: boolean;
  LCSetUp: boolean;
  innitialHelpFromWebTeam = false;

  features: { feature: string, requested: boolean, pageTitle: string }[] = [
    { requested: true, feature: 'Class Schedule', pageTitle: 'Schedule' },
    { requested: true, feature: 'Memberships', pageTitle: 'Pricing' },
    { requested: true, feature: 'Courses', pageTitle: 'Courses' },
    { requested: true, feature: 'Trainers', pageTitle: 'Personal Trainers' },
    { requested: true, feature: 'Facilities', pageTitle: 'Rent our Space' }
  ];
  pagePublicationMethod: string; // asap, self, inFuture
  publicationDate: Date;

  integrationRequestSubmitted = false;
  jiraIssueKey: string;
}
