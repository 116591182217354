import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import { Branch } from "src/models/branch";
import { Admin } from "src/models/admin";
import { BranchService } from "src/modules/manager-portal/services/branch.service";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-new-branch-parent",
  templateUrl: "./new-branch-parent.component.html",
  styleUrls: ["./new-branch-parent.component.sass"],
})
export class NewBranchParentComponent implements OnInit {
  branch: Branch;

  @Input()
  managerId: string;
  admins: Admin[] = [];

  @ViewChild("stepper") private myStepper: MatStepper;

  validBranch = false;
  validAdmin = false;

  @Output() branchSubmitted = new EventEmitter<Branch>();

  constructor(private branchService: BranchService) { }

  ngOnInit() { }
  onBranchCreated(newbranch: Branch) {
    this.myStepper.next();
    this.branch = newbranch;

    this.branch.admins = [];
    this.validBranch = true;
  }
  onAdminCreated(admin: Admin) {
    this.admins.push(admin);
    this.validAdmin = true;
    this.branch.admins = this.admins;
    this.branch._obmanager = this.managerId;
    this.branchService.createBranch(this.branch).subscribe((newbranch) => {
      console.log(newbranch);
      this.branch = newbranch;
      this.myStepper.next();
      this.admins.forEach((adm) => {
        this.branchService.registerAdmin(adm).subscribe();
      });
    });
  }
  submitBranch() {
    if (this.branch) {
      this.branchSubmitted.emit(this.branch);
      console.log(this.branch);
    } else {
      console.log("nada");
    }
  }
}
