import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserDetails, TokenPayload, TokenResponse } from 'src/models/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private token: string;
  private SERVER_URL = environment.SERVER_URL;


  constructor(private http: HttpClient, private router: Router) { }


  public login(user: TokenPayload): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/login`, user).pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );
  }

  public register(user: TokenPayload): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/register`, user).pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );
  }

  public profile(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${this.token}`);
    return this.http.get(`${this.SERVER_URL}/profile`, {headers}).pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );
  }

  private saveToken(token: string) {
    localStorage.setItem('glofox-token', token);
    this.token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('glofox-token');
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  selfResgister(admin: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/self-register`, {admin});
  }

 

  requestPasswordResetLink(adminEmail: string, domain?: string): Observable<any> {
    if (domain) {
      return this.http.get(`${this.SERVER_URL}/forgot-password/request-email/${adminEmail}?domain=${domain}`);
    } else {
      return this.http.get(`${this.SERVER_URL}/forgot-password/request-email/${adminEmail}`);

    }
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('glofox-token');
    this.router.navigateByUrl('/');
  }
}
