export class LeadCaptureData {

  branchId: string;
  membershipId: string;
  planId: string;

  constructor(branchId: string, membershipId: string, planId: string) {

    this.branchId = branchId;
    this.membershipId = membershipId;
    this.planId = planId;
    }
}


