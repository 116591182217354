<div class="form_header">Standalone App Upsell Form</div>
<p>This form is for fully onboarded clients that have recently purchased the Standalone App package</p>
<hr style="width: 90%;">
<form [formGroup]="standaloneAppUpsellFG">
  <div class="form_row">
    <div class="form-element">
      <mat-form-field class="form_input" color="accent">
        <mat-label>Please enter a Branch ID</mat-label>
        <input matInput placeholder="Please enter a Branch Id" formControlName="branchId">
        <mat-error *ngIf="
      !standaloneAppUpsellFG.get('branchId').valid &&
      standaloneAppUpsellFG.get('branchId').touched
    ">Please enter a Branch Id</mat-error>
      </mat-form-field>
    </div>
    <div class="form-element">
      <mat-form-field class="form_input" color="accent">
        <mat-label>Please enter a Branch Name</mat-label>
        <input matInput placeholder="Please enter a Branch Name"  formControlName="branchName">
        <mat-error *ngIf="
      !standaloneAppUpsellFG.get('branchName').valid &&
      standaloneAppUpsellFG.get('branchName').touched
    ">Please enter a Branch Name</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="form_row">
    <div class="form-element">
      <mat-form-field class="form_input" color="accent">
        <mat-label>Please enter the Clients First Name</mat-label>
        <input matInput placeholder="John Doe" formControlName="adminFirstName">
        <mat-error *ngIf="
      !standaloneAppUpsellFG.get('adminFirstName').valid &&
      standaloneAppUpsellFG.get('adminFirstName').touched
    ">Please enter the Clients First Name</mat-error>
      </mat-form-field>
    </div>
    <div class="form-element">
      <mat-form-field class="form_input" color="accent">
        <mat-label>Please enter the Clients Last Name</mat-label>
        <input matInput placeholder="John Doe" formControlName="adminLastName">
        <mat-error *ngIf="
      !standaloneAppUpsellFG.get('adminLastName').valid &&
      standaloneAppUpsellFG.get('adminLastName').touched
    ">Please enter the Clients Last Name</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="form_row">
    <div class="form-element">
      <mat-form-field class="form_input" color="accent">
        <mat-label>Please enter the Clients Email Address</mat-label>
        <input matInput placeholder="johndoe@user.com" type="email" formControlName="adminEmail">
        <mat-error *ngIf="
      !standaloneAppUpsellFG.get('adminEmail').valid &&
      standaloneAppUpsellFG.get('adminEmail').touched
    ">Please enter the Admins Email Address</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="formBtnContainer">
    <button mat-stroked-button color="warn" class="mr-3"  [mat-dialog-close]="true">Cancel</button>
    <button mat-raised-button [disabled]="standaloneAppUpsellFG.invalid" color="primary" (click)="onSubmitAppDetail()">Submit</button>
  </div>
</form>
