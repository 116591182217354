<div class="loading" *ngIf="loading">
  <div>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<div *ngIf="!loading" class="container" style="height:100%;">
  <div class="row">
    <div class="col-12 intro-text">
      <h3>Integrating Glofox with your Squarespace Website</h3>
      <hr />
        
        <div class="pt-3 pb-3">
          <strong >NB: Your Squarespace subscription must be Business plan or higher.</strong>
        </div>
        
       <div class="pb-3">
         You can contact <b><a href="mailto:webdevelopment@glofox.com?Subject=Web%20query" target="_top">webdevelopment@glofox.com</a></b> for further
         queries.
       </div>
      
       <button type="button" mat-raised-button (click)="scrollToFAQ()">Read our Squarespace Integration Guide</button>
       
       <a href="https://support.squarespace.com/hc/en-us/articles/206795137" target="_blank" class="ml-3"><button type="button" mat-raised-button>Visit the Squarespace Knowledge Base</button></a>

    </div>
  </div>
  <hr />
  <div class="row">
    <!-- <div class="wistia_responsive_wrapper" style="height:100%;left:0; top:0;width:100%;"><iframe
      src="https://fast.wistia.net/embed/iframe/y78ikzyzif?videoFoam=true" title="SquareSpace Class Schedule Video"
      allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen
      mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script> -->
    <div style="width:100%;height:0px;position:relative;padding-bottom:53.125%;"><iframe src="https://streamable.com/e/etcoq7" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>

  </div>
  <div class="row mt-4" id="integration-tips">
    <div class="col-12 mt-3">
      <h4>Squarespace Integration Guide</h4>
      <hr>
    </div>

    <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Adding Glofox to your Squarespace Website.</h5>
      <div class="row">
        <div class="col-12">
          <div>
            <div class="pt-2 pb-2">Integrating Glofox with your Squarespace website is as simple as pasting a code snippet into Squarespace's code element.</div>
            <div>
              Just follow these steps:
            </div>
          </div>

          <ol class="mt-2">
            <li>
              <div>
                Select the page that you wish to add Glofox to and click 'Edit' on the section where you wish to add the Glofox feature.
            </div>
            <img class="pt-3 pb-3" src="../../../../assets/images/ss_edit_page.png" alt="">
            </li>
            <li><div>Next, click the + icon, to add some content to the page.</div>
              <img class="pt-3 pb-3" style="width: 100%" src="../../../../assets/images/ss_add_content.png" alt="">
            </li>
            <li>
              <div>
                Next, find the 'code' element by typing 'code' into the search field 
              </div>
              <img class="pt-3 pb-3" src="../../../../assets/images/ss_add_code.png" alt="">
            </li>
            <li>
              <div>
                Now you are going to paste the relevant code snippet from the 'Website Code Snippets' page (see the menu on the left), into the textarea on Squarespace. <br>
                Then hit 'Apply'.
              </div>
              <img class="pt-3 pb-3" src="../../../../assets/images/ss_apply_code.png" alt="">

            </li>
            <li><div>
              Finally hit 'Save'.
            </div>
            <img style="width: 100%" class="pt-3 pb-3" src="../../../../assets/images/ss_save_page.png" alt="">
          
          </li>
          <li>
            In order to preview the feature you added, please click onto another one of your pages and then back onto the page you just edited.
          </li>
            
          </ol>
        </div>

      </div>
      
    </div>
      
    <div class="col-12 p-3" style="background-color: #fff;">
        
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Disable AJAX Loading</h5>
      <div class="pt-2 pb-2"> In order to make sure that your Glofox integration displays correctly, please make sure 'Enable AJAX Loading' is not toggled on. </div>
      <div class="pt-2 pb-2"> You can find this setting at Design -> Site Styles -> Enable AJAX Loading </div>

      <div class="row mt-2">
        <div class="col">
          <img style="" src="../../../../assets/images/ss_ajax_1.png" alt="">
        </div>
        <div class="col">
          <img style="" src="../../../../assets/images/ss_ajax_2.png" alt="">
        </div>
        <div class="col">
          <img style="" src="../../../../assets/images/ss_ajax_3.png" alt="">
        </div>
      </div>
    </div>

  </div>
</div>
