<div class="container" style="font-family: Soleil, sans-serif">
  <div class="row">
    <div class="form-container col-xs-12 col-md-12 col-lg-10">
      <div class="form-container">
        <div style="width: 100%">
          <form [formGroup]="appDetailsFG" (ngSubmit)="submitAppDetails()">
            <div class="mt-2" style="width: 100%">
              <div style="padding-left: 5px">
                <h5>1. {{ "app.config.app_name_field" | translate }}</h5>
                <div class="mb-3">
                  {{ "app.config.name_must_be_unique" | translate }}
                </div>
              </div>
              <div class="d-flex">
                <mat-form-field class="pr-3 pl-3" style="width: 80%">
                  <input matInput placeholder="App Name" formControlName="appName" />
                  <mat-error *ngIf="!appDetailsFG.get('appName').valid && appDetailsFG.get('appName').touched">{{ "app.config.please_enter_app_name" | translate }}</mat-error>
                </mat-form-field>
                <div>
                  <button mat-raised-button type="button" style="min-width: 80px" [disabled]="!appDetailsFG.get('appName').valid" (click)="checkAppNameIsUnique()">
                    <span *ngIf="!checkingIfNameIsUnique">Check Availability</span>
                    <div *ngIf="checkingIfNameIsUnique" style="width: 100px">
                      <img class="loadingIcon" src="https://img.icons8.com/material-two-tone/24/000000/loading.png" />
                    </div>
                  </button>
                </div>
              </div>
              <div class="pl-3">
                <div class="small" style="color: #eb7631" *ngIf="appNameChecked && !uniqueAppName">
                  <strong>Oh No!</strong>
                  {{ "app.config.app_name_not_unique" | translate }}
                </div>
                <div class="small text-success" *ngIf="appNameChecked && uniqueAppName">
                  {{ "app.config.app_name_is_unique" | translate }}
                </div>
              </div>
              <div>
                <div style="padding-left: 5px">
                  <h5 class="mt-4">
                   2. {{ "app.config.apple_dev_acc_info" | translate }}
                  </h5>
                  <div class="mb-3">
                    {{ "app.config.where_to_find_info" | translate }}
                    <a href="https://developer.apple.com/account" target="_blank">{{ "app.config.app_dev_program_acc" | translate }}</a>
                  </div>
                </div>
                <mat-form-field style="font-family: Soleil" class="pr-3 pl-3" style="width: 100%">
                  <input style="font-family: Soleil" matInput placeholder="Apple Team ID" formControlName="teamId" />
                  <mat-error *ngIf="!appDetailsFG.get('teamId').valid && appDetailsFG.get('teamId').touched">{{ "app.config.please_enter_apple_team_id" | translate }}</mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="pr-3 pl-3" style="width: 100%">
                  <input matInput placeholder="Apple Developer Account Name" formControlName="appleDeveloperAccountName" />
                  <mat-error *ngIf="!appDetailsFG.get('appleDeveloperAccountName').valid && appDetailsFG.get('appleDeveloperAccountName').touched">{{ "app.config.please_enter_apple_account_name" | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <button [disabled]="appDetailsFG.invalid" mat-raised-button type="submit">
                {{ "app.config.save_and_continue" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
