import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stage-test',
  templateUrl: './stage-test.component.html',
  styleUrls: ['./stage-test.component.sass']
})
export class StageTestComponent implements OnInit {

  showResults= false;  
  result = undefined;

  questions: {question: string, options: {potentialAnswer: string, isCorrect: boolean, selected: boolean}[], gotCorrect?: boolean, followUpMsg: string}[] = [
    {question: 'Glofox is the best fitness software provider on the market?', options: [
      { potentialAnswer: 'Yes', isCorrect: true, selected: false },
      { potentialAnswer: 'No', isCorrect: false, selected: false },
    ], followUpMsg: 'Please review this article: link here'},
    {question: 'Glofox has integrations with which of the following payment providers?', options: [
      { potentialAnswer: 'Stripe', isCorrect: true, selected: false  },
      { potentialAnswer: 'Paypal', isCorrect: false, selected: false  },
      { potentialAnswer: 'GoCardless', isCorrect: true, selected: false  },
      { potentialAnswer: "Dave's Payments", isCorrect: false, selected: false  },
    ], followUpMsg: 'Please review this article on Glofox Payment Integrations: link here'},
    {question: "Glofox aims to have new clients using the platform in how many days?", options: [
      { potentialAnswer: '21', isCorrect: false, selected: false  },
      { potentialAnswer: '30', isCorrect: true, selected: false  },
      { potentialAnswer: '40', isCorrect: false, selected: false  },
    ], followUpMsg: 'Please review this article on Glofox onboarding: link here'},
    {question: "Studio's can communicate with their member's by which of the following mediums?", options: [
      { potentialAnswer: 'Email', isCorrect: true, selected: false  },
      { potentialAnswer: 'SMS', isCorrect: true, selected: false  },
      { potentialAnswer: 'Carrier Pidgeon', isCorrect: false, selected: false  },
      { potentialAnswer: 'Push Notification', isCorrect: true, selected: false  },
    ], followUpMsg: 'Please review this article on Glofox communication tools: link here'},
  ];

  constructor() { }

  ngOnInit() {
  }

  toggleAsAnswer(question: any, potentialAnswer: any): void {
    const multipleCorrectAnswers = question.options.filter(ans => ans.isCorrect).length > 1;
    if (!multipleCorrectAnswers) {
      question.options.forEach(opt => opt.selected = false);
    } 
    potentialAnswer.selected = !potentialAnswer.selected;
  }

  hasAnsweredAllQuestions(): boolean {
    let result = true;
    this.questions.forEach(q => {
      const allOptionsUnselected = q.options.every(o => !o.selected);
      if (allOptionsUnselected) {
        result = false;
      }
    });
    return result;
  }


  checkAnswers() {
    let nCorrect = 0;
    this.questions.forEach(question => {
      let correctOptions = question.options.filter(opt => opt.isCorrect);
      let wrongOptions =  question.options.filter(opt => !opt.isCorrect);
      if (correctOptions.every(opt => opt.selected) && wrongOptions.every(opt => !opt.selected)) {
        question.gotCorrect = true;
        nCorrect++;
      } else {
        question.gotCorrect = false;
      }
    })
    this.showResults = true;
    this.result = nCorrect / this.questions.length * 100;
  }

}
