import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerService } from '../../services/manager.service';
import { OBManager } from 'src/models/ob-manager';
import { Branch } from 'src/models/branch';
import { log } from 'util';

@Component({
  selector: 'app-my-branches',
  templateUrl: './my-branches.component.html',
  styleUrls: ['./my-branches.component.sass']
})
export class MyBranchesComponent implements OnInit {

  manager: OBManager;
  branches: Branch[] = [];
  filteredBranches: Branch[];

  constructor(private auth: AuthenticationService, private managerService: ManagerService) { }

  ngOnInit() {
    this.filteredBranches = this.branches;
    if (this.auth.isLoggedIn()) {
      const user = this.auth.getUserDetails();
      this.managerService.getManager(user.email).subscribe((data: OBManager) => {
        this.manager = data;
        const _branches = [];
        this.managerService.getBranchesForManager(this.manager._id).subscribe(data => {
          data.forEach(element => {
            const b = new Branch();
            b._id = element._id;
            b.name = element.name;
            b.goLiveDate = element.goLiveDate;
            b.lastOnlineAt = element.lastOnlineAt;
            b.joblist = element.joblist;
            b.onboardingOptions = element.onboardingOptions;
            b.obmanager = element.obmanager;
            b.disengaged = element.disengaged;
            b.fullyOnBoarded = element.fullyOnBoarded;
            _branches.push(b);
          });
          this.branches = _branches;
        });
      });
    }
  }

  

}
