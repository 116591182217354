import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-standalone-app-upsell-dialog',
  templateUrl: './new-standalone-app-upsell-dialog.component.html',
  styleUrls: ['./new-standalone-app-upsell-dialog.component.sass']
})
export class NewStandaloneAppUpsellDialogComponent implements OnInit {

  managerId: string;

  constructor(
    public dialogRef: MatDialogRef<NewStandaloneAppUpsellDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.managerId = data.managerId;
     }

  ngOnInit() {
    // console.log('manager1', this.managerId)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAppCreated($event) {
    this.dialogRef.close($event);

}

}
