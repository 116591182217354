
<div mat-dialog-title>
    <h5>Edit Branch</h5>
    <hr class="mt-0 mb-0">
</div>
<div mat-dialog-content>
    <div class="container mt-1">
        <form [formGroup]="updateBranchFG"  (ngSubmit)="updateBranch()">
          <div>
             
                  <mat-form-field style="width: 45%" class="mb-3 mr-2">
                    <input matInput placeholder="Branch Name" formControlName="name" />
                    <mat-error *ngIf="
                        !updateBranchFG.get('name').valid &&
                        updateBranchFG.get('name').touched
                      ">Please enter  branch name</mat-error>
                  </mat-form-field>
                  <mat-form-field style="width: 45%"  class="mb-3">
                      <input matInput placeholder="Glofox Branch ID" formControlName="branchId" />
                      <mat-error *ngIf="
                          !updateBranchFG.get('branchId').valid &&
                          updateBranchFG.get('branchId').touched
                        ">Please enter a Glofox Branch Id</mat-error>
                    </mat-form-field>
            
    
          <div class="mb-2">
            <button class="mr-2" mat-raised-button (click)="onNoClick()">Cancel</button>
            <button [disabled]="updateBranchFG.invalid" mat-raised-button color="accent">
              Save
            </button>
            <!-- <button *ngIf="emailCheckPending" [disabled]="adminCreateForm.invalid" mat-raised-button color="accent">
              <fa-icon [icon]="faLoading" [spin]="true"></fa-icon>
            </button> -->
          </div>
        </div>
        </form>
      </div>
      
</div>
<!-- <div mat-dialog-actions class="d-flex justify-content-center">
  <button class="btn btn-info mr-3" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-success" [mat-dialog-close]="{}" >Save</button>
</div> -->