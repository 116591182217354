<mat-card class="mb-2 " *ngIf="branch">
    <div class="card_wrapper ">
        <div class="ob_profile_photo">
            <div class="outterCircle"
                [ngClass]="{'outterCircle': !showManagerNote, 'outterCircle_closed': showManagerNote }">
                <img src="{{ branch.obmanager.imageURL }}"
                    [ngClass]="{'manager_img_open': showManagerNote, 'manager_img_closed': !showManagerNote }" />
            </div>
        </div>
        <div class="ob_details_wrapper" *ngIf="!showManagerNote">
            <div class="title">{{"manager_card.your_onbaording_manager" | translate}}</div>
            <div class="ob_name">{{branch.obmanager.firstName}} {{branch.obmanager.lastName}}</div>
            <div class="email">{{branch.obmanager.email}}</div>
        </div>
        <div class="ob_details_wrapper" *ngIf="showManagerNote">
            <div *ngIf="branch.managerNote.note && !branch.managerNote.dismissed">
                <app-manager-note [branch]="branch"></app-manager-note>
            </div>
        </div>
    </div>
    <div class="btn_container">
        <span class="spacer"></span>
        <div *ngIf="admin && admin.canBookCalls && branch.obmanager.calendlyURL">
            <button *ngIf="!showManagerNote" mat-stroked-button (click)="openCalendlyPopUp()" class="mr-1">
                <p style="margin: auto;">{{'client_nav.book_call' | translate}}</p>
            </button>
        </div>
        <div
            *ngIf="branch && branch.managerNote && branch.managerNote.note.length > 0 && !branch.managerNote.dismissed">
            <button *ngIf="!showManagerNote" mat-stroked-button
                (click)="onDisplayMessage()">{{"manager_card.messages" | translate}} <mat-icon
                    *ngIf="!branch.managerNote.seen" matBadge="1" matBadgeColor="warn">email</mat-icon></button>

        </div>
    </div>
    <div class="cancelBtnContainer">
        <button *ngIf="showManagerNote && !branch.managerNote.seen" mat-stroked-button (click)="updateNoteAsSeen()"
            color="primary">{{"manager_card.mark_as_seen" | translate}} <mat-icon color="warn">remove_red_eye</mat-icon>
            </button>
        <button *ngIf="showManagerNote && branch.managerNote.seen" mat-stroked-button (click)="updateNoteAsSeen()"
            color="primary">{{"manager_card.close" | translate}} <mat-icon color="warn">close</mat-icon></button>
    </div>
</mat-card>


