import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Test } from 'src/models/test';
import { Subscription, Observable, Subject } from 'rxjs';
import { TrackBuilderService } from '../../services/track-builder.service';
import { Question } from 'src/models/question';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TrackService } from 'src/app/services/track.service';
import { TestService } from 'src/app/services/test.service';
import { ToastrService } from 'ngx-toastr';
import { Track } from 'src/models/track';
import { TranslatorService } from 'src/app/services/translator.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.sass']
})
export class QuestionListComponent implements OnInit, OnDestroy {

  @Input()
  test: Test;

  @Input()
  track: Track;

  
  public set selectedQuestion(v : Question) {
    this._selectedQuestion = v;
    this.createQuestionFG = this.fb.group({
      question: this.fb.control(this._selectedQuestion.question),
      followUpMsg: this.fb.control(this._selectedQuestion.followUpMsg),
      options: this.fb.array(
        this._selectedQuestion.options.map(opt => {
          return this.fb.group({
            potentialAnswer: this.fb.control(opt.potentialAnswer, [Validators.required]),
            isCorrect: this.fb.control(opt.isCorrect)
          })
        })
      )
    })
  }

  public get selectedQuestion(): Question {
    return  this._selectedQuestion;
  }
  
  _selectedQuestion: Question;
  
  private unsubscribe$ = new Subject<void>();
  subscription: Subscription = new Subscription();

  createQuestionFG: FormGroup;



  constructor(private trackBuilderService: TrackBuilderService, private fb: FormBuilder, private testService: TestService, private toastr: ToastrService, private translator: TranslatorService, private changeRef: ChangeDetectorRef) {
    this.trackBuilderService.selectedQuestionChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.selectedQuestion = data;
      this.changeRef.detectChanges();
    })
   }

  ngOnInit() {
    this.selectedQuestion = this.test.questions[0];
  }




  createQuestion() {
    this.createQuestionFG = this.fb.group({
      question: this.fb.control(''),
      followUpMsg: this.fb.control(''),
      options: this.fb.array([
        this.fb.group({
          potentialAnswer: this.fb.control('', [Validators.required]),
          isCorrect: this.fb.control(false)
        }),
        this.fb.group({
          potentialAnswer: this.fb.control('', [Validators.required]),
          isCorrect: this.fb.control(false)
        })
      ])
    })
    // this.addQuestionMode = true;

  }

  editQuestion(question: Question) {
    // this.selectedQuestion = question;
    this.createQuestionFG = this.fb.group({
      question: this.fb.control(question.question),
      followUpMsg: this.fb.control(question.followUpMsg),
      options: this.fb.array(
        question.options.map(opt => {
          return this.fb.group({
            potentialAnswer: this.fb.control(opt.potentialAnswer, [Validators.required]),
            isCorrect: this.fb.control(opt.isCorrect)
          })
        })
      )
    })
    // this.editQuestionMode = true;

  }

  getOptions() {
    return this.createQuestionFG.get('options') as FormArray;
  }

  removePotentialAnswer(optIndex: number) {
    const options = this.getOptions().controls;
    options.splice(optIndex,1);
  }

  saveQuestion() {

    // if (this.addQuestionMode) {
    //    question = new Question();
    // } else {
    //    question = this.selectedQuestion;
    // }
    this.selectedQuestion.question = this.createQuestionFG.controls.question.value;
    this.selectedQuestion.followUpMsg = this.createQuestionFG.controls.followUpMsg.value;
    this.selectedQuestion.options = [];
    this.getOptions().controls.forEach(opt => {
      this.selectedQuestion.options.push({ 'potentialAnswer': (opt as FormGroup).controls.potentialAnswer.value, 'isCorrect': (opt as FormGroup).controls.isCorrect.value })
    })

    if (!this.selectedQuestion._id) {
      this.testService.addQuestionToTest(this.test._id, this.selectedQuestion).subscribe(data => {

        this.selectedQuestion = data;
        this.trackBuilderService.addNewQuestion(data);
        this.toastr.success('Question Saved.')

      })
   } else {
    this.testService.updateQuestion(this.selectedQuestion).subscribe(data => {
      // this.selectedQuestion = data;
      this.toastr.success('Question Updated.')

    })
   }

  //  this.addQuestionMode = false;
  //  this.editQuestionMode = false;

  }

  addPotentialAnswer() {
    console.log('hit ad potential arr');
    
    let optionsArray = this.createQuestionFG.controls.options as FormArray;

    let newOption: FormGroup = this.fb.group({
      potentialAnswer: this.fb.control(''),
      isCorrect: this.fb.control(false)
    })

    optionsArray.insert(optionsArray.length, newOption);
  }

  deleteQuestion(question: Question): void {
    this.testService.deleteQuestion(question).subscribe(data => {
      this.trackBuilderService.deleteQuestion(this.selectedQuestion);
      this.toastr.success('Question Deleted');
    })
  }

  saveTranslation() {
    this.testService.saveQuestionTranslation(this.selectedQuestion).subscribe(data => {
      this.toastr.success('Translation Saved!')
    })
  }

  translateQuestion() {
    this.translator.translateQuestion(this.selectedQuestion, this.track.translations).subscribe(data => {
      let questionTranslation = data[0];
      // let detailsTranslation = data[1];
      console.log(1, this.selectedQuestion.translations);

      this.track.translations.forEach(lang => {
        let trans = this.getTranslation(lang);
        if (!trans || !trans.translation) {
          trans = {language: lang, translation :{question: '', options: []} }
          // trans.translation.description = descriptionTranslation.translations.find(descTrans=> descTrans.to === lang).text;
          trans.translation.question = questionTranslation.translations.find(questTrans=> questTrans.to === lang).text;
          for (let index = 1; index < data.length; index++) {
            const optionTranslation = data[index];
            trans.translation.options.push(optionTranslation.translations.find(optTrans => optTrans.to === lang).text);
          }
          this.selectedQuestion.translations.push(trans);
        } else {
          // trans.translation.description = descriptionTranslation.translations.find(descTrans=> descTrans.to === lang).text;
          trans.translation.question = questionTranslation.translations.find(questTrans=> questTrans.to === lang).text;
          trans.translation.options = [];
          for (let index = 1; index < data.length; index++) {
            const optionTranslation = data[index];
         
            trans.translation.options.push(optionTranslation.translations.find(optTrans => optTrans.to === lang).text);
          }
        }
      })
      console.log(2, this.selectedQuestion.translations);
      

    })
  }

  getTranslation(lang: string) {

    return this.selectedQuestion.translations.find(trans => trans.language === lang);
  }

  getAnswerLabel(optIndex: number): string {
    return String.fromCharCode(65 + optIndex);
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
}
