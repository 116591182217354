<div class="container p-0" >
  <!-- <div class="jumbotron d-flex justify-content-between p-4" >
    <h3></h3>
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn btn-secondary active roleSelector clickable" (click)="filterBranches(-1)">
        <input type="radio" name="options" id="option1" autocomplete="off" checked />
        All
      </label>
      <label class="btn btn-secondary roleSelector clickable" (click)="filterBranches(0)">
        <input type="radio" name="options" id="option2" autocomplete="off" />
        In Progress
      </label>
      <label class="btn btn-secondary roleSelector clickable" (click)="filterBranches(1)">
        <input type="radio" name="options" id="option3" autocomplete="off" />
        Disengaged
      </label>
      <label class="btn btn-secondary roleSelector clickable  " (click)="filterBranches(2)">
        <input type="radio" name="options" id="option4" autocomplete="off" />
        Fully On-Boarded
      </label>
    </div>
  </div> -->
  <app-branch-list style="padding-top: 2rem; padding-bottom: 2rem;" *ngIf="filteredBranches" [branches]="branches" ></app-branch-list>
</div>
