import { Injectable } from '@angular/core';

const ICONS: {name: string, path: string}[] = [
    {name: 'get started', path: '../../assets/images/card_icons/get_started.svg'},
    {name: 'classes', path: '../../assets/images/card_icons/classes.svg'},
    {name: 'manage you members', path: '../../assets/images/card_icons/manage_your_members.svg'},
    {name: 'memberships', path: '../../assets/images/card_icons/memberships.svg'},
    {name: 'schedule', path: '../../assets/images/card_icons/schedule.svg'},
    {name: 'debt recovery and payments', path: '../../assets/images/card_icons/debt_recovery.svg'},
    {name: 'payment processing', path: '../../assets/images/card_icons/payment_processing.svg'},
    {name: 'migration info', path: '../../assets/images/card_icons/migration_info.svg'},
    {name: 'reporting', path: '../../assets/images/card_icons/reporting.svg'},
    {name: 'store', path: '../../assets/images/card_icons/store.svg'},
    {name: 'communicating with members', path: '../../assets/images/card_icons/communicating_with_members.svg'},
    {name: 'access', path: '../../assets/images/card_icons/access.svg'},
    {name: 'personal training', path: '../../assets/images/card_icons/personal_training.svg'},
    {name: 'new features', path: '../../assets/images/card_icons/new_features.svg'},
    {name: 'glofox pro', path: '../../assets/images/card_icons/glofox_pro.svg' },
    {name: 'communications', path: '../../assets/images/card_icons/communications.svg' },
    {name: 'members journey', path: '../../assets/images/card_icons/members_journey.svg' },
    {name: 'membership updates', path: '../../assets/images/card_icons/making_changes_to_members_membership.svg'},
    {name: 'code', path: '../../assets/images/card_icons/code.svg'},
    {name: 'dashboard', path: '../../assets/images/card_icons/dashboard.svg'},
    {name: 'advanced settings', path: '../../assets/images/card_icons/advanced_settings.svg'},
    {name: 'default', path: '../../assets/images/card_icons/default.svg'},
]


@Injectable({
    providedIn: 'root'
  })
  export class StageIconService {

    getIcons() {
        return ICONS;
    }

    getIconList() {
        return ICONS.keys();
    }

    getIcon(name: string): string {
        const icon = ICONS.find(i => i.name === name);
        return icon ? icon.path : ICONS.find(i => i.name === 'default').path;
    }

  }
