
<div *ngIf="loading"  class="loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
  <!-- <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div> -->
  <table mat-table [dataSource]="dataSource" matSort cdkDropList class="example-list" #table (cdkDropListDropped)="drop($event)" class="mat-elevation-z8"   >
      <!-- Weight Column -->
      <ng-container matColumnDef="videoName" >
        <th mat-header-cell *matHeaderCellDef class="branch_th" style="width: 15% !important;"> Video Name</th>
        <td mat-cell *matCellDef="let video" >
          <div><a href="http://youtube.com/watch?v={{video.id}}" target="_blank">{{video.name}}</a></div>
        </td>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="videoId">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Video Id </th>
        <td mat-cell *matCellDef="let video" style="text-align: center;" >
          {{video.id}}

        </td>
      </ng-container>
      <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef style="text-align: center!important;">Category</th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
              {{video.category}}
          </td>
        </ng-container>
        <ng-container matColumnDef="nViews">
          <th mat-header-cell *matHeaderCellDef style="text-align: center!important;"># Views</th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
            {{video.nViews}}
          </td>
        </ng-container>
        <ng-container matColumnDef="likes">
          <th mat-header-cell *matHeaderCellDef style="text-align: center!important;">Likes</th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
              {{video.nLikes}}
          </td>
        </ng-container>
        <ng-container matColumnDef="dislikes">
          <th mat-header-cell *matHeaderCellDef style="text-align: center!important;">Dislikes</th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
              {{video.nDislikes}}
          </td>
        </ng-container>
        <ng-container matColumnDef="netScore">
          <th mat-header-cell *matHeaderCellDef style="text-align: center!important;">Net Score</th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
            {{video.nLikes - video.nDislikes}}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Actions </th>
          <td mat-cell *matCellDef="let video" style="text-align: center;">
              <button mat-icon-button color="warn"
              class="mr-3" (click)="deleteVideo(video)">&nbsp;<mat-icon color="#fff">close</mat-icon></button>
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-box" cdkDrag [cdkDragData]=row></tr>
    </table>
    <mat-paginator [length]="_returnedVideoFromServer.length" [pageSizeOptions]="[15 ,25, 50, 100, 200]" ></mat-paginator>

