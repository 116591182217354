<div class="container-fluid mt-3 mb-5">
  <div class="row">
    <!-- Unassigned Web Integrations -->
    <div class="col-12 col-md-4">
      <div class="expansion-table mt-5">
        <mat-accordion class="padding-remover">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="unassigned-title">
                  <h5 *ngIf="unassigneds.length" class="table-header-text">Unassigned Web Integrations</h5>
                  <mat-icon matBadge="{{unassigneds.length}}" *ngIf="unassigneds.length" class="ml-3"
                    matBadgeColor="warn">fiber_new</mat-icon>
                </div>
                <div class="unassigned-title">
                  <h5 *ngIf="!unassigneds.length" class="table-header-text">Unassigned Web Integrations</h5>
                  <mat-icon matBadge="{{unassigneds.length}}" *ngIf="unassigneds.length === 0" class="ml-3"
                    matBadgeColor="warn">fiber_new</mat-icon>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table class="mat-elevation-z8 unassignedWebInt" [dataSource]="dataSource" >
              <ng-container matColumnDef="branch.name">
                <th mat-header-cell *matHeaderCellDef>Studio</th>
                <td mat-cell *matCellDef="let unassigned"> {{ unassigned.branch.name }}
                </td>
              </ng-container>
              <ng-container>
                <td mat-cell *matCellDef="let unassigned"> {{unassigned.branch.name}}
                  <p *ngIf="unassigned.branch"> There are no new Web Ints</p>
                </td>
              </ng-container>
              <ng-container matColumnDef="plan">
                <th mat-header-cell *matHeaderCellDef> Plan </th>
                <td mat-cell *matCellDef="let unassigned"> {{unassigned.branch.plan | plan }} </td>
              </ng-container>
              <ng-container matColumnDef="take">
                <th mat-header-cell *matHeaderCellDef> Take It </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-stroked-button color="primary" (click)="takeWebIntegration(element)">Take it</button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedUnassignedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedUnassignedColumns;"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>

      </div>

      <app-web-int-details [selectedWebInt]="selectedWebInt"></app-web-int-details>
    </div>

    <!-- Assigned Web Integrations -->

    <div class="mt-5 col-12 col-md-8">

      <mat-button-toggle-group name="fontStyle" #group="matButtonToggleGroup" aria-label="Font Style">
        <mat-button-toggle value="assigned">Your Web Integrations</mat-button-toggle>
        <mat-button-toggle value="smt">Quality Assurance</mat-button-toggle>
      </mat-button-toggle-group>

      <app-branches-with-smt *ngIf="group.value === 'smt'" (selectWebInt$)="selectWebInt($event)"></app-branches-with-smt>
      <div class="table-container" *ngIf="group.value !== 'smt'">
        <div class="table-header">
          <div class="d-flex align-items-center">
            <h5 class="table-header-text">{{webTeamMember.firstName}}'s Web Integrations</h5>
            <mat-icon matBadge="{{assignedWebInts.length}}" class="ml-3" matBadgeColor="warn">code</mat-icon>
          </div>

          <div class ="webInt-filter-container">
            <form class="d-flex align-items-center">
                <mat-form-field class="example-full-width mr-3 ml-1">
                <mat-label>Search Branches</mat-label>
                <input matInput placeholder="Search Branches" (keyup)="applyFilter($event)" autocomplete="off">
                </mat-form-field>
            <mat-form-field class=" mr-2 ml-2" style=" width: 300px;">
              <mat-label>Web Integration Status</mat-label>
              <mat-select [formControl]="states" (selectionChange)="applyFilters($event)" >
                <mat-option *ngFor="let state of stateList" [value]="state">
                  {{state | webIntStatus}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
          </div>
        </div>
        <table mat-table style="width: 100%;" [dataSource]="assignedDataSource" class="mat-elevation-z8 assignedWebInt"
          matSort  >
          <ng-container matColumnDef="name">
            <th  mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 35% !important;">Name</th>
            <td mat-cell *matCellDef="let assigned">
              <div class="d-flex align-items-center">
                <div [class]="getBranchStatus(assigned)"></div>
                <div class="pl-5"> {{assigned.branch.name}}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header  style="width: 15% !important;"> Plan </th>
            <td mat-cell *matCellDef="let assigned"> {{assigned.branch.plan | plan }} </td>
          </ng-container>

          <ng-container matColumnDef="assigned.status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15% !important;"> Status</th>
            <div class="cetered" style="align-items: center;">
              <td mat-cell *matCellDef="let assigned"> {{assigned.status | webIntStatus}}
                <!-- <div [class]="assigned.status !== undefined ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div> -->
                <!-- con-->
              </td>
            </div>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 14%; text-align:center;">Action</th>
            <td mat-cell *matCellDef="let webIntegration" style="text-align:center;">
              <button mat-stroked-button color="primary" *ngIf="webIntegration.status === 0"
                (click)="sendWelcomeEmail(webIntegration)">Send
                Email</button>
              <p *ngIf="webIntegration.status === 1" class="email-sent">
                {{ webIntegration.dateOfFirstContact | date:'d, MMMM' }}</p>
              <p *ngIf="webIntegration.status === 2" class="email-sent"><button mat-flat-button
                  (click)="viewDetails(webIntegration)">Open CMS</button></p>
              <p *ngIf="webIntegration.status === 3" class="email-sent">Complete 🎉</p>
              <p *ngIf="webIntegration.status === 4" class="email-sent"><button mat-stroked-button style="color:#F44335"
                  (click)="(webIntegration)">Remove</button></p>
            </td>
          </ng-container>
          <ng-container matColumnDef="upDateStatus">
            <th mat-header-cell *matHeaderCellDef style="width: 11% !important; text-align: center;">
              Update</th>
            <div class="cetered" style="text-align:center;">
              <td mat-cell *matCellDef="let assigned" style="text-align: center;">
                <mat-icon aria-hidden="false" id="udpdate-btn" style="text-align: center;" class="udpdate-btn"
                  (click)="decrementStatus(assigned)" aria-label="Example remove icon">remove</mat-icon>
                <mat-icon aria-hidden="false" id="udpdate-btn2" style="text-align: center;" class="udpdate-btn"
                  (click)="incrementStatus(assigned)" aria-label="Example add  icon">add</mat-icon>
              </td>
            </div>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedAssignedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedAssignedColumns" (click)="viewDetails(row)"></tr>
        </table>
      </div>
      <mat-paginator [length]="assignedWebInts.length" [pageSizeOptions]="[15 ,25, 50, 100, 200]" (page)="onChangePage($event)"></mat-paginator>
    </div>
  </div>
</div>
