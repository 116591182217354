
export class Video {
  _id?: string;
  name: string;
  id: string;
  category?: string[] = [];
  hasSeen?: boolean = false;
  hasLiked?: boolean = false;
  hasDisliked?: boolean = false;
  adminsWhoHaveLikedTheVideo?: string[] = [];
  adminsWhoHaveDislikedTheVideo?: string[] = [];

}
