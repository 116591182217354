import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SurveyService } from 'src/app/services/survey.service';
import { Admin } from 'src/models/admin';
import { OBManager } from 'src/models/ob-manager';
import { ManagerService } from 'src/modules/manager-portal/services/manager.service';

@Component({
  selector: 'app-survey-portal',
  templateUrl: './survey-portal.component.html',
  styleUrls: ['./survey-portal.component.sass']
})
export class SurveyPortalComponent implements OnInit {

  constructor(
    private surveyService: SurveyService,
    private managerService: ManagerService,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  adminSurveyData: any[] = [];
  omSurveyAverageScores: any[] = [];

  manager: OBManager;
  onboardingManagers: OBManager[] = [];
  smbOnboardingManagers: any[] = [];



  ngOnInit(): void {


    this.surveyService.getAdminSurveyResponses()
      .subscribe(surveyData => {
        console.log('survey list', surveyData)
        this.adminSurveyData = surveyData.admins;
      })
    this.managerService.getOBManagers()
      .subscribe(managers => {
        console.log('managers', managers)
        this.onboardingManagers = managers;
        this.smbOnboardingManagers = this.onboardingManagers.filter(m => m.market === 0);
        console.log('smb managers', this.smbOnboardingManagers)
      });
    if (this.route.snapshot.queryParams.managerId) {
      this.filterByManager(this.route.snapshot.queryParams.managerId)
    }

  }
  filterByManager($event) {
    console.log('ID', $event)
    const managerId = $event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        managerId
      }
    });
    this.surveyService.getManagerSurveyResponses(managerId)
      .subscribe(data => {
        console.log('Data Frm Srvr', data)
        this.adminSurveyData = data.admins;
        this.omSurveyAverageScores = data.avgScores;
        console.log('SCORES', this.omSurveyAverageScores);
        console.log('new ', this.adminSurveyData);
      });
  }

}
