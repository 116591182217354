import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { WebIntegration } from 'src/models/web-integration';
import { Observable, Observer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebIntService {

  SERVER_URL = environment.SERVER_URL;

  constructor(private http: HttpClient) { }

  requestWebTeamAssistance(webIntId: string, basicInfo: any): Observable<any>  {
    console.log('hits service', webIntId, basicInfo );
    return this.http.post<{ message: string }>(`${this.SERVER_URL}/api/webInts/${webIntId}/requestWebTeamAssistance`, basicInfo );

  }
  submitBasicInfo(webInt: WebIntegration, basicInfo: any): Observable<any>  {
    console.log('hits service', webInt, basicInfo );
    return this.http.post<{ message: string }>(`${this.SERVER_URL}/api/webInts/${webInt._id}/basicInfo`, basicInfo );

  }

  updateWebsiteURL(webInt: WebIntegration): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/webInts/${webInt._id}/updateWebsiteUrl`, {websiteUrl: webInt.websiteUrl});
  }

  submitWebIntDetail(webInt: WebIntegration, webIntDetails: any): Observable <any> {
    console.log('hits service', webInt, webIntDetails);
    return this.http.patch<{ message: string }>(`${this.SERVER_URL}/api/webInts/${webInt._id}/webIntDetails`, webIntDetails);
  }


  assignNoteToWebInt(webIntId: string, noteDetail: string ): Observable<any> {
    // return this.http.post(`${this.SERVER_URL}/api/webInts/${webIntId}/notes`, {webIntId, noteDetail});
    return this.http.patch(`${this.SERVER_URL}/api/webInts/${webIntId}/note`, {noteDetail});

  }

  makeWebIntRequest(webInt: WebIntegration): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/webInts/${webInt._id}/createJiraRequest`, {webInt: webInt});
  }
  // deleteSelectedWebInt(webIntId: string): Observable<any> {
  //   console.log('web id', webIntId);
  //   return this.http.delete(`${this.SERVER_URL}/api/webInts/${webIntId}/delete`);
  // }
}
