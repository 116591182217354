import { Component, OnInit, Inject } from '@angular/core';
import { Branch } from 'src/models/branch';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-branch-dialog',
  templateUrl: './update-branch-dialog.component.html',
  styleUrls: ['./update-branch-dialog.component.sass']
})
export class UpdateBranchDialogComponent implements OnInit {

  branch: Branch;
  plans: number[] = [0, 1, 2];
  updateBranchFG: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UpdateBranchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.branch = data.branch;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log('opened admin dialog');
    this.updateBranchFG = new FormGroup({
      name: new FormControl(this.branch.name, [Validators.required]),
      branchId: new FormControl(this.branch.branchId, [Validators.required, Validators.minLength(10)]),
    })
    
  }

  updateBranch() {
    this.branch.name = this.updateBranchFG.controls.name.value;
    this.branch.branchId = this.updateBranchFG.controls.branchId.value.trim();
    this.dialogRef.close(this.branch);
  }

}
