import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { log } from 'util';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginFormGroup: FormGroup;
  hide = true;
  emailForReset: string = '';

  validationMsg = '';
  unauthorized = false;

  requestedPasswordReset = false;

  loadingIcon = faCircleNotch;

  loginPending = false;

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.loginFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)])
    });

  }


  onFormSubmit(): void {
    this.loginPending = true;
    const email = (this.loginFormGroup.controls['email'].value as string).toLowerCase();
    const password = this.loginFormGroup.controls['password'].value;
    this.authenticationService.login({ email, password }).subscribe(data => {
      const user = this.authenticationService.getUserDetails();
      this.loginPending = false;

      if (user.role === 'manager') {
        this.router.navigate(['/manager-dashboard']);
      } else if (user.role === 'webTeam') {
        this.router.navigate(['/web-team-dashboard']);
      } else {
        this.router.navigate(['/client-dashboard']);
      }
    }, err => {
      if (err.status === 401) {
        this.loginPending = false;
        this.unauthorized = true;
        this.validationMsg = 'Email/Password pair not found.\n Please try again or contact your on-boarding manager.';
      }
    });
  }

  invalidResetEmail(): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(this.emailForReset, re.test(this.emailForReset.toLowerCase()))
    return re.test(this.emailForReset.toLowerCase());
  }

  requestPasswordResetForm(): void {
    this.requestedPasswordReset = true;
  }

  cancelPasswordReset() {
    this.requestedPasswordReset = false;
  }

  requestPasswordReset() {
    this.authenticationService.requestPasswordResetLink(this.emailForReset.toLowerCase()).subscribe(data => {
      if (data.status === 'success') {
        document.getElementById('resetEmailInput').style.display = 'none';
        document.getElementById('resetFeedback').innerHTML = `<div style="text-align: center; width: 100%; color: #fff">Email sent! Please check your inbox.</div>`
      } else {
        this.emailForReset = '';
        document.getElementById('resetFeedback').innerHTML = `<div style="text-align: center; color: #f45f5f; margin-top: .7rem;">${data.message}</div><div style="text-align: center; color: #f45f5f">Please try again.</div>`
      }
    });
  }

}
