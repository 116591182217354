
<mat-dialog-content >
    <div class="row">
        <div class="col-4">
            <ul class="list-group" >
                <li *ngFor="let category of categories" (click)="selectCat(category)" [ngClass]="{'active': category.id === selectedCat.id}" class="list-group-item clickable">{{category.name}}</li>
              </ul>
        </div>
        <div class="col-4" *ngIf="selectedCat">
            <ul class="list-group" >
                <li *ngFor="let section of selectedCat.sections" (click)="selectSect(section)" [ngClass]="{'active': section.id === selectedSect.id}" class="list-group-item clickable">{{section.name}}</li>
              </ul>
        </div>
        <div class="col-4" *ngIf="selectedSect">
            <ul class="list-group" >
                <li *ngFor="let article of selectedSect.articles" class="list-group-item clickable" (click)="selectArticle(article)" [ngClass]="{'list-group-item-success': isSelected(article)}">{{article.title}}</li>
              </ul>
        </div>
    </div>
</mat-dialog-content>