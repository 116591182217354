<div class="container" *ngIf="stage">
  <div class="row">
    <div class="card-container mt-3">
      <a [routerLink]="['stage', stage._id]" class="card">
        <div class="card-content">
          <div>
            <img src="{{ getStageIcon(stage) }}" alt="" />
          </div>
        </div>
        <div class="mb-3 card-title multiLineLabel">
          <span class="textMaxLine"> {{ index }}. {{ getStageTranslation(stage).title }} </span>
        </div>
        <div class="d-flex" style="background-color: #44d8c3; width: 100%; height: 6px" *ngIf="stageProgress > 98"></div>
        <div class="d-flex" style="background-color: rgba(235, 117, 49, 0.205)" *ngIf="stageProgress < 98">
          <div style="height: 6px; background-color: #eb7631; border-radius: 1.5px" [ngStyle]="{ width: getStageProgress(stage) }"></div>
          <!-- <div style="height: 6px; background-color: #EB7631; border-radius: 1.5px; " [ngStyle]="{'width': getStageProgress()}"></div> -->
        </div>
      </a>
    </div>
  </div>
</div>
