<div id="loginFormWrapper" class="container-fluid">
  <div id="branding" style="display: flex; align-items: center; flex-direction:column;
  " >
    <div id="logoWrapper" class="d-flex flex-direction-column  mr-2">
      <img id="logo" src="../../../../assets/images/glofox_login.png" alt="" srcset="" />
    </div>
        <h1 class="header">
          Best-in-Class Software for Building Fitness Empires
        </h1>

    <!-- <div class="col-lg-3 d-none d-lg-block">
      <h1 class="header">
        Best-in-Class Software for Building Fitness Empires
      </h1>
    </div> -->
  </div>
  <div *ngIf="unauthorized" class="text-danger mt-4" style="text-align: center;">
    <p>{{validationMsg}}</p>
  </div>


  <form id="loginForm" class="d-flex flex-column mt-5" [formGroup]="loginFormGroup" *ngIf="!requestedPasswordReset" (ngSubmit)="onFormSubmit()">
    <!-- <div class="input-group">
      <input formControlName="email" type="email" placeholder="Email" />
    </div>
    <div class="input-group">
      <input formControlName="password" placeholder="Password" type="password" />
    </div> -->
    <mat-form-field  style="color: #fff !important">
      <input matInput type="email" formControlName="email" required style="color: white !important;">
      <mat-placeholder style="color:#fff">Email Address</mat-placeholder>

  </mat-form-field>
  <mat-form-field>
    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required style="color: white !important;">
    <mat-placeholder style="color:#fff">Password</mat-placeholder>

    <mat-icon matSuffix (click)="hide = !hide" style="color: white !important; cursor: pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
</mat-form-field>

    <div class="input-group d-flex justify-content-center">
      <button type="submit" *ngIf="!loginPending" [disabled]="loginFormGroup.invalid"
        style="width: 200px; margin-bottom: 3rem; color: #330F2E" class="glofoxBtn glofoxBtnCyan">
        Log In
      </button>
      <button *ngIf="loginPending" type="submit" [disabled]="loginFormGroup.invalid"
        style="width: 200px; margin-bottom: 3rem; color: #330F2E" class="glofoxBtn glofoxBtnCyan">
        <fa-icon [icon]="loadingIcon" [spin]="true"></fa-icon>
      </button>
    </div>
  </form>

  <div class="container" style="margin-top: 5rem" *ngIf="requestedPasswordReset">
    <div class="row justify-content-center">
      <div class="col-12 col-md-9 col-lg-6">
        <app-request-password-reset-email [allowCancel]="true" (cancelPasswordReset)="requestedPasswordReset = false"></app-request-password-reset-email>
      </div>
    </div>
</div>
  <a class="mt-2 mb-3 d-flex justify-content-center clickable forgotPasswordLink"  style="text-decoration: underline;" *ngIf="!requestedPasswordReset" (click)="requestPasswordResetForm()">
    Forgot your password?
</a>
  <!-- <div class="row" style="margin-top: 5rem" *ngIf="requestedPasswordReset">
    <div class="col-12  d-flex flex-column justify-content-center">
      <h4 id="requestPasswordH2" style="color: #fff; width: 100%;">Request a Password Reset Email</h4>
      <div id="resetEmailInput" >
        <div class="d-flex justify-content-between mt-3 mb-2">


        <div class="input-group mr-2 ">
          <input [(ngModel)]="emailForReset" type="email" placeholder="Email" />
        </div>
        <button  class="glofoxBtn glofoxBtnCyan ml-2" [disabled]="!invalidResetEmail()" style="width: 150px; padding: 0.3rem 0.2rem; margin-bottom: 0rem; font-size: .8rem; color: #330F2E" (click)="requestPasswordReset()" >Submit</button>
      </div>
      <div class="mt-4 mb-3 d-flex justify-content-center clickable forgotPasswordLink"  style=" text-decoration: underline;" (click)="cancelPasswordReset()">Cancel</div>
      </div>
      <div id="resetFeedback"></div>
    </div>
  </div> -->


</div>
