import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wistiaTitle'
})
export class WistiaTitlePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if (value.includes('&amp')) {
      return value.replace('&amp;', '&');
    } else {
      return value;
    }
    return null;
  }

}
