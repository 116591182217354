
<div mat-dialog-title>
    <h5>{{mode | titlecase}} Admin</h5>
    <hr class="mt-0 mb-0">
</div>
<div mat-dialog-content>
    <div class="container mt-1">
        <form [formGroup]="adminCreateForm"  (ngSubmit)="addAdmin()">
          <div>
            <mat-form-field>
              <input matInput placeholder="First Name" formControlName="firstName" />
              <mat-error *ngIf="
                  !adminCreateForm.get('firstName').valid &&
                  adminCreateForm.get('firstName').touched
                ">Please Enter First Name</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Last Name" formControlName="lastName" />
              <mat-error *ngIf="
                  !adminCreateForm.get('lastName').valid &&
                  adminCreateForm.get('lastName').touched
                ">Please Enter Last Name</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field class="example-full-width">
            <input matInput type="email" placeholder="Email Address" formControlName="email" />
            <mat-error *ngIf="adminCreateForm.get('email').hasError('unavailable')">Email address already exists!</mat-error>
            <mat-error *ngIf="
                !adminCreateForm.get('email').valid &&
                adminCreateForm.get('email').touched && !adminCreateForm.get('email').hasError('unavailable')
              ">Please enter a valid email address
            </mat-error>
          </mat-form-field>
          <div class="mb-2">
            <button class="mr-2" mat-raised-button (click)="onNoClick()" type="button">Cancel</button>
            <button *ngIf="!emailCheckPending" [disabled]="adminCreateForm.invalid" type="submit" mat-raised-button color="accent">
              Save
            </button>
            <button *ngIf="emailCheckPending" [disabled]="adminCreateForm.invalid" mat-raised-button color="accent">
              <fa-icon [icon]="faLoading" [spin]="true"></fa-icon>
            </button>
          </div>
        </form>
      </div>
      
</div>
<!-- <div mat-dialog-actions class="d-flex justify-content-center">
  <button class="btn btn-info mr-3" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-success" [mat-dialog-close]="{}" >Save</button>
</div> -->