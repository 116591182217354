import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Branch, Plan } from 'src/models/branch';
import { Observable, Observer } from 'rxjs';
import { Admin } from 'src/models/admin';
import { Step } from 'src/models/steps';
import { ActionList } from 'src/models/action-list';
import { log } from 'util';
import { Task } from 'src/models/task';
import { environment } from '../../../environments/environment';
import { Job } from 'src/models/job';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

   SERVER_URL = environment.SERVER_URL;

  branches: Branch[] = [];

  constructor(private http: HttpClient) { }

  getBranches(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches`);
  }

  getBranchesV2(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/v2/branches`);
  }

  getBranch(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${id}`);
  }

  getBranch2(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${id}`);
  }

  getBranchAdmins(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${id}/admins`);
  }

  createBranch(branch): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/new`, branch);
  }

  updateBranchStatus(id: string, body: any): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${id}/updateBranchStatus`, body);
  }

  createWebIntegration(id: string): Observable <any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${id}/createWebIntegration`);
  }

  getWebIntegration(webIntId: string): Observable <any> {
    return this.http.get(`${this.SERVER_URL}/api/webInts/${webIntId}`);
  }

  updateBranch(branchId: string, name: string, newBranchId: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/update`, {name, branchId: newBranchId});
  }


  deleteBranch(branch: Branch): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/branches/${branch._id}`);
  }

  registerAdmin(admin: Admin): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/register`, { email: admin.email, password: 'glofox_123', role: 'client' });
  }

  addAdminToBranch(branchId: string, admin: Admin): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/${branchId}/newAdmin`, {admin});
  }


  updateNote(id: string, note: string, send: boolean = false): Observable<any> {
    const managerNote = { seen: false, note };
    return this.http.patch(`${this.SERVER_URL}/api/branches/${id}/updateManagerNote`, {managerNote, send});
  }

  updateNoteAsSeen(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${id}/seenManagerNote`);
  }

  updateBranchStartDate(branchId: string, date: Date): Observable<any> {
    console.log('here', date);

    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/updateStartDate`, {date});
  }

  updateBranchGoLiveDate(branchId: string, date: Date): Observable<any> {
    console.log('here', date);
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/updateGoLiveDate`, {date});
  }

  updateJobStatus(branchId: string, jobItemId: string, status: boolean): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/joblist/${jobItemId}`, { status });
  }

  updateDisengagedStatus(branchId: string, disengaged: boolean): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/disengaged`, {disengaged});
  }

  updateFullyOnBoardedStatus(branchId: string, fullyOnBoarded: boolean): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/fullyOnBoarded`, {fullyOnBoarded});
  }

  dismissWelcomeMsg(branchId: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/dismissWelcomeMsg`, {});
  }

  dismissManagerNote(branchId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/branches/${branchId}/dismissManagerNote`);
  }

  updateLastOnlineAt(branchId: string): Observable<any> {
    const lastOnlineAt = new Date(Date.now());
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/updateLastOnline`, { lastOnlineAt });
  }

  updateOnboardingOptions(branchId: string, onboardingOption: string, action: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/v2/branches/${branchId}/updateOptions`, { onboardingOption,  action});
  }

  checkIfUnique(name: string, branchId: string): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/checkIfUnique`, { name, branchId });
  }

  transferOwnership(oldManagerId: string, newManagerId: string, branches: string[]): Observable<any> {
    const body = {
      oldManagerId,
      newManagerId,
      branches
    }
    return this.http.post(`${this.SERVER_URL}/api/branches/transferOwnership`, body);
  }

  toggleHideCompletedCards(branch: Branch): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branhces/${branch._id}/hideCompletedCards`, branch);
  }

  reviewJob(branch:Branch, job: Job, admin: Admin, rating: number): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branch._id}/reviewJob/${job._id}`, {admin, rating});
  }

  leaveAdditionalFeedback(branch:Branch, job: Job, admin: Admin, feedback: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branch._id}/leaveAdditionalFeedback/${job._id}`, {admin, feedback})
  }

  createAppUpsellBranch(branch: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/${branch.branchId}/upsell`, {branch});
  }

  search(query: string): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/branches/smb/search`, {query});
  }
}
