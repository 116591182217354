// import { ClientRequest } from 'http';
import { Branch } from './branch';

export class Admin {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  canBookCalls = true;
  branch: Branch;
  resetLink?: string;
  surveyLink?: string;
  external_survey_token: string;
  onboardingSurvey?: {
    survey_url: string,
    netPromoterScore: number,
    obmanager: string,
    has_submitted: boolean,
    date_submitted: Date,
    how_submitted: string,
    percent_complete_when_submitted: number,
    response: {
      netPromoterScore: number,
      rateGlofoxOnboardingProcess: number,
      rateOBM: number,
      expectationsScore: any,
      improvements: [],
      otherFeedBack: any,

    };

  };

  // get fullName() { return `${this.firstName} ${this.lastName}`; }
}

