<div class="p-2">
  <div class="row align-items-center mt-3 ">
    <div class="col-9">
      <mat-form-field appearance="fill">
        <mat-label>Filter by name...</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="My example studio" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill">
        <mat-label>Account Status</mat-label>
        <mat-select [formControl]="states" (selectionChange)="applyFilters($event)" multiple>
          <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table class="mt-4" style="width: 100%;" #table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let branch"> {{branch.name}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="width: 40px; padding-left: 8px; padding-right: 8px"> </th>
      <td mat-cell class="m-0" style="padding-left: 15px;" *matCellDef="let branch">
        <div [class]="getBranchStatus(branch)"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="hasLoggedIn">
      <th mat-header-cell *matHeaderCellDef>Has Logged In </th>
      <td mat-cell style="text-align: center; padding-left: 30px;" *matCellDef="let branch">
        <div [class]="(branch.lastOnlineAt !== undefined && branch.lastOnlineAt !== null) ? 'branchStatus branchLive': 'branchStatus branchDisengaged'"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> % Total </th>
      <td mat-cell style="text-align: center;" *matCellDef="let branch"> {{branch.percent_complete | number:'1.0-0'}}</td>
    </ng-container>

    <ng-container matColumnDef="progress-essential">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> % Essential </th>
      <td mat-cell style="text-align: center;" *matCellDef="let branch"> {{branch.percent_complete_essential ? branch.percent_complete_essential : 0 | number:'1.0-0'}}</td>
    </ng-container>


    <ng-container matColumnDef="webIntStatus">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Web Integration Status </th>
      <td mat-cell style="text-align: center;" *matCellDef="let branch ">
        <span *ngIf="branch.webIntegration && branch.webIntegration.integrationRequestSubmitted">
          {{branch.webIntegration.status | webIntStatus}}
        </span>
        <span *ngIf="branch.webIntegration && !branch.webIntegration.integrationRequestSubmitted">
          -
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="appStatus">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> App Status </th>
      <td mat-cell style="text-align: center;" *matCellDef="let branch ">
        <span *ngIf="branch.hasStandAloneApp && branch.standAloneApp.hasBeenSubmitted">
          {{branch.standAloneApp.status}}
        </span>
        <span *ngIf="branch.hasStandAloneApp && !branch.standAloneApp.hasBeenSubmitted">
          Pending Client Submission
        </span>
        <span *ngIf="!branch.hasStandAloneApp">
          -
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="goLiveDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Go Live </th>
      <td mat-cell style="text-align: right;" *matCellDef="let branch"> {{branch.goLiveDate | date:'MMM d '}}</td>
    </ng-container>

    <ng-container matColumnDef="disclaimer">
      <td mat-footer-cell *matFooterCellDef colspan="2">
        <span>No branches assigned yet.</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="clickable" [routerLink]="['/manager-dashboard/branches', row._id]" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <tr mat-footer-row *matFooterRowDef="['disclaimer']" [ngClass]="dataSource ? 'hide' : 'show' "></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>