<div id="loginFormWrapper" class=" loginWrapper container-fluid d-flex flex-column align-items-center justify-content-center"
  style="height: 100vh;">
  <div>
    <div id="branding" style="display: flex; align-items: center; flex-direction:column;
    " >
      <div id="logoWrapper" class="d-flex flex-direction-column  mr-2">
        <img id="logo" src="../../../../assets/images/glofox_login.png" alt="" srcset="" />
      </div>
    </div>
    <div *ngIf="tokenIsValid">

    <h4 class="resetMsg">Reset Your Password</h4>
    <form *ngIf="!attemptMade" id="loginForm" class="d-flex flex-column" [formGroup]="resetPasswordFG" (ngSubmit)="onFormSubmit()">

      <!-- PASSWORD FIELD -->
      <mat-form-field>
        <input matInput [type]="hidePW ? 'password' : 'text'" formControlName="password" required
          style="color: white !important;">
        <mat-placeholder style="color:#fff">Password</mat-placeholder>

        <mat-icon matSuffix (click)="hidePW = !hidePW" style="color: white !important; cursor: pointer">
          {{hidePW ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>

      <!-- CONFIRM PASSWORD FIELD -->
      <mat-form-field>
        <input matInput [type]="hideCPW ? 'password' : 'text'" formControlName="confirmPassword" required
          style="color: white !important;">
        <mat-placeholder style="color:#fff">Confirm Password</mat-placeholder>

        <mat-icon matSuffix (click)="hideCPW = !hideCPW" style="color: white !important; cursor: pointer">
          {{hideCPW ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>


      <div class="input-group d-flex justify-content-center">
        <button type="submit" [disabled]="resetPasswordFG.invalid || !checkPasswords(resetPasswordFG)"
          style="width: 200px; padding: 0.4em 2em; color: #330F2E" class="glofoxBtn glofoxBtnCyan">
          Reset Password
        </button>
      </div>
    </form>
  </div>
<div *ngIf="!tokenIsValid" style="text-align: center; color: #fff;   font-family: Soleil, sans-serif;">
  <h2 style="font-weight: 600; margin-top:1.5rem; margin-bottom:1.5rem;">Oops! Invalid Password Reset Token.</h2>
  <app-request-password-reset-email [allowCancel]="false" (cancelPasswordReset)="cancel()"></app-request-password-reset-email>
  <h6>Already set your password? <a class="loginLink clickable" (click)="redirectToLogin()">Login</a></h6>
</div>

    <div *ngIf="attemptMade">
      <div *ngIf="successfulReset" style="color: white; text-align: center;">
        <h6 class="mt-5">Password was reset successfully!</h6>
        <!-- <button class="btn btn-success mt-3" [routerLink]="['/']" routerLinkActive="router-link-active">Log In</button> -->
        <button mat-raised-button  class="mt-5" color="primary" [routerLink]="['/']" routerLinkActive="router-link-active">Log In</button>

      </div>

      <div style="color: white; text-align: center;" *ngIf="!successfulReset">
        <h6>Password reset failed!</h6>
        <p>{{feedbackMsg}}</p>
      </div>
    </div>
  </div>
</div>
