<!-- comment to be removed -->
<div class="container" *ngIf="branchCreateForm">
  <!-- <form (submit)="onAddForm(branchForm)" #branchForm="ngForm"> -->

  <form [formGroup]="branchCreateForm" (ngSubmit)="onAddForm()">
    <!-- <mat-card> -->
    <mat-form-field class="example-full-width">
      <input matInput type="text" name="studioName" formControlName="studioName" placeholder="Studio Name" />
      <mat-error *ngIf="branchCreateForm.get('studioName').hasError('unavailable')">That branch name already exists!</mat-error>
      <mat-error *ngIf="
          !branchCreateForm.get('studioName').valid &&
          branchCreateForm.get('studioName').touched && !branchCreateForm.get('studioName').hasError('unavailable')
        ">Please Enter Studio Name</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="text" placeholder="Branch ID" formControlName="branchID" name="branchID" />
      <mat-error *ngIf="branchCreateForm.get('branchID').hasError('unavailable')">That branch ID already exists!</mat-error>
      <mat-error *ngIf="
          !branchCreateForm.get('branchID').valid &&
          branchCreateForm.get('branchID').touched && !branchCreateForm.get('branchID').hasError('unavailable')
        ">Please Enter Branch ID</mat-error>
    </mat-form-field>
    <br />
    <div class="radioSection">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="plan"
        name="plan">
        <mat-radio-button class="example-radio-button" *ngFor="let option of options"  [value]="option">
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field>
      <mat-label>Track</mat-label>
      <mat-select formControlName="track">
        <!-- <mat-select-trigger>
          {{toppings.value ? toppings.value[0] : ''}}
          <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
            (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger> -->
        <mat-option value="5fb7d93bab877c1488b63e65">Standard SMB (V2)</mat-option>
        <mat-option value="5f02df42d00d0260fe27195d">Gympass Two Week</mat-option>
        <mat-option value="5f92b080c4e14301db3ce4e6">App Upsell</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" *ngIf="!branchCheckPending" type="submit" [disabled]="branchCreateForm.invalid" >
        Submit
      </button>
      <button *ngIf="branchCheckPending" mat-raised-button color="accent" id="uniqueCheckBTn" >
        <fa-icon [icon]="faLoading" [spin]="true"></fa-icon>
      </button>
    </div>
  </form>
  <!-- </mat-card> -->
</div>
