<mat-card class="banner welcome-message mb-2 " style="background-color: #fff">
  <!-- *ngIf="branch.percent_complete_essential < 10"  -->
  <h4 class="msgFrom mb-3 pl-3" style="display: inline; padding-bottom: 0.75rem; font-weight: bold">Welcome to
    Glofox </h4>
  <div class="mt-4 pl-3">
    <div class="welcome_txt pb-2">
      Hello {{admin.firstName}}, my name is {{branch.obmanager.firstName}} and I am going to be helping you
      get set up with Glofox.
    </div>
    <div class="welcome_txt"> We have created this portal to give you all the resources you need to get started with
      Glofox as
      quickly as possible.
    </div>
  </div>

  <div class="mt-3 pl-3 welcome_txt">
    On the right you can see two sections:
  </div>

  <div class="mt-3 pl-3">
    <div class="welcome_title">1. Essential Setup </div>
    <div class="welcome_txt">
      This section will guide you through all the important tasks you need to complete to get started with
      Glofox.
    </div>
  </div>

  <div class="mt-3 mb-3 pl-3">
    <div class="welcome_title">2. Advanced Setup</div> 
    <div class="welcome_txt">
      This section explains how to use what you have created in the Essential Setup. Learn how to sell memberships, manage bookings, and more!
    </div>
  </div>
</mat-card>