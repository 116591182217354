import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebIntegration } from 'src/models/web-integration';
import { Branch } from 'src/models/branch';

@Injectable({
  providedIn: 'root'
})
export class WebTeamService {

  SERVER_URL = environment.SERVER_URL;
  statusUpdated = new EventEmitter<number>();

  constructor(private http: HttpClient) { }

  getWebTeamMemberByEmail(email: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/webTeam/email/${email}`);
  }

  getUnassignedWebInts(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/webTeam/webInts/unassigned`);
  }

  getWebIntsForWTM(id: string ): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/webTeam/webInts/${id}`);

  }

  updateWebIntStatus(webIntegration: WebIntegration): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/webTeam/webInt/${webIntegration._id}/updateStatus`, {status: webIntegration.status});

  }

  getBranchesWithSMT(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/webInts/branches/hasSMT`);
  }

  getBranchesWhoNeverRequestedHelp(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/webInts/branches/self-integrating`);
  }


  takeWebInt(wtmId: string, webIntId: string): Observable<any> {
    // console.log('web team member ID' + wtmId + '' + 'webInt Id ' + webIntId);
    return this.http.get(`${this.SERVER_URL}/api/webTeam/${wtmId}/webIntegration/${webIntId}/take`);
  }

  sendWelcomeEmail( webIntId: string): Observable<any> {
      // console.log( 'webInt Id ' + webIntId);
      return this.http.get(`${this.SERVER_URL}/api/webTeam/sendFirstContact/${webIntId}/`);

    }

    updateWebIntQAStatus(webintId, body): Observable<any> {
      return this.http.post(`${this.SERVER_URL}/api/webInts/${webintId}/updateQAStatus`, body);
    }
}



