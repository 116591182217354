import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/modules/manager-portal/services/manager.service';

@Component({
	selector: 'app-new-manager-dialog',
	templateUrl: './new-manager-dialog.component.html',
	styleUrls: ['./new-manager-dialog.component.sass']
})
export class NewManagerDialogComponent implements OnInit {

	createManagerForm: FormGroup;

	constructor(
		private managerService: ManagerService,
		private toastr: ToastrService,
		public dialogRef: MatDialogRef<NewManagerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
		this.createManagerForm = new FormGroup({
			firstName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
			lastName: new FormControl(null, Validators.required),
			email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@abcfitness.com$')]),
			slackID: new FormControl(null, Validators.required),
			slackImg: new FormControl(null, Validators.required),
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	getSlackImage() {
		return `https://ca.slack-edge.com/E05KG0EFGUW-${this.createManagerForm.controls.slackID.value}-e701ea53f802-512`;
	}

	onSubmit() {
		this.managerService.createManagerV2(this.createManagerForm.value).subscribe((manager) => {
			this.dialogRef.close(manager);
			this.toastr.success(
				'They have received login instructions via slack',
				'Manager created!'
			);
		}, (err) => {
			this.toastr.error(err.error.message ?? '', 'Error creating manager');
		});
	}
}
