<div class="container-fluid mt-4 pb-4">
    <div class="mt-5">
        <div>
            <div class="row">
                <div class="col-12">
                    <app-admins-survey-response-data
                        *ngIf="adminSurveyData && smbOnboardingManagers && omSurveyAverageScores"
                        [adminSurveyData]="adminSurveyData" [smbOnboardingManagers]="smbOnboardingManagers"
                        (selectedManagerId)="filterByManager($event)" [omAdminSurveyData]="adminSurveyData"
                        [omSurveyAverageScores]="omSurveyAverageScores">
                    </app-admins-survey-response-data>
                </div>
            </div>
        </div>

    </div>
    <ng-template #loading>loading</ng-template>

</div>