import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-request-password-reset-email',
  templateUrl: './request-password-reset-email.component.html',
  styleUrls: ['./request-password-reset-email.component.sass']
})
export class RequestPasswordResetEmailComponent implements OnInit {

  emailForReset: String;

  emailFG: FormGroup;

  @Input()
  allowCancel: boolean;

  @Output()
  cancelPasswordReset: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.emailFG = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  invalidResetEmail(): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(this.emailForReset, re.test(this.emailForReset.toLowerCase()))
    return re.test(this.emailForReset.toLowerCase());
  }

  cancel() {
    this.cancelPasswordReset.emit(true);
  }

  requestPasswordReset() {
    let domain = document.URL.split('/')[2];
    this.authenticationService.requestPasswordResetLink(this.emailFG.controls.email.value.toLowerCase(), domain).subscribe(data => {
      console.log(data);
      if (data.status === 'success') {
        document.getElementById('resetEmailInput').style.display = 'none';
        document.getElementById('resetFeedback').innerHTML = `<div style="text-align: center; width: 100%; color: #fff">Email sent! Please check your inbox.</div>`
      } else {
        this.emailForReset = '';
        document.getElementById('resetFeedback').innerHTML = `<div style="text-align: center; color: #f45f5f; margin-top: .7rem;">${data.message}</div><div style="text-align: center; color: #f45f5f">Please try again.</div>`
      }
    });
  }

}
