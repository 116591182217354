<div class="container-fluid header mb-3" style="font-family: Soleil, sans-serif;" *ngIf="!loading && masterlist && masterlist.tracks">
    <div class="row d-flex align-items-center">
        <div class="col-2">
            <mat-form-field>
                <mat-label>Selected Track</mat-label>
                <mat-select [formControl]="selectedTrackControl">
                    <mat-option *ngFor="let track of masterlist.tracks" [value]="track">{{track.title}}</mat-option>
                </mat-select>
            </mat-form-field>

            
        </div>
        <div class="col-10 d-flex justify-content-between align-items-center" *ngIf="selectedTrack">
            <div>
                <h2 style="font-family: Soleil, sans-serif; font-weight: 700;">{{selectedTrack.title}}</h2>
                <small class="text-secondary">Track ID: {{selectedTrack._id}}</small>
            </div>
            <div>
                <h6 class="mb-2">Available Translations</h6>
                <div class="d-flex">
                    <div *ngFor="let trans of masterlist.availableTranslations" class="mr-4"> <mat-checkbox [checked]="trackHasTranslation(trans)" (change)="updateStageTranslations($event.checked, trans)">{{trans | language}}</mat-checkbox> </div>
                </div>
            </div>
            <div>
                <button mat-raised-button class="mr-2">Help</button>
                <button mat-raised-button class="mr-2" (click)="resetDemoBranch()" *ngIf="selectedTrack.demoBranch">Reset Demo Branch</button>
                <a mat-raised-button [routerLink]="['../branches', selectedTrack.demoBranch]" target="_blank" *ngIf="selectedTrack.demoBranch">View Demo Branch</a>
                <button mat-raised-button (click)="openDemoDialog()" *ngIf="!selectedTrack.demoBranch">Add Demo Branch</button>

            </div>
        </div>
    </div>
</div>

<div class="container-fluid mb-4" *ngIf="selectedTrack">
    <div class="row">
        <div class="col-3">
            <app-stage-list [track]="selectedTrack" (selectedStageChanged$)="changeSelectedStage($event)"></app-stage-list>
        </div>
        <div class="col-3">
            <div class="mb-3">
                <mat-button-toggle-group  style="width: 100%" #group="matButtonToggleGroup" value="jobs" aria-label="Font Style">
                    <mat-button-toggle  style="width: 50%" value="details">Details</mat-button-toggle>
                    <mat-button-toggle  style="width: 50%" value="jobs">Jobs</mat-button-toggle>
                    <mat-button-toggle  style="width: 50%" value="test">Review</mat-button-toggle>
                </mat-button-toggle-group>
            </div>


            <div *ngIf="group.value == 'jobs'">
                <app-job-list [stage]="selectedStage" (selectedJobChanged$)="changeSelectedJob($event)"></app-job-list>
            </div>



            <div  *ngIf="group.value == 'test'" >
                <div *ngIf="selectedStage.test">
                    <app-selected-test [test]="selectedStage.test" [(stage)]="selectedStage"></app-selected-test>
                </div>
                <div *ngIf="!selectedStage.test">
                    <app-new-test [stage]="selectedStage"></app-new-test>
                </div>
            </div>

            <div *ngIf="group.value == 'details'">
                <app-edit-stage [stage]="selectedStage" [track]="selectedTrack"></app-edit-stage>
            </div>


        </div>
        <div class="col-6">
            <div *ngIf="group.value == 'jobs'">
                <app-selected-job-details [job]="selectedJob" [categorisedOptions]="categorisedOptions" [options]="options" [stage]="selectedStage" (stageUpdated$)="stageUpdated($event)" [track]="selectedTrack"></app-selected-job-details>
            </div>

            <div  *ngIf="group.value == 'test'" >
                <div *ngIf="selectedStage.test && selectedStage.test._id">
                    <app-question-list [test]="selectedStage.test" [track]="selectedTrack"></app-question-list>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading">
    <app-loading></app-loading>
</div>