import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch } from 'src/models/branch';
import { OBManager } from 'src/models/ob-manager';
import { DialogData } from 'src/modules/client-portal/components/video-dialog/video-dialog.component';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { ManagerService } from 'src/modules/manager-portal/services/manager.service';

@Component({
  selector: 'app-reassign-om-dialog',
  templateUrl: './reassign-om-dialog.component.html',
  styleUrls: ['./reassign-om-dialog.component.sass']
})
export class ReassignOmDialogComponent implements OnInit {

  currentOM: OBManager;
  otherManagers: OBManager[] = [];
  loading = false;
  selectedOM: OBManager;
  branch: Branch;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<ReassignOmDialogComponent>, private managerService: ManagerService, private branchService: BranchService) {}

  ngOnInit(): void {    
    this.currentOM = this.data['currentOM'];
    this.branch = this.data['branch'];
    this.loading = true;
    this.managerService.getOtherManagers(this.currentOM).subscribe(res => {
      this.otherManagers = res['managers'];
      this.loading = false;
    });
  }

  selectOM(manager: OBManager) {
    this.selectedOM = manager;
  }

  reassignBranch() {
    this.branchService.transferOwnership(this.currentOM._id, this.selectedOM._id, [this.branch._id]).subscribe(res => {
      if (res.status === 'success') {
        this.dialogRef.close(this.selectedOM);
      }
    })
  }

}
