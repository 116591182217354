import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from 'src/app/services/survey.service';
import { Admin } from 'src/models/admin';
import { Branch } from 'src/models/branch';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-onboarding-experience-survey',
  templateUrl: './onboarding-experience-survey.component.html',
  styleUrls: ['./onboarding-experience-survey.component.sass'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', [animate(300)])
    ])
  ]
})
export class OnboardingExperienceSurveyComponent implements OnInit {
  @Input()
  branch: Branch;
  @Input()
  admin: Admin;



  score = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  scoreBoolean = ['Yes', 'No'];
  has_submitted = false;
  // tslint:disable-next-line: max-line-length
  improvements = ['More one to one meetings with my onboarding manager', 'Better resources and direction on onboarding portal', 'Would have liked to know more about Glofox before signing up', 'Would have liked to see more features in my Glofox dashboard', 'Better support from the web integrations team']
  how_submitted = '';
  surveyDetail: {
    has_submitted: boolean,
    obmanager: string,
    date_submitted: Date,
    how_submitted?: string,
    percent_complete_when_submitted: number,
    branchId: string,
    response: {
      netPromoterScore: any,
      rateGlofoxOnboardingProcess: any,
      rateOBM: any,
      expectationsScore: any,
      improvements: [],
      otherFeedBack: any,
    };
  };

  @Input()
  displaySurveyInput;

  showStage = true;
  displayViaStorage = false;

  @Output()
  displayStageHeaders = new EventEmitter<Boolean>();

  @Output()
  adminUpdated = new EventEmitter<Admin>();
  onboardingExperienceSurvey: FormGroup;

  @Output()
  submittedSurveyDetail = new EventEmitter<any>();



  constructor(private formBuilder: FormBuilder, private surveyService: SurveyService, private toastrService: ToastrService, private route: ActivatedRoute) { }



  ngOnInit(): void {

    let x = localStorage.getItem('displayForm');
    if (x) {
      this.displayViaStorage = true;
    } else {
      this.displaySurveyInput;
    }


    console.log("branch", this.branch.percent_complete)
    this.onboardingExperienceSurvey = this.formBuilder.group({
      netPromoterScore: ['', [Validators.required]],
      rateGlofoxOnboardingProcess: ['', [Validators.required]],
      rateOBM: ['', [Validators.required]],
      expectationsScore: ['', [Validators.required]],
      improvements: this.formBuilder.array([]),
      otherFeedBack: ['', [Validators.required]],
    });

  }


  onChange(event) {
    const improvements = <FormArray>this.onboardingExperienceSurvey.get('improvements') as FormArray;
    if (event.checked) {
      improvements.push(new FormControl(event.source.value));
    } else {
      const i = improvements.controls.findIndex(x => x.value === event.source.value);
      improvements.removeAt(i);
    }
  }


  saveForm() {
    if (this.onboardingExperienceSurvey.valid) {
      const netPromoterScore = this.onboardingExperienceSurvey.value.netPromoterScore;
      const rateGlofoxOnboardingProcess = this.onboardingExperienceSurvey.value.rateGlofoxOnboardingProcess;
      const rateOBM = this.onboardingExperienceSurvey.value.rateOBM;
      const expectationsScore = this.onboardingExperienceSurvey.value.expectationsScore;
      const improvements = this.onboardingExperienceSurvey.value.improvements;
      const otherFeedBack = this.onboardingExperienceSurvey.value.otherFeedBack;
      this.has_submitted = true;
      this.surveyDetail = {
        obmanager: this.branch.obmanager._id,
        has_submitted: this.has_submitted,
        branchId: this.branch.branchId,
        date_submitted: new Date(),
        how_submitted: this.how_submitted,
        percent_complete_when_submitted: this.branch.percent_complete,
        response: {
          netPromoterScore,
          rateGlofoxOnboardingProcess,
          rateOBM,
          expectationsScore,
          improvements,
          otherFeedBack
        }
      }
      console.log('FORM', this.surveyDetail);
      this.submittedSurveyDetail.emit(this.surveyDetail);
      this.showStage = !this.showStage;

    }
    else if (this.onboardingExperienceSurvey.invalid) {
      window.scrollTo({
        top: 175,
        behavior: 'smooth'
      });
      return;
    }


  }


}
