import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';

import { DialogData } from 'src/modules/client-portal/components/video-dialog/video-dialog.component';
import { ArticlesService } from 'src/app/services/articles.service';
import { Category } from 'src/models/category';
import { Article } from 'src/models/article';
import { Section } from 'src/models/section';

import { Observable } from 'rxjs';


@Component({
  selector: 'app-article-selector-dialog',
  templateUrl: './article-selector-dialog.component.html',
  styleUrls: ['./article-selector-dialog.component.sass']
})
export class ArticleSelectorDialogComponent implements OnInit {

  private categoriesCompleteList: Category[] = [];
  constructor(private articlesService: ArticlesService, public dialogRef: MatDialogRef<ArticleSelectorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  
  searchResults: Category[] = [];
  selectedCat: Category;
  selectedSect: Section;

  selectedArticles: Article[] = [];

  get
    categories(): Category[] {
    return this.searchResults.length ? this.searchResults : this.cats;
  }

  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  loading = false;
  noResultsFlag = false;
  searchQuery: string;

  cats: Category[] = [];
  sects: Section[] = [];
  arts: Article[] = [];



  ngOnInit() {
    // this.getAllArticles();

    this.selectedArticles = this.data['articles'];
          console.log('PASSED IN', this.data['articles']);

    if (!this.cats.length) {
      this.loading = true;
      this.articlesService.getCategories().subscribe(data => {
        this.cats = data.categories;
        console.log(this.cats);
        this.selectedCat = this.cats[0];
        
        this.articlesService.getSections().subscribe(datum => {
          this.sects = datum.sections;
          console.log('sects', this.sects);

          
          this.cats.forEach(cat => {
            cat.sections = [];
            cat.sections.push(...this.sects.filter(sect => sect.category_id === cat.id));
            console.log(cat.sections);
            this.selectedSect = this.selectedCat.sections[0];
          });

          this.getArticles().subscribe(_ => {
            // console.log('articles: ', _.articles);
          });

          
          

        });

        
      });





      // window.addEventListener('scroll', () => {
      //   const catList = document.getElementById('catList');
      //   if (catList) {
      //     if (window.scrollY > 365) {
      //       catList.classList.add('fixed');
      //       $('#catList').width($('#catListParent').width());
      //     } else {
      //       catList.classList.remove('fixed');
      //     }
      //   }
      // });

    }
  }

  onNoClick(): void {
    this.dialogRef.close(this.selectedArticles);
  }

  private getAllArticles() {
    this.loading = true;
    this.categoriesCompleteList = [];
    let allArticles: Article[] = [];
    const step = 90;
    let page = 1;
    if (this.categories.length < 250) {
      this.articlesService.getAllArticles().subscribe(data => {
        allArticles = allArticles.concat(data['articles']);
        if (data.next_page) {
          page = 2;
          this.articlesService.getAllArticles(page).subscribe(_ => {
            allArticles = allArticles.concat(_['articles']);
            if (_.next_page) {
              page = 3;
              this.articlesService.getAllArticles(page).subscribe(d => {
                allArticles = allArticles.concat(d['articles']);
                this.loading = false;
                this.categoriesCompleteList = this.articlesService.filterArticlesAndCategorise(allArticles);
              });
            }
          });
        }
      });
    }
  }

  searchArticles(query) {
    if (query.length > 1) {
      this.loading = true;
      this.searchQuery = query;
      this.noResultsFlag = false;
      this.articlesService.searchArticles(query).subscribe(data => {

        this.articlesService.getCategories().subscribe(catData => {
          const categories = catData.categories;
          this.articlesService.getSections().subscribe(sectionData => {
            const sections = sectionData.sections;
            console.log('sections: ', sections);
            data['results'].forEach((article: Article) => {
              console.log('article: ', article);
              const sect = sections.find((section: Section) => section.id === article.section_id);
              if (!sect.articles) {
                sect.articles = [];
              }
              const cat = categories.find(category => category.id === sect.category_id);
              sect.articles.push(article);
              if (!cat.sections) {
                cat.sections = [];
              }
              if (!cat.sections.includes(sect)) {
                cat.sections.push(sect);
              }
            });
          });
          this.searchResults = categories;
          this.noResultsFlag = this.searchResults.length === 0;
          this.loading = false;
        });
        // this.searchResults = this.articlesService.filterArticlesAndCategorise(data['results']);
      });
    }
  }

  getArticles(page: number = 1): Observable<any> {
    this.articlesService.getArticles(page).subscribe(data => {
      this.arts.push(...data.articles);
      if (data.next_page !== null) {
        return this.getArticles(page + 1);
      } else {
        this.cats.forEach(cat => {
          cat.sections.forEach(sect => {
            sect.articles = [];
            sect.articles.push(...this.arts.filter(art => art.section_id === sect.id));
            this.loading = false;
            // this.route.queryParams.subscribe(data => {
            //   const id = data.id;
            //   if (id) {
            //     setTimeout(() => {
            //       document.getElementById(id).scrollIntoView({
            //         behavior: 'auto'
            //       });
            //     }, 50);
            //   }
            // });
          });
        });
      }
    });
    return new Observable();
  }

  selectCat(category: Category) {
    this.selectedCat = category;
    this.selectedSect = this.selectedCat.sections[0];

  }

  selectSect(section: Section) {
    this.selectedSect = section;
  }
 
  selectArticle(article: Article) {
    if (this.selectedArticles.includes(article)) {
      this.selectedArticles = this.selectedArticles.filter(art => art.id !== article.id);
    } else {
      this.selectedArticles.push(article);
    }
  }

  isSelected(article: Article): boolean {
    return this.selectedArticles.find(a => article.id === a.id) !== undefined;
  }

}
