import { Component, Input, OnInit } from '@angular/core';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-leadcapture-integration',
  templateUrl: './leadcapture-integration.component.html',
  styleUrls: ['./leadcapture-integration.component.sass']
})
export class LeadcaptureIntegrationComponent implements OnInit {
  loading = true;

  @Input()
  branch: Branch;

  leadCapData: any;

  constructor() {

  }

  ngOnInit() {
    this.loading = false;

  }

  onFormSubmit(websiteIntegrationFG): void {
    
  }

}
