import { ActivatedRoute, Router, Params } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Admin } from 'src/models/admin';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OBManager } from 'src/models/ob-manager';
@Component({
  selector: 'app-admins-survey-response-data',
  templateUrl: './admins-survey-response-data.component.html',
  styleUrls: ['./admins-survey-response-data.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminsSurveyResponseDataComponent implements OnInit {


  @Input()
  smbOnboardingManagers: OBManager[] = [];

  @Input()
  adminSurveyData: any[] = [];

  @Input()
  _omSurveyAverageScores: any[] = [];

  dataSource: MatTableDataSource<Admin>;
  displayedColumns: string[] = ['name', 'branch', 'obmanager', 'nps', 'obhealthscore', 'dateSubmitted']
  expandedAdmin: Admin | null;

  selectedManager: OBManager = undefined;

  @Input()
  public set omAdminSurveyData(v: Admin[]) {
    this.adminSurveyData = v;
    this.dataSource = new MatTableDataSource(this.adminSurveyData.reverse());
  }

  public get omAdminSurveyData(): Admin[] {
    return this.adminSurveyData;
  }


  @Input()
  public set omSurveyAverageScores(v: any[]) {
    this._omSurveyAverageScores = v;

  }

  public get omSurveyAverageScores(): any[] {
    return this._omSurveyAverageScores;
  }

  @Output()
  selectedManagerId: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatTable, { static: false }) adminTable: MatTable<any>;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    if (this.adminSurveyData.length) {
      this.dataSource = new MatTableDataSource(this.adminSurveyData.reverse());
    }

  }

  getPercent(admin: Admin) {
    let nps = admin.onboardingSurvey.response.netPromoterScore;
    let obm = admin.onboardingSurvey.response.rateGlofoxOnboardingProcess;
    let obExp = admin.onboardingSurvey.response.rateOBM;
    let healthScore = (nps + obm + obExp) / 30 * 100;
    return Math.round(healthScore);
  }
  avgHealthScore(score) {
    let avgNps = score.avgNps;
    let avgOmScore = score.avgOmScore;
    let avgObPScore = score.avgObPScore
    let avgHealthScore = (avgNps + avgObPScore + avgOmScore) / 30 * 100;
    return Math.round(avgHealthScore)
  }

  calculateObDurationEst(element) {
    let branchStartDate = element.branch.startDate;
    let surveySubmitedDate = element.onboardingSurvey.date_submitted;
    branchStartDate = branchStartDate.split('T')[0];
    surveySubmitedDate = surveySubmitedDate.split('T')[0];
    const date1: any = new Date(branchStartDate);
    const date2: any = new Date(surveySubmitedDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;

  }
  applyFilters(data) {
    const managerId = data._id;
    this.selectedManager = data;
    this.selectedManagerId.emit(managerId);

  }



}
