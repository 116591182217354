import { Component, OnInit, Input } from '@angular/core';
import { ActionList } from 'src/models/action-list';

@Component({
  selector: 'app-client-actionlist-thumb',
  templateUrl: './client-actionlist-thumb.component.html',
  styleUrls: ['./client-actionlist-thumb.component.sass']
})
export class ClientActionlistThumbComponent implements OnInit {

  @Input()
  actionlist: ActionList;

  constructor() { }

  ngOnInit() {
  }

}
