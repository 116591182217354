<div class="container-fluid mt-4 pb-4">
    <div class="d-flex flex-row-reverse">
        <mat-button-toggle-group name="fontStyle" value="tracks" #viewMode="matButtonToggleGroup" aria-label="Font Style">
            <mat-button-toggle value="tracks">Tracks</mat-button-toggle>
            <mat-button-toggle value="admins">Admins</mat-button-toggle>
          </mat-button-toggle-group>
    </div>

    <div [ngSwitch]="viewMode.value">
        <div *ngSwitchCase="'admins'">
            <div class="container mt-2">
                <div class="admin-list" *ngIf="admins else loading">
                    <table mat-table [dataSource]="admins" #adminTable class="mat-elevation-z8" style="width: 100%;" multiTemplateDataRows>

                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

                          <div class="example-element-detail"
                          [@detailExpand]="element == expandedAdmin ? 'expanded' : 'collapsed'">
                          <ng-container  *ngIf="adminLoaded else adminLoading">

                            <mat-list class="mb-3" role="list" dense style="width: 60%">
                              <mat-list-item>
                                <div class="container-fluid">
                                  <div class="row">
                                    <div class="col-8 pl-0">
                                      <div style="white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;"><strong>Job</strong></div>
                                    </div>
                                    <div class="col-2">
                                      <div style="text-align:center"><strong >{{expandedAdmin ? expandedAdmin.firstName : ''}}'s Rating</strong></div>
                                    </div>
                                    <div class="col-2">
                                      <div style="text-align:center"><strong>Average Rating</strong></div>
                                    </div>
                                  </div> 
                                </div>
                              </mat-list-item>
                              <mat-list-item role="listitem" style="font-size: 13px; height: 25px;" *ngFor="let job of expandedAdmin.reviewedJobs">
                                <div class="container-fluid">
                                  <div class="row">
                                    <div class="col-8 pl-0">
                                      <div style="white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;">{{job.description}}</div>
                                    </div>
                                    <div class="col-2">
                                      <div style="text-align:center"> {{job.review.rating}}</div>
                                    </div>
                                    <div class="col-2">
                                      <div style="text-align:center">{{job.averageScore | number:'1.1-1'}}</div>
                                    </div>
                                  </div>
                                </div>
                              </mat-list-item>
                            </mat-list>
                            <div>
                              <div class="d-flex justify-content-end">
                                <button class="mt-3 mr-1" mat-raised-button (click)="deleteFeedbackForAdmin(expandedAdmin)">Clear Reviews</button>
                              </div>
                              <div class="d-flex justify-content-center align-items-center">
                                <ngx-charts-pie-chart [view]="[500, 300]" [scheme]="colorScheme" [results]="barChartData" [gradient]=false [legend]=false legendPosition="below" [labels]=true [doughnut]=false>
                                </ngx-charts-pie-chart>
                              </div>
                            </div>
                        

                          </ng-container>

                          <ng-template #adminLoading>
                            <div class="mt-3 mb-3" style="width: 100%;">
                              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>

                          </ng-template>
                    
                          </div>
                        </td>
                      </ng-container>
                      
                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
                        </ng-container>
                      
                        <!-- Branch Column -->
                        <ng-container matColumnDef="branch">
                          <th mat-header-cell *matHeaderCellDef> Branch </th>
                          <td mat-cell *matCellDef="let element"> {{element.branch.name}} </td>
                        </ng-container>
                      
                        <!-- nReviews Column -->
                        <ng-container matColumnDef="count">
                          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> # Reviews </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.count}} </td>
                        </ng-container>
                      
                        <!-- Average Score Column -->
                        <ng-container matColumnDef="averageScore">
                          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Avg. Score </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center;"> {{(element.averageScore / 3 * 100 | number:'1.1-1')}}%</td>
                        </ng-container>

    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedAdmin === row" class="example-element-row clickable"
                        (click)="expandedAdmin = expandedAdmin === row ? null : row"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                      </table>
                  </div>
            </div>
        </div>
        <div *ngSwitchCase="'tracks'">
            <div class="row">
                <div class="col-12">
                  <div class="review-list d-flex" style=" overflow-x: auto;" *ngIf="reviewedTracks else loading">
                    <mat-card class="m-1" style="min-width: 300px; cursor: pointer" *ngFor="let trackSummary of reviewedTracks" (click)="selectTrack(trackSummary)" [ngClass]="{'selectedTrack': trackSummary === selectedTrack}">
                      <mat-card-content>
                          <div class="d-flex align-items-center">
                              <div *ngIf="trackSummary === selectedTrack" class="spot mr-2"></div>
                              <h5>{{trackSummary.title}}</h5>
                          </div>
                        <small>Num of Reviews: {{trackSummary.nReviews}}</small>
                        <div class="mt-1"><strong>Track Rating: {{trackSummary.averageScore / 3 * 100 | number:'1.1-1'}}%</strong></div>
                        <mat-progress-bar class="mt-2" [value]="(trackSummary.averageScore/3) * 100"></mat-progress-bar>
                      </mat-card-content>
                    </mat-card>
                  </div>
            
            
                </div>
                <div class="col-4">
                  <div *ngIf="selectedTrack">
            
                    <div class="mt-3" *ngIf="selectedTrack.stages else loading">
            
                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let stageSummary of selectedTrack.stages; let isFirst = first" [expanded]="isFirst">
                          <mat-expansion-panel-header>
                            <mat-panel-title style="width: 70%">
                              <div style="width: 100%">
                                <div style="white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;">{{stageSummary.title}}</div>
                                <mat-progress-bar style="width: 100%;" class="mt-2" [value]="(stageSummary.averageScore/3) * 100"></mat-progress-bar>
            
                              </div>
                            </mat-panel-title>
                            <mat-panel-description>
                              <div style="margin:auto;">
                                <small>{{stageSummary.averageScore / 3 * 100 | number:'1.1-1'}}% ({{stageSummary.nReviews}})</small>
                              </div>
            
                            </mat-panel-description>
                          </mat-expansion-panel-header>
            
                          <div class="pl-4 pr-3 pb-3">
                            <div class="job" (click)="selectJob(job)" *ngFor="let job of stageSummary.jobs">
                              <div class="pb-2" *ngIf="job.reviews && job.reviews.length">
                                <div class="job_title" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                  {{job.description}}
                                </div>
                                <div>
                                  <small><strong>{{job.reviews.length}} reviews - {{getAverageScore(job) / 3 * 100 | number:'1.1-1'}}%</strong></small>
                                </div>
                              </div>
            
                            </div>
                          </div>
                        </mat-expansion-panel>
            
                      </mat-accordion>
                    </div>
                  </div>
                </div>
            
                <div class="col-8 mt-3" *ngIf="selectedTrack">
                  <div *ngIf="selectedJob else loading">
                    <mat-card>
                      <mat-card-content>
                          <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h3>{{selectedJob.description}}</h3>
                                <small>Job ID: {{selectedJob._id}}</small>
                            </div>
                            <div>
                                <button *ngIf="!viewJobMode" mat-raised-button (click)="viewJob()">View Job</button>
                                <button *ngIf="viewJobMode" mat-raised-button (click)="viewRatings()">View Ratings</button>
            
                            </div>
                        </div>
                        <div class="mt-1">
                            <mat-progress-bar class="mt-2" style="height: 30px; position: absolute; width: 97%;" [value]="(selectedJob['averageScore'] / 3) * 100"></mat-progress-bar>
                            <div style="position: relative; padding-top: 12px; padding-left: 1rem;">Rating: {{selectedJob['averageScore'] / 3 * 100 | number:'1.1-1'}}% |  {{selectedJob['nReviews']}} Reviews</div>
                        </div>                  
                   
            
                        <div class="container-fluid">
                            <div class="row" style="min-height: 375px" *ngIf="!viewJobMode">
                                <div class="col-6">
                                    <div class="mt-5">
                                        <div class="mb-2 p-3" *ngFor="let review of selectedJob.reviews" style="background-color: whitesmoke;">
                                          <div> From: <strong>{{review.admin['firstName']}} {{review.admin['lastName']}}</strong> at <strong>{{review.admin['branch']['name']}}</strong></div>
                                          <div>Score: {{review.rating}} / 3</div>
                                          <div>On: {{review.date_created | date}}</div>
                                          <div class="mt-1" *ngIf="review.feedback">Feedback: {{review.feedback}}</div>
                                        </div>
                                      </div>
                                </div>
                                <div class="col-6 d-flex justify-content-center">
            
                                        <ngx-charts-pie-chart  [scheme]="colorScheme" [results]="barChartData" [gradient]=false [legend]=false legendPosition="below" [labels]=true [doughnut]=false>
                                        </ngx-charts-pie-chart>
                       
                                </div>
                            </div>
            
                            <div *ngIf="viewJobMode" style="margin-top: 2rem;">
                                <div [innerHTML]="selectedJob.details" class="ck-content"></div>
                            </div>
                        </div>
            
                
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
        </div>
        
    </div>

 

  <ng-template #loading>loading</ng-template>

</div>
