import { Component, OnInit, Input } from '@angular/core';
import { Job } from 'src/models/job';
import { BranchService } from 'src/modules/manager-portal/services/branch.service';
import { AdminService } from '../../services/admin.service';
import { Media } from 'src/modules/videos/models/media';
import { Router } from '@angular/router';
import { Video } from 'src/modules/videos/models/video';
import { VideosService } from 'src/modules/videos/services/videos.service';

@Component({
  selector: 'app-job-thumb',
  templateUrl: './job-thumb.component.html',
  styleUrls: ['./job-thumb.component.sass']
})
export class JobThumbComponent implements OnInit {


  @Input()
  job: Job;

  @Input()
  deadline: Date;

  @Input()
  jobItem: any;

  @Input()
  branchId: string;

  constructor(private adminService: AdminService, private router: Router, private videoService: VideosService) { }

  ngOnInit() {
    // console.log(this.job.description, this.job.videoIds);

  }

  goToMedia(videoId: string) {
     this.router.navigate(['client-dashboard/videos'], { queryParams: { mediaId: videoId } });
  }

  getVideo(videoId: string): Video {
    // console.log('vidId', videoId, this.videoService.getVideos().find(vid => vid.id === videoId).name);
    const vid = this.videoService.getAllVideos().find(vid => vid.id === videoId);
    return vid;
  }

  isOverdue(deadline: Date): boolean {
    return new Date(Date.now()).getTime() > deadline.getTime();
  }

  goToModule(moduleToWatch: string) {
    this.router.navigate(['client-dashboard/videos'], { queryParams: { module: moduleToWatch }} );
  }

  updateStatus($event, jobItem): void {
    // console.log($event.checked);
    this.adminService.updateJobStatus(this.branchId, jobItem._id, $event.checked).subscribe(branch => {
      // this.branch.joblist = branch.joblist;
    });
  }

  goToKBArticle(articleId: string) {
    window.open(`https://support.glofox.com/hc/en-us/articles/${articleId}`)
  }

}
