
<div class="mb-2">
    <div class ="d-flex  mb-3">
        <h4 class="web-status mt-3 pl-3"><strong>Website Integration Status</strong></h4>
    </div>
        <div class="d-flex mb-3 pl-3 pr-3 mt-1 status_container">
          <p *ngIf="webIntegration.status === 0" class="step" > Your Website Integration request has been sent to the web team.</p>
          <p *ngIf="webIntegration.status === 1" class="step"> A Glofox web developer has successfully accessed your website and will begin building the integration ASAP. </p>
          <p *ngIf="webIntegration.status === 2" class="step"> A Glofox web developer is working on your website integration. </p>
          <p *ngIf="webIntegration.status === 3" class="step"> Your website integration is now complete! &nbsp;🎉 &nbsp; <br> You should recieve an email from a member of our team shortly.  </p>
  
        </div>
        <div class="icon_container mb-3">
          <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div class="progressNode">
              <div class="icon complete" *ngIf="webIntegration.status >= 0">
                <fa-icon style="color:#fff" [icon]="tickIcon"></fa-icon>
              </div>
              <div class="step">
                Queued
              </div>
            </div>
            <div class="progressNode">
              <div class="icon complete" *ngIf="webIntegration.status >= 1" >
                <fa-icon style="color:#fff" [icon]="tickIcon"></fa-icon>
              </div>
              <div class="icon incomplete" *ngIf="webIntegration.status < 1">
                2
              </div>
              <div class="step">
                Site Accessed
              </div>
            </div>
  
            <div class="progressNode">
              <div class="icon complete" *ngIf="webIntegration.status >= 2">
                <fa-icon style="color:#fff" [icon]="tickIcon"></fa-icon>
              </div>
              <div class="icon incomplete" *ngIf="webIntegration.status < 2">
                3
              </div>
              <div class="step">
               In Build
              </div>
            </div>
  
            <div class="progressNode">
              <div class="icon complete" *ngIf="webIntegration.status >= 3">
                <fa-icon style="color:#fff" [icon]="tickIcon"></fa-icon>
              </div>
              <div class="icon incomplete" *ngIf="webIntegration.status < 3">
                4
              </div>
              <div class="step">
                Complete
              </div>
            </div>
          </div>
          </div>
  </div>
  