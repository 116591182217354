<div class="loading" *ngIf="loading">
  <div>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<div *ngIf="!loading" class="container" style="height:100%;">
  <div class="row">
    <div class="col-12 intro-text">
      <h3>Adding Lead Capture to your website</h3>
      <hr />
      <!-- <p>
        The video below outlines how to build a Glofox Lead Capture form into your WordPress CMS (Content
        Management System).</p> -->
      <div class="page-txt">
        We have found that enabling new customers to quickly book a trial session and experience your studio has a big
        impact on membership sales.
      </div>
      <br>
      <strong class="mt-2 mb-2 page-txt">Lead capture enables you to:</strong>
      <br>
      <ul>
        <li> Capture the details of people who want to do trials in your studio
        </li>
        <li> Get them registered in Glofox</li>
        <li> Enable them to book into a trial class immediately as part of the workflow
        </li>
      </ul>


      <article class="mb-3 mt-5">

        <div class="row">
          <div class="col-6">
            <div id="hero-form">
              <div id="iframecontainer">
            <iframe class="glofox_iframe" id="glofox_1" src="https://staging.glofox.com/portal/#/branch/5e14670a7852a2001d3b81a3/lead-register/membership/5e1da6950d256a01bf2ab8c7/plan/1579001475206" width="100%" appiframeresizer scrolling="no"
            frameborder="0"></iframe>
            </div>
            </div>
          </div>
          <div class="col-6">
            <h4 class="mb-3">Test out the lead capture form!</h4>
            <strong>This is demo of a lead capture form for a free trial.</strong>
            <div>
              <div class="mt-2">
                The user flow:
              </div>
    
              <ul class="about-list">
                <li>
                  <strong>Page One</strong> - the user enters their basic information - at which point they will appear as a 'lead' on
                  the Leads tab of the Glofox Dashboard
                </li>
                <li><strong>Page Two</strong> - The user is prompted to book into a class.</li>
                <li>
                  <strong>Page Three </strong>- The user adds some further contact informtion (DOB, postcode, where they
                  heard about the studio)
                </li>
              </ul>
            </div>
          </div>
        </div>
    

   

      </article>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3">
      <h3>What is lead capture and how do I add it to my website?</h3>
      <hr>
    </div>
    <div style="width:100%;height:0px;position:relative;padding-bottom:53.125%;"><iframe src="https://streamable.com/e/yc3y7e" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>
  </div>


  <div class="row">
    <div class="col-12 mt-5">
      <h3>Generate your Lead Capture Code</h3>
      <hr>
    </div>
    <div *ngIf="branch.hasSMT" class="input-container col-12 ">
      <div class="mb-3 text-box">
        <div>
          To generate your Lead Capture code blocks, please enter a valid membership plan URL into the input field below.
          <!-- <br>Just click the <button mat-icon-button><mat-icon>file_copy</mat-icon></button> icon to copy the code. -->
        </div>
      </div>
      <div class="input-container mb-3">
        <form #websiteIntegrationFG="ngForm">
          <mat-card>
            <mat-form-field>
              <input matInput type="text" ngModel name="userInput"
                placeholder="Enter a valid Membership URL" required minlength="24"
                #formInput="ngModel" (keyup.enter)="onFormSubmit(websiteIntegrationFG)"
                (blur)="onFormSubmit(websiteIntegrationFG)"><br>
              <mat-error *ngIf="formInput.invalid">Please enter a Membership Plan Url</mat-error>
            </mat-form-field>
            <div class="pt-2 d-flex">
              <a mat-button class="mr-2" style= "color:#848584;" href="https://app.glofox.com/dashboard/#/memberships" target="_blank">Go to Memberships</a>
              <button type="reset" (click)="onFormSubmit(websiteIntegrationFG)" type="button" mat-raised-button
              style="background-color:#3AD8C4; color:#fff;"
              > Submit
              </button>
            </div>
          </mat-card>
        </form>
      </div>
    </div>
    <div *ngIf="branch.hasSMT && leadCapData">
      <app-lead-capture [potato]="leadCapData"></app-lead-capture>
    </div>
  </div>
  <!-- <div class="row">
    <div class="wistia_responsive_wrapper" style="height:100%;left:0; top:0;width:100%;"><iframe
        src="https://fast.wistia.net/embed/iframe/mkfx9grz0a?videoFoam=true" title="lead-capture Video"
        allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen
        mozallowfullscreen webkitallowfullscreen qualityMax oallowfullscreen msallowfullscreen width="100%"
        height="100%"></iframe>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
  </div> -->
  <div class="row mt-4" id="integration-tips">
    <div class="col-12 mt-3">
      <h3>Lead Capture Integration FAQ</h3>
      <hr>
    </div>

    <div class="col-12 mt-3 mb-3 p-3" style="background-color: #fff;">
      <h5 style="border-bottom: 2px solid #44D8C3; padding-bottom: .6rem;">Generating your Lead Capture Code</h5>
      <div class="row">
        <div class="col">
          <ol class="mt-2">
            <li class="mb-2">Open your Glofox dashboard and go to your <a
                href="https://app.glofox.com/dashboard/#/memberships" target="_blank">Memberships</a>. </li>
            <li>
              <div class="mt-3 page-txt">Click on 'Edit' on the membership you wish to associate with the Lead Capture form.</div>
              <img class="mt-3 mb-3" src="../../../../assets/images/lc_1.png" alt="">
            </li>
            <li>
              <div class="mt-3 page-txt">Next, choose the specific plan and copy the URL for that plan.</div>
              <img class="mt-3 mb-3" style="width: 100%; border: 1px solid #ccc;" src="../../../../assets/images/lc_2.png" alt="" srcset="">
            </li>
            <li>
              <div class="mt-3 page-txt">Now that we have the URL for the membership plan, we are going to generate the code for the form. Click on 'Website Code Snippets' in the menu on the left and enter the URL you copied into the input
                field.
              </div>
              <img class="pt-2 mb-3" style="width: 100%" src="../../../../assets/images/lc_3.png" alt="" srcset="">
            </li>
            <li>
              <div class="mt-3 page-txt">Thats it! You now have the code for your Lead Capture form, just copy and paste it into the content
                management system for your website.</div>
            </li>
            <img style="width: 100%; padding: .5rem;" src="../../../../assets/images/lc_4.png" alt="" srcset="">
          </ol>
        </div>
      </div>

    </div>

</div>


</div>