import { Component, OnInit } from '@angular/core';
import { OBManager } from 'src/models/ob-manager';

@Component({
  selector: 'app-manager-portal',
  templateUrl: './manager-portal.component.html',
  styleUrls: ['./manager-portal.component.sass']
})
export class ManagerPortalComponent implements OnInit {

  manager: OBManager;

  constructor() { }

  ngOnInit() {
  }

}
