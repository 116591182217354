import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagerService } from '../../services/manager.service';
import { OBManager } from 'src/models/ob-manager';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-other-manager',
  templateUrl: './other-manager.component.html',
  styleUrls: ['./other-manager.component.sass']
})
export class OtherManagerComponent implements OnInit {

  manager: OBManager;

  constructor(private route: ActivatedRoute, private managerService: ManagerService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params.id;
      this.managerService.getManagerById(id).subscribe(managerData => {
        this.manager = managerData;
        this.manager.branches = [];
        this.managerService.getBranchesForManager(id).subscribe(branchData => {
          let _branches = branchData;
         
          this.manager.branches = _branches;
        });
      });
    });

  }

}
