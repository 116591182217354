
<div *ngIf="videos && videos.length">
  <div *ngIf="selectedVideo">
    <div id="player" style="height:500px !important; width:100%;"></div>
  </div>
  <div id="controlBar">
    <button mat-stroked-button class="playerControl ml-5" [disabled]="!hasPrev()" (click)="playPrev()"><mat-icon color="">skip_previous</mat-icon></button>
    <div id="nowPlaying">{{"videos.now_playing" | translate}} <strong>{{selectedVideo.name}}</strong> </div>
    <button  mat-stroked-button  class="playerControl mr-5"  [disabled]="!hasNext()" (click)="playNext()"><mat-icon color="">skip_next</mat-icon></button>
  </div>
   <div class="videoRatingBar" fxLayout="row" *ngIf="translate.currentLang === 'en'">
        <div class="video_title">Was this video helpful?</div>
        <div class="btn_container">
          <div class="wrapper mr-2" *ngIf="selectedVideo" >
            <button mat-icon-button [disabled]="selectedVideo.hasLiked" (click)="submitVideoRating('liked')">
              <mat-icon [ngClass]="{'liked' : selectedVideo.hasLiked }" class="mr-2" >thumb_up</mat-icon>
            </button>
            <div *ngIf="selectedVideo.adminsWhoHaveLikedTheVideo">
              <!-- <div>{{selectedVideo.adminsWhoHaveLikedTheVideo.length}}</div> -->
            </div>
            

          </div>
          <div class="wrapper ml-2" *ngIf="selectedVideo" >
            <button mat-icon-button [disabled]="selectedVideo.hasDisliked" (click)="submitVideoRating('disliked')">
              <mat-icon [ngClass]="{'disliked' : selectedVideo.hasDisliked }" class="mr-2">thumb_down</mat-icon>
            </button>
            <div *ngIf="selectedVideo.adminsWhoHaveDislikedTheVideo">
              <!-- <div>{{selectedVideo.adminsWhoHaveDislikedTheVideo.length}}</div> -->

            </div>
          </div>
        </div>
    </div>  

    
</div>



