import { Component, OnInit, Input } from '@angular/core';
import { WebIntegration } from 'src/models/web-integration';
import { StandAloneApp } from 'src/models/standalone-app';
import { Branch } from 'src/models/branch';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.sass']
})
export class ProgressTrackerComponent implements OnInit {
  


  @Input()
  branch: Branch;
  tickIcon;

  
  constructor() { }

  ngOnInit() {
  }

}
