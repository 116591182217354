import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Admin } from 'src/models/admin';
import { Branch } from 'src/models/branch';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  readonly SERVER_URL = environment.SERVER_URL;


  constructor(private http: HttpClient) { }

  getAdminByEmail(email: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admins/email/${email}`);
  }
  getAdminById(adminId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admins/${adminId}`);
  }
  getAdminBySurveyToken(externalSurveyToken: string): Observable<any> {
    return this.http.get<{ admin: Admin, brandhId: string, obmanagerId: string  }>(`${this.SERVER_URL}/api/admin/surveyToken/${externalSurveyToken}`);
  }

  getBranchForAdmin(admin: Admin): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admins/${admin._id}/branch`);
  }
  getBranchForAdminSurvey(admin: Admin): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admin/survey/${admin._id}/branch`);
  }
  updateAdminBookCallStatus(admin: Admin): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/admins/${admin._id}/callBookStatus`, { canBookCalls: !admin.canBookCalls });
  }

  updateAdmin(admin: Admin): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/admins/${admin._id}/update`, { firstName: admin.firstName, lastName: admin.lastName, email: admin.email });
  }

  updateJobStatus(branchId: string, jobItemId: string, status: boolean): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/api/branches/${branchId}/joblist/${jobItemId}`, { status });
  }

  getPasswordResetLink(adminEmail: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/reset-password/user/${adminEmail}`);
  }

  generateExternalSurveyLink(adminId: string, onboardingMangerId: any ): Observable<any> {
    return this.http.put<{ message: string, admin: Admin }>(`${this.SERVER_URL}/api/admin/request-survey-url/`, { adminId, onboardingMangerId});
  }

  checkIfTokenIsValid(token: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admins/checkIfTokenIsValid/${token}`);
  }
  updatePassword(password: string, resetToken: string): Observable<any> {
    return this.http.patch(`${this.SERVER_URL}/reset-password`, { password, resetToken });
  }

  checkIfEmailIsUnique(email: string): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/admins/checkEmailIsUnique`, { email });
  }

  getAdminByResetToken(resetToken: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/admins/token/${resetToken}`);
  }
}
