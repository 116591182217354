import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Question } from 'src/models/question';
import { Test } from 'src/models/test';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  SERVER_URL = environment.SERVER_URL;
  constructor(private http: HttpClient) { }

  getTest(testId:string):Observable<any> {
    return this.http.get(`${this.SERVER_URL}/api/tests/${testId}`)
  }

  getTestForStage(stage_id: string): Observable<any> {
      return this.http.get(`${this.SERVER_URL}/api/tests/stage/${stage_id}`);
  }

  newTest(stage_id: string, name: string): Observable<any> {
    console.log(name);
    
    return this.http.post(`${this.SERVER_URL}/api/tests/stage/${stage_id}/newTest`, {name});
  }

  addQuestionToTest(test_id: string, question: Question): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/tests/${test_id}/newQuestion`, {question})
  }

  updateQuestion(question: Question): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/questions/${question._id}/update`, {question});
  }

  deleteQuestion(question: Question): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/tests/questions/${question._id}/delete`);
  }

  updateTestName(test: Test): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/tests/${test._id}/update`, {test});
  }

  saveQuestionTranslation(question: Question): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/questions/${question._id}/saveTranslation`, {translations: question.translations})
  }

  saveQuestionSpecificTranslation(question_id: string, translation: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/questions/${question_id}/updateSpecificTranslation`, {translation: translation});
  }

  deleteTest(test: Test): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/api/tests/${test._id}`);
  }

  updateQuestionOrder(test_id: string, questions: string[]): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/api/tests/${test_id}/reorderQuestions`, {questions});
  }
}
