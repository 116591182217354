<mat-toolbar fxLayout="row"  class="bg-hero-img">
 <a class="ml-2 mr-3" ><img src="../../../../assets/images/web-dash-logo.png" style="height:33px;"></a>
 <button mat-button *ngFor="let item of menuItems" 
     [fxShow]="item.showOnDesktop" 
     [fxShow.xs]="item.showOnMobile"
     [routerLink]="[item.url]"
     routerLinkActive="active"
     (click)="navigate(item)"
     [routerLinkActiveOptions]="{ exact: true }"
     [fxShow.sm]="item.showOnTablet" style="color:#fff">
     <mat-icon  
     style="color:#fff" 
     class="mr"
     [routerLinkActiveOptions]="{ exact: true }"
     routerLinkActive="active"
     >{{item.icon}}</mat-icon>
     {{item.label}}
</button>
 <span fxFlex></span>
 <div>
  <div *ngIf="manager">
    <div class="mr-2 nav-action" fxLayoutAlign="space-around center">
      <mat-icon class="mr" style="color:#fff">login</mat-icon>&nbsp;Logged in as: &nbsp;{{manager.firstName}} {{manager.lastName}}
    </div>
  </div>
 </div>
 <ng-container>
     <button mat-icon-button style="color:#fff" [matMenuTriggerFor]="dropMenu">
         <mat-icon>more_vert</mat-icon>
     </button>
     <mat-menu #dropMenu="matMenu">
         <ng-container *ngFor="let item of menuItems">
             <div [fxShow]="!item.showOnDesktop" 
                  [fxShow.sm]="!item.showOnTablet" 
                  [fxShow.xs]="!item.showOnMobile">
                 <button mat-menu-item 
                  [routerLink]="[item.url]"
                  routerLinkActive="active"
                  (click)="navigate(item)"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >
                     <mat-icon 
                     class="mr"
                     [routerLinkActiveOptions]="{ exact: true }"
                     routerLinkActive="active"  
                     >{{item.icon}}</mat-icon>
                     {{item.label}}
                 </button>
                 <mat-divider></mat-divider>
             </div>
         </ng-container>
     </mat-menu>
 </ng-container>
</mat-toolbar>