import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-client-guide-dialog',
  templateUrl: './client-guide-dialog.component.html',
  styleUrls: ['./client-guide-dialog.component.sass']
})
export class ClientGuideDialogComponent implements OnInit {


  
  constructor(
    public dialogRef: MatDialogRef<ClientGuideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    
    ngOnInit(): void {
      throw new Error("Method not implemented.");
    }
    
    onNoClick(): void {
    this.dialogRef.close();
  }
}
